import React, { useState, useEffect } from 'react'
import { Box, Typography, makeStyles, Grid, Slider, } from "@material-ui/core";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList

} from "recharts";

import httpInstance from '../../utils/instance'
import { GET_HOLDINGS, GET_FUND_HOUSING_DETAILS } from '../../utils/endpoints';
import { namespace, BASE_URL } from "../../environment";
import moment from "moment";
import { Skeleton } from '@mui/material';


const FundHouseDetails = ({ schemeCode, fundData }) => {
    const useStyles = makeStyles((theme) => ({

        // graphButtonInactive: {
        //   border: "none",
        //   background: "none",
        //   padding: "5px",
        //   borderRadius: "10px",
        //   fontSize: "0.7rem",
        //   cursor: "pointer",
        //   outline: "none",
        //   "&:hover": {
        //     background: "#6FDDB8",
        //     color: "white",
        //     padding: "5px",
        //   },
        //   "&:active": {
        //     background: "#6FDDB8",
        //     color: "white"
        //   }
        // },

        // activeButton: {
        //   background: "#6FDDB8",
        //   color: "white",
        //   padding: "5px",
        //   border: "none",
        //   borderRadius: "10px",
        //   fontSize: "0.7rem",
        //   cursor: "pointer",
        //   outline: "none",
        // },

        root: {
            marginTop: "1rem",
            padding: "0px 5rem 1rem 8rem",

        },
        mainBox: {
            borderRadius: "12px",
            // boxShadow: "0 0 8px -3px #444444",
            border: '1px solid #E4E4E4',
            // boxShadow: '0 0 8px -3px #444444',
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
            padding: "1rem",
        },
        title: {
            fontWeight: 600,
            // padding: "0px 8rem",
            fontSize: "1.2rem"
        },

        borderBox: {
            // border: "1px solid #E4E4E4",
            borderRadius: "5px",
            // padding: "1.5rem",
            // margin: "0 7rem",
            // width: "75%",
            '& .MuiSlider-valueLabel': {
                '& > *': {

                    backgroundColor: 'white',
                    color: "black"
                },
            }
        },
        ButtonInactive: {
            border: "none",
            background: "none",
            padding: "5px 20px",
            borderRadius: "20px",
            fontSize: "0.75rem",
            fontFamily: "inherit",
            marginRight: "1rem",
            marginLeft: "1rem",
            cursor: "pointer",
            outline: "none",
            "&:hover": {
                background: "#0E76BC",
                color: "white",

            },
            "&:active": {
                background: "#0E76BC",
                color: "white"
            }
        },

        activeButton: {
            background: "#0E76BC",
            color: "white",
            padding: "5px 20px",
            border: "none",
            borderRadius: "20px",
            fontSize: "0.75rem",
            fontFamily: "inherit",
            marginRight: "1rem",
            marginLeft: "1rem",
            cursor: "pointer",
            outline: "none",
        },
        activeYearButton: {
            background: "#0E76BC",
            color: "white",
            padding: "5px 20px",
            border: "none",
            borderRadius: "20px",
            fontSize: "0.7rem",
            fontWeight: 700,
            fontFamily: "inherit",
            marginRight: "1rem",
            marginLeft: "1rem",
            cursor: "pointer",
            outline: "none",

        },

        inactiveYearButton: {
            border: "1px solid #0E76BC ",
            background: "none",
            padding: "5px 20px",
            borderRadius: "20px",
            fontSize: "0.7rem",
            fontWeight: 700,
            fontFamily: "inherit",
            marginRight: "1rem",
            marginLeft: "1rem",
            color: "#0E76BC",
            cursor: "pointer",
            outline: "none",
            "&:hover": {
                background: "#0E76BC",
                color: "white",

            },
            "&:active": {
                background: "#0E76BC",
                color: "white"
            }
        },

        text: {
            fontSize: "0.8rem"
        },
        textMain: {
            fontSize: "1rem",
            fontWeight: 600,
        },
        textMainSmall: {
            fontSize: "0.7rem",
            fontWeight: 500,
            color: "#808080"
        },
        // title: {
        //   fontSize: "0.9rem",
        //   marginTop: "1rem"
        // },

        thumb: {
            background: "#F3C04F",
            width: "15px",
            height: "15px",
            boxShadow: 'none !important',
            ' &.Mui-focusVisible': {
                boxShadow: 'none !important',
            },
            '&.Mui-active': {
                boxShadow: 'none !important',
            },

        },
        mark: {
            background: "black"
        },
        rail: {
            background: "#FFE3A4",
            height: "5px"
        },
        track: {
            background: "#F3C04F",
            height: "5px"
        },
        rupees: {
            fontFamily: "Open Sans"
        },
        "@media (max-width: 600px)": {
            activeButton: {
                marginRight: "0rem",
            },
            ButtonInactive: {
                marginRight: "0rem",
            },
            activeYearButton: {
                marginRight: "0.5rem",
            },
            inactiveYearButton: {
                marginRight: "0.5rem",
            }
        },
        "@media (max-width: 766.99px)": {
            root: {
                padding: "0px 10px 0px 10px",
            }
        },
        firstLetterPlace: {
            fontSize: "1.5rem ",
            color: "#fff",
            borderRadius: "50%",
            padding: "0.5rem 1.1rem",
            backgroundColor: "#0E76BC",
            marginRight: "0.3rem",
        }
    }))
    const classes = useStyles();

    const [button, setButton] = useState('sip')
    const [yearButton, setYearButton] = useState('1Y')
    const [totalReturn, setTotalReturn] = useState()
    const [invest, setInvest] = useState(5000)

    const [sliderVlaueOnChangeEnd, setSliderVlaueOnChangeEnd] = useState(5000)
    const [calculatedThisFund, setCalculatedThisFund] = useState(null);
    const [calculatedProfit, setCalculatedProfit] = useState(null)
    const [fundHouseDetails, setFundHouseDetails] = useState()






    const get_holding = async () => {
        // console.log(schemeCode)
        // const formData={
        //     scheme_code:schemeCode,
        //     as_on:"",
        //     portfolio_type:''
        // }
        const httpConfig = {
            method: "GET",
            // tokenType: "user",
            test: 'test',
            // headers:{
            //   "Content-Type": "application/json",
            // },
            // data:formData,
        };
        try {
            const response = await httpInstance(`${GET_FUND_HOUSING_DETAILS}?fundPlanId=${schemeCode}`, httpConfig)
            setFundHouseDetails(response.data?.data)
            // console.log(response?.data?.data, 'response')
        }
        catch {

        }
    }
    // console.log(holdingData)
    useEffect(() => {
        get_holding()
    }, [])






    return (
        <div className={classes.root}>
            <Box className={classes.mainBox}>


                <Typography className={classes.title}>Fund Housing Details</Typography>
                <Box className={classes.borderBox} mt={2}>
                    {/* <Box px={{ xs: 2, md: 10 }} > */}


                    {/* <Box display='flex' justifyContent='center'> */}
                    {!fundHouseDetails ?


                        [1,].map((key, index) => (
                            <Box container key={key} mt={3} display='flex' alignItems='center'>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={3} style={{ display: 'flex', }}>
                                        {/* <img
                                                    // src={fundData?.img_url} 
                                                    // src={`${BASE_URL}${fundData?.amcLogoUrl}`}
                                                    src={`${BASE_URL}${ele?.amcLogoUrl}`}

                                                    height="50px" width="50px" style={{ padding: '5px' }} /> */}
                                        <Skeleton variant="circular" border width={70} height={50} />
                                        <Box display={"flex"} flexDirection="column" width={"100%"} pl={2}>
                                            <Skeleton variant="text" width="80%" />
                                            <Skeleton variant="text" width="50%" />

                                        </Box>



                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <Skeleton variant="text" width="30%" />
                                        <Skeleton variant="text" width="100%" />
                                        <Skeleton variant="text" width="90%" />
                                        <Skeleton variant="text" width="30%" />
                                        <Skeleton variant="text" width="80%" />
                                        {/* <Skeleton variant="text" width="70%" /> */}
                                    </Grid>
                                </Grid>
                            </Box>
                        ))
                        :
                        (fundHouseDetails)?.map((ele, i) => {
                            return (
                                <>
                                    <Box container key={ele.id} mt={1} display='flex' alignItems='center'>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={3} style={{ display: 'flex', alignItems: 'center' }}>
                                                <Box>
                                                    <Typography variant="body1" color="textPrimary" className={classes.firstLetterPlace}>{ele?.person?.personName?.charAt(0)}</Typography>
                                                </Box>
                                                <Box>
                                                    <Box item className={classes.textMain}>
                                                        {ele?.person?.personName}
                                                        {/* Add other details you want to display here */}
                                                    </Box>
                                                    <Box item className={classes.textMainSmall}>
                                                        Since {" "}
                                                        {moment(ele?.dateFrom).format("DD MMM YYYY")}
                                                        {/* Add other details you want to display here */}
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                <Box item display='flex' flexDirection='column'>
                                                    <Box className={classes.textMain}>
                                                        Education:
                                                    </Box>
                                                    <Typography variant="body1" color="textPrimary" className={classes.textMainSmall}>
                                                        {ele?.person?.education}
                                                    </Typography>
                                                    {/* Add other details you want to display here */}
                                                </Box>
                                                <Box item display='flex' flexDirection='column'>
                                                    <Box className={classes.textMain}>
                                                        Experience:
                                                    </Box>
                                                    <Typography variant="body1" color="textPrimary" className={classes.textMainSmall}>
                                                        {ele?.person?.experience}
                                                    </Typography>
                                                    {/* Add other details you want to display here */}
                                                </Box>
                                            </Grid>
                                        </Grid>

                                    </Box>
                                    <hr style={{ border: '1px solid #E4E4E4', }} />
                                </>
                            )
                        })
                    }

                    {/* </Box> */}
                    {/* <Box mt={3} display='flex' >
                    <Grid item xs={2} md={1} display='flex' alignItems='center' justifyContent='center'>
                        <img
                            src={`${BASE_URL}${fundData?.amc?.logoUrl}`}
                            height="auto" width="70px" />
                    </Grid>
                    <Grid item xs={4} display='flex' alignItems='center' justifyContent='center' flexDirection='row'>
                        <Typography>{fundData?.schemeName} </Typography>
                        <Box display='flex' flexDirection='row' alignItems='center'>
                            <Typography className={classes.subText}>As of {moment(fundData?.amc?.updatedAt).format("DD MMM YYYY")} </Typography>

                        </Box>


                    </Grid>
                    <Grid item xs={7} md={2}>
                        <Typography className={classes.text}>

                            {fundData?.amc?.address1}
                        </Typography>
                        <Typography className={classes.text}>

                            {fundData?.amc?.address2}
                        </Typography>
                        <Typography className={classes.text}>

                            {fundData?.amc?.address3}
                        </Typography>
                        <Typography className={classes.text}>{fundData?.amc?.phone}</Typography>
                    </Grid>

                </Box> */}

                    <Box mt={3} display='flex'>
                        <Grid container spacing={1}>
                            <Grid container xs={12} sm={6} md={5}>
                                <Grid item xs={12} sm={4} container alignItems='center' justifyContent='start'>
                                    {fundData ?
                                        <img src={`${BASE_URL}${fundData?.amc?.logoUrl}`} height="auto" width="70px" alt='logo' style={{borderRadius: '12px'}}/>
                                        :
                                        <Skeleton variant="rounded" border width={80} height={50} />
                                    }
                                </Grid>
                                <Grid item xs={12} sm={8} container style={{ alignItems: 'center', justifyContent: 'start' }}>
                                    <Box width={"100%"}>
                                        {fundData ? <>
                                            <Typography className={classes.textMain}>{fundData?.amc?.amcFullName}</Typography>
                                            
                                            <Typography className={classes.textMainSmall}>As of {moment(fundData?.amc?.updatedAt).format("DD MMM YYYY")}</Typography>
                                        </>
                                            :
                                            <>
                                                <Skeleton variant="text" width="90%" />
                                                <Skeleton variant="text" width="30%" />
                                            </>
                                        }
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={6} md={7} style={{ display: 'flex', alignItems: 'start', justifyContent: 'center', flexDirection: 'column' }}>

                                <Box display={'flex'} alignItems='center' width={"100%"}>
                                    {fundData ?
                                        <>
                                            <Typography className={classes.text} style={{ fontWeight: 600 }}>Address:</Typography>
                                            &nbsp;
                                            <Typography className={classes.text}> {fundData?.amc?.address1} {fundData?.amc?.address2} {fundData?.amc?.address3} {fundData?.amc?.pin}</Typography>
                                            {/* <Typography className={classes.text}>{fundData?.amc?.address2}</Typography>
                                <Typography className={classes.text}>{fundData?.amc?.address3} {fundData?.amc?.pin}</Typography> */}
                                        </>
                                        :
                                        < >
                                            <Skeleton variant="text" width="30%" />

                                            <Skeleton variant="text" width="70%" />
                                        </>

                                    }
                                </Box>
                                <Box display={'flex'} alignItems='center' width={"100%"} >
                                    {fundData ?
                                        <>
                                            <Typography className={classes.text} style={{ fontWeight: 600 }}>Phone:</Typography>
                                            &nbsp;
                                            <Typography className={classes.text}>{fundData?.amc?.phone}</Typography>
                                        </>
                                        :
                                        <>
                                            <Skeleton variant="text" width="20%" />
                                            <Skeleton variant="text" width="40%" />
                                        </>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box >
        </div >

    )
}

export default FundHouseDetails