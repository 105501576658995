import React, { useState } from "react";
import { Box, makeStyles, CircularProgress, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import axios from 'axios'
import { GET_ALL_SPECIFIC_AMC_LIST, GET_PORTFOLIO_FUND_CHANGEDDATA_DISTRIBUTE, GET_PORTFOLIO_FUND_DISTRIBUTE, SEARCH_FUNDS_BY_NAME } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { toast } from "react-toastify";
import SearchIcon from "@mui/icons-material/Search";
import PanCardData from "../../Dashboard/DigitalGold/GoldKyc/PanCardData";
import SimpleCard from "../../Dashboard/Funds/card";
import CustomToast from "../../../components/UI/CustomToast";

function ChangeAmountForm({ setModal, handleShowSuccess, selectedFund }) {

    const useStyles = makeStyles((theme) => ({
        root: {
            padding: "2rem 12rem",
            position: "fixed",
            top: 0,
            zIndex: 30,
            backdropFilter: "grayscale(0.2) blur(1px)"
        },
        innerContainer: {
            borderRadius: "1rem"
        },
        heading: {
            color: "#272727",
            fontSize: "1.2rem"
        },
        paragraph: {
            fontSize: "0.9rem",
            fontWeight: "normal",
            color: "#474747",
            lineHeight: "1.4rem"
        },
        label: {
            color: "#272727",
            fontSize: "0.8rem"
        },
        inputText: {
            // background: "#e6e6e6",
            fontSize: "0.9rem",
            border: "none",
            borderBottom: "1px solid #000",
            borderRadius: "0px",
            padding: "0.6rem 0.4rem",
            outline: 'none',
        },
        messageInputText: {
            fontSize: "0.82rem",
            background: "#e6e6e6",
            border: "none",
            borderRadius: "10px",
            padding: "0.6rem 0.4rem",
            height: "6rem",
            resize: "none",
            fontFamily: "inherit"

        },
        buttonHolder: {
            width: "50%",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "start",
            padding: "0px 0px 1rem 0px",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "0px 710px 0px 0px"
        },
        button: {
            border: "none",
            borderRadius: "40px",
            background: "#0E76BC",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            padding: "0.7rem 3rem",
            cursor: "pointer",
            width: 'fit-content',
        },
        borderedButton: {
            border: "1px solid #0E76BC",
            borderRadius: "40px",
            background: "#fff",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            color: "#0E76BC",
            padding: "0.5rem 3rem",
            cursor: "pointer"
        },
        closeBtn: {
            cursor: "pointer"
        },

        error: {
            color: "#cf1020",
            fontSize: "0.8rem"
        },

        mobileError: {
            display: "none"
        },

        circularProgress: {
            height: "10px",
            width: "10px"
        },
        rupeesSymbol: {
            position: 'relative',
            right: '2px',
            top: '26px',
            width: '10px',
            // marginRight: '5px',
            fontSize: '13px',
            fontFamily: "Open Sans",

        },

        "@media (max-width: 900px)": {
            button: {
                padding: "0.7rem 1.4rem",
            },
            borderedButton: {
                padding: "0.5rem 1.4rem",
            }
        },

        "@media (max-width: 468px)": {
            mobileError: {
                display: "block",
                color: "#cf1020",
                fontSize: "0.8rem",
                marginTop: "0px"
            },

            error: {
                display: "none"
            }
        },
        searchBox: {
            borderRadius: "30px",
            boxShadow:
                "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
            padding: "12px",
            "& input:focus": {
                outline: "none",
            },
            "& .box:focus-within": {
                border: "thin solid black",
            },
            //   "& input":{
            //  textAlign:"center",
            //     "& ::placeholder": {
            //       textAlign: "center",
            //     },
            //     " & :-moz-placeholder" :{
            //       textAlign: "center"
            //     }
            //   }
        },

        inputBox: {
            border: "none",
            width: "90%",
            padding: "0px 12px",
            fontSize: "0.9rem",
        },
        "@media (max-width:600px)": {
            searchBox: {
                padding: "8px",
            },
        },
    }));
    const classes = useStyles();
    //--------------------------------------------------------------------------------------------//
    const [loading, setLoading] = useState(false)

    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
        reset,
        setValue,
    } = useForm();
    const [amountError, setAmountError] = useState('')
    const [amountData, setAmountData] = useState('')

    const [allAmcIds, setAllAmcIds] = useState('')
    const [allFunds, setAllFunds] = useState([])
    const [loadingFunds, setLoadingFunds] = useState(false)


    const get_all_amc_ids = async () => {
        const httpConfig = {
            method: "GET",
            tokenType: "user",
            // test: 'test',
            headers: {
                "Content-Type": "application/json",
            },
        };

        try {

            const response = await httpInstance(`${GET_ALL_SPECIFIC_AMC_LIST}`, httpConfig);
            const amcIds = response.data.data.map((item) => item.amcId);
            setAllAmcIds(amcIds)
            // get_allFunds();
        } catch { }

    };


    const get_allSearchFunds = async (data) => {
        setLoadingFunds(true)
        const httpConfig = {
            method: "GET",
            // tokenType: "user",
            test: 'test',
            // headers: {
            //   "Content-Type": "application/json",
            // },
            // data: { ...formData, ...filterData },
            // data: { ...formData },
        };


        try {

            const response = await httpInstance(`${SEARCH_FUNDS_BY_NAME}?name=${data}&amcIds=${allAmcIds}`, httpConfig);
            setAllFunds(response.data?.data);
            setLoadingFunds(false)
            // console.log(response.data.data)
            //   if (response.data?.responseCount == 0) {
            //     setHasMore(false);
            //   } else {
            //     if (response.data?.responseCount >= page * 10) {
            //       setAllFunds([...allFunds, ...response.data?.data]);
            //       set_page();
            //       console.log('more is there ')
            //     } else {
            //   setAllFunds([...allFunds, ...response.data?.data]);
            //   set_page();
            // }

            //   }
        } catch {
            setLoadingFunds(false)

        }
    }


    const handleFormSubmit = async (data) => {
        console.log(data)
        setAmountData(data)

        get_all_amc_ids()
        // if (data.amount < selectedFund?.items.amount) {
        //   setAmountError("Amount have to be more than Current Amount")
        // } else {

        //   setLoading(true)
        //   setAmountError("")
        //   setLoading(true)
        //   const httpConfig = {
        //     method: "POST",
        //     tokenType: "user",
        //     headers: {
        //       "Content-Type": "application/json",
        //     },
        //     data: {
        //       isin: selectedFund?.items?.fund_isin,
        //       duration: selectedFund?.items?.number_of_installments,
        //       amount: data.amount,
        //       is_onetime: false
        //     },
        //     // data: formData
        //   };


        //   try {
        //     const responseReturn = await httpInstance(`${GET_PORTFOLIO_FUND_CHANGEDDATA_DISTRIBUTE}`, httpConfig);
        //     // const response = await httpInstance(`${POST_GOALS_CALCULATE_MY_FIRST_GOAL}`, httpConfig1);
        //     // console.log(response?.data, 'fadfadfadsfasdfasdfasdf')
        //     setLoading(false)

        //     setModal(responseReturn)
        //     handleShowSuccess()
        //     toast.success('Sucessfully upadated')

        //   } catch (error) {
        //     console.log(error.response.data?.error)
        //     setAmountError(error.response.data?.error)
        //   } finally {
        //     setLoading(false)

        //   }

        // }
    }

    // change fund data api call
    const handleFundChange = async (fund, items) => {

        // try {
        //     const responseReturn = await httpInstance(`${GET_PORTFOLIO_FUND_DISTRIBUTE}`, httpConfig);
        //     console.log(responseReturn);
        //     // const response = await httpInstance(`${POST_GOALS_CALCULATE_MY_FIRST_GOAL}`, httpConfig1); 
        //     // console.log(response?.data, 'fadfadfadsfasdfasdfasdf') 
        //     setLoading(false);
        // }
        // catch (error) {
        //     console.log(error.response.data?.error);
        //     setErrorMessage(error.response.data?.error);
        // }
        // finally { setLoading(false); }
    }
    console.log(selectedFund)

    const handle_search = (text) => {
        console.log(text.target.value)
        if (text.target.value?.length > 3) {
            get_allSearchFunds(text.target.value)
        }
    }

    const handleSetFund = async (data) => {
        // console.log(data)
        // setLoading(true)
        setAmountError("")
        setLoadingFunds(true)
        const httpConfig = {
            method: "POST",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                isin: data?.isinCode,
                duration: selectedFund?.items?.number_of_installments,
                amount: Number(amountData?.amount),
                is_onetime: false
            },
            // data: formData
        };


        try {
            const responseReturn = await httpInstance(`${GET_PORTFOLIO_FUND_CHANGEDDATA_DISTRIBUTE}`, httpConfig);
            // const response = await httpInstance(`${POST_GOALS_CALCULATE_MY_FIRST_GOAL}`, httpConfig1);
            // console.log(response?.data, 'fadfadfadsfasdfasdfasdf')
            setLoadingFunds(false)

            setModal(responseReturn)
            handleShowSuccess()
            // toast.success('Sucessfully upadated')
            CustomToast("Sucessfully upadated", "SUCCESS")


        } catch (error) {
            console.log(error?.response?.data?.error)
            // toast.error(error?.response?.data?.error)
            CustomToast(error?.response?.data?.error || 'Somthing went wrong', "FAILED")
        } finally {
            setLoadingFunds(false)

        }


    }


    return (

        <Box className={classes.innerContainer}>
            {amountData ?
                <>
                    <Box
                        display="flex"
                        alignItems={"center"}
                        justifyContent="space-between"
                        flexDirection="row"
                        width="100%"
                    >
                        <Box className={classes.paragraph}>
                            Search Funds
                        </Box>
                        <Box onClick={() => setModal(false)}>
                            <img
                                src="/images/Icon.svg"
                                height="16"
                                width="16"
                                className={classes.closeBtn}
                            />
                        </Box>
                    </Box>
                    <Box className={classes.searchBox} display="flex" mt={3}>
                        <SearchIcon style={{ opacity: 0.5 }} />
                        <input
                            className={classes.inputBox}
                            placeholder={"Search for Mutual Funds"}
                            onChange={handle_search}

                        //onFocus={(e) => e.target.placeholder = ''}
                        />
                    </Box>
                    <Box>
                        {!loadingFunds ?
                            allFunds?.length > 0 ?
                                <Box height={"400px"} overflow={'auto'} mt={3} >
                                    {allFunds.map((fund, index) => {
                                        return <SimpleCard key={index} fund={fund} access={'recomended'} handleSetFund={handleSetFund} />;
                                    })}
                                </Box>

                                :
                                <Box textAlign={'center'} display={'flex'} alignItems={'center'} justifyContent={'center'} mt={2} height={"100%"} maxHeight={'300px'} minHeight={'200px'}>
                                    No funds
                                </Box>

                            :
                            <Box textAlign={'center'} mt={2} height={"100%"} maxHeight={'300px'} minHeight={'200px'}>
                                <CircularProgress size="1rem" color="inherit" />
                            </Box>
                        }
                    </Box>
                </>
                :
                <>
                    <Box
                        display="flex"
                        alignItems={"center"}
                        justifyContent="space-between"
                        flexDirection="row"
                        width="100%"
                    >
                        <Box className={classes.paragraph}>
                            Change Amount
                        </Box>
                        <Box onClick={() => setModal(false)}>
                            <img
                                src="/images/Icon.svg"
                                height="16"
                                width="16"
                                className={classes.closeBtn}
                            />
                        </Box>
                    </Box>

                    <Box display="flex" flexDirection="row" mt={4} width="100%">
                        <Box width="100%">

                            {/* <Box className={classes.heading}>Feel free to write to us</Box> */}
                            <Box display="flex" flexDirection="column" mt={2} mr={3}>
                                <form onSubmit={handleSubmit(handleFormSubmit)}>
                                    <Box width="100%" display="flex" mb={1} sx={{ flexDirection: { xs: "column", md: "row" } }}>


                                        <Box display={"flex"} flexDirection="column" sx={{ width: { xs: "100%", md: "100%" } }}>
                                            <label className={classes.label}>Enter The Amount</label>
                                            <span className={classes.rupeesSymbol} style={{ marginRight: '5px' }}>₹</span>
                                            <input type="text"
                                                placeholder="Enter the amount"
                                                inputmode="numeric"
                                                className={classes.inputText}
                                                py={1}
                                                {...register("amount", {
                                                    required: true,
                                                    pattern: {
                                                        value: /^[0-9]+$/i,
                                                        message: "Only numbers are allowed",
                                                    },
                                                })}
                                                onChange={(e) => {
                                                    // Sanitize input: allow only numbers
                                                    const numericValue = e.target.value.replace(/[^0-9]/g, "");
                                                    setValue("amount", numericValue, { shouldValidate: true });
                                                }}
                                            />
                                            {errors.amount && (errors.amount.type === "required" ? (
                                                <p className={classes.error}>*Fill this field</p>
                                            ) : (
                                                <p className={classes.error}>{errors.amount.message}</p>
                                            ))}

                                        </Box>
                                    </Box>



                                    <Box display="flex" justifyContent='center' flexDirection={'column'} alignItems={'center'}>
                                        {amountError &&
                                            <p className={classes.error}>{amountError}</p>
                                        }
                                        <button
                                            className={classes.button}
                                            type="submit"
                                        >
                                            {loading ? <CircularProgress size="1rem" color="inherit" /> : "Proceed"}
                                        </button>
                                    </Box>
                                </form>
                            </Box>
                        </Box>
                    </Box>
                </>
            }

        </Box>

    );
}

export default ChangeAmountForm;
