import React, { useEffect, useState } from "react";
import { Box, Typography, makeStyles, Grid, Slider, CircularProgress } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { POST_GOALS_CALCULATE_TAX_SAVER } from "../../utils/endpoints";
import httpInstance from "../../utils/instance";
import { namespace, BASE_URL } from "../../environment";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList

} from "recharts";
import ShowRecommendedFunds from "./ShowRecommendedFunds";
import { formatAmount, formatAmountNumber, formatAmountNumberSign } from "../../components/common/FormatAmount";
import CountUp from "react-countup";


const ScrollingNumber = ({ value, time }) => {
    const [displayedValue, setDisplayedValue] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (displayedValue < value) {
                setDisplayedValue(prevValue => prevValue + 1);
            } else if (displayedValue > value) {
                setDisplayedValue(prevValue => prevValue - 1);
            }
        }, time); // Adjust speed of animation as needed

        return () => clearInterval(interval);
    }, [displayedValue, value]);
    console.log(value);

    return <span>{displayedValue}</span>;
};



const PrincipalProtection = ({ stateData }) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            marginBottom: '3rem',
            // padding: "10px",
            borderRadius: "12px",
            // boxShadow: "0 0 8px -3px #444444",
            border: '1px solid #E4E4E4',
            // boxShadow: '0 0 8px -3px #444444',
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
            padding: "1rem",
        },
        mainGrid: {
            padding: "30px 4rem 0px 8rem",
        },
        subTabtext: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
            fontWeight: 500,
        },
        textUnderline: {
            fontSize: "0.7rem",
            textDecoration: "underline",
            fontFamily: "inherit",
        },
        text: {
            fontSize: "0.8rem",
            fontWeight: 700,
        },
        subText: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
            fontWeight: 500,
            paddingLeft: '5px',
        },
        text1: {
            fontSize: "0.8rem",
        },
        text2: {
            fontSize: "1.2rem",
            fontFamily: "inherit",
            fontWeight: 500,
            // paddingLeft: '5px', 
        },
        text3: {
            fontSize: "1.2rem",
            fontFamily: "inherit",
            fontWeight: 700,
            // paddingLeft: '5px', 
        },
        text4: {
            fontSize: "1rem",
            fontWeight: 700,
        },
        textBold: {
            fontSize: "0.8rem",
            fontWeight: 700,
            marginBottom: "1rem",
        },

        // thumb: {
        //     background: "#bdbdbd",
        //     width: "20px",
        //     height: "20px",
        //     boxShadow: 'none !important',
        //     ' &.Mui-focusVisible': {
        //         boxShadow: 'none !important',
        //     },
        //     '&.Mui-active': {
        //         boxShadow: 'none !important',
        //     },
        //     // boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
        // },
        // mark: {
        //     background: "black"
        // },
        // rail: {
        //     background: "#EBEBEB",
        //     height: "10px",
        //     borderRadius: '10px',
        // },
        // track: {
        //     background: "#0E76BC",
        //     height: "10px",
        //     borderRadius: '10px',
        // },

        thumb: {
            background: "#fff",
            border: "3px solid #0E76BC",
            // marginBottom: '-12px',
            width: "16px",
            height: "16px",
            boxShadow: 'none !important',
            ' &.Mui-focusVisible': {
                boxShadow: 'none !important',
            },
            '&.Mui-active': {
                boxShadow: 'none !important',
            },
        },
        mark: {
            background: "black"
        },
        rail: {
            background: "#0E76BC",
            height: "7px",
            borderRadius: '5px',
        },
        track: {
            background: "#0E76BC",
            height: "7px",
            borderRadius: '5px',
        },
        valueLabel: {
            width: "16px",
            height: "16px",
            top: '-20px',
            // left: '-1px',
            backgroundColor: 'transparent',
            '& .PrivateValueLabel-circle-233': {
                backgroundColor: 'transparent',
            },
            '& .PrivateValueLabel-circle-229': {
                backgroundColor: 'transparent',
            },
            '& > *': {
                backgroundColor: 'transparent',
                // color: "black"
            },
            '& .MuiSlider-valueLabel': {
                '& > *': {
                    backgroundColor: 'transparent',
                    // color: "black"
                },
            }
        },

        borderBox: {
            // border: "1px solid #E4E4E4",
            // borderRadius: "5px",
            // padding: "1.5rem",
            // width: "75%",
            // '& .MuiSlider-valueLabel': {
            //     '& > *': {

            //         backgroundColor: 'white',
            //         color: "black"
            //     },
            // }
        },
        rupees: {
            fontFamily: "Open Sans"
        },
        primaryButton: {
            padding: "0.8rem 5rem",
            background: "#0E76BC",
            color: "#fff",
            fontSize: "0.8rem",
            fontWeight: 600,
            borderRadius: "45px",
            border: "none",
            cursor: "pointer",
            fontFamily: "inherit",
            border: '1px solid #0E76BC',
            '&:hover': {
                backgroundColor: "#fff",
                color: "#0E76BC",
            },
            '&:disabled': {
                opacity: '0.7',
                cursor: 'not-allowed',
            },

        },
        "@media (max-width: 767px)": {
            mainGrid: {
                padding: "0px 10px 0px 10px",
            },
        },
        dotGrey: {
            color: '#ccc',
            backgroundColor: '#ccc',
            height: '10px',
            width: '10px',
            fontSize: '1rem',
            marginRight: '3px',
            marginLeft: '10px',
            borderRadius: '5px'
        },
        dotBlue: {
            color: '#0E76BC',
            backgroundColor: '#0E76BC',
            height: '10px',
            width: '10px',
            fontSize: '1rem',
            marginRight: '3px',
            marginLeft: '10px',
            borderRadius: '5px'
        },
        mainGainBox: {
            marginBottom: '2rem',

        },
        subGainBox: {
            // padding: "10px",
            borderRadius: "12px",
            // boxShadow: "0 0 8px -3px #444444",
            border: '1px solid #E4E4E4',
            // boxShadow: '0 0 8px -3px #444444',
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
            padding: "1rem",
        },
        borderHr: {
            border: '1px solid #E4E4E4',
        },


        timeline: {
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
            position: "relative",
        },
        iconWrapper: {
            // position: "relative",
            zIndex: 1,
        },
        icon: {
            color: "#2196f3",
            fontSize: 24,
        },
        line: {
            borderLeft: "0.5px dashed #C4C4C4",
            height: "30px",
            marginTop: "-10px",
            marginLeft: "24px",
            zIndex: 0,
            // position: "relative",
        },
        text: {
            // marginBottom: 24,
            textAlign: "center",
            fontSize: '0.8rem',
            fontWeight: 500,
        },

    }));
    const classes = useStyles();
    const location = useLocation();

    const [goalsSelection, setGoalsSelection] = useState(null)
    const [sliderValueOnChangeEnd, setSliderValueOnChangeEnd] = useState(100000)
    const [sliderValueOnChangeEndYear, setSliderValueOnChangeEndYear] = useState(5)
    const [loading, setLoading] = useState(false)
    const [taxSavingData, setTaxSavingData] = useState(null);
    const [showBarchart, setShowBarChart] = useState(false);
    const [showRecommendedFundsPage, setShowRecommendedFundsPage] = useState(false)

    const navigate = useNavigate();


    // calculate my first corre post request 
    const CalculatePrincipalProtection = async () => {
        setShowBarChart(true)


        // setLoading(true)
        // const formData = {
        //     investment_per_month: sliderValueOnChangeEnd,
        //     years: sliderValueOnChangeEndYear,
        // }
        // const httpConfig1 = {
        //     method: "POST",
        //     tokenType: "user",
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        //     // params: { isin: fundData?.isinCode },
        //     data: formData
        // };

        // try {
        //     const response = await httpInstance(`${POST_GOALS_CALCULATE_TAX_SAVER}`, httpConfig1);
        //     // console.log(response?.data, 'fadfadfadsfasdfasdfasdf')

        //     setTaxSavingData(response?.data);
        //     setShowBarChart(true)
        // } catch {

        // } finally {
        //     setLoading(false)

        // }
    }


    //As the slider change in the area 
    useEffect(() => {
        if (showBarchart) {
            CalculatePrincipalProtection();
        }
    }, [sliderValueOnChangeEnd])



    //See recomendation function call after graph 
    const SeeRecommendedFunds = () => {
        setShowRecommendedFundsPage(true)
        handle_navigate(taxSavingData)

    }

    // handle navigation to the recommendation page 
    const handle_navigate = (data) => {
        // console.log(data?.data)
        navigate(`${namespace}${location.pathname}/recommendation`, {
            state: {
                data: {
                    value: sliderValueOnChangeEnd,
                    time: sliderValueOnChangeEndYear
                },
                goalData: stateData.data
            },
        });
    };


    const steps = [
        { icon: <img src={'/images/goalsRupeesPrincipal.svg'} height={'50px'} width={'50px'} className={classes.icon} />, text: "You invest a Lumpsum" },
        { icon: <img src={'/images/goalsGraphPrincipal.svg'} height={'50px'} width={'50px'} className={classes.icon} />, text: "It gets invested in liquid funds" },
        { icon: <img src={'/images/goalsNextPrincipal.svg'} height={'50px'} width={'50px'} className={classes.icon} />, text: "0.5% monthly transfers to index funds" },
    ];


    return (
        <>
            <Box
                display="flex"
                justifyContent="flex-start"
                px={{ xs: 2, md: 15 }}
                mt={5}
                className={classes.backButton}
            >
                {!showBarchart ?
                    <Link to={`${namespace}/goals`}>
                        <img src="/images/backIcon.svg" height="auto" width="20px" />
                    </Link>
                    :
                    <div onClick={() => {
                        setShowBarChart(false)
                    }} style={{ cursor: 'pointer' }}>
                        <img src="/images/backIcon.svg" height="auto" width="20px" />
                    </div>
                }
            </Box>
            <Box
                // display="flex"
                px={{ xs: 1, md: 15 }}
                pt={2}
            // style={{ borderBottom: "1px solid #E6E3E3" }}
            >
                <div className={classes.root}>
                    {!showBarchart ? (
                        <>
                            < Typography className={classes.text2} style={{ textAlign: 'start', paddingBottom: '10px' }}> Principal Protection </Typography>
                            {/* <ScrollingNumber value={7} /> */}
                            <Box>
                                < Typography className={classes.text3} style={{ marginTop: '5px' }}>Grow Your Investments, Protect Your Principal</Typography>

                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} md={6} style={{}}>
                                        < Typography className={classes.text1} style={{ marginTop: '5px' }}>What is the Amount You Would Like to Invest?</Typography>
                                        <Box mt={3} px={3} className={classes.borderBox}>
                                            <Slider
                                                classes={{
                                                    thumb: classes.thumb,
                                                    rail: classes.rail,
                                                    track: classes.track,
                                                    valueLabel: classes.valueLabel,
                                                    mark: classes.mark
                                                }}
                                                defaultValue={sliderValueOnChangeEnd || 100000}
                                                aria-label="Default"
                                                valueLabelDisplay="on"
                                                size="medium"
                                                valueLabelFormat={(x) => {
                                                    return (
                                                        <p style={{ paddingLeft: '10px', width: '50px', color: "black" }}><span className={classes.rupees}>₹</span>{(formatAmount(x))}</p>
                                                    )
                                                }}
                                                min={100000}
                                                max={50000000}
                                                // value={sliderValueOnChangeEnd}
                                                // onChange={(e, value) => return_calculator(value)}
                                                onChangeCommitted={(e, value) => {
                                                    // console.log("Slider value after release:", value);
                                                    // sliderAmountChangeIntheReturnCalculator(value)
                                                    setSliderValueOnChangeEnd(value)
                                                    // Add any other logic you want to perform after the slider is released
                                                }}
                                            />

                                        </Box>
                                        <Box px={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography
                                                variant="body2"
                                            //   onClick={() => setVal(MIN)}
                                            //   sx={{ cursor: 'pointer' }}
                                            >
                                                <span className={classes.rupees}>₹</span>

                                                {(formatAmount(100000)).toLocaleString('en-IN')}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                            //   onClick={() => setVal(MAX)}
                                            //   sx={{ cursor: 'pointer' }}
                                            >
                                                <span className={classes.rupees}>₹</span>

                                                {(formatAmount(50000000)).toLocaleString('en-IN')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6} style={{}}>
                                        < Typography className={classes.text1} style={{ marginTop: '5px' }}>Investment Duration?</Typography>

                                        <Box mt={3} px={3} className={classes.borderBox}>
                                            <Slider
                                                classes={{
                                                    thumb: classes.thumb,
                                                    rail: classes.rail,
                                                    track: classes.track,
                                                    valueLabel: classes.valueLabel,
                                                    mark: classes.mark
                                                }}
                                                defaultValue={sliderValueOnChangeEndYear || 5}
                                                aria-label="Default"
                                                valueLabelDisplay="on"
                                                size="medium"
                                                valueLabelFormat={(x) => {
                                                    return (
                                                        <p style={{ paddingLeft: '10px', color: "black" }}><span className={classes.rupees}></span>{x.toLocaleString('en-IN')}Y</p>
                                                    )
                                                }}
                                                min={5}
                                                max={15}
                                                // value={sliderValueOnChangeEndYear}
                                                // onChange={(e, value) => return_calculator(value)}
                                                onChangeCommitted={(e, value) => {
                                                    // console.log("Slider value after release:", value);
                                                    // sliderAmountChangeIntheReturnCalculator(value)
                                                    setSliderValueOnChangeEndYear(value)
                                                    // Add any other logic you want to perform after the slider is released
                                                }}
                                            />

                                        </Box>
                                        <Box px={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography
                                                variant="body2"
                                            //   onClick={() => setVal(MIN)}
                                            //   sx={{ cursor: 'pointer' }}
                                            >
                                                {/* <span className={classes.rupees}>₹</span> */}
                                                5Y
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                            //   onClick={() => setVal(MAX)}
                                            //   sx={{ cursor: 'pointer' }}
                                            >
                                                {/* <span className={classes.rupees}>₹</span> */}
                                                15Y
                                            </Typography>
                                        </Box>
                                    </Grid>

                                </Grid>
                                {/* < Typography className={classes.subText} style={{ marginTop: '5px' }}>Your profile is verified and these details are non-editable, in case any questions please contact support team.</Typography> */}

                            </Box>
                            <Box mt={4}>
                                <hr className={classes.borderHr} />
                                <Typography variant="h5" className={classes.text3}>
                                    Invest in Index Fund in systematic way

                                </Typography>
                                <Typography variant="h6" className={classes.text4}>
                                    Best used as

                                </Typography>
                                <Typography
                                    variant="body2"
                                    className={classes.text1}
                                >
                                    Long term large amount one time investment
                                </Typography>
                                <hr className={classes.borderHr} />
                                <Typography variant="h6" className={classes.text4}>
                                    How does it work?

                                </Typography>
                                <Typography
                                    variant="body2"
                                    className={classes.text1}
                                >
                                    Money is first transferred to top liquid funds. Their growth is invested in top index funds, every month.
                                </Typography>
                                <hr className={classes.borderHr} />
                                <Typography variant="h6" className={classes.text4}>
                                    On what date will the investment into equity go through?
                                </Typography>
                                <Typography
                                    variant="body2"
                                    className={classes.text1}
                                >
                                    Transfers into equity funds will happen after 28 days, on the closest 1st of the month.
                                </Typography>
                                <hr className={classes.borderHr} />
                                <Typography variant="h6" className={classes.text4}>
                                    How do you ensure my principal is protected when mutual funds are market linked?
                                </Typography>
                                <Typography
                                    variant="body2"
                                    className={classes.text1}
                                >
                                    We've created a powerful and easy-to-use investment plan that relies on historic behaviour of asset classes to achieve a very high probability of principal remaining protected.
                                    <br />
                                    Please note that the probability increases as time passes and we ask that you commit to this for a minimum 5 years! Both debt and equity mutual funds are market linked and we cannot guarantee the safety of principal.
                                </Typography>
                                <hr className={classes.borderHr} />
                            </Box>
                            <Box mt={3} display='flex' justifyContent='center' alignItems='center'>
                                <button onClick={CalculatePrincipalProtection} className={classes.primaryButton}
                                    disabled={loading}
                                >
                                    {loading ? (
                                        <CircularProgress size="1rem" color="inherit" />
                                    ) : (
                                        "Calculate Now"
                                    )}</button>
                            </Box>
                        </>
                    ) : (
                        <>
                            {showRecommendedFundsPage ? (
                                <ShowRecommendedFunds />

                            ) : (




                                <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                                    < Typography className={classes.text4} style={{ marginTop: '5px' }}>Invest Your Lumpsum Amount in Liquid Funds and Gradually Shift the Gains to Index Funds.</Typography>


                                    <Box className={classes.timeline} mt={3}>
                                        {steps.map((step, index) => (
                                            <Box key={index} >
                                                <Box display="flex" justifyContent={'start'} justalignItems="start" alignItems={'center'} >
                                                    <Box display="flex" justifyContent={'start'} justalignItems="start" alignItems={'center'} mr={2} className={classes.iconWrapper}>{step.icon}</Box>
                                                    <Typography className={classes.text}>{step.text}</Typography>
                                                </Box>
                                                {index < steps.length - 1 && <Box className={classes.line} />}

                                            </Box>

                                        ))}
                                    </Box>
                                    <Box mt={3} display='flex' justifyContent='center' alignItems='center'>
                                        <button onClick={SeeRecommendedFunds} className={classes.primaryButton}
                                            disabled={loading}
                                        >
                                            {loading ? (
                                                <CircularProgress size="1rem" color="inherit" />
                                            ) : (
                                                "See Recommended Funds"
                                            )}</button>
                                    </Box>
                                </Box>
                            )}
                        </>
                    )}

                </div >
            </Box >
        </>
    );
};

export default PrincipalProtection;
