import React from "react";
import { Box, makeStyles, Typography, Grid } from "@material-ui/core";
import { Skeleton } from "@mui/material";

const OrderListLoder = ({ orderData }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      borderRadius: "12px",
      // boxShadow: "0 0 8px -3px #444444",
      border: '1px solid #E4E4E4',
      // boxShadow: '0 0 8px -3px #444444',
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
      padding: "1rem",
      //borderRadius: "5px",
    },
    header: {
      fontSize: "0.85rem",
    },
    greyText: {
      fontSize: "0.85rem",
      color: "#C0C0C0",
    },
    successText: {
      color: "#6FDDB8",
      fontSize: "0.8rem",
      textAlign: "end",
      fontWeight: 500,
      paddingRight: "10px",
    },
    rupees: {
      fontFamily: "Open Sans",
    },
    failedText: {
      color: "#F27E45",
      fontSize: "0.8rem",
      textAlign: "end",
      fontWeight: 500,
      paddingRight: "10px",
    },
    pendingText: {
      color: "#F2AF1A",
      fontSize: "0.8rem",
      textAlign: "end",
      fontWeight: 500,
      paddingRight: "10px",
    },
  }));
  const classes = useStyles();



  return (
    <div>
      <Box className={classes.root} p={2} mt={1}>
        <Grid container>
          <Grid item xs={12} md={7} lg={7}>
            <Box display="flex" alignItems="center">
              <Skeleton variant="rounded" border width={40} height={40} />
              <Box pl={2} display="flex" alignItems="start" flexDirection={'column'} width={'100%'}>
                <Skeleton variant="text" width={'80%'} />

                <Skeleton variant="text" width={'80%'} />

              </Box>
              {/* <Box className={classes.type} style={{color:orderTypeColor[ele?.orderType],background:`${orderTypeColor[ele?.orderType]}80`}}>{orderTypeObj[ele?.orderType]}</Box> */}
            </Box>
          </Grid>

          <Grid item xs={6} lg={3} alignItems="center" display="flex">

            <Box display="flex" alignItems="center" justifyContent={'center'} flexDirection={'row'} width={'100%'}>
              <Box px={1}  width={'100%'}>
                <Skeleton variant="text" width={'80%'} />
                <Skeleton variant="text" width={'80%'} />
              </Box>
              <Box px={1}  width={'100%'}>
                <Skeleton variant="text" width={'80%'} />
                <Skeleton variant="text" width={'80%'} />

              </Box>
            </Box>
          </Grid>

          <Grid item xs={6} lg={2}>
            <Box display="flex" justifyContent="end" alignItems="end" width={'100%'}>
              <Skeleton variant="text" width={'70%'} />
            </Box>
            <Box display="flex" justifyContent="end" alignItems="end" width={'100%'}>

            <Skeleton variant="text" width={'50%'} />
            </Box>

          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default OrderListLoder;
