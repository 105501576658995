import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  makeStyles,
  CircularProgress,
  Slider,
  withStyles,
  styled,
  Tabs,
  Tab,
  Container,
} from "@material-ui/core";
import PropTypes from 'prop-types';
import { useNavigate, useParams } from "react-router-dom";
import ErrorMessage from "../../../components/Validation/ErrorMessage";
import { useSelector } from "react-redux";
import { namespace } from "../../../environment";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextInputField from "../../../components/signup/Input/TextInputField";
import Chart from 'chart.js/auto';
import { max } from "date-fns";


const Index = () => {
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography component={"span"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const useStyles = makeStyles((theme) => ({
    subTabtext: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
    },
    textUnderline: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
    },

    text: {
      textAlign: "center",
      marginTop: "10px",
    },
    heading: {
      fontSize: "1.2rem",
      textAlign: "center",
      fontWeight: 500,
      marginBottom: "20px",
    },
    inputBox: {
      background: "#E6E6E6",
      padding: "0.7rem 0.95rem",
      borderRadius: "10px",
      border: "none",
      width: "90.5%",
      fontFamily: "inherit",
      paddingLeft: '20px',

    },
    label: {
      fontSize: "1 rem",
      color: "#272727",
      fontWeight: 500,
      fontFamily: "Poppins"
    },
    text: {
      fontSize: "1.2 rem",
      color: "#272727",
      fontWeight: 500,
      fontFamily: "Poppins"
    },
    text1: {
      fontSize: "1.2 rem",
      color: "#272727",
      fontWeight: 600,
      fontFamily: "Poppins"
    },
    primaryButton: {
      padding: "0.8rem 3rem",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      '&:disabled': {
        opacity: '0.7',
        cursor: 'not-allowed',
      },
    },
    box: {
      maxWidth: '700px',
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    root: {
      "& .MuiTab-root": {
        fontFamily: "inherit",
        fontSize: "1rem",
        color: "black",
        opacity: 1,
        textTransform: "none",
        background: "none",
        minWidth: "auto",
      },
      "& .MuiTab-textColorInheri": {
        opacity: 0.9,
      },
    },

    activeTab: {
      "& .MuiTab-wrapper": {
        borderBottom: "2px solid #0E76BC",
        color: "#0E76BC",
        fontSize: "1rem",
      },
    },
    thumb: {
      background: "#0E76BC",
      width: "15px",
      height: "15px",
      boxShadow: 'none !important',
      ' &.Mui-focusVisible': {
        boxShadow: 'none !important',
      },
      '&.Mui-active': {
        boxShadow: 'none !important',
      },

    },
    mark: {
      background: "black"
    },
    rail: {
      background: "#bfbfbf",
      height: "5px"
    },
    track: {
      background: "#0E76BC",
      height: "5px"
    },
    rupees: {
      fontFamily: "Open Sans"
    },
    tab_box: {
      padding: "0px 4rem"
    },
    tab_style: {
      width: "100%",
      background: "white",
      color: "black",
      // borderBottom: '1px solid #E5E3E3',
      // "& .MuiTab-root": {
      //   fontFamily: "inherit",
      //   fontSize: "1rem",
      //   color: "black",
      //   opacity: 1,
      //   textTransform: "none",
      //   background: "none",
      //   minWidth: "auto",
      //   padding: "0px"
      // },
      // "& .MuiTab-textColorInheri": {
      //   opacity: 0.9
      // },
      // "& .MuiTabs-root": {
      //   height: "67px",
      //   minHeight: "42px",
      //   padding: "0px",
      // },
      "& .MuiTabs-flexContainer": {
        display: "flex",
        justifyContent: "center",
      },
      // "& .MuiTabs-scrollable": {
      //   overflowY: "clip"
      // }


    },
    tabWidth: {
      width: '25%',
      color: '#272727',
      fontSize: '15px',
      fontFamily: 'Poppins',
      fontWeight: '400',
    },
    activeTab: {
      "& .MuiTab-wrapper": {
        borderBottom: "2px solid #0E76BC",
        color: "#0E76BC",
        paddingBottom: "5px",

      }

    },

    inActiveTab: {
      "& .MuiTab-wrapper": {
        paddingBottom: "5px",

      }

    },
    "@media (max-width: 600px)": {
      tab_box: {
        padding: "20px"
      },
      activeTab: {
        "& .MuiTab-wrapper": {
          padding: "10px"
        }
      },
      inActiveTab: {
        "& .MuiTab-wrapper": {
          padding: "10px"
        }
      },
    }
  }));
  const classes = useStyles();

  const [error, setError] = useState("");
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");

  const { userStore } = useSelector((state) => state);
  const navigate = useNavigate();

  const { code } = useParams();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [chartData, setChartData] = useState({
    labels: ['Invested Amount', 'Est. Gains'],
    datasets: [{
      data: [0, 0],
      backgroundColor: ['#0E76BC', '#0ebc54'],
    }]
  });

  const [chartOptions] = useState({
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 60 // Thickness of the doughnut
  });

  useEffect(() => {
    const ctx = document.getElementById('myChart').getContext('2d');
    const myChart = new Chart(ctx, {
      type: 'doughnut',
      data: chartData,
      options: chartOptions
    });

    return () => {
      myChart.destroy();
    };
  }, [chartData, chartOptions]);

  const [calculatorType, setCalculatorType] = useState('sip');
  const [sipAmount, setSipAmount] = useState('10000');
  const [sipReturns, setSipReturns] = useState(12);
  const [sipYears, setSipYears] = useState(10);
  const [lumpsumInvestment, setLumpsumInvestment] = useState(100000);
  const [lumpsumReturn, setLumpsumReturn] = useState(12);
  const [lumpsumYears, setLumpsumYears] = useState(10);

  const switchCalculator = (type) => {
    setCalculatorType(type);
  };

  const updateSIPAmount = (value) => {
    setSipAmount(value);
  };

  const updateSIPReturns = (value) => {
    setSipReturns(value);
  };

  const updateSIPYears = (value) => {
    setSipYears(value);
  };

  const updateInvestment = (value) => {
    setLumpsumInvestment(value);
  };

  const updateReturn = (value) => {
    setLumpsumReturn(value);
  };

  const updateYears = (value) => {
    setLumpsumYears(value);
  };

  const formatIndianNumber = (number) => {
    // console.log("number", new Intl.NumberFormat('en-IN').format(number))
    return new Intl.NumberFormat('en-IN').format(number);
  };

  const updateResults = () => {
    if (value === 0) {
      const ratePerPeriod = sipReturns / 12 / 100;
      const totalPeriods = sipYears * 12;
      const sipInvested = sipAmount * totalPeriods;
      const sipGains = calculateSIPReturns(sipAmount, ratePerPeriod, totalPeriods) - sipInvested;
      const sipTotalValue = sipInvested + sipGains;

      setChartData({
        ...chartData,
        datasets: [{ data: [sipInvested, sipGains], backgroundColor: ['#0E76BC', '#0ebc54'] }]
      });

    } else {
      const ratePerPeriod = lumpsumReturn / 100;
      const totalPeriods = lumpsumYears;
      const lumpsumInvested = lumpsumInvestment;
      const lumpsumGains = calculateLumpsumReturns(lumpsumInvestment, ratePerPeriod, totalPeriods) - lumpsumInvested;
      const lumpsumTotalValue = lumpsumInvested + lumpsumGains;

      setChartData({
        ...chartData,
        datasets: [{ data: [lumpsumInvested, lumpsumGains], backgroundColor: ['#0E76BC', '#0ebc54'] }]
      });
    }
  };

  useEffect(() => {
    updateResults();
  }, [calculatorType, sipAmount, sipReturns, sipYears, lumpsumInvestment, lumpsumReturn, lumpsumYears]);

  const calculateSIPReturns = (principal, ratePerPeriod, totalPeriods) => {
    return principal * ((Math.pow(1 + ratePerPeriod, totalPeriods) - 1) / ratePerPeriod) * (1 + ratePerPeriod);
  };

  const calculateLumpsumReturns = (principal, ratePerPeriod, totalPeriods) => {
    return principal * Math.pow(1 + ratePerPeriod, totalPeriods);
  };





  return (
    <div>
      <Box display="flex" alignItems="start" px={{ xs: 1, md: 17 }} pt={2}>
        <Typography className={classes.subTabtext}>My Profile</Typography>

        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Typography className={classes.textUnderline}>Calculator</Typography>
        </Box>
      </Box>

      <Box sx={{ width: "100%" }}>
        <Box px={{ md: 15 }}>
          <div id="calculator" >
            <Box className="">
              <div
              // className={`col-xl-6 col-lg-6 col-md-12 col-sm-12`}
              >
                <Container className={classes.tab_box}>
                  {/* <label>
                    <input type="radio" name="calculatorType" value="sip" checked={calculatorType === 'sip'} onChange={() => switchCalculator('sip')} /> SIP
                  </label>
                  <label>
                    <input type="radio" name="calculatorType" value="lumpsum" checked={calculatorType === 'lumpsum'} onChange={() => switchCalculator('lumpsum')} /> Lumpsum
                  </label> */}
                  <div
                    className={classes.tab_style}
                  >


                    <Tabs indicatorColor=" " value={value} onChange={handleChange} variant="scrollable" allowScrollButtonsMobil={true} centered style={{ marginTop: '10px', marginBottom: '20px' }}>
                      <Tab label="Sip" {...a11yProps(0)} className={`${value == 0 ? classes.activeTab : classes.inActiveTab}`}
                      // icon={<img alt="Custom Avatar" src="/images/Graph.svg" height={25} style={{ margin: '0' }} />}
                      />
                      <Tab label="Lumpsum" {...a11yProps(1)} className={`${value == 1 ? classes.activeTab : classes.inActiveTab}`}
                      // icon={<img alt="Custom Avatar" src="/images/target 2.svg" height={25} style={{ margin: '0' }} />}
                      />
                    </Tabs>

                  </div>

                </Container>

                {value === 0 ? (
                  <div className="inputs" id="sipInputs">
                    <div className="slider-container">
                      <label
                        //  className="slider-label"
                        className={classes.label}
                      >Monthly SIP Amount: <span id="sipAmountLabel">₹ {formatIndianNumber(sipAmount)}</span></label>

                      {/* <input type="range" min="500" max="100000" step="500" class="slider" id="sipAmount" value={sipAmount}
                        onChange={(e) => updateSIPAmount(e.target.value)}
                      // onChange="updateSIPAmount()" 
                      /> */}
                      <Slider
                        classes={{
                          thumb: classes.thumb,
                          rail: classes.rail,
                          track: classes.track,
                          valueLabel: classes.valueLabel,
                          mark: classes.mark
                        }}
                        defaultValue={5000}
                        aria-label="Default"
                        // valueLabelDisplay="on"
                        size="medium"
                        min={500}
                        max={100000}
                        step={500}
                        value={sipAmount}
                        onChange={(e, value) => updateSIPAmount(value)}
                        aria-labelledby="sip-amount-slider"
                      />

                    </div>

                    <div className="slider-container">

                      <label
                        //  className="slider-label"
                        className={classes.label}
                      >Expected Returns (p.a): <span id="sipReturnsLabel">{sipReturns}%</span></label>

                      {/* <input type="range" min="1" max="30" className="slider" id="sipReturns"
                        // value="12"
                        value={sipReturns}
                        // oninput="updateSIPReturns()" 
                        onChange={(e) => updateSIPReturns(e.target.value)}
                      /> */}
                      <Slider
                        classes={{
                          thumb: classes.thumb,
                          rail: classes.rail,
                          track: classes.track,
                          valueLabel: classes.valueLabel,
                          mark: classes.mark
                        }}
                        defaultValue={20}
                        aria-label="Default"
                        // valueLabelDisplay="on"
                        size="medium"
                        // valueLabelFormat={(x) => {
                        //   return (
                        //     <p style={{ color: "black" }}><span className={classes.rupees}>₹</span>{x.toLocaleString('en-IN')}</p>
                        //   )
                        // }}
                        min={1}
                        max={30}
                        step={0.1}
                        value={sipReturns}
                        onChange={(e, value) => updateSIPReturns(value)}
                        aria-labelledby="sip-returns-slider"
                        color="#0E76BC"
                      />
                    </div>

                    <div className="slider-container">

                      <label
                        // className="slider-label"
                        className={classes.label}
                      >Time Period: <span id="sipYearsLabel">{sipYears} years</span></label>

                      {/* <input type="range" min="1" max="40" className="slider" id="sipYears"
                        // value="10" 
                        value={sipYears}
                        // oninput="updateSIPYears()" 
                        onChange={(e) => updateSIPYears(e.target.value)}

                      /> */}
                      <Slider
                        classes={{
                          thumb: classes.thumb,
                          rail: classes.rail,
                          track: classes.track,
                          valueLabel: classes.valueLabel,
                          mark: classes.mark
                        }}
                        defaultValue={10}
                        aria-label="Default"
                        // valueLabelDisplay="on"
                        size="medium"
                        min={1}
                        max={30}
                        step={1}
                        value={sipYears}
                        onChange={(e, value) => updateSIPYears(value)}
                        aria-labelledby="sip-years-slider"
                      />

                    </div>
                  </div>
                ) : (
                  <div className="inputs" id="lumpsumInputs">
                    <div className="slider-container">

                      <label
                        // className="slider-label"
                        className={classes.label}
                      >Total Investment: <span id="investmentLabel">₹ {formatIndianNumber(lumpsumInvestment)}</span></label>

                      {/* <input type="range" min="10000" max="1000000" step="5000" className="slider" id="investment"
                        // value="100000"
                        value={lumpsumInvestment}
                        //  oninput="updateInvestment()" 
                        onChange={(e) => updateInvestment(e.target.value)}
                      /> */}

                      <Slider
                        classes={{
                          thumb: classes.thumb,
                          rail: classes.rail,
                          track: classes.track,
                          valueLabel: classes.valueLabel,
                          mark: classes.mark
                        }}
                        defaultValue={10}
                        aria-label="Default"
                        // valueLabelDisplay="on"
                        size="medium"
                        min={10000}
                        max={1000000}
                        step={5000}
                        value={lumpsumInvestment}
                        onChange={(e, value) => updateInvestment(value)}
                        aria-labelledby="lumpsum-return-slider"
                      />
                    </div>

                    <div className="slider-container">

                      <label
                        // className="slider-label"
                        className={classes.label}
                      >Expected Returns (p.a): <span id="returnLabel">{lumpsumReturn}%</span></label>

                      {/* <input
                       type="range" 
                       min="1" 
                       max="30" 
                       className="slider" 
                       id="return"
                        // value="12"
                        value={lumpsumReturn}
                        //  oninput="updateReturn()" 
                        onChange={(e) => updateReturn(e.target.value)}

                      /> */}
                      <Slider
                        classes={{
                          thumb: classes.thumb,
                          rail: classes.rail,
                          track: classes.track,
                          valueLabel: classes.valueLabel,
                          mark: classes.mark
                        }}
                        defaultValue={10}
                        aria-label="Default"
                        // valueLabelDisplay="on"
                        size="medium"
                        min={1}
                        max={30}
                        step={0.1}
                        value={lumpsumReturn}
                        onChange={(e, value) => updateReturn(value)}
                        aria-labelledby="lumpsum-return-slider"
                      />

                    </div>

                    <div className="slider-container">

                      <label
                        // className="slider-label"
                        className={classes.label}
                      >Time Period: <span id="yearsLabel">{lumpsumYears} years</span></label>

                      {/* <input type="range" min="1" max="40" className="slider" id="years"
                        // value="10"
                        value={lumpsumYears}
                        // oninput="updateYears()"
                        onChange={(e) => updateYears(e.target.value)}
                      /> */}
                      <Slider
                        classes={{
                          thumb: classes.thumb,
                          rail: classes.rail,
                          track: classes.track,
                          valueLabel: classes.valueLabel,
                          mark: classes.mark
                        }}
                        id="years"
                        defaultValue={10}
                        aria-label="Default"
                        // valueLabelDisplay="on"
                        size="medium"
                        min={1}
                        max={40}
                        step={1}
                        value={lumpsumYears}
                        onChange={(e, value) => updateYears(value)}
                        aria-labelledby="years"
                      />
                    </div>
                  </div>
                )}
                <Box display={'flex'} justifyContent={'center'} width={'100%'} padding={'10px 0px'}>

                  <div className={classes.box}>
                    <Box padding={'0px 7px'} textAlign={'center'}>
                      <p className={classes.text}>Invested Amount:</p>
                      {/* <p id="investedAmount">Rs.  */}
                      <p className={classes.text1} id="investedAmount">₹ {chartData?.datasets?.[0]?.data?.[0] !== undefined ? formatIndianNumber(Number(chartData?.datasets?.[0]?.data?.[0]).toFixed(0)) : '0.00'}</p>

                      {/* {formatIndianNumber(chartData?.datasets[0]?.data[0]?.toFixed(0))} */}
                      {/* {console.log("chart data", chartData)} */}
                      {/* </p> */}
                    </Box>

                    <Box padding={'0px 7px'} textAlign={'center'}>
                      <p className={classes.text} >Est. Gains:</p>
                      <p className={classes.text1} id="estimatedGains">₹ {formatIndianNumber(chartData?.datasets[0]?.data[1]?.toFixed(0))}</p>
                    </Box>

                    <Box padding={'0px 7px'} textAlign={'center'}>
                      <p className={classes.text}>Total Value:</p>
                      <p className={classes.text1} id="totalValue">₹ {/* {formatIndianNumber((chartData?.datasets[0]?.data[0] + chartData?.datasets[0]?.data[1])?.toFixed(0))}
                     */}
                        {formatIndianNumber(((Number(chartData?.datasets?.[0]?.data?.[0]) || 0) + (Number(chartData?.datasets?.[0]?.data?.[1]) || 0)).toFixed(0))}

                      </p>
                    </Box>
                  </div>
                </Box>

              </div>
              <div className={`col-xl-6 col-lg-6 col-md-12 col-sm-12`}>
                <div id="chart-container">
                  <canvas id="myChart"></canvas>
                </div>
              </div>
            </Box>
          </div>
        </Box>
      </Box>

    </div>
  );
};

export default Index;
