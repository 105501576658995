import {
  Box,
  MenuItem,
  Select,
  FormControl,
  createTheme,
  ThemeProvider,
  capitalize,
  CircularProgress,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useEffect, useState } from "react";
import TextInputField from "../Input/TextInputField";
import TitleLayoult from "../TitleLayoult";
import { useStyles } from "../styles";
import { useForm, Controller } from "react-hook-form";
import ErrorMessage from "../../Validation/ErrorMessage";
import {
  GET_ALL_WEALTH_SOURCE,
  GET_ALL_COUNTRIES,
  ADD_FATCA_DETAILS,
  GET_FATCA_DETAILS,
} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";
import { namespace } from "../../../environment";
import CustomToast from "../../UI/CustomToast";
import StepRedirector from "../Common/StepRedirector";
import StatusApiData from "../Common/StatusApiData";

function FatcaDetails() {
  const classes = useStyles();

  const defaultValues = {
    income: "",
    taxAnotherCountry: "",
    taxCountry: "",
    country: "",
    typeOfTax: "",
    taxNumber: "",
    SourceIncome: "",
    OccupationType: "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({ defaultValues });

  const [searchParams, setSearchParams] = useSearchParams();
  const steps = searchParams.get("stepcount");
  
  const navigate = useNavigate();

  const [sourceIncome, setSourceIncome] = useState([
    { id: 1, type: 'Upto 1 lakh', value: 'upto_1lakh' },
    { id: 2, type: 'Above 1 lakh upto 5 lakh', value: 'above_1lakh_upto_5lakh' },
    { id: 3, type: 'Above 5 lakh upto 10 lakh', value: 'above_5lakh_upto_10lakh' },
    { id: 4, type: 'Above 10 lakh upto 25 lakh', value: 'above_10lakh_upto_25lakh' },
    { id: 5, type: 'Above 25 lakh upto 1 cr', value: 'above_25lakh_upto_1cr' },
    { id: 6, type: 'Above 1cr', value: 'above_1cr' },
  ]);
  const [occupationType, setOccupationType] = useState([
    // { id: 1, type: 'Business', value: 'business' },
    // { id: 2, type: 'Professional', value: 'professional' },
    // { id: 3, type: 'Self employed', value: 'self employed', },
    // { id: 4, type: 'Retired', value: 'retired' },
    // { id: 5, type: 'Housewife', value: 'housewife' },
    // { id: 6, type: 'Student', value: 'housewife' },
    // { id: 7, type: 'Public sector', value: 'public sector' },
    // { id: 8, type: 'Private sector', value: 'private sector' },
    // { id: 9, type: 'Government sector', value: 'government sector' },
    // { id: 10, type: 'Others', value: 'others' }
    { id: 1, type: 'business', value: 'business' },
    { id: 2, type: 'professional', value: 'professional' },
    { id: 3, type: 'self employed', value: 'self_employed' },
    { id: 4, type: 'retired', value: 'retired' },
    { id: 5, type: 'housewife', value: 'housewife' },
    { id: 6, type: 'student', value: 'student' },
    { id: 7, type: 'public sector', value: 'public_sector' },
    { id: 8, type: 'private sector', value: 'private_sector' },
    { id: 9, type: 'government sector', value: 'government_sector' },
    { id: 10, type: 'others', value: 'others' }
  ])

  const [countries, setCountries] = useState([]);
  const [taxAnotherCountry, setTaxAnotherCountry] = useState(false);
  const [fatcaData, setFatcData] = useState('')
  const [isComplient, setIsComplient] = useState(false);
  const [error, setError] = useState();
  const [apiError, setApiError] = useState(null);
  const [loading, setLoading] = useState(false);


  const theme = createTheme({
    overrides: {
      MuiInputBase: {
        input: {
          padding: "8px 10px",
        },
      },
      MuiSelect: {
        select: {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
  });

  const resetData = (data) => {
    reset({
      // income: data.grossAnnualIncome,
      // taxAnotherCountry: data.haveAnotherTaxResidence ? "Yes" : "No",
      // taxCountry: data.taxResidencyCountry?.id,
      // country: data.countryOfBirth.id,
      // typeOfTax: data.typeOfTaxIdentification,
      // taxNumber: data.taxIdentificationNum,
      // SourceIncome: data.fatcaSourceOfWealth?.id,

      OccupationType: data?.occupation,
      SourceIncome: data?.annual_income,
      taxAnotherCountry: data?.nationality,
    });
    // setTaxAnotherCountry(data.nationality);
  };

  useEffect(async () => {
    if (localStorage.getItem("compliant")) {
      setIsComplient(localStorage.getItem("compliant"))
    }
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // const [incomesource, countries] = await Promise.all([
      //   httpInstance(`${GET_ALL_WEALTH_SOURCE}`, httpConfig),
      //   // httpInstance(`${GET_ALL_COUNTRIES}`, httpConfig),
      // ]);

      // setSourceIncome(incomesource?.data?.data);
      // setCountries(countries?.data?.data);
      const fatca = await httpInstance(`${GET_FATCA_DETAILS}`, httpConfig);
      resetData(fatca?.data?.data);
      setIsComplient(fatca.data.data?.is_kyc_compliant);
      setFatcData(fatca?.data?.data)
      const complient = await StatusApiData()
      // setIsComplient(complient.data?.data?.isKycCompliant);
      const dataFinal = StepRedirector(complient.data.data, steps) // secutiry redirection
      if (dataFinal) {
        navigate({
          pathname: `${namespace}/dashboard/mutualfund`,
          // search: `?${createSearchParams({ stepcount: "2" })}`,
        });
      }
    } catch (error) { }
  }, []);

  const handletaxAnotherCountry = (e) => {
    // if (e.target.value == "Yes") {
    //   setTaxAnotherCountry(true);
    // } else {
    //   setTaxAnotherCountry(false);
    // }
  };

  const submit_fatca_details = async (data) => {
    // console.log(data)
    setLoading(true)
    setError(false)
    setApiError(null)
    // const formData = {
    //   grossAnnualIncome: data.income,
    //   haveAnotherTaxResidence: data.taxAnotherCountry === "Yes" ? true : false,
    //   taxResidencyCountryId: data.taxCountry ? data.taxCountry : null,
    //   countryOfBirthId: data.country,
    //   typeOfTaxIdentification: data.typeOfTax ? data.typeOfTax : null,
    //   taxIdentificationNum: data.taxNumber ? data.taxNumber : null,
    //   fatcaSourceOfWealthId: data.SourceIncome,
    // };
    const formData = {
      user_id: localStorage.getItem("user_id"),
      annual_income: data.SourceIncome,
      occupation: data.OccupationType,
      nationality: data.taxAnotherCountry,
    }

    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };
    console.log(httpConfig)

    try {
      await httpInstance(`${ADD_FATCA_DETAILS}`, httpConfig);
      // navigate({
      //   pathname: `${namespace}/signIn`,
      //   search: `?${createSearchParams({ stepcount: "8" })}`,
      // });
      navigate({
        pathname: `${namespace}/onBoarding`,
        search: `?${createSearchParams(isComplient === true ? { stepcount: "5" } : { stepcount: "12" })}`,
      });
      setLoading(false)
      CustomToast(fatcaData?.annual_income ? "Updated Occupation Details" : "Added Occupation Detials", "SUCCESS")
    } catch (error) {
      CustomToast(error.response.data.error, "FAILED")
      setApiError(error?.response?.data?.error)
      setError(true)
      setLoading(false)
    }
  };

  const handleback = () => {
    // navigate({
    //   pathname: `${namespace}/signIn`,
    //   search: `?${createSearchParams({ stepcount: "3" })}`,
    // });
    navigate({
      pathname: `${namespace}/onBoarding`,
      search: `?${createSearchParams(
        isComplient ? { stepcount: "3" } : { stepcount: "10" }
      )}`,
    });
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="flex-start"
        px={20}
        className={classes.backButton}
        onClick={handleback}
      >
        <img src="/images/backIcon.svg" />
      </Box>
      <Box display={"flex"} justifyContent="center">
        <Box alignItems="flex-start" flexDirection={"column"}>
          <Box sx={{ width: "100%" }}>
            <TitleLayoult
              // title="Fatca Details"
              title="Add Occupation Details"
              description="Please confirm the PAN details that displayed below"
            // description="Please enter your fatca details "
            />
          </Box>
          <Box>
            <form onSubmit={handleSubmit(submit_fatca_details)}>

              <ThemeProvider theme={theme}>
                <Box
                  my={2}
                  //display="flex"
                  alignItems={"flex-start"}
                  justifyContent="flex-start"
                  flexDirection="column"
                  width={"100%"}
                >
                  <label className={classes.label}>Occupation Type</label>
                  <Box width={"100%"} className={classes.Select}>
                    <FormControl fullWidth className={classes.Select}>
                      <Controller
                        control={control}
                        name={`OccupationType`}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            disableUnderline
                            className={classes.selectItem}
                            style={{ textTransform: 'capitalize' }}
                            IconComponent={KeyboardArrowDownIcon}
                            inputProps={{
                              style: {
                                textTransform: "capitalize",
                              },
                            }}
                            MenuProps={{
                              style: {
                                top: 5,
                                minWidth: 200,
                              },
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            {occupationType &&
                              occupationType.map((ele) => {
                                return (
                                  <MenuItem
                                    key={ele.id}
                                    className={classes.menuItem}
                                    style={{
                                      textTransform: "capitalize"
                                    }}
                                    value={ele.value}
                                  >
                                    {ele.type}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Box>
                  {errors.OccupationType && (
                    <ErrorMessage message="Please Select Source of Occupation Type" />
                  )}
                </Box>
              </ThemeProvider>


              <ThemeProvider theme={theme}>
                <Box
                  my={2}
                  //display="flex"
                  alignItems={"flex-start"}
                  justifyContent="flex-start"
                  flexDirection="column"
                  width={"100%"}
                >
                  <label className={classes.label}>Annual Income</label>
                  <Box width={"100%"} className={classes.Select}>
                    <FormControl fullWidth className={classes.Select}>
                      <Controller
                        control={control}
                        name={`SourceIncome`}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            disableUnderline
                            className={classes.selectItem}
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={{
                              style: {
                                top: 5,
                                minWidth: 200,
                              },
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            {sourceIncome &&
                              sourceIncome.map((ele) => {
                                return (
                                  <MenuItem
                                    key={ele.id}
                                    className={classes.menuItem}
                                    value={ele.value}
                                  >
                                    {ele.type}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Box>
                  {errors.SourceIncome && (
                    <ErrorMessage message="Please Select Source of Income" />
                  )}
                </Box>
              </ThemeProvider>

              <Box
                display="flex"
                flexDirection="column"
                alignItems={"flex-start"}
                justifyContent="flex-start"
                width={"100%"}
              >
                <Box my={1}>
                  <Box my={1} className={classes.label}>
                    Nationality
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <input
                        type="radio"
                        height="16px"
                        width="16px"
                        name="fillType"
                        style={{
                          height: "16px",
                          width: "16px",
                          margin: 0,
                          outline: "none",
                        }}
                        value="Indian"
                        {...register("taxAnotherCountry", {
                          required: true,
                          onChange: (e) => handletaxAnotherCountry(e),
                        })}
                      />
                      <Box fontSize={12} fontWeight={500} ml={1}>
                        Indian
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="flex-start"
                      ml={6}
                    >
                      <input
                        type="radio"
                        height="16px"
                        width="16px"
                        name="fillType"
                        style={{
                          height: "16px",
                          width: "16px",
                          margin: 0,
                          outline: "none",
                        }}
                        value="NRI"
                        {...register("taxAnotherCountry", {
                          required: true,
                          onChange: (e) => handletaxAnotherCountry(e),
                        })}
                      />
                      <Box fontSize={12} fontWeight={500} ml={1}>
                        NRI
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {errors.taxAnotherCountry && (
                  <ErrorMessage message={"Please select"} />
                )}
              </Box>




              {/* -------------------------------- old code part -------------------------------- */}

              {/* <Box my={1}>
                <lable className={classes.rupeesSymbol}>₹</lable>
                <TextInputField
                  name="income"
                  label="Annual Income"
                  placeholder="In terms of 1000s"
                  register={register}
                  inputPattern={/^[0-9]+$/}
                />
                {errors.income && errors.income.type === "required" && (
                  <ErrorMessage message="Enter Annual income" />
                )}
                {errors.income && errors.income.type === "pattern" && (
                  <ErrorMessage message="Enter valid income" />
                )}
              </Box> */}

              {/* <ThemeProvider theme={theme}>
                <Box
                  my={2}
                  //display="flex"
                  alignItems={"flex-start"}
                  justifyContent="flex-start"
                  flexDirection="column"
                  width={"100%"}
                >
                  <label className={classes.label}>Source of Income</label>
                  <Box width={"100%"} className={classes.Select}>
                    <FormControl fullWidth className={classes.Select}>
                      <Controller
                        control={control}
                        name={`SourceIncome`}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            disableUnderline
                            className={classes.selectItem}
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={{
                              style: {
                                top: 5,
                                minWidth: 200,
                              },
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            {sourceIncome &&
                              sourceIncome.map((ele) => {
                                return (
                                  <MenuItem
                                    key={ele.id}
                                    className={classes.menuItem}
                                    value={ele.id}
                                  >
                                    {ele.type}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Box>
                  {errors.SourceIncome && (
                    <ErrorMessage message="Please Select Source of Income" />
                  )}
                </Box>
              </ThemeProvider> */}



              {/* <ThemeProvider theme={theme}>
                <Box
                  my={1}
                  display="flex"
                  alignItems={"flex-start"}
                  justifyContent="flex-start"
                  flexDirection="column"
                  width={"100%"}
                >
                  {" "}
                  <label className={classes.label}>Country of Birth</label>
                  <Box width={"100%"} className={classes.Select}>
                    <FormControl fullWidth className={classes.Select}>
                      <Controller
                        control={control}
                        name={`country`}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            disableUnderline
                            className={classes.selectItem}
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={{
                              style: {
                                maxHeight: 300,
                                top: 5,
                                minWidth: 200,
                              },
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            {countries &&
                              countries?.map((ele) => {
                                return (
                                  <MenuItem
                                    key={ele.id}
                                    className={classes.menuItem}
                                    value={ele.id}
                                  >
                                    {ele.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Box>
                </Box>
                {errors.country && (
                  <ErrorMessage message="Please Select Country of Birth" />
                )}
              </ThemeProvider> */}
              {/* <Box
                display="flex"
                flexDirection="column"
                alignItems={"flex-start"}
                justifyContent="flex-start"
                width={"100%"}
              >
                <Box my={1}>
                  <Box my={1} className={classes.label}>
                    Tax Residence of another Country?
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <input
                        type="radio"
                        height="16px"
                        width="16px"
                        name="fillType"
                        style={{
                          height: "16px",
                          width: "16px",
                          margin: 0,
                          outline: "none",
                        }}
                        value="Yes"
                        {...register("taxAnotherCountry", {
                          required: true,
                          onChange: (e) => handletaxAnotherCountry(e),
                        })}
                      />
                      <Box fontSize={12} fontWeight={500} ml={1}>
                        Yes
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="flex-start"
                      ml={6}
                    >
                      <input
                        type="radio"
                        height="16px"
                        width="16px"
                        name="fillType"
                        style={{
                          height: "16px",
                          width: "16px",
                          margin: 0,
                          outline: "none",
                        }}
                        value="No"
                        {...register("taxAnotherCountry", {
                          required: true,
                          onChange: (e) => handletaxAnotherCountry(e),
                        })}
                      />
                      <Box fontSize={12} fontWeight={500} ml={1}>
                        No
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {errors.taxAnotherCountry && (
                  <ErrorMessage message={"Please select"} />
                )}
              </Box> */}

              {taxAnotherCountry && (
                <div>
                  <ThemeProvider theme={theme}>
                    <Box
                      my={1}
                      display="flex"
                      alignItems={"flex-start"}
                      justifyContent="flex-start"
                      flexDirection="column"
                      width={"100%"}
                    >
                      {" "}
                      <label className={classes.label}>
                        Country of Tax Residence
                      </label>
                      <Box width={"100%"} className={classes.Select}>
                        <FormControl fullWidth className={classes.Select}>
                          <Controller
                            control={control}
                            name={`taxCountry`}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                disableUnderline
                                className={classes.selectItem}
                                classes={{ paper: classes.selectPaper }}
                                IconComponent={KeyboardArrowDownIcon}
                                MenuProps={{
                                  style: {
                                    maxHeight: 300,
                                    top: 5,
                                    minWidth: 200,
                                  },
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                }}
                              >
                                {countries &&
                                  countries?.map((ele) => {
                                    return (
                                      <MenuItem
                                        key={ele.id}
                                        className={classes.menuItem}
                                        value={ele.id}
                                      >
                                        {ele.name}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Box>
                    </Box>
                    {errors.taxCountry && (
                      <ErrorMessage message="Please Select Country" />
                    )}
                  </ThemeProvider>
                  <Box my={1}>
                    <TextInputField
                      name="typeOfTax"
                      label="Type of Tax Identification"
                      placeholder=""
                      register={register}
                    />
                    {errors.typeOfTax && (
                      <ErrorMessage message="Enter Type of Tax Identification" />
                    )}
                  </Box>

                  <Box my={1}>
                    <TextInputField
                      name="taxNumber"
                      label="Tax Identification Number"
                      placeholder=""
                      register={register}
                    />
                    {errors.taxNumber &&
                      errors.taxNumber.type === "required" && (
                        <ErrorMessage message="Enter Tax Identification Number" />
                      )}
                    {errors.taxNumber &&
                      errors.taxNumber.type === "pattern" && (
                        <ErrorMessage message="Enter valid Number" />
                      )}
                  </Box>
                </div>
              )}
              {error && <ErrorMessage message={apiError || "Something wrong happened"} />}

              <Box mt={2}>
                <button className={classes.primaryButton} type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress size="1rem" color="inherit" />
                  ) : (
                    "Next"
                  )}
                </button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default FatcaDetails;
