import React, { useEffect, useState } from 'react'
import { Box, Container, makeStyles, Typography, Select, FormControl } from "@material-ui/core";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,

} from "recharts";
import moment from "moment";
import { GET_NAV_HISTORY, GET_PORTFOLIO_RETURN_GRAPH, GET_PORTFOLIO_RETURN_NAV_GRAPH } from '../../../../utils/endpoints';
import httpInstance from '../../../../utils/instance';
import ReactLoading from 'react-loading'
import { Skeleton } from '@mui/material';

const PerformanceSummary = ({ }) => {
    const useStyles = makeStyles((theme) => ({
        graphButtonInactive: {
            border: "none",
            background: "none",
            padding: "5px",
            borderRadius: "7px",
            fontSize: "0.8rem",
            fontWeight: 600,
            cursor: "pointer",
            outline: "none",
            transition: "0.5s ease",
            border: "1px solid #0E76BC",
            color: "#0E76BC",
            "&:hover": {
                background: "#0E76BC",
                color: "white",
                padding: "5px",
            },
            "&:active": {
                background: "#0E76BC",
                color: "white"
            }
        },

        activeButton: {
            background: "#0E76BC",
            color: "white",
            padding: "5px",
            border: "none",
            borderRadius: "7px",
            fontSize: "0.8rem",
            fontWeight: 600,
            cursor: "pointer",
            outline: "none",
        },

        tootipBox: {
            boxShadow: "0px 4.45122px 4.45122px rgba(0, 0, 0, 0.12)",
            borderRadius: "13px",
            background: "white",
        },

        tooltipText: {
            fontSize: "0.7rem",
            fontWeight: 600,
            marginBottom: "3px",
            padding: "0px 5px",
        },

        livetext: {
            fontSize: "1.1rem",
            color: "#0E76BC",
            fontFamily: "inherit"

        },
        rupees: {
            fontFamily: "Open Sans"
        },
        spinner: {
            height: "250px"
        },
        title: {
            fontWeight: 600,
            // padding: "0px 7rem",
            fontSize: "1.2rem"
        },
        text: {
            fontWeight: 500,
            padding: "0px 1rem",
            fontSize: "15px"
        },
        lineblueColor: {
            display: 'inline-block',
            height: '2px',
            verticalAlign: 'middle',
            width: '50px',
            backgroundColor: '#0E76BC',
            borderRadius: '10px',
        },
        lineredColor: {
            display: 'inline-block',
            height: '2px',
            verticalAlign: 'middle',
            width: '50px',
            backgroundColor: '#e2343e',
            borderRadius: '10px',
        },
        graphDataDesign: {
            padding: '1rem',
            margin: '15px 0px',
            border: '1px solid #E4E4E4',
            // boxShadow: '0 0 2px rgba(0,0,0,.18)',
            // boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
            borderRadius: '12px',
        },
    }));

    const CustomTooltip = ({ active, payload, label }) => {
        // console.log(payload)
        if (active && payload?.[0] && payload.length) {
            return (
                <Box className={classes.tootipBox}>
                    <Box display="flex">
                        <p className={classes.tooltipText}>
                            {" "}
                            NAV: <span className={classes.rupees}>₹</span> {payload[0].payload.nav}
                        </p>
                        <p className={classes.tooltipText}>
                            {moment(payload[0].payload.navDate).format("DD MMM YYYY")}
                        </p>
                    </Box>
                    <Box display="flex">
                        {payload?.[1] &&
                            <>
                                <p className={classes.tooltipText}>
                                    {" "}
                                    NAV: <span className={classes.rupees}>₹</span> {payload[1].payload.unrealized_gain}
                                </p>
                                <p className={classes.tooltipText}>
                                    {moment(payload[1].payload.date).format("DD MMM YYYY")}
                                </p>
                            </>
                        }
                    </Box>
                </Box>
            );
        }
        // else if (active && payload?.[1] && payload.length) {
        //     return (
        //         <Box display="flex" className={classes.tootipBox}>
        //            <p className={classes.tooltipText}>
        //                     {" "}
        //                     NAV: <span className={classes.rupees}>₹</span> {payload[1].payload.unrealized_gain}
        //                 </p>
        //                 <p className={classes.tooltipText}>
        //                     {moment(payload[1].payload.date).format("DD MMM YYYY")}
        //                 </p>
        //         </Box>
        //     );
        // }

        return null;
    };

    const formattedDate = (date) => {
        try {
            const parsedDate = new Date(date);
            if (isNaN(parsedDate)) {
                throw new Error("Invalid date string");
            }
            return parsedDate.toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            });
        } catch (error) {
            console.error("Error parsing date:", error.message);
            return "Invalid Date"; // Return a default value or handle the error appropriately
        }

    }


    const classes = useStyles();

    const [spinner, setSpinner] = useState(true)
    const [button, setButton] = useState(365)
    const [data, setData] = useState()
    const [retunGraphData, setReturnGraphData] = useState()

    useEffect(() => {
        get_filteredData(365)
    }, [])



    const get_filteredData = async (range) => {
        // console.log(range)
        setButton(range)
        setSpinner(true)
        // console.log(range)
        const fromDate = moment().subtract(range, 'months').format('YYYY-MM-DD')
        // console.log(fromDate)
        // const formData = {
        // //   scheme_code: code,
        //   from: fromDate,
        //   to: moment().format('YYYY-MM-DD'),
        //   frequency: range < 13 ? "day" : "week"
        // }
        const httpConfig = {
            method: "GET",
            tokenType: "user",
            //   test: 'test',
            headers: {
                "Content-Type": "application/json",
            },
            // data:formData
            params: { duration: range }
        };

        try {
            const [responseReturn, responseBenchmarkNav] = await Promise.all([
                httpInstance(`${GET_PORTFOLIO_RETURN_GRAPH}`, httpConfig),
                httpInstance(`${GET_PORTFOLIO_RETURN_NAV_GRAPH}`, httpConfig),
            ])
            //   const response = await httpInstance(`)
            let filteredData = responseReturn?.data?.data.filter(item => {
                // Convert the item's date string to Date object
                const itemDate = new Date(item?.date);
                // Check if the item's date is less than or equal to navDate
                const newItemDate = new Date(responseBenchmarkNav?.data?.data?.[responseBenchmarkNav?.data?.data.length - 1]?.navDate)
                return itemDate <= newItemDate;
            });
            // console.log(responseReturn.data?.data)
            const navData = responseBenchmarkNav.data?.data?.map((ele, i) => {
                return { ...ele, xid: i }
            })
            // filteredData.map((item, i) => ({
            //     ...item,
            //     unrealized_gain: item.unrealized_gain + 10
            // }));
            // const returnData = filteredData.map((ele, i) => {
            //     return { ...ele, xid: responseBenchmarkNav.data?.data.length - filteredData.length + i }
            // })
            const returnData = responseReturn.data?.data?.map((ele, i) => {
                return { ...ele, xid: responseBenchmarkNav.data?.data.length - filteredData.length + i }
            })
            // console.log(returnData)
            setData(navData)
            setReturnGraphData(returnData)
            setSpinner(false)

        }
        catch {

        }
    }

    return (
        <div>
            <Box py={10} px={2} className={classes.graphDataDesign}>
            <Typography className={classes.title}>Performance Summary</Typography>
                <Box pr={5}  >
                    {spinner ?
                        <Box display="flex" justifyContent="center" alignItems={'center'} className={classes.spinner}><ReactLoading type="spinningBubbles" color="	#0E76BC" height={'5%'} width={'5%'} /></Box>
                        :
                        <ResponsiveContainer width={"100%"} height={250}>
                            <LineChart
                                width={700}
                                height={200}
                                connectNulls={true}
                                data={data}
                            >
                                <Tooltip
                                    content={<CustomTooltip />}
                                    position={{ x: "auto", y: 0 }}
                                />
                                <Line
                                    data={data}
                                    type="monotone"
                                    connectNulls={false}
                                    dataKey="nav"
                                    stroke={"#0E76BC"}
                                    activeDot={{ r: 8, color: "#ef731f" }}
                                    dot={false}
                                    //className={props?.data?.amc?.amcShortName}
                                    //type="number"
                                    strokeWidth={2}
                                />
                                <Line
                                    data={retunGraphData}
                                    type="monotone"
                                    connectNulls={false}
                                    dataKey="unrealized_gain"
                                    stroke={"#e2343e"}
                                    activeDot={{ r: 8, color: "#e2343e" }}
                                    dot={false}
                                    //className={props?.data?.amc?.amcShortName}
                                    //type="number"
                                    strokeWidth={2}
                                />
                                <XAxis
                                    interval={0}
                                    dataKey="xid"
                                    //interval={0}
                                    type="number"
                                    tick={false}
                                    tickLine={false}
                                    axisLine={false}
                                    domain={["dataMin", "dataMax + 1"]}
                                    allowDuplicatedCategory={false}
                                />
                                <YAxis
                                    allowDuplicatedCategory={false}
                                    //yAxisId="left"
                                    // dataKey="baseValue"
                                    tick={false}
                                    axisLine={false}
                                    domain={["dataMin", "dataMax + 1"]}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    }
                </Box>
                {data &&
                    <Box style={{ borderBottom: "1px solid #0D87F4", height: "30px" }} display="flex" justifyContent="space-between" mx={5}>
                        {/* <p>{data?.[0]?.navDate}</p> */}
                        <p className={classes.tooltipText}>{moment(data?.[0]?.navDate).format("DD MMM YYYY")}</p>
                        {/* <p className={classes.tooltipText}>{formattedDate(data[0].navData)}</p> */}
                        <p className={classes.tooltipText}>{moment(data[data?.length - 1].navDate).format("DD MMM YYYY")}</p>

                    </Box>
                }
                {!spinner ?
                    <Box display="flex" justifyContent="space-between" px={15} mt={2}>
                        <button
                            // className={button==6 ? classes.activeButton : classes.graphButtonInactive}
                            // onClick={()=>{get_filteredData(1)}}
                            className={button == 30 ? classes.activeButton : classes.graphButtonInactive}
                            onClick={() => { get_filteredData(30) }}
                        >
                            1M
                        </button>
                        <button
                            // className={button==6 ? classes.activeButton : classes.graphButtonInactive}
                            // onClick={()=>{get_filteredData(1)}}
                            className={button == 90 ? classes.activeButton : classes.graphButtonInactive}
                            onClick={() => { get_filteredData(90) }}
                        >
                            3M
                        </button>
                        <button
                            // className={button==6 ? classes.activeButton : classes.graphButtonInactive}
                            // onClick={()=>{get_filteredData(1)}}
                            className={button == 180 ? classes.activeButton : classes.graphButtonInactive}
                            onClick={() => { get_filteredData(180) }}
                        >
                            6M
                        </button>
                        <button
                            // className={button==12 ? classes.activeButton :classes.graphButtonInactive}
                            // onClick={()=>{get_filteredData(12)}}
                            className={button == 365 ? classes.activeButton : classes.graphButtonInactive}
                            onClick={() => { get_filteredData(365) }}
                        >
                            1Y
                        </button>
                        <button
                            // className={button==36 ? classes.activeButton :classes.graphButtonInactive}
                            // onClick={()=>{get_filteredData(36)}}
                            className={button == 1095 ? classes.activeButton : classes.graphButtonInactive}
                            onClick={() => { get_filteredData(1095) }}
                        >
                            3Y
                        </button>
                        <button
                            // className={button==60 ? classes.activeButton :classes.graphButtonInactive}
                            // onClick={()=>{get_filteredData(60)}}
                            className={button == 1825 ? classes.activeButton : classes.graphButtonInactive}
                            onClick={() => { get_filteredData(1825) }}
                        >
                            5Y
                        </button>
                        {/* <button
                        // className={button == "all" ? classes.activeButton : classes.graphButtonInactive}
                        // onClick={()=>{get_filteredData(120)}}
                        className={button == "1826" ? classes.activeButton : classes.graphButtonInactive}
                        onClick={() => { get_filteredData(1826) }}
                    >
                        All
                    </button> */}
                    </Box>
                    : <Box mx={3}><Skeleton variant="text" width="100%" height={40} /></Box>}

                <Box display="flex" justifyContent="center" px={15} mt={5} flexDirection='row'>

                    <Box display="flex" justifyContent="center" alignItems='center'>
                        <span className={classes.lineblueColor}></span>

                        <Typography className={classes.text}> Benchmark</Typography>

                    </Box>
                    <Box display="flex" justifyContent="center" alignItems='center'>
                        <span className={classes.lineredColor}></span>

                        <Typography className={classes.text}> Your Investment</Typography>
                    </Box>
                </Box>
            </Box>

        </div>
    )
}

export default PerformanceSummary