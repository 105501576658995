import React, { useEffect, useState } from "react";
import {
  Box,
  Menu,
  MenuItem,
  Grid,
  makeStyles,
  Badge,
  CircularProgress,
  useMediaQuery,
} from "@material-ui/core";
import { Skeleton, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  getNotification,
  readNotification,
  getNotificationCount,
  readAllNotification,
} from "../../redux/Notification/NotifiActions";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { Close } from "@mui/icons-material";



const Notification = () => {
  const useStyles = makeStyles({
    imageBack: {
      padding: "4px 5px 0px",
      background: "#ADDFFF",
      borderRadius: "50%",
      width: "fit-content",
      marginRight: "10px",
    },

    badgeColor: {
      cursor: "pointer",
      "& .MuiBadge-colorPrimary": {
        background: "#0E76BC",
      },
    },

    root: {
      minWidth: "25rem",
    },

    mainMenu: {
      maxWidth: "25rem",
      width: '100%',
      borderRadius: '12px',
      border: '1px solid #0E76BC',
      // boxShadow: '0 0 8px -3px #444444',
      // boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      '& ul:first-of-type': { // Targets the first `ul` inside `mainMenu`
        paddingTop: 0,
      },
    },

    menu: {
      maxWidth: "25rem",
      width: '100%',

      // padding: "1rem",
      //minWidth:"25rem",
      "& .MuiMenuItem-root": {
        whiteSpace: "normal",
        borderBottom: "1px solid #DDDDDD",
        minWidth: "25rem !important",

        "& .MuiMenu-list": {
          minWidth: "25rem !important",
        },
      },
      "& .MuiListItem-button": {
        "&:hover": {
          backgroundColor: '#DDDDDD',
        },
      },
      "&::-webkit-scrollbar": {
        width: "5px",
      },

      /* Track */
      "&::-webkit-scrollbar-track": {
        boxShadow: "none",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb ": {
        borderRadius: "10px",
        background: "#DDDDDD",
      },
    },

    unread: {
      background: "#f6f6f6",
    },

    text: {
      fontSize: "0.7rem",
      color: "#444444",
      margin: "5px 0px",
    },
    title: {
      fontSize: "0.85rem",
      fontFamily: "inherit",
      fontWeight: 600,
      margin: "5px 0px",
    },
    dateFont: {
      fontSize: "0.7rem",
      color: "#0E76BC",
      margin: "0px",
    },
    header: {
      fontSize: "1.1rem",
      fontWeight: 600,
      fontFamily: "inherit",
      margin: "3px",
    },
    headerBox: {
      borderBottom: "1px solid #DDDDDD",
      backgroundColor: "#0E76BC",
      color: "#FFFFFF",
    },
    text1: {
      fontSize: "0.7rem",
      color: "#0E76BC",
      cursor: "pointer",
      marginRight: "5px",
    },
    "@media (max-width: 600px)": {
      menu: {
        maxWidth: "20rem",
      },
      mainMenu: {
        maxWidth: "20rem",

      }
    }
  });

  const classes = useStyles();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(1);
  const [isMore, setIsMore] = useState(true);


  const LoaderComp = () => {
    return (
      // <div
      //   style={{
      //     display: "flex",
      //     justifyContent: "center",
      //     marginTop: "1rem",
      //     overflow: "hidden",
      //   }}
      // >
      //   <CircularProgress size="2rem" />
      // </div>
      <Box className={classes.unread}>
        <Grid container spacing={2}>
          <Grid item xs={2} style={{ paddingTop: "12px" }}>
            <div className={classes.imageBack} style={{ background: 'none' }}>
              <Skeleton variant="circular" height={'30px'} width={'30px'} />
            </div>
          </Grid>
          <Grid item xs={8} md={10} style={{ paddingRight: "15px" }}>
            <Skeleton variant="text" width={'100%'} />

            <Skeleton variant="text" width={'100%'} />
            <Skeleton variant="text" width={'50%'} />
            <Box display="flex" justifyContent="end">
              <p className={classes.dateFont}>
                <Skeleton variant="text" width={'100%'} />
              </p>
            </Box>

          </Grid>
        </Grid>
      </Box>
    );
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    dispatch(getNotification(page, handle_has_more, handle_page));
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setPage(1)
    setIsMore(true)
    setAnchorEl(null);
  };

  //------redux---------------------------------//
  const dispatch = useDispatch();
  const { notificationStore } = useSelector((state) => state);

  // useEffect(() => {
  //   dispatch(getNotificationCount());

  // }, []);

  //------------------date format---------------------------------//

  const date_format = (date) => {
    let notifiDate = moment(date);
    if (moment().diff(notifiDate, "days") == 1) {
      return "Yesterday"; // '
    } else if (moment().diff(notifiDate, "days") == 0) {
      return "Today";
    } else {
      return moment(date).format("DD MMM");
    }
  };
  //-------------------------handle read---------------------------------//
  const handle_read = (id) => {
    const array = notificationStore?.notifications?.filter(
      (ele) => ele.id == id
    );
    if (!array[0].isRead) {
      dispatch(readNotification(id));
    }
  };

  //-------------------handle all read---------------------------//
  const handle_all_read = () => {
    dispatch(readAllNotification());
  };

  //-------------------handle has more---------------------------//
  const handle_has_more = () => {
    setIsMore(false);
  };

  //--------------------handle page------------------------//
  const handle_page = () => {
    setPage(page + 1);
  };

  //--------------------fetch Data-------------------------------//
  const fetchData = () => {
    dispatch(getNotification(page, handle_has_more, handle_page));
  };

  return (
    <div >
      <Badge
        badgeContent={notificationStore?.count}
        color="primary"
        className={classes.badgeColor}
        onClick={handleClick}
      >
        <img src="/images/bell.svg" height="25px" width="25px" />
      </Badge>

      {notificationStore?.notifications && (

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          getContentAnchorEl={null}
          classes={{ paper: classes.mainMenu }}
          id="page-component"
          style={{ marginTop: isMobile ? "23px" : "30px" }} // Add margin to move it down further
        >
          <Box
            px={2}
            className={classes.headerBox}
            py={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <p className={classes.header}>Notifications</p>
            {/* {notificationStore?.count>0 && <p className={classes.text1} onClick={handle_all_read}>
              Mark all as read
            </p>} */}
            <Close onClick={handleClose} style={{ cursor: 'pointer' }} />
          </Box>


          <div id="page-component"
            style={{ height: 400, overflowX: "hidden" }}
          >
            <InfiniteScroll
              dataLength={notificationStore?.notifications?.length}
              next={fetchData}
              hasMore={isMore}
              loader={<LoaderComp />}
              //scrollableTarget="page-component"
              height={400}
              className={classes.menu}
              style={{ overflowX: "hidden" }}
              endMessage={
                <p style={{ textAlign: 'center', fontSize: "0.85rem" }}>
                  No result
                </p>
              }
            >
              {notificationStore?.notifications?.map((ele) => {
                return (
                  <MenuItem
                    key={ele.id}
                    className={ele.isRead ? "none" : classes.unread}
                  // onClick={() => handle_read(ele.id)}
                  >
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={2} style={{ paddingTop: "12px" }}>
                          <div className={classes.imageBack}>
                            <img
                              src={ele?.icon ? ele.icon : "/images/about.svg"}
                              height="30px"
                              width="30px"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={8} md={10} style={{ paddingRight: "15px" }}>
                          <p className={classes.title}>
                            {ele.title}
                          </p>
                          <p className={classes.text}>{ele.body}</p>
                          {ele.createdAt &&
                            <Box display="flex" justifyContent="end">
                              <p className={classes.dateFont}>
                                {date_format(ele.createdAt)}
                              </p>
                            </Box>
                          }
                        </Grid>
                      </Grid>
                    </Box>
                  </MenuItem>
                );
              })}
            </InfiniteScroll>
          </div>

        </Menu>

      )}


    </div>
  );
};

export default Notification;
