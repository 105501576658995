import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { useLocation, Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { INITIATE_PAYMENT, GET_ALL_BANK } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import LocaleString from "../../../hooks/LocaleString";
import number_ordinals from "../../../hooks/NumberOrdinal";
import { namespace, BASE_URL } from "../../../environment";
import ErrorMessage from "../../../components/Validation/ErrorMessage";
import Sliders from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Skeleton } from "@mui/material";

const settings = {
  dots: true, // Enable navigation dots
  infinite: false, // Prevent infinite scrolling
  speed: 500, // Transition speed
  slidesToShow: 1, // Number of items to show in view
  slidesToScroll: 1, // Number of items to scroll at a time
  // appendDots: dots => (
  //     <div
  //       style={{
  //         // backgroundColor: "#ddd",
  //         borderRadius: "10px",
  //         // padding: "10px"
  //       }}
  //     >
  //       <ul style={{ margin: "0px" }}> 1{dots} </ul>
  //     </div>
  //   ),
  responsive: [
    {
      breakpoint: 768, // For tablets and smaller
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480, // For mobile devices
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};


const OrderSummary = () => {
  const useStyles = makeStyles({
    root: {
      padding: "10px 30% ",
    },
    subTabtext: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
    },
    textUnderline: {
      fontSize: "0.7rem",
      textDecoration: "underline",
      fontFamily: "inherit",
    },
    text: {
      fontSize: "0.8rem",
      fontWeight: 700,
    },
    subText: {
      fontSize: "0.9rem",
      fontWeight: 500,
      textAlign: "center",
      fontFamily: "inherit",
      textTransform: 'uppercase',
    },
    header: {
      fontSize: "1.2rem",
      fontWeight: 500,
    },

    title: {
      fontSize: "1.1rem",
    },
    primaryButton: {
      padding: "0.8rem 4rem",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      marginTop: "1rem",
    },
    rupees: {
      fontFamily: "Open Sans",
    },

    "@media (max-width:600px)": {
      root: {
        padding: "10px ",
      },
    },

    "@media (min-width:601px) and (max-width:768px)": {
      root: {
        padding: "10px 23% ",
      },
    },
    sliderCarousel: {
      "& .slick-dots li button:before": {
        color: "#777777",
        backgroundColor: "#777777",
        // padding: "0px 5px",
        margin: "0px 5px",
        borderRadius: '3px',
        height: '12px',
      }
    },
    OrderSummaryData: {
      border: "1px solid #cccc",
      borderRadius: '10px',
      padding: '10px',

    },
    orderText1: {
      fontSize: '0.9rem',
      // fontWeight: 600,
      color: '#000',

    },
    bankSelection: {
      border: "1px solid #cccc",
      cursor: 'pointer',
      marginBottom: '10px',
      position: 'relative',
      padding: '10px',
      borderRadius: '10px',
      "&:hover": {
        border: "1px solid #0E76BC",

      }
    },
    bankSelectionActive: {
      border: "1px solid #0E76BC",
    },
  });
  const classes = useStyles();

  const { state } = useLocation();
  const { orderData, type, schemeCode, fundData, consentId } = state;

  console.log(orderData, fundData, schemeCode, consentId, state)
  const [loading, setLoading] = useState(false);
  const [bankList, setBankList] = useState(null);
  const [selectedBank, setSelectedBank] = useState('');
  const [error, setError] = useState('');


  const get_all_the_bank_details = async () => {
    const httpConfig1 = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${GET_ALL_BANK}`, httpConfig1);
      // console.log(fpres?.data?.data.sip_frequency_data?.MONTHLY, 'fadfadfadsfasdfasdfasdf')
      console.log(response.data.data)
      setBankList(response?.data?.data);
    } catch { }
  };
  useEffect(() => {
    get_all_the_bank_details()
  }, [])




  const initiate_payment = async () => {
    if (!selectedBank) {
      setError('Select a bank first!')
      return;
    }
    setLoading(true);
    // if (type == "sip") {
    //   window.location.replace(orderData.tokenUrl);
    // } else {
    const formData2 = {
      // userId: localStorage.getItem("user_id"),
      // paymentType: "NETBANKING",
      // euin: orderData.euin,
      // referenceCode: "",
      // id: orderData.id,
      // isWeb: true,
      transaction_basket_id: consentId,
      method: selectedBank.bankType.toUpperCase(),
      bank_id: selectedBank.id
    };
    const httpConfig1 = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData2,
    };
    // sessionStorage.removeItem("sip");
    // sessionStorage.setItem("lumsumId", orderData?.id);

    try {
      const response = await httpInstance(`${INITIATE_PAYMENT}`, httpConfig1);
      // console.log(response?.data?.data)
      window.location.replace(response.data?.data?.token_url);
    } catch (error) {
      setLoading(false);
      // console.log(error.response.data.error)
      setError(error?.response?.data?.error ? error?.response?.data?.error : 'somethin went wrong')
    }
    // }
  };


  const selectionOfBank = (selectionOfBank, type) => {
    setError('')
    // console.log(type)
    // console.log(selectionOfBank)
    // selectionOfBank['bankType'] = type
    // console.log(selectionOfBank)
    // // Now you can use the updatedSelection array as needed
    // setSelectedBank(selectionOfBank);
    const updatedSelectionOfBank = { ...selectionOfBank };

    // console.log(type);
    // console.log(selectionOfBank);

    // Make changes to the copied object
    updatedSelectionOfBank['bankType'] = type;

    // console.log(updatedSelectionOfBank);

    // Now you can use the updatedSelectionOfBank as needed
    setSelectedBank(updatedSelectionOfBank);
  }
  const formatAccountNumber = (accountNumber) => {
    const length = accountNumber.length;

    // Show only the first 2 and last 2 characters, replace the rest with "x"
    return accountNumber.substring(0, 2) + 'x'.repeat(length - 4) + accountNumber.substring(length - 2);
  };


  return (
    <div>
      <Box
        display="flex"
        alignItems="start"
        px={{ xs: 1, md: 17 }}
        pt={2}
        style={{ borderBottom: "1px solid #E6E3E3" }}
      >
        <Link
          to={`${namespace}/myportfolio`}
          style={{ color: "black" }}
        >
          <Typography className={classes.textUnderline}>
            Mutual Funds
          </Typography>
        </Link>
        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Typography className={classes.subTabtext}>Order Summary</Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        px={{ xs: 1, md: 18 }}
        mt={2}
        className={classes.backButton}
      >
        <Link to={`${namespace}/mutualfund/scheme-code/${schemeCode}`}>
          <img src="/images/backIcon.svg" height="20px" width="20px" />
        </Link>
      </Box>
      <Box
        className={classes.root}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography className={classes.header}>Order Summary</Typography>

        {/* <Sliders
          {...settings}
          className={classes.sliderCarousel}
        >
          {orderData?.transaction_basket_items.map(() => {
            return (
              // <p className={classes.rupees}>
              // testasta
              // </p>
              <Box display="flex" justifyContent="space-between" mt={0} width={'100%'} >
                <Box
                  display="flex"
                  alignItems="start"
                  flexDirection="column"
                  mt={0}
                >

                  {type == "sip" && <p>SIP Date</p>}
                  <p>
                    Amount(<span className={classes.rupees}> ₹</span>)
                  </p>
                  <p>Purchase Type</p>
                  <p>Folio No.</p>
                </Box>
                <Box
                  display="flex"
                  alignItems="start"
                  flexDirection="column"
                  mt={0}
                >
                  {type == "sip" && (
                    <p>{number_ordinals(orderData?.transaction_basket_items?.[0]?.installment_day)} of every {orderData?.transaction_basket_items?.[0]?.frequency ? orderData?.transaction_basket_items?.[0]?.frequency.replace(/ly$/, '') : ''}</p>
                  )}
                  <p>{LocaleString(orderData?.transaction_basket_items?.[0]?.amount)}</p>
                  <p>{orderData?.transaction_basket_items?.[0]?.transaction_type}</p>
                  <p>{orderData?.transaction_basket_items?.[0]?.folio_number ? orderData?.transaction_basket_items?.[0]?.folio_number : "-"}</p>

                </Box>
              </Box>

            )
          })}

        </Sliders> */}
        {orderData?.transaction_basket_items.map((order, index) => {
          return (
            // <p className={classes.rupees}>
            // testasta
            // </p>
            <Box key={index} className={classes.OrderSummaryData} width={'100%'} mt={2}>
              <Box display="flex" alignItems="center" mt={0}>
                <img
                  src={`${BASE_URL}${fundData?.amc?.logoUrl}`}
                  height="50px"
                  width="50px"
                  style={{ marginRight: "15px" }}
                />
                <Typography className={classes.title}>
                  {fundData?.schemeName}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" mt={0} >
                <Box
                  display="flex"
                  alignItems="start"
                  flexDirection="column"
                  mt={0}
                  width={'100%'}
                >
                  {type == "sip" &&
                    <Typography variant="body" className={classes.orderText1}>
                      SIP Date:  {number_ordinals(orderData?.transaction_basket_items?.[0]?.installment_day)} of every {orderData?.transaction_basket_items?.[0]?.frequency ? orderData?.transaction_basket_items?.[0]?.frequency.replace(/ly$/, '') : ''}
                    </Typography>
                  }
                  <Typography variant="body" className={classes.orderText1}>
                    Investment Amount: <span className={classes.rupees}>₹</span> {order.amount.toLocaleString('en-IN')}
                  </Typography>
                  <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
                    <Typography variant="body" className={classes.orderText1}>
                      Purchase Type:  {order.transaction_type}
                    </Typography>
                    <Typography variant="body" className={classes.orderText1} style={{ width: '100%', maxWidth: '150px' }}>
                      Folio No:  {order?.folio_number ? order?.folio_number : "-"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )
        })}
        <Grid container>

          {/* <Grid item xs={8} md={8}>

            <Box
              display="flex"
              alignItems="start"
              flexDirection="column"
              mt={3}
            >

              {type == "sip" && <p>SIP Date</p>}
              <p>
                Amount(<span className={classes.rupees}>₹</span>)
              </p>
              <p>Purchase Type</p>
              <p>Folio No.</p>
            </Box>
          </Grid>
          <Grid item xs={4} md={4}>
            <Box
              display="flex"
              alignItems="start"
              flexDirection="column"
              mt={3}
            >
              {type == "sip" && (
                <p>{number_ordinals(orderData?.transaction_basket_items?.[0]?.installment_day)} of every {orderData?.transaction_basket_items?.[0]?.frequency ? orderData?.transaction_basket_items?.[0]?.frequency.replace(/ly$/, '') : ''}</p>
              )}
              <p>{LocaleString(orderData?.transaction_basket_items?.[0]?.amount)}</p>
              <p>{orderData?.transaction_basket_items?.[0]?.transaction_type}</p>
              <p>{orderData?.transaction_basket_items?.[0]?.folio_number ? orderData?.transaction_basket_items?.[0]?.folio_number : "-"}</p>

            </Box>
          </Grid> */}

          <Grid item xs={12} md={12}>
            <Box display={'flex'} justifyContent={'center'}>
              <p>
                Netbanking
              </p>
            </Box>
            {!bankList ?
              <>
                <Box
                  item xs={2} md={1}
                  display="flex"
                  // key={i}
                  style={{
                    border: '1px solid #cccc',
                    cursor: 'pointer',
                    marginBottom: '10px', // Add margin to create spacing between items
                    position: 'relative',
                    padding: '10px', // Add padding for better aesthetics
                    borderRadius: '10px',
                  }}
                  p={1}
                >
                  <Skeleton variant="rounded" border width={50} height={50} />
                  <Box width='100%' paddingLeft='10px' display='flex' justifyContent='center' alignItems={"center"} flexDirection={'column'}>

                    <Skeleton variant="text" width={'40%'} />

                    <Skeleton variant="text" width={'40%'} />

                  </Box>
                </Box>
              </>
              :
              bankList?.map((list, i) => {
                return (
                  <Box
                    item xs={2} md={1}
                    display="flex"
                    key={i}
                    // style={{
                    //   border: (selectedBank?.id === list?.id && selectedBank.bankType === 'Netbanking') ? '1px solid #007bff' : '1px solid #cccc',
                    //   cursor: 'pointer',
                    //   marginBottom: '10px', // Add margin to create spacing between items
                    //   position: 'relative',
                    //   padding: '10px', // Add padding for better aesthetics
                    // }}
                    className={`${classes.bankSelection} ${selectedBank?.id === list?.id && classes.bankSelectionActive}`}

                    p={1}
                    onClick={() => {
                      selectionOfBank(list, 'Netbanking')
                    }}
                  >
                    <img src={`${BASE_URL}${list?.logo_url}`} padding="2px 10px" height="50px" width='auto' style={{ borderRadius: '10px' }} />

                    <Box width='100%' paddingLeft='10px' display='flex' justifyContent='center' alignItems={"center"} flexDirection={'column'}>
                      <Typography className={classes.text}>
                        {/* {list.account_number} */}
                        {formatAccountNumber(list.account_number)}

                      </Typography>

                      <Typography className={classes.subText}>
                        {list.bank_name}
                      </Typography>
                    </Box>

                    <Box width={17} marginRight='10px' display='flex' justifyContent='center' alignItems={"center"} >
                      {(selectedBank?.id === list?.id && selectedBank.bankType === 'Netbanking') && (
                        <img
                          src="/images/checkMark.svg"
                          height="16"
                          width="16"
                          className={classes.closeBtn}
                        />
                      )}
                    </Box>

                  </Box>
                );
              })}

          </Grid>
          <Grid item xs={12} md={12}>
            <Box display={'flex'} justifyContent={'center'}>
              <p>
                UPI
              </p>
            </Box>
            {!bankList ?
              <>
                <Box
                  item xs={2} md={1}
                  display="flex"
                  // key={i}
                  style={{
                    border: '1px solid #cccc',
                    cursor: 'pointer',
                    marginBottom: '10px', // Add margin to create spacing between items
                    position: 'relative',
                    padding: '10px', // Add padding for better aesthetics
                    borderRadius: '10px',
                  }}
                  p={1}
                >
                  <Skeleton variant="rounded" border width={50} height={50} />
                  <Box width='100%' paddingLeft='10px' display='flex' justifyContent='center' alignItems={"center"} flexDirection={'column'}>

                    <Skeleton variant="text" width={'40%'} />

                    <Skeleton variant="text" width={'40%'} />

                  </Box>
                </Box>
              </>
              :
              bankList?.map((list, i) => {
                return (
                  <Box
                    item xs={2} md={1}
                    display="flex"
                    key={i}
                    // style={{
                    //   border: (selectedBank?.id === list?.id && selectedBank.bankType === 'UPI') ? '1px solid #007bff' : '1px solid #cccc',
                    //   cursor: 'pointer',
                    //   marginBottom: '10px', // Add margin to create spacing between items
                    //   position: 'relative',
                    //   padding: '10px', // Add padding for better aesthetics
                    // }}
                    className={`${classes.bankSelection} ${selectedBank?.id === list?.id && classes.bankSelectionActive}`}
                    p={1}
                    onClick={() => selectionOfBank(list, 'UPI')}
                  >
                    <img src={`${BASE_URL}${list?.logo_url}`} padding="2px 10px" height="50px" width='auto' style={{ borderRadius: '10px' }} />

                    <Box width='100%' paddingLeft='10px' display='flex' justifyContent='center' alignItems={"center"} flexDirection={'column'}>
                      <Typography className={classes.text}>
                        {/* {list.account_number} */}
                        {formatAccountNumber(list.account_number)}

                      </Typography>

                      <Typography className={classes.subText}>
                        {list.bank_name}
                      </Typography>
                    </Box>
                    <Box width={17} marginRight='10px' display='flex' justifyContent='center' alignItems={"center"} >
                      {(selectedBank?.id === list?.id && selectedBank.bankType === 'UPI') && (

                        <img
                          src="/images/checkMark.svg"
                          height="16"
                          width="16"
                        // className={classes.closeBtn}
                        />
                      )}
                    </Box>

                  </Box>
                );
              })}
          </Grid>
        </Grid>
        <div>{error && <ErrorMessage message={error} />}</div>
        <button className={classes.primaryButton} onClick={initiate_payment}>
          {loading ? (
            <CircularProgress size="1rem" color="inherit" />
          ) : (
            "Proceed to pay"
          )}
        </button>

      </Box>
    </div >
  );
};

export default OrderSummary;
