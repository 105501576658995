import React, { useState, useEffect } from 'react'
import { Box, makeStyles, Typography, Accordion, AccordionSummary, AccordionDetails, CircularProgress, Grid, Container } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { GET_REPORTS, GET_USER_RISK_PROFILE } from '../../../utils/endpoints'
import httpInstance from '../../../utils/instance'
import { BASE_URL } from '../../../environment';
import ErrorMessage from '../../../components/Validation/ErrorMessage'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link } from "react-router-dom";
import { namespace } from "../../../environment";
import Questionareies from '../../Assisted/Dashboard/Questionareies';
import RiskProfileChart from '../../../components/Assisted/RiskProfileChart';

// import GetStarted from "../../Dashboard/Funds/GetStarted";

const Index = () => {
    const useStyles = makeStyles((theme) => ({
        root: {
            border: "1px solid #C4C4C4",
            borderRadius: "10px",
            // padding:"5px 85px",
            marginTop: "1rem",
            fontSize: "0.8rem",
            fontWeight: 400,
            //width:"55%"

        },
        boxWidth: {
            width: "35%",
        },
        subTabtext: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
            fontWeight: 500,
        },
        textUnderline: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
        },

        box1: {
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.12)",
            borderRadius: "8px",
            border: "1px solid #DDDDDD",
            // width: "35%",
        },

        header: {
            fontSize: "1.2rem"
        },
        mainText: {
            fontFamily: "Poppins",
            fontSize: "28px",
            fontWeight: "600",
            lineHeight: "48px",
            color: "#0E76BC",
        },
        subText: {
            fontFamily: "Poppins",
            fontSize: "12px",
            fontWeight: "400",
            // lineHeight: "24px",
            // width: "285px",
            // height: "48px",
            marginTop: "12px",
            marginBottom: "32px",

        },
        primaryButton: {
            minWidth: "300px",
            padding: "0.6rem 1.5rem",
            background: "#0E76BC",
            color: "#fff",
            fontSize: "0.8rem",
            fontFamily: "inherit",
            fontWeight: 600,
            borderRadius: "45px",
            border: "1px solid #0E76BC",
            // marginRight: "15px",
            outline: "none",
            cursor: "pointer",
            "& .MuiCircularProgress-root": {
                padding: "0px 20px"
            }
        },

        accordion: {
            "& .MuiAccordionDetails-root": {
                justifyContent: "end",
                boxShadow: "none",
                display: "block"
            }
        }
    }))

    const classes = useStyles()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [showQuestions, setShowQuestions] = useState(false);
    const [showChart, setShowChart] = useState(true);
    const [riskProfileData, setRiskProfileData] = useState(null)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const questionsAreDone = () => {
        setShowChart(true)
    }
    const showQuestion =() =>{
        setShowQuestions(false)
        setShowChart(false)
    }

    const handleGetRiskProfile = async () => {
        setLoading(true)
        setError("")
        const httpConfig = {
            method: "GET",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            // params: { userId: localStorage.getItem("user_id") }
        };

        try {
            const response = await httpInstance(`${GET_USER_RISK_PROFILE}`, httpConfig)
            setRiskProfileData(response?.data?.data)
            // window.open(`${BASE_URL}${response.data?.data}`, '_blank', 'noopener,noreferrer');
            setLoading(false)
        }
        catch (error) {
            setShowChart(false)

            setLoading(false)
            setError(error.response.data?.message)
        }
    }


    useEffect (()=>{
        handleGetRiskProfile()
    },[])



    return (
        <div>
            <Box
                display="flex"
                alignItems="start"
                px={{ xs: 1, md: 17 }}
                pt={2}
                style={{ borderBottom: "1px solid #E6E3E3", }}
            >

                <Typography className={classes.subTabtext}>
                    My Profile
                </Typography>

                <Box display="flex">
                    <ChevronRightIcon style={{ height: "1rem" }} />
                    <Typography
                        className={classes.textUnderline}
                    >
                        Risk Profile
                    </Typography>
                </Box>
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                mt={3}
            >
                {showChart ? (
                    <RiskProfileChart data={riskProfileData}  showQuestion={showQuestion}/>
                ) : (
                    <>
                        {showQuestions ? (
                            <Questionareies questionsAreDone={questionsAreDone}  />
                        ) : (
                            <>
                                <Typography style={{ fontSize: "1.2rem", fontWeight: 500 }}>Risk Profile</Typography>
                                <Box width="100%" mt={4}>

                                    <Container maxWidth="lg" >
                                        <Grid container spacing={4}>
                                            <Grid item md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                                <Box style={{ textAlign: 'center' }}>
                                                    {isMobile && <img src="/images/riskProfileImage.svg" style={{ maxWidth: '533px', maxHeight: '100%', height: 'auto' }} />}
                                                    <Typography className={classes.mainText}>
                                                        Lets Understand Your Investment Style Better
                                                    </Typography>
                                                    <Typography className={classes.subText}>
                                                        Before making any investment decisions, its important to understand you attitude towards the risk. This help identity an appropriate mix of investment that you are comfortable with.

                                                    </Typography>
                                                    {/* <Typography style={{ fontSize: "1.2rem", fontWeight: 500, marginTop: '30px', }} >Lets understand your investment style better</Typography> */}
                                                    {/* <Link
                                        to={`${namespace}/dashboard/mutualfund/assisted-services/questionaries`}
                                    > */}


                                                    <button className={classes.primaryButton} type="button" onClick={() => setShowQuestions(true)}>
                                                        Continue
                                                    </button>
                                                    {/* </Link> */}
                                                </Box>
                                            </Grid>
                                            <Grid item md={6} style={{ textAlign: 'center' }}>
                                                {!isMobile && <img src="/images/riskProfileImage.svg" style={{ maxWidth: '533px', maxHeight: '100%', height: 'auto' }} />}
                                            </Grid>
                                        </Grid>
                                    </Container>


                                </Box>
                            </>
                        )}
                    </>
                )}
            </Box>
        </div>
    )
}

export default Index