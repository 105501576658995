import React, { useState } from "react";
import { Box, Tab, Tabs, Typography, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import DigitalaGold from "./DigitalGold/index";
import MutualFund from "./MutualFund";
import { namespace } from "../../environment";

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

const Index = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiTab-root": {
        fontFamily: "inherit",
        fontSize: "1rem",
        color: "black",
        opacity: 1,
        textTransform: "none",
        background: "none",
        minWidth: "auto",
      },
      "& .MuiTab-textColorInheri": {
        opacity: 0.9,
      },
    },

    activeTab: {
      "& .MuiTab-wrapper": {
        borderBottom: "2px solid #0E76BC",
        color: "#0E76BC",
        fontSize: "1rem",
      },
    },
    headingPart: {
      color: "#0E76BC",
      fontSize: "1.5rem",
    }
  }));
  const classes = useStyles();

  // const navigate = useNavigate();

  // const [searchParams, setSearchParams] = useSearchParams();

  // const tab = searchParams.get("tab");

  // const [value, setValue] = useState(tab === "mutualfund" ? 0 : 1);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  //   if (newValue == 0) {
  //     navigate({
  //       pathname: `${namespace}/myportfolio`,
  //       search: `?${createSearchParams({
  //         tab: "mutualfund",
  //         category: "diy",
  //       })}`,
  //     });
  //   } else {
  //     navigate({
  //       pathname: `${namespace}/myportfolio`,
  //       search: `?${createSearchParams({ tab: "digitalgold" })}`,
  //     });
  //   }
  // };

  return (
    <Box sx={{ width: "100%" }}>
      <Box px={{ md: 13 }} mt={5} textAlign={'center'}>
        <Typography variant="h6" className={classes.headingPart}>
          My Portfolio
        </Typography>
      </Box>
      {/*  <Box px={{ md: 15 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className={classes.root}
          indicatorColor={""}
        >
          <Tab
            label="Mutual Funds"
            {...a11yProps(0)}
            className={tab == "mutualfund" && classes.activeTab}
          /> */}
      {/* <Tab
            label="Digital Gold"
            {...a11yProps(1)}
            className={tab == "digitalgold" && classes.activeTab}
          /> */}
      {/* </Tabs>
      </Box> */}
      <Box >
        {/* <TabPanel value={value} index={0}> */}
        <MutualFund />
        {/* </TabPanel> */}

        {/* <TabPanel value={value} index={1}>
          <DigitalaGold />
        </TabPanel> */}
      </Box>
    </Box>
  );
};

export default Index;
