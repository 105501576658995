import React from 'react'
import Modal from "@material-ui/core/Modal";
import {
    Box
} from "@material-ui/core";
import Contact from '../../../components/Contact';
import AmountForm from './AmountForm';

const modalstyle = {
    // backgroundImage: "url('/images/Components.svg')",
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "right bottom",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    boxShadow: 24,
    p: 4,
    background: "#fff",
    borderRadius: "1rem",
    padding: "30px",
    outline: 'none',
    "@media (max-width: 480px)": {
        width: "90%",
        top: "64%",
        display: "column"
    }
};


function AmountModal(props) {
    const { showModal, handleCloseModal, handleShowSuccess, handleCloseSuccessModal, selectedFund } = props
    console.log(selectedFund)
    return (
        <Modal
            open={showModal}
            onClose={handleCloseModal}
            style={{ overflow: 'scroll' }}
        >
            <Box sx={modalstyle}>

                <AmountForm setModal={handleCloseModal} handleShowSuccess={handleShowSuccess} selectedFund={selectedFund}/>
            </Box>
        </Modal>

    )
}

export default AmountModal