import React, { useState } from 'react'
import { Box, makeStyles, Grid, Typography, FormControlLabel, Radio, Checkbox } from '@material-ui/core'

const Question = ({ data, index, store_answers, answers }) => {
    const useStyles = makeStyles({
        question: {
            color: "#0E76BC",
            fontWeight: 500
        },
        answerBox: {
            maxWidth: '690px',
            // border: '1px solid #DDDDDD',
            borderBottom: 0
        },
        radioButton: {

            "& .MuiRadio-colorPrimary.Mui-checked ": {
                color: "#0E76BC"
            },
            border: "1px solid #DDDDDD",
            margin: '10px 0px',
            borderRadius: '10px',
            "& .MuiTypography-body1": {
                fontSize: "0.85rem"
            }
        },
        radioButtonChecked: {
            radioButton: {

                "& .MuiRadio-colorPrimary.Mui-checked ": {
                    color: "#0E76BC"
                },
                border: "1px solid #0E76BC",
                margin: '10px 0px',
                borderRadius: '10px',
                "& .MuiTypography-body1": {
                    fontSize: "0.85rem"
                }
            }
        }
    })
    const classes = useStyles()

    const [answerId, setAnswerId] = useState('')

    const handle_answer = (e) => {
        setAnswerId(e.target.value)
        store_answers({ id: index, quizeChoiceMapId: e.target.value })
    }
    const CustomIcon = () => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0V0z"/>
            <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
          </svg>
        );
      };

      const CircularIcon = ({ selected }) => {
        return (
          <div style={{
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            border: '1px solid #DDDDDD',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            backgroundColor: selected ? '#0E76BC' : 'transparent',
            color: selected ? '#fff' : '#0E76BC',
          }}>
            {selected && <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '12px'}}>&#10003;</span>} {/* Render check mark if selected */}
          </div>
        );
      };


    return (
        <div>
            <Box>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography className={classes.question}>Question {index + 1}</Typography>
                        <p>{data?.question}</p>
                    </Grid>
                    <Grid item xs={12} >
                        <Box className={classes.answerBox}>
                            {data?.risk_answer_choices?.map((ele, i) => {
                                return (
                                    <Box key={i} className={classes.radioButton} px={2} py={1}
                                        style={{
                                            border: (answers?.some((choice => choice.quizeChoiceMapId == ele.id))) ? '1px solid #0E76BC' : '1px solid #DDDDDD',
                                        }}
                                    >

                                        {/* <FormControlLabel value={ele.id} control={<Radio color="primary" onChange={handle_answer} checked={answers?.some((choice => choice.quizeChoiceMapId == ele.id))} />} label={ele.answer} /> */}
                                        <FormControlLabel
                                            value={ele.id}
                                            control={
                                                <Radio
                                                    color="primary"
                                                    // icon={<Checkbox color="primary" selected={false}/>}
                                                    // checkedIcon={<CustomIcon />}
                                                    icon={<CircularIcon selected={false} />} // Use circular icon for unchecked state
                                                    checkedIcon={<CircularIcon selected={true} />} 
                                                    onChange={handle_answer}
                                                    checked={answers?.some((choice => choice.quizeChoiceMapId == ele.id))}
                                                />
                                            }
                                            label={ele.answer}
                                        />
                                    </Box>

                                )
                            })}


                        </Box>
                    </Grid>

                </Grid>
            </Box>
        </div>
    )
}

export default Question