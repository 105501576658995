import React from "react";
import { Box, Hidden, makeStyles } from "@material-ui/core";
import transitions from "@material-ui/core/styles/transitions";
function Footer() {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-around",
      flexDirection: "row",
      padding: "2rem 0rem 2rem 0rem",
      // background: "#484848",
      background: "#FFFFFF"
    },
    heading: {
      fontWeight: 500,
      fontSize: "1.2rem",
      color: "#3F3D56",
      margin: "0px 0px 1.4rem 0px",
    },
    subtitle: {
      fontWeight: 400,
      fontSize: "0.9rem",
      color: "#3F3D56",
      margin: "0.1rem 0",
      transitions: ".5s ease",
      "&:hover": {
        color: "#28282a",
      },
    },
    subDataAddressMobileNumber: {
      fontSize: '15px',
      fontWeight: '400',
      lineHeight: '27px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#3F3D56',
    },
    "@media (max-width: 900px)": {
      root: {
        display: "flex",
        alignItems: "start",
        flexDirection: "column",
        padding: "20px",
      },
      logo: {
        margin: "0 auto!important",
      },
    },
  }));
  const classes = useStyles();
  return (
    <Box maxWidth="lg" mt={4} style={{ borderTop: '1px solid #E4E4E4', boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px", }}>
      <footer className={classes.root}>
        <Box display={"flex"} flexDirection="column">
          {/* <Box overflow={'hidden'} height={100} width={100} textAlign={'center'}> */}
          {/* <img src="/MF 5 6.svg" alt="MF Wealth" height={100} /> */}
          {/* </Box> */}
          <Box
            overflow="hidden"
            height={{ xs: '80px', sm: '130px' }} // Smaller height for mobile, larger for desktop
            width={{ xs: '150px', sm: '250px' }} // Adjust width similarly
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <img
              src="/images/MF 5 5.svg"
              alt="MF Wealth"
              style={{
                height: 'auto',
                width: '100%', // Ensures the image scales with the container
                maxWidth: '425.12px', // Keeps the image within bounds for larger screens
              }}
            />
          </Box>
          {/* <Box >
            <a
              href="https://www.facebook.com/Bullsurge/photos/?ref=page_internal"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/facebook.svg"
                alt="facebook"
                width={22}
                height={24}
                style={{
                  padding: "5px",
                }}
              />
            </a>
            <a
              href="https://www.instagram.com/bullsurge/?utm_medium=copy_link"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/insta2.svg"
                alt="twitter"
                width={22}
                height={24}
                style={{ borderRadius: "50%", padding: "5px" }}
              />
            </a>
            <a
              href="https://twitter.com/bullsurge1"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/twitter.svg"
                alt="twitter"
                width={22}
                height={24}
                style={{
                  padding: "5px",
                }}
              />
            </a>

            <a
              href="https://www.linkedin.com/company/bullsurge/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/linkedin.svg"
                alt="linkedin"
                width={22}
                height={24}
                style={{
                  padding: "5px",
                }}
              />
            </a>

          </Box> */}
          <Box pt={3} className={classes.subDataAddressMobileNumber}>
            {/* <p> */}
            D13, 3RD FLOOR, SECTOR 3, NOIDA,<br />
            UTTAR PRADESH, PIN- 201301 <br />
            {/* </p> */}
          </Box>
          <Box pt={1} className={classes.subDataAddressMobileNumber}>
            <a href="tel:+919717438991" style={{ textDecoration: 'none' }} className={classes.subDataAddressMobileNumber}>+91 9717438991</a>
          </Box>
          <Box pt={1} className={classes.subDataAddressMobileNumber}>
            Email Us:
            {/* support@MF Wealth.in */}
            <a href="mailto:support@mfwealth.co.in" style={{ textDecoration: 'none' }} className={classes.subDataAddressMobileNumber}> &nbsp; support@mfwealth.co.in</a>
          </Box>
        </Box>
        <Box my={1}>
          <Box className={classes.heading}>Links</Box>
          <Box display="flex" flexDirection="column" mt={1}>
            <a href="/terms-conditions" style={{ textDecoration: "none" }}>
              <Box className={classes.subtitle}>Terms And Conditions</Box>
            </a>
          </Box>
          <Box display="flex" flexDirection="column" mt={1}>
            <a href="/privacy-policy" style={{ textDecoration: "none" }}>
              <Box className={classes.subtitle}>Privacy Policy</Box>
            </a>
          </Box>
          <Box display="flex" flexDirection="column" mt={1}>
            <a href="/cancellation-policy" style={{ textDecoration: "none" }}>
              <Box className={classes.subtitle}>Refund or Cancelation policy</Box>
            </a>
          </Box>
          <Box display="flex" flexDirection="column" mt={1}>
            <a href="/contact-us" style={{ textDecoration: "none" }}>
              <Box className={classes.subtitle}>Contact Us</Box>
            </a>
          </Box>
          <Box display="flex" flexDirection="column" mt={1}>
            <a href="/about-us" style={{ textDecoration: "none" }}>
              <Box className={classes.subtitle}>About Us</Box>
            </a>
          </Box>
          {/* <Box display="flex" flexDirection="column" mt={1}>
              <a href="" style={{ textDecoration: "none" }}>
                <Box className={classes.subtitle}>Digital Gallery</Box>
              </a>
            </Box> */}
        </Box>
        <Box my={1}>
          <Box className={classes.heading}>Social Media</Box>
          <Box display="flex" flexDirection="column" >
            <Box display="flex" flexDirection="column" >
              <a
                href="https://www.instagram.com/mf_wealth/?next=%2F"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Box className={classes.subtitle}>Instagram</Box>
              </a>
            </Box>

            <Box display="flex" flexDirection="column" mt={1}>
              <a
                href="https://www.facebook.com/"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Box className={classes.subtitle}>Facebook</Box>
              </a>
            </Box>
            <Box display="flex" flexDirection="column" mt={1}>
              <a
                href="https://x.com/MF_Wealth"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Box className={classes.subtitle}>Twitter</Box>
              </a>
            </Box>
          </Box>
        </Box>
        {/* <Box my={1}>
          <Box className={classes.heading}>Company</Box>
          <Box display="flex" flexDirection="column" mt={1}>
            <a href="/" style={{ textDecoration: "none" }}>
              <Box className={classes.subtitle}>Pricing</Box>
            </a>
          </Box>
        </Box>
        <Box my={1}>
          <Box className={classes.heading}>About MF Wealth</Box>
          <Box display="flex" flexDirection="column" mt={1}>
            <a
              href="/"
              style={{ textDecoration: "none" }}
            >
              <Box className={classes.subtitle}>About Us</Box>
            </a>

            <a
              href="/"
              style={{ textDecoration: "none" }}
            >
              <Box className={classes.subtitle}>Blog</Box>
            </a>
          </Box>
        </Box> */}
        {/* <Box my={1}>
        <Box className={classes.heading}>Product</Box>
        <Box display="flex" flexDirection="column" mt={1}>
          <a
            href="https://www.neosurge.money/Equity"
            style={{ textDecoration: "none" }}
          >
            <Box className={classes.subtitle}>Equity & ETF</Box>
          </a>

          <a
            href="https://www.neosurge.money/USStocks"
            style={{ textDecoration: "none" }}
          >
            <Box className={classes.subtitle}>US Stocks</Box>
          </a>
          <a
            href="https://www.bullsurge.in/"
            style={{ textDecoration: "none" }}
          >
            <Box className={classes.subtitle}>Authorized Partner</Box>
          </a>
        </Box>
      </Box> */}

        {/* <Box my={1}>
          <Box className={classes.heading}>Contact</Box>
          <Box display="flex" flexDirection="column" mt={1}>
            <Box className={classes.subtitle} fontWeight="fontWeightBold">
              Email Us:
            </Box>
            <a
              href="mailto:support@bullsurge.in"
              style={{ textDecoration: "none" }}
            >
              <Box
                className={classes.subtitle}
                style={{ textDecoration: "underline" }}
              >
                Support@Bullsurge.com
              </Box>
            </a>
            <Box className={classes.subtitle}>Write For Us:</Box>
            <a
              href="mailto:support@bullsurge.in"
              style={{ textDecoration: "none" }}
            >
              <Box
                className={classes.subtitle}
                style={{ textDecoration: "underline" }}
              >
                writeforus@Bullsurge.com
              </Box>
            </a>
          </Box>
        </Box> */}
      </footer>
    </Box>
  );
}

export default Footer;
