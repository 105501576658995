import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs, Typography, makeStyles, Grid, useMediaQuery } from "@material-ui/core";
import PropTypes from "prop-types";
import {
    useNavigate,
    createSearchParams,
    useSearchParams,
    useLocation,
} from "react-router-dom";
import { namespace } from "../../environment";
import { GET_GOALS_LIST } from "../../utils/endpoints";
import httpInstance from "../../utils/instance";
import { Skeleton } from "@mui/material";
import transitions from "@material-ui/core/styles/transitions";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const Index = () => {
    const useStyles = makeStyles((theme) => ({
        root: {
            "& .MuiTab-root": {
                fontFamily: "inherit",
                fontSize: "1rem",
                color: "black",
                opacity: 1,
                textTransform: "none",
                background: "none",
                minWidth: "auto",
            },
            "& .MuiTab-textColorInheri": {
                opacity: 0.9,
            },
        },

        activeTab: {
            "& .MuiTab-wrapper": {
                borderBottom: "2px solid #0E76BC",
                color: "#0E76BC",
                fontSize: "1rem",
            },
        },
        stepBox: {
            borderLeft: "1px solid #333",
            position: "absolute",
            margin: "2rem 0",
            flex: 1,
            height: "180px",
            display: "flex",
            alignItems: "start",
            justifyContent: "space-between",
            flexDirection: "column"
        },
        circle: {
            height: "12px",
            width: "12px",
            borderRadius: "100%",
            position: "relative",
            background: "#F3C04F",
            margin: "0px 0px 0px -6px",
            outline: "10px solid #fff"
        },
        stepContainer: {
            flexDirection: "row",
            marginTop: '2rem',
        },
        stepsTextHolder: {
            display: "flex",
            justifyContent: "space-around",
            alignItems: "space-around",
            flex: 1
        },
        gridGoalsImage: {
            display: 'flex',
            justifyContent: 'center',
            // alignItems: 'center',
            flexDirection: 'column',
        },
        imageHolder: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: 'column',
        },
        text: {
            fontFamily: 'Poppins',
            fontWeight: '500',
            fontSize: '30px',
            color: '#0D0D0DAD',
        },
        subText: {
            fontFamily: 'Poppins',
            fontWeight: '500',
            fontSize: '18px',
            color: '#5B5B5BDE',
        },
        goalsBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '164px',
            height: '125px',
            padding: '19.88px 15.9px 19.88px 15.9px',
            gap: '0px',
            borderRadius: '7.95px',
            border: '0.99px 0px 0px 0px',
            justifyContent: 'space-between',
            opacity: '0px',
            border: '0.99px solid #EDECF3',
            boxShadow: '0px 3.999999761581421px 3.999999761581421px 0px #00000040',
            margin: '10px 5px',
            cursor: 'pointer',
            transitions: 'transform .3s ease',
            '&:hover': {
                transition: '.2s ease-in-out',
                backgroundColor: '#f7f7f7',
                transform: 'translateY(-10px)',
                // border: '0.99px solid #bfd4ff',

            },
        },
        goalsBoxText: {
            fontFamily: 'Poppins',
            fontWeight: '500',
            fontSize: '14.91px',
            color: '#333333',
            textAlign: "center",
        },
        goalsSetUp: {
            display: "flex",
            // flexDirection: "column",
            alignItems: "center",
            justifyContent: "end",
            transition: '.3s ease-in-out',
            // gap: '10px',
        },

        "@media (max-width: 768px)": {
            title1: {
                display: "none"
            },
            container: {
                display: "flex",
                flexDirection: "column"
            },
            mobileTitle1: {
                display: "block",
                textAlign: "center"
            },
            stepContainer: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start"
            }
        },
        "@media (max-width: 480px)": {
            title1: {
                display: "none"
            },
            container: {
                display: "flex",
                flexDirection: "column"
            },
            mobileTitle1: {
                display: "block",
                textAlign: "center"
            },
            stepContainer: {
                display: "flex",
                // flexDirection: "column-reverse",
                alignItems: "center",
                justifyContent: "flex-start"
            },
            paragraph: {
                width: "100%"
            },
            imageHolder: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            },
            image: {
                margin: "2rem 0"
            }
        }
    }));
    const classes = useStyles();
    const location = useLocation();
    const isXs = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme => theme.breakpoints.between('sm', 'md'));

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const tab = location.pathname;

    const [value, setValue] = useState(tab === `${namespace}/goals` ? 0 : 1);
    const [goals, setGoals] = useState(null)


    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue == 0) {
            navigate({
                pathname: `${namespace}/goals`,
                // search: `?${createSearchParams({
                //   tab: "goals",
                //   category: "diy",
                // })}`,
            });
        } else {
            //   navigate({
            //     pathname: `${namespace}/myportfolio`,
            //     search: `?${createSearchParams({ tab: "digitalgold" })}`,
            //   });
        }
    };

    const HandleGoalsSelection = (value) => {
        // console.log(value)
        // navigate({
        //     pathname: `${namespace}/goals/type/${value?.name}`,
        //     state: {data: value}
        //     // search: `?${createSearchParams({
        //     //   tab: "value",
        //     // //   category: "diy",
        //     // })}`,
        // });
        navigate(`${namespace}/goals/type/${value?.name}`, {
            state: { data: value },
          });
    }




    // calculate my first corre post request 
    const getAllTheGoals = async () => {
        // const formData = {
        //     target_corpus: sliderValueOnChangeEnd,
        //     years: sliderValueOnChangeEndYear,
        //     expected_returns: percentage,
        // }
        const httpConfig1 = {
            method: "GET",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            // params: { isin: fundData?.isinCode },
        };

        try {
            const response = await httpInstance(`${GET_GOALS_LIST}`, httpConfig1);
            // console.log(response.data.data)
            setGoals(response?.data?.data);
            // setShowBarChart(true)
        } catch (error) {
            console.log(error.response?.data?.error)
        } finally {

        }

    }


    useEffect(() => {
        getAllTheGoals()
    }, [])


    const loadingGoals = [1, 2, 3, 4, 5, 6]




    return (
        <Box sx={{ width: "100%" }}>
            {/* <Box px={{ md: 15 }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className={classes.root}
                    indicatorColor={""}
                >
                    <Tab
                        label="Goals"
                        {...a11yProps(0)}
                        className={tab == `${namespace}/goals` && classes.activeTab}
                    />
                </Tabs>
            </Box> */}
            <Box px={{ md: 13 }}>
                <Box 
                // style={{ borderTop: "1px solid #E6E3E3", marginTop: "-12px" }}
                >
                    <TabPanel value={value} index={0}>

                        <Grid container className={classes.stepContainer} >
                            <Grid item xs={12} sm={6} lg={6} className={classes.gridGoalsImage}>
                                <Typography className={classes.text}>My Life Goals</Typography>
                                <Box className={classes.imageHolder} width="100%" >
                                    <img
                                        src="/images/SavingsBroGoal.svg"
                                        style={{ height: "319px", width: "auto" }}
                                        className={classes.image}
                                        alt="goalMain"
                                    />
                                    <Typography className={classes.subText}>Ready to start planning your financial goals?</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <Box
                                    width="100%"
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="start"
                                >
                                    <Typography className={classes.text} style={{ marginBottom: '5px' }}>Setup My Life Goals</Typography>
                                    {/* <Grid container className={classes.goalsSetUp} >
                                        <Grid item xs={12} sm={6} lg={6} style={isXs ? { display: 'flex', justifyContent: 'center' } : (isSm ? { display: 'flex', justifyContent: 'center' } : { display: 'flex', justifyContent: 'end' })} className={classes.goalsSingleGrid}>
                                            <Box className={classes.goalsBox} onClick={() => HandleGoalsSelection("MyFirstCrore")}>
                                                <img
                                                    src="/images/myFirstCrore.svg"
                                                    style={{ height: "60px", width: "auto", marginTop: '10px' }}
                                                    className={classes.image}
                                                />
                                                <Typography className={classes.goalsBoxText}>My First Crore</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={6} style={isXs ? { display: 'flex', justifyContent: 'center' } : (isSm ? { display: 'flex', justifyContent: 'center' } : { display: 'flex', justifyContent: 'start' })} className={classes.goalsSingleGrid}>
                                            <Box className={classes.goalsBox} onClick={() => HandleGoalsSelection("TaxSaivngs")} >
                                                <img
                                                    src="/images/taxSavings.svg"
                                                    style={{ height: "60px", width: "auto", marginTop: '10px' }}
                                                    className={classes.image}
                                                />
                                                <Typography className={classes.goalsBoxText}>Tax Saivngs</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} style={isXs ? { display: 'flex', justifyContent: 'center' } : (isSm ? { display: 'flex', justifyContent: 'center' } : { display: 'flex', justifyContent: 'center' })} className={classes.goalsSingleGrid}>
                                            <Box className={classes.goalsBox} onClick={() => HandleGoalsSelection("ParkYourSpareMoney")} >
                                                <img
                                                    src="/images/parkYourSpareMoney.svg"
                                                    style={{ height: "60px", width: "auto", marginTop: '10px' }}
                                                    className={classes.image}
                                                />
                                                <Typography className={classes.goalsBoxText}>Park Your Spare Money</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} style={isXs ? { display: 'flex', justifyContent: 'center' } : (isSm ? { display: 'flex', justifyContent: 'center' } : { display: 'flex', justifyContent: 'center' })} className={classes.goalsSingleGrid}>
                                            <Box className={classes.goalsBox} onClick={() => HandleGoalsSelection("Retirement")} >
                                                <img
                                                    src="/images/retirement.svg"
                                                    style={{ height: "60px", width: "auto", marginTop: '10px' }}
                                                    className={classes.image}
                                                />
                                                <Typography className={classes.goalsBoxText}>Retirement</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} style={isXs ? { display: 'flex', justifyContent: 'center' } : (isSm ? { display: 'flex', justifyContent: 'center' } : { display: 'flex', justifyContent: 'center' })} className={classes.goalsSingleGrid}>
                                            <Box className={classes.goalsBox} onClick={() => HandleGoalsSelection("LongTermWealth")} >
                                                <img
                                                    src="/images/longTermWealth.svg"
                                                    style={{ height: "60px", width: "auto", marginTop: '10px' }}
                                                    className={classes.image}
                                                />
                                                <Typography className={classes.goalsBoxText}>Long Term Wealth</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={6} style={isXs ? { display: 'flex', justifyContent: 'center' } : (isSm ? { display: 'flex', justifyContent: 'center' } : { display: 'flex', justifyContent: 'end' })} className={classes.goalsSingleGrid}>
                                            <Box className={classes.goalsBox} onClick={() => HandleGoalsSelection("PrincipalProtection")} >
                                                <img
                                                    src="/images/principalProtection.svg"
                                                    style={{ height: "60px", width: "auto", marginTop: '10px' }}
                                                    className={classes.image}
                                                />
                                                <Typography className={classes.goalsBoxText}>Principal Protection</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={6} style={isXs ? { display: 'flex', justifyContent: 'center' } : (isSm ? { display: 'flex', justifyContent: 'center' } : { display: 'flex', justifyContent: 'start' })} className={classes.goalsSingleGrid}>
                                            <Box className={classes.goalsBox} onClick={() => HandleGoalsSelection("EmergencyFund")} >
                                                <img
                                                    src="/images/emergencyFund.svg"
                                                    style={{ height: "60px", width: "auto", marginTop: '10px' }}
                                                    className={classes.image}
                                                />
                                                <Typography className={classes.goalsBoxText}>Emergency Fund</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid> */}
                                    {goals ?
                                        <Grid container className={classes.goalsSetUp}>
                                            {goals.map((goal, index) => (
                                                <>
                                                    {/* {console.log(index, goals.length - 1, index === goals.length)} */}
                                                    <Grid
                                                        key={index}
                                                        item
                                                        xs={6}
                                                        sm={index === goals.length - 1 ? ((goals.length - 1) % 2 === 0 ? 12 : 6) : 6}
                                                        // lg={index === (2 || 3 || 4) ? 4 : 6} // For the second row, set lg to 4, else 6
                                                        lg={index === (3) ? 4 : 6 && index === (2) ? 4 : 6 && index === (4) ? 4 : 6 && index === (5) ? 12 : 6} // For the second row, set lg to 4, else 6

                                                        style={
                                                            isXs
                                                                ? { display: 'flex', justifyContent: 'center' }
                                                                : isSm
                                                                    ? { display: 'flex', justifyContent: 'center' }
                                                                    // : { display: 'flex', justifyContent: index === 2 ? 'center' : (index == 0 ? 'end' : (index == 1 ? 'start' : (index % 2 === 0 ? 'start' : 'end'))) && index === 3 ? 'center' : (index == 0 ? 'end' : (index == 1 ? 'start' : (index % 2 === 0 ? 'start' : 'end'))) && index === 4 ? 'center' : (index == 0 ? 'end' : (index == 1 ? 'start' : (index % 2 === 0 ? 'start' : 'end'))) } // For the second row, center, else end
                                                                    : { display: 'flex', justifyContent: index === 2 ? 'center' : (index == 0 ? 'end' : (index == 1 ? 'start' : index == 5 ? "center" : (index % 2 === 0 ? 'start' : 'end'))) }
                                                            // : { display: 'flex', justifyContent: 'center' } // For the second row, center, else end
                                                        }
                                                        className={classes.goalsSingleGrid}
                                                    >
                                                        <Box className={classes.goalsBox} onClick={() => HandleGoalsSelection(goal)}>
                                                            <img
                                                                src={goal.icon}
                                                                style={{ height: "60px", width: "auto", marginTop: '10px' }}
                                                                className={classes.image}
                                                                alt={goal.name}
                                                            />
                                                            {/* {index} */}
                                                            <Typography className={classes.goalsBoxText}>{goal.name}</Typography>
                                                        </Box>
                                                    </Grid>
                                                </>
                                            ))}
                                        </Grid>
                                        :
                                        <Grid container className={classes.goalsSetUp}>
                                            {loadingGoals.map((key, index) => (
                                                <>
                                                    {/* {console.log(index, goals.length - 1, index === goals.length)} */}
                                                    <Grid
                                                        key={index}
                                                        item
                                                        xs={6}
                                                        sm={index === loadingGoals.length - 1 ? ((loadingGoals.length - 1) % 2 === 0 ? 12 : 6) : 6}
                                                        // lg={index === (2 || 3 || 4) ? 4 : 6} // For the second row, set lg to 4, else 6
                                                        lg={index === (3) ? 4 : 6 && index === (2) ? 4 : 6 && index === (4) ? 4 : 6 && index === (5) ? 12 : 6} // For the second row, set lg to 4, else 6

                                                        style={
                                                            isXs
                                                                ? { display: 'flex', justifyContent: 'center' }
                                                                : isSm
                                                                    ? { display: 'flex', justifyContent: 'center' }
                                                                    // : { display: 'flex', justifyContent: index === 2 ? 'center' : (index == 0 ? 'end' : (index == 1 ? 'start' : (index % 2 === 0 ? 'start' : 'end'))) && index === 3 ? 'center' : (index == 0 ? 'end' : (index == 1 ? 'start' : (index % 2 === 0 ? 'start' : 'end'))) && index === 4 ? 'center' : (index == 0 ? 'end' : (index == 1 ? 'start' : (index % 2 === 0 ? 'start' : 'end'))) } // For the second row, center, else end
                                                                    : { display: 'flex', justifyContent: index === 2 ? 'center' : (index == 0 ? 'end' : (index == 1 ? 'start' : index == 5 ? "center" : (index % 2 === 0 ? 'start' : 'end'))) }
                                                            // : { display: 'flex', justifyContent: 'center' } // For the second row, center, else end
                                                        }
                                                        className={classes.goalsSingleGrid}
                                                    >
                                                        <Box className={classes.goalsBox}>
                                                            <Skeleton variant="rounded" width={60} height={60} />
                                                            <Skeleton variant="text" width="100%" height={40} />
                                                        </Box>
                                                    </Grid>
                                                </>
                                            ))}
                                        </Grid>
                                    }
                                </Box>
                            </Grid>

                        </Grid>
                    </TabPanel>
                </Box>
            </Box>

        </Box>
    );
};

export default Index;
