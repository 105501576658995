import React, { useEffect, useState } from "react";
import { Box, makeStyles, Typography, Grid } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import { GET_MY_INVESTMENT } from "../../../utils/endpoints";
// import httpInstance from "../../../utils/instance";
import moment from "moment";
import { Link } from "react-router-dom";
import PerformanceSummary from "./PerformanceSummary";
import AssestAllocation from "./AssestAllocation.js";

const Index = ({ holdingsData }) => {
    const useStyles = makeStyles({
        subTabtext: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
            fontWeight: 500,
        },
        textUnderline: {
            fontSize: "0.7rem",
            textDecoration: "underline",
            fontFamily: "inherit",
        },

        title: {
            fontSize: "1.1rem",
            fontWeight: 600,
        },
        textgrey: {
            fontSize: "0.8rem",
            color: "#626262",
        },
        searchBar: {
            padding: "5px 8px",
            display: "flex",
            alignItems: "center",
            margin: "20px 0px 30px 0px",
            borderRadius: "10px",
            "& .MuiSvgIcon-root": {
                opacity: 0.4,
            },
        },
        input: {
            marginLeft: "10px",
            flex: 1,
            width: 100,
        },
        text: {
            textAlign: "center",
        },
    });
    const classes = useStyles();

    const [investements, setInvestments] = useState([]);
    const [inactiveInvest, setInactiveInvest] = useState([]);
    const [value, setValue] = useState("active");

    const orderTypeObj = {
        2: "SIP",
        5: "STP",
        6: "SWP",
    };
    const orderTypeColor = {
        2: "#F27E45",
        5: "#4579F5",
        6: "#CB5D38",
    };

    const get_investment = async () => {
        const userId = localStorage.getItem("user_id");
        const httpConfig = {
            method: "GET",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            // params: { userId: userId, isActive: true },
        };

        const httpConfig1 = {
            method: "GET",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            params: { userId: userId, isActive: false },
        };

        // try {
        //   const [response1, response2] = await Promise.all([
        //     httpInstance(`${GET_MY_INVESTMENT}`, httpConfig),
        //     // httpInstance(`${GET_MY_INVESTMENT}`, httpConfig1),
        //   ]);
        //   // console.log(response1)
        //   setInvestments(response1.data?.data);
        //   holdingsData(response1.data?.data)
        //   // setInactiveInvest(response2.data?.data);

        //   // const inactive= await httpInstance(`${GET_INACTIVE_SIP}`,httpConfig)
        //   // setInactiveSip(inactive.data?.data)
        // } catch {}
    };

    useEffect(() => {
        get_investment();
    }, []);


    // console.log(investements, 'orderTypeObj')



    return (
        <div>
            {/* <Box
                display="flex"
                alignItems="start"
                px={{ xs: 1, md: 17 }}
                pt={2}
                style={{ borderBottom: "1px solid #E6E3E3" }}
            >
                <Link
                    to="/myportfolio"
                    style={{ color: "black" }}
                >
                    <Typography className={classes.textUnderline}>
                        My Portfolio
                    </Typography>
                </Link>
                <Box display="flex">
                    <ChevronRightIcon style={{ height: "1rem" }} />
                    <Typography className={classes.subTabtext}>
                        Portfolio Analysis
                    </Typography>
                </Box>
            </Box> */}


            <Box px={{ xs: 1, md: 17 }} mt={4}>
                <Box display="flex" alignItems={'center'} alignContent={'center'}
                >
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        alignItems={'center'}
                        alignContent={'center'}
                        // px={17}
                        pr={2}
                        // mt={2}
                        className={classes.backButton}
                    >
                        <Link to="/myportfolio" style={{ display: 'flex', alignItems: 'center' }}>
                            <img src="/images/backIcon.svg" height="20px" width="20px" />
                        </Link>
                    </Box>
                    <Typography className={classes.title}>
                        Portfolio Analysis
                    </Typography>
                </Box>

                {/* <Typography className={classes.textgrey}>
          Tansfer a fixed amount of money every month from a mutual fund
        </Typography> */}
                <PerformanceSummary />

                <Box>
                    <AssestAllocation />
                </Box>
            </Box>

        </div>
    );
};

export default Index;
