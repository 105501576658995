import React, { useState, useEffect } from 'react'
import { Box, makeStyles, Typography, Grid } from '@material-ui/core'
import { GET_ALL_RETURNS } from '../../../utils/endpoints'
import httpInstance from '../../../utils/instance'
import LocaleString from '../../../hooks/LocaleString'
import { namespace } from '../../../environment'
import {
    useNavigate,
    createSearchParams,
    useSearchParams,
} from "react-router-dom";
import CountUp from 'react-countup'

const Total_Invest = ({ holdings }) => {
    const useStyles = makeStyles({
        root: {
            borderRadius: "12px",
            // boxShadow: "0 0 8px -3px #444444",
            border: '1px solid #E4E4E4',
            // boxShadow: '0 0 8px -3px #444444',
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
        },
        header1: {
            fontSize: "1.2rem",
            fontWeight: 600
        },
        header: {
            fontSize: "2rem",
            fontWeight: 600
        },
        title: {
            fontSize: "1rem",
            fontWeight: 500
        },
        text: {
            fontSize: "0.9rem",
            color: "#F32424"
        },
        text1: {
            fontSize: "1.5rem",
            fontWeight: 600,

        },
        text2: {
            fontSize: "1.5rem",
            color: "#4cbc4c",
            fontWeight: 600,
        },
        text3: {
            fontSize: "1rem",
            fontWeight: 500,

        },
        grid: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            "& p": {
                margin: "5px"
            }
        },
        rupees: {
            fontFamily: "Open Sans"
        },
        primaryButton: {
            padding: "0.7rem 3rem",
            background: "#0E76BC",
            color: "#fff",
            fontSize: "0.9rem",
            fontFamily: "Poppins",
            fontWeight: 600,
            borderRadius: "5px",
            border: "none",
            outline: "none",
            cursor: "pointer",
        },

    })
    const classes = useStyles()
    const [data, setData] = useState()
    const navigate = useNavigate();

    const GoToAnalysis = () => {
        // console.log('analysis page')
        navigate(
            `${namespace}/myportfolio/view-analysis`,
            // {
            //   state: {
            //     fundsData: response.data?.data,
            //     totalAmount: amount,
            //     tenure: year,
            //   },
            // }
        );
    }

    // const get_all_investment=async()=>{
    //     const userId=localStorage.getItem("user_id")
    //     const httpConfig = {
    //         method: "GET",
    //         tokenType: "user",
    //         headers:{
    //           "Content-Type": "application/json",
    //         },
    //         params:{userId:userId}
    //       };
    //       try{
    //         const response=await httpInstance(`${GET_ALL_RETURNS}`,httpConfig)
    //         setData(response.data?.data)
    //       }
    //       catch{

    //       }
    // }

    // useEffect(()=>{
    //     get_all_investment()
    // },[])
    return (
        <div>
            {holdings &&
                <Box className={classes.root} p={{ xs: 1, md: 4 }} px={{ xs: 0, md: 5 }} display="flex" flexDirection="column" alignItems="center" mt={3}>
                    <Typography className={classes.header1}>Currently invested value</Typography>
                    <Typography className={classes.header}><span className={classes.rupees}> ₹</span>
                        <CountUp
                            end={holdings?.current_value ? holdings?.current_value : 0}
                            duration={2}
                            decimals={2}
                            decimal="."
                            formattingFn={(number) => { return number.toLocaleString('en-IN') }}
                        />
                        {/* {LocaleString(holdings?.current_value)} */}
                    </Typography>
                    <p className={classes.text3}>1 day  change: <span className={classes.rupees}> ₹</span>{LocaleString(holdings?.day_change?.toFixed(2))} </p>
                    <Grid container style={{ marginTop: "10px" }}>
                        {/* <Grid item xs={6} md={3} className={classes.grid}>
                   
                    <p className={classes.title}>1D Returns</p>
                    <p className={classes.text}>+₹31 (1.5%)</p>
                    
                </Grid> */}
                        <Grid item xs={4} className={classes.grid}>
                            <p className={classes.title}>Invested Amount</p>
                            <p className={classes.text1}><span className={classes.rupees}> ₹</span>{LocaleString(holdings?.invested_amount)} </p>
                        </Grid>
                        <Grid item xs={4} className={classes.grid}>
                            <p className={classes.title}>Total Returns</p>
                            <p className={holdings?.absolute_return > 0 ? classes.text2 : classes.text1}>{holdings?.absolute_return > 0 && "+"}<span className={classes.rupees}> ₹</span>{LocaleString(holdings?.unrealized_gain?.toFixed(2))}
                                {/* ({holdings?.unrealized_gain?.toFixed(2)}%) */}
                            </p>
                        </Grid>
                        <Grid item xs={4} className={classes.grid}>
                            <p className={classes.title}>XIRR %</p>
                            <p className={classes.text1}><span className={classes.rupees}> ₹</span>{LocaleString(holdings?.xirr ? holdings?.xirr?.toFixed(2) : '0.00')} </p>
                        </Grid>
                        {/* <Grid item xs={12} md={4} className={classes.grid}>
                    <p className={classes.title}>Abs return</p>
                    <p className={classes.text1}>{holdings?.returnsPercentage.toFixed(2)}%</p>
                </Grid> */}
                        {/* <Grid item xs={12} md={4} className={classes.grid}>
                    <p className={classes.title}>Current Value</p>
                    <p className={classes.text1}>₹{LocaleString(data?.currentValue)}</p>
                </Grid> */}
                    </Grid>
                    <Box mt={2}>
                        <button className={classes.primaryButton} onClick={GoToAnalysis} style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                            View Analysis <img src="/images/rightArrow.svg" alt="Right Arrow" style={{ paddingLeft: '5px' }} width={15} height={15} />
                        </button>
                    </Box>
                </Box>
            }
        </div>
    )
}

export default Total_Invest