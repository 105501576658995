import React, { useEffect, useState } from "react";
import { useStyles } from "../styles";
import { Box, CircularProgress } from "@material-ui/core";
import TitleLayoult from "../TitleLayoult";
import httpInstance from "../../../utils/instance";
import {
  VERIFY_MOBILE_OTP,
  ADD_MOBILE_NUMBER,
  VERIFY_FORGOT_MPIN,
  VERIFY_MOBILE_OTP_LOGIN,
  GET_USER,
  GET_USER_ONBOARDING_DETAILS,
  GET_USER_ONBOARDING_STATUS,
} from "../../../utils/endpoints";
import ErrorMessage from "../../Validation/ErrorMessage";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import OtpInput from "react-otp-input";
import { namespace } from "../../../environment";
import CustomToast from "../../UI/CustomToast";


function PhoneOtp({ step, descriptionData }) {

  // const LoaderComp = () => {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         marginTop: "1rem",
  //         overflow: "hidden",
  //       }}
  //     >
  //       <CircularProgress size="2rem" />
  //     </div>
  //   );
  // };


  const classes = useStyles();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false)


  const resetMpin = searchParams.get("reset");

  const [otpError, setOtpError] = useState(false);
  const [otp, setOpt] = useState("");
  const [resendmsg, setResendMsg] = useState("");

  const handleChange = (code) => setOpt(code);

  //-------------------------------------SUBMIT OTP----------------------------------------------------------------------//
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    // if (resetMpin) {
    // const httpConfig = {
    //   method: "POST",
    //   tokenType: "user",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   data: { otp: otp },
    // };
    // try {
    //   await httpInstance(`${VERIFY_FORGOT_MPIN}`, httpConfig);
    //   navigate({
    //     pathname: `${namespace}/signIn`,
    //     search: `?${createSearchParams({
    //       stepcount: "mpin_generate",
    //       reset: "mpin",
    //     })}`,
    //   });
    // } catch { }
    // } else {

    // Old code ---------------------------------------------------
    // const formData = {
    //   mobile: descriptionData.mobile,
    //   otp: otp,
    // };

    // New code -----------------------------------------
    // console.log(descriptionData, descriptionData?.Alldata?.id, descriptionData?.mobile)
    const formData = {
      ...(descriptionData.Alldata?.mobile ? { user_id: descriptionData?.Alldata?.id } : { mobile: descriptionData?.mobile }),
      // ...(descriptionData.Alldata ? { mobile: descriptionData?.mobile } :  { user_id: descriptionData?.Alldata?.id } ),
      otp: otp,
    };

    const httpConfig = {
      method: "POST",
      // tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    // console.log("httpConfig", httpConfig)
    // console.log(descriptionData.Alldata.mobile_verified)
    // console.log(`${descriptionData.Alldata.mobile_verified ? VERIFY_MOBILE_OTP:  VERIFY_MOBILE_OTP_LOGIN }`)

    try {
      const response = await httpInstance(`${!descriptionData.Alldata.id ? VERIFY_MOBILE_OTP_LOGIN : VERIFY_MOBILE_OTP}`, httpConfig);
      // console.log(response.data)
      // -------------------------------------------------------- old code ----------------------------------------------- //
      // navigate({
      //   pathname: `${namespace}/signIn`,
      //   // search: `?${createSearchParams({ stepcount: "mpin_generate" })}`,
      // });

      // -------------------------------------------------------- new code ----------------------------------------------- //
      localStorage.setItem("user_token", response.data?.access_token);
      // localStorage.setItem("refresh_token", response.data?.refreshToken);
      localStorage.setItem("user_id", response.data?.id);

      const httpConfig1 = {
        method: "GET",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
      };
      navigate({
        pathname: `${namespace}/dashboard/mutualfund`,
        // search: `?${createSearchParams({ stepcount: "mpin_generate" })}`,
      });
      setLoading(false)
    } catch (error) {
      console.log(error.response)
      setOtpError(error.response.data?.error);
      setLoading(false)
      CustomToast(error?.response?.data?.error || "Something went wrong", "FAILED")

      if (error.response.data?.error === "No Onboarding found" && error.response.data?.status === 404) {
        navigate({
          pathname: `${namespace}/signIn`,
          search: `?${createSearchParams({ stepcount: "2" })}`,
        });
      }

      // if (
      //   error.response.data?.message === "Invalid OTP" ||
      //   error.response.data?.message === "OTP Expired"
      // ) {
      //   // setOtpError(true);
      //   setOtpError(error.response?.error);
      // }
    }


    // } catch (error) {
    //   console.log(error)
    //   // setOtpError(error.response.data?.message);
    // }
    // }
  };
  //-------------------------------resend OTP------------------------------------------------------------------------//
  const handleResentOtp = async (data) => {
    const httpConfig = {
      method: "POST",
      // tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        mobile: descriptionData.mobile,
        is_generate: null
      },
    };

    try {
      const response = await httpInstance(`${ADD_MOBILE_NUMBER}`, httpConfig);
      setResendMsg(response.data?.message);
      CustomToast("OTP sent successfully", "SUCCESS")

    } catch (error) {
      CustomToast(error?.response?.data?.error || "Something went wrong", "FAILED")
    }
  };

  useEffect(() => {
    if (!descriptionData.mobile) {
      navigate({
        pathname: `${namespace}/user`,
        // search: `?${createSearchParams(
        //   isComplient ? { stepcount: "2" } : { stepcount: "9" }
        // )}`,
      });
    }


  }, [descriptionData])

  const handleback = () => {
    navigate({
      pathname: `${namespace}/user`,
      // search: `?${createSearchParams(
      //   isComplient ? { stepcount: "2" } : { stepcount: "9" }
      // )}`,
    });
  };

  return (
    <div>

      <Box display={"flex"} justifyContent="center">
        <Box
          display={"flex"}
          alignItems="flex-start"
          flexDirection={"column"}
          p={3}
        >
          <Box>
            <Box
              width={'100%'}
              display="flex"
              justifyContent="flex-start"
              // px={20}
              className={classes.backButton}
              onClick={handleback}
            >
              <img src="/images/backIcon.svg" />
            </Box>
            <Box display={'flex'} justifyContent={'center'}>
              <Box height={80} width={160} overflow={'hidden'} display={'flex'} justifyContent={'center'} style={{ cursor: 'pointer' }}>
                <img src="/MF 5 6.svg" height={100} width={250} alt="logo" />
              </Box>
            </Box>
            <TitleLayoult
              title="Verify your Phone number"
              description={`Please Enter the OTP sent to ${descriptionData.mobile
                ? descriptionData.mobile.substring(0, 2)
                : ""
                }********to verify your phone number`}
            />
          </Box>
          <Box width={'100%'}>
            <form onSubmit={handleOtpSubmit} >
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={4}
                renderSeparator={<span style={{ width: "8px" }}></span>}
                isInputNum={true}
                shouldAutoFocus={true}
                renderInput={(props) => <input {...props} />}

                // inputStyle={{
                //   border: "0px 0px 0px 1px",
                //   // borderRadius: "8px", 
                //   borderRadius: "0px",
                //   borderBottom: "1px solid #000 !important",
                //   width: "40px",
                //   height: "40px",
                //   fontSize: "13px",
                //   color: "#000",
                //   fontWeight: "400",
                //   caretColor: "blue",
                //   background: "#E6E6E6",
                // }}
                // focusStyle={{
                //   // border: "1px solid #CFD3DB",
                //   borderBottom: "1px solid #000",
                //   background: "#E6E6E6",
                //   outline: "none",
                // }}
                inputStyle={{
                  border: "none",
                  borderRadius: "0px",
                  borderBottom: "1px solid #000",
                  width: "40px",
                  height: "40px",
                  fontSize: "13px",
                  color: "#000",
                  fontWeight: "400",
                  caretColor: "blue",
                }}
                focusStyle={{
                  border: "none",
                  borderRadius: "0px",
                  borderBottom: "1px solid #000", // Ensure this matches inputStyle
                  background: "#E6E6E6",
                  outline: "none",
                }}
              />
              <p className={classes.resendOtpText} onClick={handleResentOtp}>
                Resend OTP
              </p>
              <p className={classes.verifiedKycText}>{resendmsg}</p>
              {otpError && <ErrorMessage message={otpError} />}
              <Box mt={5} display="flex" justifyContent="center">
                <button className={classes.primaryButton} type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress size="1rem" color="inherit" />
                  ) : (
                    "Verify"
                  )}
                </button>
              </Box>
              {/* <LoaderComp /> */}
            </form>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default PhoneOtp;
