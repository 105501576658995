import React, { useState, useEffect } from "react";
import Question from "../../../components/Assisted/Question";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { GET_USER_QUESTIONS, SUBMIT_ANSWERS } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import ErrorMessage from "../../../components/Validation/ErrorMessage";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUser } from "../../../redux/User/userActions";
import { namespace } from "../../../environment";

const Questionareies = ({questionsAreDone}) => {
  const useStyles = makeStyles({
    slideBoxActive: {
      padding: "3px",
      background: "#0E76BC",
      width: "40%",
      margin: "0px 5px",
      cursor: "pointer",
    },
    slideBox: {
      padding: "3px",
      background: "#DDDDDD",
      width: "40%",
      margin: "0px 5px",
      cursor: "pointer",
    },

    primaryButton: {
      color: "white",
      backgroundColor: "#0E76BC",
      border: "none",
      borderRadius: "39px",
      fontSize: "15px",
      fontWeight: "500",
      padding: "10px 3rem",
      fontFamily: "inherit",
      cursor: "pointer",
    },
  });
  const classes = useStyles();

  const [index, setIndex] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [error, setError] = useState("");
  const [riskProfileScore, setRiskProfileScore] = useState("");
  const navigate = useNavigate();

  const { userStore } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleIndex = (i) => {
    if (answers[i]) {
      setIndex(i);
    }
  };

  const get_questions = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${GET_USER_QUESTIONS}`, httpConfig);
      setQuestions(response.data?.data);
    } catch { }
  };

  useEffect(() => {
    setRiskProfileScore(userStore?.user?.riskProfileScore);
  }, [userStore, riskProfileScore]);

  useEffect(() => {
    get_questions();
  }, []);

  const handle_next = () => {
    if (index === questions.length - 1) {
      if (answers.length == questions.length) {
        submit_answer(answers[index]);
      } else {
        setError("Please answer all the questions");
      }
    } else {
      if (answers[index]) {
        submit_answer(answers[index]);
        setError(false);
        setIndex(index + 1);
      } else {
        setError("Please answer the questions");
      }
    }
  };

  //-------------------------update or insert item----------------------------------------//
  const upsert = (item) => {
    let array = [...answers];
    const i = array.findIndex((_item) => _item.id === item.id);
    if (i > -1) array[i] = item; // (2)
    else array.push(item);

    setAnswers(array);
  };

  //-----------------------------set answers-----------------------------------//
  const store_answers = (data) => {
    upsert(data);
  };
  //---------------------------submit answers------------------------------------------//
  const submit_answer = async (ans) => {
    console.log(ans, 'ans')
    // let answerArry = answers.map((ele) => {
    //   return { quizeChoiceMapId: ele.quizeChoiceMapId };
    // });
    const formData = {
      user_id: localStorage.getItem("user_id"),
      risk_profile_question_id:ans?.id + 1,
      risk_answer_choice_id: ans?.quizeChoiceMapId

      // userQuizeChoices: answerArry,
    };
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    try {
      await httpInstance(`${SUBMIT_ANSWERS}`, httpConfig);
      if (index === questions.length - 1){
        questionsAreDone()
      }
      // dispatch(getUser());
      // navigate(
      //   `${namespace}/dashboard/mutualfund/assisted-services/invest-detail`
      // );
    } catch { }
  };
  return (
    <div style={{width:'100%',}}>
      {/* {typeof riskProfileScore != "undefined" && ( */}
      <>
         <Box px={{ md: 20 }} mt={6} >
          <Typography style={{ fontSize: "1.2rem", fontWeight: 500 }}>Risk Profile Questions</Typography>

          <Box display="flex" justifyContent="center" mt={5}>
            {[...Array(questions?.length)].map((ele, i) => {
              return (
                <Box
                  key={i}
                  className={
                    i == index ? classes.slideBoxActive : classes.slideBox
                  }
                  onClick={() => handleIndex(i)}
                ></Box>
              );
            })}
          </Box>
          <Box  mt={6} p={0}>
            <Question
              data={questions && questions[index]}
              index={index}
              store_answers={store_answers}
              answers={answers}
            />
          </Box>


          <Box display="flex" justifyContent="center" mt={3}>
            <button onClick={handle_next} className={classes.primaryButton}>
              Next
            </button>
          </Box>
          <Box display="flex" justifyContent="center" mt={1}>
            {error && <ErrorMessage message={error} />}
          </Box>
        </ Box>
      </>
      {/* )} */}
    </div>
  );
};

export default Questionareies;
