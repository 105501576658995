import React, { useState, useEffect } from 'react'
import { Box, Typography, makeStyles, Grid, FormControlLabel, Checkbox, IconButton, Menu, MenuItem } from '@material-ui/core'
import AddBankForm from './AddBankForm'
import { useSelector } from 'react-redux'
import { GET_BANK_DETAILS, UPDATE_PRIMARY_BANK, UPDATE_INVESTOR_ACCOUNT, GET_MANDATE, GET_ALL_BANK, GET_KYC_DATA, GET_USER_ONBOARDING_DETAILS } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { fontSize } from '@mui/system'
import ReactLoading from 'react-loading';
import ConfirmPrimary from './ConfirmPrimary'
import { BASE_URL } from '../../../environment'
import { Skeleton } from '@mui/material';




const Index = () => {
  const useStyles = makeStyles((theme) => ({
    root: {

    },
    header: {
      fontWeight: 600
    },
    imageBack: {
      padding: "4px 5px 0px",
      background: "#ADDFFF",
      borderRadius: "50%"
    },
    title1: {
      fontSize: "0.8rem",
      padding: "0px 10px",
      fontWeight: 600
    },
    text: {
      fontSize: "0.75rem",
      fontWeight: 500
    },
    textblue: {
      fontSize: "0.75rem",
      fontWeight: 500,
      color: "#0E76BC",
      display: "flex",
      alignItems: "center",
      marginLeft: "10px"
    },
    text2: {
      fontSize: "0.8rem",
      padding: "5px 0px"
    },
    text3: {
      fontSize: "0.8rem",
      padding: "5px 0px",
      fontWeight: 600
    },
    borderBox: {
      // border: "1px solid #C0C0C0",
      // borderRadius: "5px",
      borderRadius: "12px",
      // boxShadow: "0 0 8px -3px #444444",
      border: '1px solid #E4E4E4',
      // boxShadow: '0 0 8px -3px #444444',
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
    },



    blueBorderBox: {
      // border: "3px solid #0E76BC",
      // borderRadius: "5px"
      borderRadius: "12px",
      // boxShadow: "0 0 8px -3px #444444",
      border: '3px solid #0E76BC',
      // boxShadow: '0 0 8px -3px #444444',
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
    },

    gridPadding: {
      padding: "0px 7rem 0px 0px "
    },
    primaryButton: {
      padding: "0.8rem 2rem",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      marginTop: "3rem",
      fontFamily: "inherit"
    },
    checkBox: {
      "& .MuiCheckbox-colorPrimary.Mui-checked": {
        color: "#0E76BC"
      },
      "& .MuiTypography-body1": {
        fontSize: "0.8rem"
      }
    },
    menu: {
      "& .MuiIconButton-root": {
        padding: "2px"
      },
    },

    grid: {
      background: '#f5f8fa',
      // borderRadius: "5px",
      // borderBottom: "1px solid #C0C0C0",
      borderRadius: "12px",
      // boxShadow: "0 0 8px -3px #444444",
      border: '1px solid #0E76BC',
      // boxShadow: '0 0 8px -3px #444444',
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
      // "&:last-child": {
      //   borderBottom: "none",
      // },
    },

    gridCursor: {
      borderBottom: "1px solid #C0C0C0",
      // "&:last-child": {
      //   borderBottom: "none",
      // },
    },
    rupees: {
      fontFamily: "Open Sans",
    },

  }))
  const classes = useStyles()

  const MenuComp = ({ id, handle_primary }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [showModal, setShowModal] = useState(false)

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handle_change = () => {
      handle_primary(id)
    }

    return (
      <>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px"
            },
          }}
        >
          <MenuItem onClick={() => setShowModal(true)} style={{ fontSize: "0.8rem" }}>
            Make primary
          </MenuItem>

          {/* handle_change(id) */}

        </Menu>

        <ConfirmPrimary show={showModal} handleClose={() => setShowModal(false)} changePrimary={handle_change} />

      </>

    )
  }
  //--------------------------------------------------------------------------------------------------------//
  const [addBank, setAddBank] = useState(false)
  const [bankData, setBankData] = useState([])
  const [isPrimary, setIsPrimary] = useState()
  const [mandate, setMandate] = useState([])
  const [bankMandate, setBankMandate] = useState()
  const [bankDetails, setBankDetails] = useState()
  const [kycStatusData, setKycStatusData] = useState([])
  const [loading, setLoading] = useState(true);


  const { userStore } = useSelector((state) => state)

  const closeAddBank = () => {
    setAddBank(false)
    setAddBank(false)
  }


  //----------------------------------get all banks------------------------------------------------------//
  const get_all_bank = async () => {
    setLoading(true)
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await httpInstance(`${GET_ALL_BANK}`, httpConfig)
      setBankData(response.data?.data)
      // const get_mandate = await httpInstance(`${GET_MANDATE}`, httpConfig)
      // setMandate(get_mandate?.data?.data)
      const kycresponse = await httpInstance(`${GET_USER_ONBOARDING_DETAILS}`, httpConfig)
      // console.log(kycresponse)
      setKycStatusData(kycresponse?.data?.data)
      setLoading(false);

      response.data?.data?.map((ele) => {
        if (ele.is_primary) {
          setIsPrimary(ele.id)
          setBankDetails(ele)
          setMandate(ele?.mandates?.[0])
          // if (ele.mandateId) {
          //   let arr_mandate = get_mandate.data?.data?.filter(mandate => mandate.mandateId == ele.mandateId)

          //   setBankMandate(arr_mandate[0])
          // }
        }
      })
    } catch {
      setLoading(false);

    }
  }
  //---------------------------------------------------------------------------------//
  useEffect(() => {
    get_all_bank()
  }, [])

  //-----------------filter-bank------------------------------------------------------//

  const filterBank = (id) => {

    const bank_detail = bankData?.filter((ele) => {
      return id == ele?.id
    })
    // console.log(bank_detail[0])
    setBankDetails(bank_detail[0])

    if (bank_detail[0].mandateId) {
      filter_mandate(bank_detail[0].mandateId)
    } else {
      setBankMandate(null)
    }
  }
  //------------------filter_mandate------------------------------//
  const filter_mandate = (mandate_id) => {
    const arry = mandate?.filter((ele) => {
      return ele.mandateId === mandate_id

    })

    setBankMandate(arry[0])
  }

  //-----------------------update primary account----------------------------------------------------------//
  const set_primary_account = async (id) => {
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { id: id }
    };
    const httpConfig1 = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },

    };
    try {
      await httpInstance(`${UPDATE_PRIMARY_BANK}`, httpConfig)
      await httpInstance(`${UPDATE_INVESTOR_ACCOUNT}`, httpConfig1)
    }
    catch {

    }
  }

  //--------------------------handle primary account---------------------------------------------------//
  const handle_primary = (id) => {

    setIsPrimary(id)
    set_primary_account(id)
    //setAnchorEl(null);

  }

  // console.log(userStore.user)

  const [showBankMandates, setShowBankMandates] = useState('')

  return (
    <div>
      <Box px={{ xs: 2, md: 20 }} mt={5}>
        {showBankMandates ?
          <>
            {/* {console.log(showBankMandates)} */}
            <Box display="flex"
              justifyContent="flex-start">
              <Box
                display="flex"
                justifyContent="flex-start"
                alignContent='center'
                pr={3}
                className={classes.backButton}
                onClick={() => {
                  setShowBankMandates('')
                }}
              >
                <img src="/images/backIcon.svg" />
              </Box>
              <Typography className={classes.header}>Linked Mandates</Typography>

            </Box>

            <Grid container spacing={2}>
              {showBankMandates?.mandates?.map((ele) => {
                return (
                  <Grid item xs={12} md={6} key={ele.id}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" p={2} my={2}
                      className={`${bankDetails?.id == ele?.id ? classes.grid : classes.gridCursor} ${classes.borderBox}`}
                    // onClick={() => setShowBankMandates(bankData)}
                    >
                      <Box width={'100%'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <img src={`${BASE_URL}${showBankMandates?.logo_url}`} height="40px" width="auto" style={{borderRadius: '10px'}}/>
                        <Box width={'100%'}>
                          <Box display="flex" alignItems="center" justifyContent="space-around" width={'100%'}>
                            <Box display="flex" alignItems="center" justifyContent="center" width={'100%'}>

                              <Typography className={classes.title1} style={{ fontSize: '1rem', textTransform: 'uppercase' }}>{showBankMandates?.bank_name}</Typography>
                            </Box>
                            {/* <Box className={classes.menu} display="flex" justifyContent="end">
                              {showBankMandates?.is_primary && <Typography className={classes.textblue}>Primary</Typography>}
                            </Box> */}
                          </Box>
                          <Box pt={2} width={'100%'} display="flex" alignItems="center" justifyContent="center">
                            <Box px={2} display="flex" alignItems="center" justifyContent="center" flexDirection={'column'}>
                              <Typography className={classes.text}>Account Number</Typography>
                              <Typography className={classes.title1}>{`XXXXXX${showBankMandates?.account_number?.slice(-4)}`}</Typography>
                            </Box>
                            <Box px={2} display="flex" alignItems="center" justifyContent="start" flexDirection={'column'}>
                              <Typography className={classes.text}>Max Limit</Typography>
                              <Typography className={classes.title1} style={{ textTransform: 'uppercase' }}>
                                <span className={classes.rupees}>₹</span>
                                {`${ele?.mandate_limit?.toLocaleString("en-IN")}`}
                              </Typography>
                            </Box>
                          </Box>
                          {/* {ele?.mandates?.[0] ? ( */}
                            <Box pt={2} width={'100%'} display="flex" alignItems="center" justifyContent="center">
                              <Box px={2} display="flex" alignItems="center" justifyContent="center" flexDirection={'column'}>
                                <Typography className={classes.text}>Digital AutoPay:</Typography>
                                <Typography className={classes.title1}>{`${ele?.status}`}</Typography>
                              </Box>
                              <Box px={2} display="flex" alignItems="center" justifyContent="start" flexDirection={'column'}>
                                <Typography className={classes.text}>Mandate ID:</Typography>
                                <Typography className={classes.title1}>{`${ele?.mandate_id}`}</Typography>
                              </Box>
                              {/* <Box px={2} display="flex" alignItems="center" justifyContent="start" flexDirection={'column'}>
                                <Typography className={classes.text}>Max Limit:</Typography>
                                <Typography className={classes.title1}>{`${ele?.mandates?.[0]?.mandate_limit}`}</Typography>
                              </Box> */}
                            </Box>
                          {/* ) : (
                            <Box pt={2} width={'100%'} display="flex" alignItems="center" justifyContent="center">
                              <Typography className={classes.title1}>No Mandate Found</Typography>
                            </Box>
                          )} */}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                )
              })}
            </Grid>

          </>
          :
          <>
            <Typography className={classes.header}>Linked Bank Account</Typography>

            {/* <Grid container  >
          <Grid item xs={12} md={6} className={classes.gridPadding}> */}
            <Grid container spacing={2}>
              {loading ?
                <Grid item xs={12} md={6} >
                  <Box display="flex" justifyContent="space-between" alignItems="center" p={2} my={2}
                    className={`${classes.borderBox}`}
                  >
                    <Box width={'100%'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                      <Skeleton variant="rounded" border width={40} height={40} />
                      <Box width={'100%'}>
                        <Box display="flex" alignItems="center" justifyContent="space-around" width={'100%'}>
                          <Box display="flex" alignItems="center" justifyContent="center" width={'100%'}>

                            <Skeleton variant="text" width={'80%'} />
                          </Box>
                          <Box className={classes.menu} display="flex" justifyContent="end">
                            <Skeleton variant="text" width={'40%'} />
                          </Box>
                        </Box>
                        <Box pt={2} width={'100%'} display="flex" alignItems="center" justifyContent="center" >
                          <Box px={2} display="flex" alignItems="center" justifyContent="center" flexDirection={'column'} width={'100%'}>
                            <Skeleton variant="text" width={'50%'} />
                            <Skeleton variant="text" width={'40%'} />
                          </Box>
                          <Box px={2} display="flex" alignItems="center" justifyContent="start" flexDirection={'column'} width={'100%'}>
                            <Skeleton variant="text" width={'40%'} />
                            <Skeleton variant="text" width={'60%'} />
                          </Box>
                        </Box>
                        {/* <Box pt={2} width={'100%'} display="flex" alignItems="center" justifyContent="center">
                          <Box px={2} display="flex" alignItems="center" justifyContent="center" flexDirection={'column'} width={'100%'}>
                            <Skeleton variant="text" width={'40%'} />
                            <Skeleton variant="text" width={'60%'} />
                          </Box>
                          <Box px={2} display="flex" alignItems="center" justifyContent="start" flexDirection={'column'} width={'100%'}>
                            <Skeleton variant="text" width={'40%'} />
                            <Skeleton variant="text" width={'60%'} />
                          </Box>
                          <Box px={2} display="flex" alignItems="center" justifyContent="start" flexDirection={'column'} width={'100%'}>
                            <Skeleton variant="text" width={'40%'} />
                            <Skeleton variant="text" width={'60%'} />
                          </Box>
                        </Box> */}

                      </Box>
                    </Box>
                  </Box>
                </Grid>
                :
                bankData.length > 0 &&
                bankData?.map((ele) => {
                  return (
                    <Grid item xs={12} md={6} key={ele.id}>
                      <Box display="flex" justifyContent="space-between" alignItems="center" p={2} my={2}
                        className={`${bankDetails?.id == ele?.id ? classes.grid : classes.gridCursor} ${classes.borderBox}`}
                        style={{cursor: 'pointer'}}
                        onClick={() => setShowBankMandates(ele)}
                      >
                        <Box width={'100%'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                          <img src={`${BASE_URL}${ele?.logo_url}`} height="40px" width="auto" style={{borderRadius: '10px'}}/>
                          <Box width={'100%'}>
                            <Box display="flex" alignItems="center" justifyContent="space-around" width={'100%'}>
                              <Box display="flex" alignItems="center" justifyContent="center" width={'100%'}>

                                <Typography className={classes.title1} style={{ fontSize: '1rem', textTransform: 'uppercase' }}>{ele?.bank_name}</Typography>
                              </Box>
                              <Box className={classes.menu} display="flex" justifyContent="end">
                                {ele?.is_primary && <Typography className={classes.textblue}>Primary</Typography>}
                                {/* <MenuComp id={ele?.id} handle_primary={handle_primary} /> */}
                              </Box>
                            </Box>
                            <Box pt={2} width={'100%'} display="flex" alignItems="center" justifyContent="center">
                              <Box px={2} display="flex" alignItems="center" justifyContent="center" flexDirection={'column'}>
                                <Typography className={classes.text}>Account Number</Typography>
                                <Typography className={classes.title1}>{`XXXXXX${ele?.account_number?.slice(-4)}`}</Typography>
                              </Box>
                              <Box px={2} display="flex" alignItems="center" justifyContent="start" flexDirection={'column'}>
                                <Typography className={classes.text}>IFSC Code</Typography>
                                <Typography className={classes.title1} style={{ textTransform: 'uppercase' }}>{`${ele?.ifsc_code}`}</Typography>
                              </Box>
                            </Box>
                            {/* {ele?.mandates?.[0] ? (
                              <Box pt={2} width={'100%'} display="flex" alignItems="center" justifyContent="center">
                                <Box px={2} display="flex" alignItems="center" justifyContent="center" flexDirection={'column'}>
                                  <Typography className={classes.text}>Digital AutoPay:</Typography>
                                  <Typography className={classes.title1}>{`${ele?.mandates?.[0]?.status}`}</Typography>
                                </Box>
                                <Box px={2} display="flex" alignItems="center" justifyContent="start" flexDirection={'column'}>
                                  <Typography className={classes.text}>Mandate ID:</Typography>
                                  <Typography className={classes.title1}>{`${ele?.mandates?.[0]?.id}`}</Typography>
                                </Box>
                                <Box px={2} display="flex" alignItems="center" justifyContent="start" flexDirection={'column'}>
                                  <Typography className={classes.text}>Max Limit:</Typography>
                                  <Typography className={classes.title1}>
                                    <span className={classes.rupees}>₹</span>
                                    {`${ele?.mandates?.[0]?.mandate_limit}`}</Typography>
                                </Box>
                              </Box>
                            ) : (
                              <Box pt={2} width={'100%'} display="flex" alignItems="center" justifyContent="center">
                                <Typography className={classes.title1}>No Mandate Found</Typography>
                              </Box>
                            )} */}
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  )

                })}
              {/* </Box> */}

              {/* testisng */}
            </Grid>

            <Box display="flex" justifyContent="center">
              {
                (kycStatusData?.is_kyc_compliant == true && kycStatusData?.status == 'nominee' && kycStatusData?.fp_investor_id ||
                  kycStatusData?.is_kyc_compliant == false && kycStatusData?.fp_esign_status == 'successful' && kycStatusData?.fp_investor_id)
                && bankData.length < 5 &&
                <button className={classes.primaryButton} onClick={() => setAddBank(true)}><img src="/images/plusIcon.svg" height="12px" width="12px" style={{ marginRight: "5px" }} /> Add Another bank</button>}
            </Box>
          </>
        }

        {/* </Grid>
          <Grid item xs={6}>
            {bankDetails && <AutoMandate bankDetails={bankDetails} bankMandate={bankMandate} isPrimary={isPrimary} get_all_bank={get_all_bank} />}
          </Grid> */}

        {/* </Grid> */}

      </Box>

      <AddBankForm dialog={addBank} closeDialog={closeAddBank} get_all_bank={get_all_bank} />

    </div>
  )
}

export default Index