import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import All_funds from './all_funds';
import { Container } from '@material-ui/core';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tab_box: {
    // padding: "0px 4rem"
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    padding: "0px 4rem"
  },
  tab_style: {
    width: "100%",
    background: "white",
    color: "black",
    padding: "0px 10px",
    borderRadius: "10px",
    // border: '1px solid #E5E3E3',
    // backgroundColor: "#CAE8FC",
    border: '1px solid #E4E4E4',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',

    "& .MuiTab-root": {
      fontFamily: "inherit",
      fontSize: "1rem",
      color: "black",
      opacity: 1,
      textTransform: "none",
      background: "none",
      minWidth: "auto",
      padding: "0px"
    },
    "& .MuiTab-textColorInheri": {
      opacity: 0.9
    },
    "& .MuiTabs-root": {
      height: "71.5px",
      minHeight: "62px",
      padding: "0px"
    },
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-between",

    },
    "& .MuiTabs-scrollable": {
      overflowY: "clip",
    },
    "& .Mui-selected": {
      // backgroundColor: "#deecf5",
      margin: "0px 10px",
      maxWidth: "300px",
      minWidth: "150px",
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      backgroundColor: "#0d0d0d0d",
      // borderBottom: "2px solid #0E76BC",
    },


  },
  tabWidth: {
    width: '25%',
    color: '#272727',
    fontSize: '15px',
    fontFamily: 'Poppins',
    fontWeight: '400'
  },
  activeTab: {

    "& .MuiTab-wrapper": {
      borderBottom: "2px solid #0E76BC",
      color: "#0E76BC",
      // padding: "1px 10px 6px 10px",
      // padding: 0,
      paddingBottom: "1px",
      paddingTop: "6px",
      backdropFilter: 'blur(8px)',

    }

  },

  inActiveTab: {

    "&:hover": {
      backgroundColor: "#0d0d0d0d",
      transition: "0.5s ease"
    },
    "& .MuiTab-wrapper": {
      paddingBottom: "3px",
      paddingTop: "2px",
      paddingLeft: " 10px",
      paddingRight: " 10px",
      maxWidth: "300px",
      minWidth: "150px",

    }

  },
  "@media (max-width: 900px)": {
    tab_box: {
      padding: "0px 3rem"
    },
    tab_style: {
      padding: "0px"
    },
    activeTab: {
      "& .MuiTab-wrapper": {
        padding: "5px 10px 2px"
      }
    },
    inActiveTab: {
      "& .MuiTab-wrapper": {
        padding: "5px 10px 2px"
      }
    },
  },
  "@media (max-width: 600px)": {
    tab_box: {
      padding: "0px 1rem"
    },
    activeTab: {
      "& .MuiTab-wrapper": {
        padding: "5px 8px 2px"
      }
    },
    inActiveTab: {
      "& .MuiTab-wrapper": {
        padding: "5px 8px 2px"

      }
    },
  },
  tablebody: {
    // "& div": {
    //     padding: "10px 0",
    // }
    "& > *": {
      padding: "10px 0",
    },
  },

}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [type, setType] = React.useState('all')




  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Container className={classes.tab_box}>
        <div
          className={classes.tab_style}
        >


          <Tabs indicatorColor=" " value={value} onChange={handleChange}
            // orientation='vertical' 
            variant="scrollable"  // Use a scrollable variant for tabs
            scrollButtons="auto"   // Automatically show scroll buttons when tabs overflow
            // allowScrollButtonsMobile={true} 
            aria-label="scrollable force tabs example">

            <Tab label="Top Rated" {...a11yProps(0)} className={value == 0 ? classes.activeTab : classes.inActiveTab}
              icon={<img alt="Custom Avatar" src="/images/fundListDashBoard/top rated.svg" height={35} style={{ margin: '0' }} />}
            />
            <Tab label="Focused Fund" {...a11yProps(1)} className={value == 1 ? classes.activeTab : classes.inActiveTab}
              icon={<img alt="Custom Avatar" src="/images/target 2.svg" height={35} style={{ margin: '0' }} />}
            />
            <Tab label="Save Tax" {...a11yProps(2)} className={value == 2 ? classes.activeTab : classes.inActiveTab}
              icon={<img alt="Custom Avatar" 
                src="/images/Tax.svg"
                //  src="/images/fundListDashBoard/tax saver.svg"
                 height={35} style={{ margin: '0' }} />}
            />
            <Tab label="Balanced Fund" {...a11yProps(3)} className={value == 3 ? classes.activeTab : classes.inActiveTab}
              icon={<img alt="Custom Avatar" src="/images/fundListDashBoard/balanced.svg" height={35} style={{ margin: '0' }} />}
            />
            <Tab label="Multi Cap" {...a11yProps(4)} className={value == 4 ? classes.activeTab : classes.inActiveTab}
              icon={<img alt="Custom Avatar" src="/images/fundListDashBoard/multicap.svg" height={35} style={{ margin: '0' }} />}
            />

            <Tab label="Liquid Fund" {...a11yProps(5)} className={value == 5 ? classes.activeTab : classes.inActiveTab}
              icon={<img alt="Custom Avatar" src="/images/Fund Your Account.svg" height={35} style={{ margin: '0' }} />}
            />
            {/* <Tab label="New Fund" {...a11yProps(6)} className={value == 6 ? classes.activeTab : classes.inActiveTab}
              icon={<img alt="Custom Avatar" src="/images/Graph.svg" height={25} style={{ margin: '0' }} />}
            />
            <Tab label="For You" {...a11yProps(7)} className={value == 7 ? classes.activeTab : classes.inActiveTab}
              icon={<img alt="Custom Avatar" src="/images/Graph.svg" height={25} style={{ margin: '0' }} />}
            /> */}
          </Tabs>

        </div>

      </Container>
      <Box pt={2}>
        <TabPanel value={value} index={0} className={classes.tablebody}>
          <All_funds type="All Fund" />
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.tablebody}>
          <All_funds type="Focused Fund" />
        </TabPanel>
        <TabPanel value={value} index={2} className={classes.tablebody}>
          <All_funds type="Save Tax" />
        </TabPanel>
        <TabPanel value={value} index={3} className={classes.tablebody}>
          <All_funds type="Balanced Fund" />
        </TabPanel>
        <TabPanel value={value} index={4} className={classes.tablebody} >
          <All_funds type="Multi Cap" />
        </TabPanel>
        <TabPanel value={value} index={5} className={classes.tablebody}>
          <All_funds type="Liquid Fund" />
        </TabPanel>
        {/* <TabPanel value={value} index={6}>
          <All_funds type="New Fund" />
        </TabPanel> */}

      </Box>
    </div>
  );
}
