import React, { useState } from "react";
import {
    Box,
    makeStyles,
    Typography,
    Grid,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";

const OrderTypeSelection = ({ handleStatus, status, values, OrderType }) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            // border: "1px solid #DDDDDD",
            borderRadius: "5px",
        },
        box1: {
            borderBottom: "1px solid #DDDDDD",
        },
        title: {
            fontSize: '1.2rem',
            fontWeight: 600,
            color: '#000'
        },
        checkBox: {
            "& .MuiTypography-body1": {
                fontSize: '1rem',
                fontWeight: 600,
            },
            "& .MuiCheckbox-colorPrimary.Mui-checked": {
                color: "#0E76BC",
            },
        },
        headerText:{
            fontSize: '1rem',
            fontWeight: 600,
        },
        box2: {
           
            borderBottom: "1px solid #E4E4E4",
            backgroundColor: "#bfe5ff52",
            color: "#000",
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            overflow: 'hidden',
        },
        box3: {
            // padding: "0.2rem 10px",
            cursor: "pointer",
            border: "3px solid transparent",
            "&:hover": {
                // border: "2px solid #fff !important",
                borderBottom: "3px solid #0E76BC",
            }
        },
        bluebox3: {
            // padding: "0.2rem 10px",
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            color: "#fff",
            backgroundColor: "#0E76BC",
            border: "3px solid #bfe5ff52",
            cursor: "pointer",
        },
    }));
    const classes = useStyles();

    // ---------------------------- Order type selection ------------------------------------ // 

    const handle_tab = (data) => {
        handleStatus(data);
    };


    return (
        <div>
            <Box className={classes.root} pb={1}>
                <Typography variant="h6" style={{ textAlign: "start" }} className={classes.title}>
                    {OrderType}
                </Typography>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    // px={6}
                    mt={2}
                    className={classes.box2}
                >

                    {values?.map((items, i) => {
                        return (
                            <Box
                                px={1}
                                py={1}
                                className={status == items ? classes.bluebox3 : classes.box3}
                                onClick={() => handle_tab(items)}
                                width={'100%'}
                                textAlign={'center'}
                            >
                                <Typography className={classes.headerText}>{items}</Typography>
                            </Box>
                        )
                    })
                    }
                    {/* <Box
                    display="flex"
                    justifyContent="space-between"
                    px={6}
                    // pb={2}
                    className={classes.box2}
                >
                    <Box
                        px={3}
                        className={status == "Successful" ? classes.bluebox3 : classes.box3}
                        onClick={() => handle_tab("Successful")}
                    >
                        <Typography  >Success</Typography>
                    </Box>
                    <Box
                        px={3}
                        className={status == "Failed" ? classes.bluebox3 : classes.box3}
                        onClick={() => handle_tab("Failed")}
                    >
                        <Typography  >Failed</Typography>
                    </Box>
                    <Box
                        px={3}
                        className={status == "Pending" ? classes.bluebox3 : classes.box3}
                        onClick={() => handle_tab("Pending")}
                    >
                        <Typography >Pending</Typography>
                    </Box> */}
                </Box >
            </Box >
        </div >
    );
};

export default OrderTypeSelection;
