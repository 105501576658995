import { GET_PERSONAL_DETAILS, KYC_STATUS } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";

const StatusApiData = async () => {
    const httpConfig = {
        method: "GET",
        tokenType: "user",
        headers: {
            "Content-Type": "application/json",
        },
    };

    try {
        const complient = await httpInstance(`${KYC_STATUS}`, httpConfig);
        return complient
    } catch (error) {
        return ''
    }
}


export default StatusApiData;