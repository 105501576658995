import React from 'react'
import ShowRecommendedFundsOnly from '../../../../containers/Goals/ShowRecommendedFundsOnly'
// import SpecificGoal from '../../../containers/Goals/SpecificGoal'

const index = () => {
  return (
    <div>
        {/* <SpecificGoal/> */}
        <ShowRecommendedFundsOnly />
        {/* test */}
    </div>
  )
}

export default index