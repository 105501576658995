import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { AppBar, Box, Card, CardContent, Container, Grid, styled } from '@material-ui/core';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  cardContent: {
    display: 'flex',
    margin: '0 5rem'
  },
  mainText: {
    fontFamily: 'Poppins',
    fontSize: '28px',
    fontWeight: '500',
    lineHeight: '48px'
  },
  subText: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '24px',
    width: '285px',
    height: '48px',
    marginTop: '32px'
  },
  learnMore: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: '500',
    width: '150px',
    height: '26px',
    marginTop: '32px',
    padding: '0',
  },
  arrowColor: {
    color: '#0E76BC',
    marginLeft: '10px',
  },
  bellBG: {
    backgroundColor: '#F2AF1A',
    borderRadius: '50%',
    height: '36px',
    width: '37px',
    color: 'white',
    fontSize: '21px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  profit: {
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '36px',
  },
  bookProfit: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '24px',
    color: '#AFAFAF',
    marginTop: '16px'
  },
  mainCard: {
    height: '224px',
    width: '415px',
  },
  cardCenter: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  }
});

export default function SimpleCard() {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <div className={classes.root}>
      <Container>
        <Box>
          <div className={classes.cardContent}>
            <Grid item lg={6}>
              <Typography className={classes.mainText} >
                Alerts on Accumulation & Exits
              </Typography>
              <Typography className={classes.subText} >
                Our Experts suggest pefect time for Invest & exit for Best Returns
              </Typography>
              <Button className={classes.learnMore} >Learn More<img className={classes.arrowColor} src="/images/learnmore.svg" height={19} width={19} /></Button>
            </Grid>
            <Grid className={classes.cardCenter} item lg={6}>
              <video width="400px" height="220px" playsinline={true} loop={true} muted={true} autoplay='true'>
                <source type="video/mp4" src="/images/Trigger.mp4"></source>
              </video>
            </Grid>
          </div>
        </Box>
      </Container>
    </div>
  );
}
