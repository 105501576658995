import React, { useState, useEffect } from 'react'
import { Box, Typography, makeStyles, Grid, Slider, Button, } from "@material-ui/core";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList

} from "recharts";
import { formatAmount } from '../../components/common/FormatAmount';
import CountUp from 'react-countup';



const ReturnCal = ({ fundData, returns, sliderAmountChangeIntheReturnCalculator }) => {
  const useStyles = makeStyles((theme) => ({

    // graphButtonInactive: {
    //   border: "none",
    //   background: "none",
    //   padding: "5px",
    //   borderRadius: "10px",
    //   fontSize: "0.7rem",
    //   cursor: "pointer",
    //   outline: "none",
    //   "&:hover": {
    //     background: "#6FDDB8",
    //     color: "white",
    //     padding: "5px",
    //   },
    //   "&:active": {
    //     background: "#6FDDB8",
    //     color: "white"
    //   }
    // },

    // activeButton: {
    //   background: "#6FDDB8",
    //   color: "white",
    //   padding: "5px",
    //   border: "none",
    //   borderRadius: "10px",
    //   fontSize: "0.7rem",
    //   cursor: "pointer",
    //   outline: "none",
    // },

    root: {
      marginTop: "1rem",
    },

    borderBox: {
      // border: "1px solid #E4E4E4",
      // borderRadius: "5px",
      padding: "1rem",
      border: '1px solid #E4E4E4',
      // boxShadow: '0 0 8px -3px #444444',
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
      borderRadius: '12px',
      // width: "75%",

    },
    titleHead: {
      fontWeight: 600,
      padding: "0px 0rem",
      fontSize: "1.2rem"
    },

    // Button tabs design 
    tabs: {
      display: 'flex',
      position: 'relative',
      overflow: 'hidden',
      border: '1px solid #0E76BC',
      borderRadius: '10px',

    },
    tablink: {
      borderRadius: '10px',
      flex: 1,
      // padding: '10px 20px',
      borderRadius: 0,
      '&:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
      position: 'relative',
      zIndex: 1,
      color: '#0E76BC',
      textTransform: 'capitalize',
    },
    active: {
      borderRadius: '10px',
      backgroundColor: '#0E76BC',
      color: theme.palette.secondary.contrastText,
    },
    activeBox: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '50%',
      height: '100%',
      // backgroundColor: theme.palette.secondary.main,
      backgroundColor: '#0E76BC',
      borderRadius: '8px',

      transition: 'left 0.3s ease-in-out',
    },


    ButtonInactive: {
      border: "none",
      background: "none",
      padding: "5px 20px",
      borderRadius: "20px",
      fontSize: "0.75rem",
      fontFamily: "inherit",
      marginRight: "1rem",
      marginLeft: "1rem",
      cursor: "pointer",
      outline: "none",
      "&:hover": {
        background: "#0E76BC",
        color: "white",

      },
      "&:active": {
        background: "#0E76BC",
        color: "white"
      }
    },

    activeButton: {
      background: "#0E76BC",
      color: "white",
      padding: "5px 20px",
      border: "none",
      borderRadius: "20px",
      fontSize: "0.75rem",
      fontFamily: "inherit",
      marginRight: "1rem",
      marginLeft: "1rem",
      cursor: "pointer",
      outline: "none",
    },
    activeYearButton: {
      background: "#0E76BC",
      color: "white",
      padding: "5px 20px",
      border: "none",
      borderRadius: "20px",
      fontSize: "0.7rem",
      fontWeight: 700,
      fontFamily: "inherit",
      marginRight: "1rem",
      marginLeft: "1rem",
      cursor: "pointer",
      outline: "none",

    },

    inactiveYearButton: {
      border: "1px solid #0E76BC ",
      background: "none",
      padding: "5px 20px",
      borderRadius: "20px",
      fontSize: "0.7rem",
      fontWeight: 700,
      fontFamily: "inherit",
      marginRight: "1rem",
      marginLeft: "1rem",
      color: "#0E76BC",
      cursor: "pointer",
      outline: "none",
      "&:hover": {
        background: "#0E76BC",
        color: "white",

      },
      "&:active": {
        background: "#0E76BC",
        color: "white"
      }
    },

    text: {
      fontSize: "0.8rem",
      color: "#666666"
    },

    title: {
      fontSize: "0.9rem",
      marginTop: "1rem"
    },
    textAnchorEndData: {
      fontSize: "0.7rem",
      position: "relative",
      bottom: "15px",
    },


    valueLabel: {
      width: "16px",
      height: "16px",
      top: '-20px',
      // left: '-1px',
      backgroundColor: 'transparent',
      '& .PrivateValueLabel-circle-233': {
        backgroundColor: 'transparent',
      },
      '& .PrivateValueLabel-circle-229': {
        backgroundColor: 'transparent',
      },
      '& > *': {
        backgroundColor: 'transparent',
        // color: "black"
      },
      '& .MuiSlider-valueLabel': {
        '& > *': {
          backgroundColor: 'transparent',
          // color: "black"
        },
      }
    },

    thumb: {
      background: "#fff",
      border: "3px solid #0E76BC",
      // marginBottom: '-12px',
      width: "16px",
      height: "16px",
      boxShadow: 'none !important',
      ' &.Mui-focusVisible': {
        boxShadow: 'none !important',
      },
      '&.Mui-active': {
        boxShadow: 'none !important',
      },
    },
    mark: {
      background: "black"
    },
    rail: {
      background: "#0E76BC",
      height: "7px",
      borderRadius: '5px',
    },
    track: {
      background: "#0E76BC",
      height: "7px",
      borderRadius: '5px',
    },
    rupees: {
      fontFamily: "Open Sans"
    },
    "@media (max-width: 600px)": {
      activeButton: {
        marginRight: "0rem",
      },
      ButtonInactive: {
        marginRight: "0rem",
      },
      activeYearButton: {
        marginRight: "0.5rem",
      },
      inactiveYearButton: {
        marginRight: "0.5rem",
      }
    }

  }))
  const classes = useStyles();

  const [button, setButton] = useState('sip')
  const [yearButton, setYearButton] = useState('1')
  const [totalReturn, setTotalReturn] = useState()
  const [invest, setInvest] = useState(5000)

  const [sliderVlaueOnChangeEnd, setSliderVlaueOnChangeEnd] = useState(5000)
  const [calculatedThisFund, setCalculatedThisFund] = useState(null);
  const [calculatedProfit, setCalculatedProfit] = useState(null)

  const [barChartData, setBarChartData] = useState([
    {
      name: 'Category Avg',
      total: 0,
      invested_amount: 0,
      // amt: 2400,
    },
    {
      name: 'Bechmark',
      total: 0,
      invested_amount: 0,
      // amt: 2210,
    },
    {
      name: 'This Fund',
      total: 0,
      invested_amount: 0,
      // amt: 2290,
    },

  ])


  const [activeTab, setActiveTab] = useState('sip');
  const [activeBoxPosition, setActiveBoxPosition] = useState(0);

  const tabClickHandler = (tabName, position) => {
    setActiveTab(tabName);
    setActiveBoxPosition(position);
  };


  const CustomBarLabel = ({ fill, ...props }) => (
    <g transform={`translate(${props.x},${props.y})`}>
      <text fill={fill} fontSize={14} textAnchor="middle" dominantBaseline="middle" dy={-10} dx={10} >
        <tspan className={classes.rupees}>₹</tspan >
        {/* {props.value.toFixed(2)} */}
        {props.value < 99999 ? parseFloat(props.value.toFixed(2)).toLocaleString('en-IN') : formatAmount(props.value.toFixed(2))}
        {/* <CountUp end={props.value ? props.value.toFixed(2) : 0} decimals={2} decimal="." duration={3} /> */}
      </text>
    </g>
  );


  useEffect(() => {
    sliderAmountChangeIntheReturnCalculator(sliderVlaueOnChangeEnd, activeTab)
  }, [sliderVlaueOnChangeEnd, activeTab])

  //Calculating Past Return
  const calculatingPastReturn = (value) => {
    console.log(value, returns)
    // console.log(returns.fund_1_year)
    let selectedFundData;
    switch (value) {
      case '1':
        selectedFundData = returns.fund_1_year;
        break;
      case '3':
        selectedFundData = returns.fund_3_year;
        break;
      case '5':
        selectedFundData = returns.fund_5_year;
        break;
      default:
        console.log("Invalid index. Please provide a valid index (1, 2, or 3).");
        return null; // Return null or handle the error case appropriately
    }

    // Now you can use 'selectedFundData' as needed
    // setCalculatedThisFund((selectedFundData.profit / selectedFundData.invested_amount) * 100)
    setCalculatedThisFund((selectedFundData?.total))

  }

  // console.log(calculatedThisFund)
  //Calculate Profit aboslute return
  const calculatingProfitAbsoluteReturn = (value) => {
    // console.log(value)
    // console.log(returns.fund_1_year)
    let selectedFundData;
    switch (value) {
      case '1':
        selectedFundData = returns.fund_1_year;
        break;
      case '3':
        selectedFundData = returns.fund_3_year;
        break;
      case '5':
        selectedFundData = returns.fund_5_year;
        break;
      default:
        console.log("Invalid index. Please provide a valid index (1, 2, or 3).");
        return null; // Return null or handle the error case appropriately
    }

    // Now you can use 'selectedFundData' as needed
    setCalculatedProfit((selectedFundData.profit * 100) / selectedFundData.total)

  }

  //Calculate Profit aboslute return
  const calculatingBarGraphValue = (value) => {
    // console.log(value)
    // console.log(returns.fund_1_year)
    let selectedFundData;
    switch (value) {
      case '1':
        selectedFundData = [
          {
            name: 'Category Avg',
            total: returns.fund_catavg_1_year.total,
            invested_amount: returns.fund_catavg_1_year.invested_amount,
            // amt: 2400,
          },
          {
            name: 'Bechmark',
            total: returns.benchmark_1_year.total,
            invested_amount: returns.benchmark_1_year.invested_amount,
            // amt: 2210,
          },
          {
            name: 'This Fund',
            total: returns.fund_1_year.total,
            invested_amount: returns.fund_1_year.invested_amount,
            // amt: 2290,
          },
        ]
        // returns.fund_1_year;
        break;
      case '3':
        selectedFundData = [
          {
            name: 'Category Avg',
            total: returns.fund_catavg_3_year.total,
            invested_amount: returns.fund_catavg_3_year.invested_amount,
            // amt: 2400,
          },
          {
            name: 'Bechmark',
            total: returns.benchmark_3_year.total,
            invested_amount: returns.benchmark_3_year.invested_amount,
            // amt: 2210,
          },
          {
            name: 'This Fund',
            total: returns.fund_3_year.total,
            invested_amount: returns.fund_3_year.invested_amount,
            // amt: 2290,
          },
        ];
        break;
      case '5':
        selectedFundData = selectedFundData = [
          {
            name: 'Category Avg',
            total: returns.fund_catavg_5_year.total,
            invested_amount: returns.fund_catavg_5_year.invested_amount,
            // amt: 2400,
          },
          {
            name: 'Bechmark',
            total: returns.benchmark_5_year.total,
            invested_amount: returns.benchmark_5_year.invested_amount,
            // amt: 2210,
          },
          {
            name: 'This Fund',
            total: returns.fund_5_year.total,
            invested_amount: returns.fund_5_year.invested_amount,
            // amt: 2290,
          },
        ];
        break;
      default:
        console.log("Invalid index. Please provide a valid index (1, 2, or 3).");
        return []; // Return null or handle the error case appropriately
    }

    // Now you can use 'selectedFundData' as needed
    setBarChartData(selectedFundData)

  }



  const handleButton = (type) => {
    setButton(type)
  }

  const handleYearButton = (type) => {
    setYearButton(type)

  }

  const get_return = () => {
    if (yearButton == '1') {
      return returns?.oneY
    } else if (yearButton == "3") {
      return returns?.threeY
    } else {
      return returns?.fiveY
    }
  }

  useEffect(() => {
    return_calculator(5000)
  }, [returns])

  useEffect(() => {
    return_calculator(invest)

  }, [yearButton, activeTab])

  useEffect(() => {
    if (returns) {
      calculatingPastReturn(yearButton)
      calculatingProfitAbsoluteReturn(yearButton)
      calculatingBarGraphValue(yearButton)
    }
  }, [yearButton, returns])

  const return_calculator = (value) => {
    // console.log("value",value)
    setInvest(value)
    if (activeTab == "monthly") {
      let n = yearButton * 12
      let i = (Number(get_return()) / (12)) / 100
      //M = P × ({[1 + i]n – 1} / i) × (1 + i).
      const totalReturnValue = value * ((Math.pow(1 + i, n) - 1) / i) * (1 + i)
      setTotalReturn(totalReturnValue)
    } else {
      //A = P (1 + r/n) ^ nt
      let n = 2
      let r = get_return() / 100
      let t = yearButton

      const totalReturnValue = value * (Math.pow(1 + r / n, n * t))
      setTotalReturn(totalReturnValue)




    }
  }

  // console.log(fundData)



  return (
    <div className={classes.root}>
      <Box className={classes.borderBox} mt={3}>
        {/* <Grid container>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}> */}
        {/* <Typography>Return Calculator</Typography> */}
        <Typography className={classes.titleHead}>Return Calculator</Typography>
        {/* </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}> */}
        {/* <Box display='flex' justifyContent='center'>
          <button className={button == 'sip' ? classes.activeButton : classes.ButtonInactive} onClick={() => handleButton('sip')}>Monthly SIP</button>
          <button className={button == 'onetime' ? classes.activeButton : classes.ButtonInactive} onClick={() => handleButton('onetime')}>One Time</button>
        </Box> */}
        <Grid container>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="caption">What if you invested in {fundData?.shortName}</Typography>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6} > */}
          {/* <Box display='flex' justifyContent='center' pt={1}>
              <Box className={classes.tabsFolder} maxWidth="350px" width='100%'>
                <div className={classes.tabs}>
                  <div className={classes.activeBox} style={{ left: `${activeBoxPosition}%` }}></div>
                  <Button
                    className={`${classes.tablink} ${activeTab === 'sip' ? classes.active : ''}`}
                    onClick={() => tabClickHandler('sip', 0)}
                  // onMouseEnter={() => tabHoverHandler('tab1', 0)}
                  >
                    SIP
                  </Button>
                  <Button
                    className={`${classes.tablink} ${activeTab === 'onetime' ? classes.active : ''}`}
                    onClick={() => tabClickHandler('onetime', 50)}>
                    Lumpsum
                  </Button>
                </div>
              </Box>
            </Box> */}
          {/* </Grid> */}
        </Grid>


        <Grid container>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ display: 'flex', alignItems: 'center' }}>
            <Box>
              <Box display='flex' justifyContent='center' pt={1}>
                <Box className={classes.tabsFolder} maxWidth="350px" width='100%'>
                  <div className={classes.tabs}>
                    <div className={classes.activeBox} style={{ left: `${activeBoxPosition}%` }}></div>
                    <Button
                      className={`${classes.tablink} ${activeTab === 'sip' ? classes.active : ''}`}
                      onClick={() => tabClickHandler('sip', 0)}
                    // onMouseEnter={() => tabHoverHandler('tab1', 0)}
                    >
                      Monthly SIP
                    </Button>
                    <Button
                      className={`${classes.tablink} ${activeTab === 'onetime' ? classes.active : ''}`}
                      onClick={() => tabClickHandler('onetime', 50)}>
                      One Time
                    </Button>
                  </div>
                </Box>
              </Box>
              <Box>
                <Box mt={4} px={3}>
                  <Slider
                    classes={{
                      thumb: classes.thumb,
                      rail: classes.rail,
                      track: classes.track,
                      valueLabel: classes.valueLabel,
                      mark: classes.mark
                    }}
                    defaultValue={5000} aria-label="Default" valueLabelDisplay="on"
                    size="medium"
                    valueLabelFormat={(x) => {
                      return (
                        <p style={{ color: "black", margin: 0 }}><span className={classes.rupees}>₹</span>{x.toLocaleString('en-IN')}</p>
                      )
                    }}
                    min={activeTab === 'sip' ? 500 : 5000}
                    max={activeTab === 'sip' ? 100000 : 1000000}
                    onChange={(e, value) => return_calculator(value)}
                    onChangeCommitted={(e, value) => {
                      // console.log("Slider value after release:", value);
                      // sliderAmountChangeIntheReturnCalculator(value)
                      setSliderVlaueOnChangeEnd(value)
                      // Add any other logic you want to perform after the slider is released
                    }}
                  />
                </Box>
                <Box display='flex' justifyContent="space-between" pl={2} pr={1} pt={1}>
                  <Typography className={classes.textAnchorEndData}><span className={classes.rupees}>₹</span>{activeTab === 'sip' ? (500).toLocaleString('en-IN') : (5000).toLocaleString('en-IN')}</Typography>
                  <Typography className={classes.textAnchorEndData}><span className={classes.rupees}>₹</span>{activeTab === 'sip' ? (100000).toLocaleString('en-IN') : (1000000).toLocaleString('en-IN')}</Typography>
                </Box>
              </Box>


              {/* <Typography className={classes.title}>Over the past</Typography> */}


              <Box mt={2}>
                {/* <Typography className={classes.text}>Total Investment of <span className={classes.rupees}>₹</span>{activeTab == "monthly" ? (invest * yearButton * 12).toLocaleString('en-IN') : invest}</Typography> */}
                {/* <Typography className={classes.text}>would have become <span className={classes.rupees}>₹</span>{(totalReturn)?.toLocaleString('en-IN', { maximumFractionDigits: 0 })} <span style={{ color: "#0E76BC" }}>({get_return()}%)</span></Typography> */}
                <Typography className={classes.text}>This funds past returns <span className={classes.rupees}> </span>  <span style={{ color: "#0E76BC", fontWeight: 600, fontSize: "1rem" }}>{calculatedThisFund ? calculatedThisFund < 99999 ? parseFloat(calculatedThisFund.toFixed(2)).toLocaleString('en-IN') : formatAmount(calculatedThisFund) : '0'}</span></Typography>
                <Typography className={classes.text}>Profit % (Absolute Retrun, based on past data) <span className={classes.rupees}> </span><span style={{ color: "#0E76BC", fontWeight: 600, fontSize: "1rem" }}>{calculatedProfit >= 0 ? "+" : "-"} {" "} {calculatedProfit ? calculatedProfit.toFixed(2) : '0'}%</span></Typography>
              </Box>
              {/* <Box display="flex" justifyContent="space-between" px={15} mt={2}>
          <button
            // className={button==12 ? classes.activeButton :classes.graphButtonInactive}
            // onClick={()=>{get_filteredData(12)}}
            className={button == 365 ? classes.activeButton : classes.graphButtonInactive}
            onClick={() => { get_filteredData(365) }}
          >
            1Y
          </button>
          <button
            // className={button==36 ? classes.activeButton :classes.graphButtonInactive}
            // onClick={()=>{get_filteredData(36)}}
            className={button == 1095 ? classes.activeButton : classes.graphButtonInactive}
            onClick={() => { get_filteredData(1095) }}
          >
            3Y
          </button>
          <button
            // className={button==60 ? classes.activeButton :classes.graphButtonInactive}
            // onClick={()=>{get_filteredData(60)}}
            className={button == 1825 ? classes.activeButton : classes.graphButtonInactive}
            onClick={() => { get_filteredData(1825) }}
          >
            5Y
          </button>
        </Box> */}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
            <Box display='flex' justifyContent='center'>
              {/* <ResponsiveContainer width={400} height={270} >
                <BarChart
                  // barGap={-30}
                  width={300} height={250} data={barChartData}
                  margin={{ top: 50, right: 30, left: 20, bottom: 5 }}
                  barCategoryGap="30%" // Adjust space between categories
                  barGap={5} // Adjust space between bars within the same category
                >
                  <XAxis dataKey="name" xAxisId={0} allowDuplicatedCategory={false}
                    //yAxisId="left"
                    // dataKey="baseValue"
                    // tick={false}
                    tick={{ fontSize: 12 }}  // Set the font size for X-axis ticks
                    label={{ fontSize: 14 }}
                    axisLine={false} />
                  <XAxis dataKey="name" xAxisId={1} hide allowDuplicatedCategory={false}
                    //yAxisId="left"
                    // dataKey="baseValue"
                    label={false}
                    tick={false}
                    // tick={null}  // Remove X-axis ticks
                    // label={null} // Remove X-axis label
                    // scale="auto"
                    axisLine={false}
                  />
                  <YAxis allowDuplicatedCategory={false}
                    //yAxisId="left"
                    // dataKey="baseValue"
                    label={false}
                    tick={false}
                    // tick={null}  // Remove X-axis ticks
                    // label={null} // Remove X-axis label
                    // scale="auto"
                    hide
                    axisLine={false}
                  /> */}
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              {/* <Tooltip /> */}
              {/* <Legend /> */}
              {/* 
                  <Bar dataKey="total" barSize={20} xAxisId={1} fill="#0E76BC" fillOpacity={1}>
                    <LabelList dataKey="total" position="top" fill='#0E76BC' content={<CustomBarLabel fill="#ff0000" />} offset={10} />
                  </Bar>

                  <Bar dataKey="invested_amount" barSize={20} xAxisId={0} fill="#ccc" />


                </BarChart> 
              </ResponsiveContainer>*/}
              <ResponsiveContainer width={400} height={200}>
                <BarChart
                  data={barChartData}
                  margin={{ top: 50, right: 30, left: 20, bottom: 5 }}
                  barCategoryGap="30%" // Adjust space between categories
                  barGap={10} // Adjust space between bars within the same category
                >
                  <XAxis
                    dataKey="name"
                    tick={{ fontSize: 12 }}
                    axisLine={false}
                  />

                  <YAxis axisLine={false} tick={false} hide />
                  {/* <Tooltip /> */}

                  {/* First Bar */}
                  <Bar
                    dataKey="total"
                    barSize={20}
                    fill="#ccc"
                    stackId="a" // Use the same stack ID to group within the same category

                    radius={[5, 5, 5, 5]} // Top corners are rounded
                  >
                    <LabelList
                      dataKey="total"
                      position="top"
                      content={<CustomBarLabel fill="#000" />}
                    />
                  </Bar>

                  {/* Second Bar */}
                  <Bar
                    dataKey="invested_amount"
                    barSize={20}

                    fill="#0E76BC"
                    stackId="b" // Use a different stack ID for side-by-side bars
                    radius={[5, 5, 5, 5]} // Top corners are rounded
                  >
                    {/* <LabelList
                      dataKey="invested_amount"
                      position="top"
                      content={<CustomBarLabel fill="#ccc" />}
                    /> */}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Box>
            <Box mt={1} display='flex' justifyContent='center'>
              <button className={yearButton == "1" ? classes.activeYearButton : classes.inactiveYearButton} onClick={() => handleYearButton("1")}>1 Y</button>
              <button className={yearButton == "3" ? classes.activeYearButton : classes.inactiveYearButton} onClick={() => handleYearButton("3")}>3 Y</button>
              <button className={yearButton == "5" ? classes.activeYearButton : classes.inactiveYearButton} onClick={() => handleYearButton("5")}>5 Y</button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default ReturnCal