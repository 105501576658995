import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Select, MenuItem, InputLabel, FormControl, ListItemText, Checkbox as MUICheckbox } from '@mui/material';

import { Checkbox, FormGroup, FormControlLabel, Typography, Popover, Button, Box, capitalize } from '@material-ui/core';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { asset_Sub_Category } from '../../../components/Data/assetSubCatogory'
import { GET_ALL_AMC_CATEGORY_LIST } from '../../../utils/endpoints';
import httpInstance from "../../../utils/instance";

const riskType = [
  // { value: "Aggressive", name: "Aggressive" },
  // { value: "Moderately Aggressive", name: "Moderately Aggressive" },
  // { value: "Conservative", name: "Conservative" },
  // { value: "Moderately Conservative", name: "Moderately Conservative" },
  // { value: "Balanced", name: "Balanced" },
  { value: "high_returns", name: "High Returns" },
  { value: "top_rated", name: "Top Rated" },
  { value: "tax_saver", name: "Tax Saver" },
  { value: "sip_100", name: "Sip 100" },
  { value: "index_fund", name: "Index Fund" },
  { value: "new_fund", name: "New Fund" },
  { value: "sip_500", name: "Sip 500" },
  { value: "beat_fd", name: "Beat FD Funds" },
  { value: "gold_funds", name: "Gold Funds" },
  { value: "global_funds", name: "Global Funds" },
  { value: "sectoral_funds", name: "Sectoral Funds" },
  { value: "liquid_funds", name: "Liquid Funds" },
  { value: "govt_funds", name: "Govt Funds" },
]


const pickReturns = [
  { value: "year10", name: "10 years" },
  { value: "year7", name: "7 years" },
  { value: "year5", name: "5 years" },
  { value: "year3", name: "3 years" },
  { value: "year2", name: "2 years" },
  { value: "year1", name: "1 year" },
  { value: "month6", name: "6 months" },
  { value: "month3", name: "3 months" },
  { value: "month1", name: "1 month" },
]


const allAmcList = [
  { amcName: "Trending", amcCode: "top_rated", categoryId: 0 },
  { amcName: "Fucused Fund", amcCode: "sectoral_fund", categoryId: 107 },
  { amcName: "Save Tax", amcCode: "tax_saver", categoryId: null },
  { amcName: "Multi Cap", amcCode: "null", categoryId: 145 },
  { amcName: "Balanced Fund", amcCode: "null", categoryId: 137 },
  { amcName: "Liquid Fund", amcCode: "liquid_funds", categoryId: 125 },
  { amcName: "New Fund Offering", amcCode: "new_fund", categoryId: null },

]


const useStyles = makeStyles((theme) => ({
  root: {
    "& p": {
      margin: "4px"
    },
    width: '100%',
    "& .MuiAccordion-root:before": {
      height: "0px",
      top: -4
    },
    "& .MuiAccordion-rounded:first-child ": {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px"
    },
    "& .MuiAccordion-rounded:last-child ": {
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px"
    },
    "& .MuiFormControlLabel-root": {
      padding: "5px 0px"
    }
  },
  heading: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  filterStyle: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: 'row',
    alignItems: "center"
  },

  checkBox: {
    fontSize: "1rem",
    "& .Muip-body1": {
      fontSize: "0.85rem"
    },
    "& .MuiCheckbox-colorPrimary.Mui-checked": {
      color: "#0E76BC",
    },
  },
  accordion: {
    borderRadius: "10px",
    // position: 'absolute',
    // zIndex: 1000,
  },
  accordionExpanded: {
    position: 'absolute',
    zIndex: 10000,
    width: '100%',
    marginTop: '10px',
    // backgroundColor: theme.palette.background.paper,
  },

  reset: {
    fontSize: "0.9rem",
    color: "#0E76BC",
    textAlign: "end",
    cursor: "pointer"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    fontSize: "1rem",
    fontWeight: 500,
    backgroundColor: '#fff', // Change button background color
    color: '#12120E', // Button text color
    textTransform: 'capitalize',
    margin: "0px 5px",
    '&:hover': {
      backgroundColor: '#f4f4f4 !important', // Darker shade on hover
    },
    transition: 'background-color 0.3s ease', // Smooth transition for background color
    boxShadow: 'none !important',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '10px',
  },
  popover: {
    // backgroundColor: '#f4f4f4', // Popover background color
    // width: '100%', // Match width to button
    // maxWidth: 300, // Max width for the popover
    // padding: theme.spacing(2),
    // boxShadow: theme.shadows[3], // Slight shadow for depth
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '10px',
  },
  formGroup: {
    margin: theme.spacing(2),
  },
  arrow: {
    position: 'absolute',
    width: '10px',
    height: '10px',
    backgroundColor: '#f4f4f4', // Same as Popover background color
    transform: 'rotate(45deg)',
    top: '-5px', // Adjust position
    left: 'calc(50% - 5px)', // Center the arrow
  },
  open: {
    transform: 'rotate(270deg)', // Rotate the arrow when open
    transition: 'transform 0.3s ease', // Smooth transition for rotation
  },
  closed: {
    transform: 'rotate(90deg)', // Default arrow rotation
    transition: 'transform 0.3s ease', // Smooth transition for rotation
  },
  buttonContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    '& > *': {
      flex: '1 1 30%', // Default to two items per row
      maxWidth: '100%',
      margin: '5px',  
      [theme.breakpoints.up('sm')]: { // For `sm` and up (>=600px), set four items per row
        flex: '1 1 23%', // Approximately 1/4 of the width (4 items per row)
        maxWidth: '35%',
      },
    },
  },
}));

const RatingImage = ({ rating }) => {
  return (
    [...Array(rating)].map((img, i) => {
      return <img key={i} src="/images/starRating.svg" />
    })
  )
}

export default function SimpleAccordion({ allAmc, amc_filter, risk_filter, subAsset_filter, rating_filter, reset_filter, type }) {
  const classes = useStyles();

  const [checkedAmc, setCheckedAmc] = useState([])
  const [checkedRisk, setCheckedRisk] = useState([])
  const [checkedAsset, setCheckedAsset] = useState([])
  const [checkRate, setCheckRate] = useState([])
  const [amcCategoryList, setAmcCategoryList] = useState([])


  //getting all the amc category list in the place 

  const get_all_amc_category = async () => {
    const httpConfig = {
      method: "GET",
      // tokenType: "user",
      test: 'test',
      // headers: {
      //   "Content-Type": "application/json",
      // },
    };

    try {

      const response = await httpInstance(`${GET_ALL_AMC_CATEGORY_LIST}`, httpConfig);

      console.log(response, 'getSpecificAMC')
      setAmcCategoryList(response?.data?.data)
    } catch { }

  };





  useEffect(() => {
    get_all_amc_category();
  }, []);







  const handle_amc = (e) => {
    const { value, checked } = e.target
    let amcArry
    // if(checked){
    //   amcArry=[...checkedAmc,value]
    // }else{
    //   amcArry=checkedAmc.filter((e)=>e!=value)
    // }
    if (checked) {
      amcArry = [value]
    } else {
      amcArry = checkedAmc.filter((e) => e != value)
    }
    setCheckedAmc(amcArry)
    console.log(amcArry[0], "AMC")
    amc_filter(amcArry.toString())
  }

  //---------------------------risk profile-----------------------------------------------//
  const handle_riskProfile = (e) => {
    const { value, checked } = e.target
    console.log(value)
    let riskArry
    // if(checked){
    //   riskArry=[...checkedRisk,value]
    // }else{
    //   riskArry=checkedRisk.filter((e)=>e!=value)
    // }
    // console.log(riskArry)
    // setCheckedRisk(riskArry)
    if (checked) {
      riskArry = [value]
    } else {
      riskArry = checkedAsset.filter((e) => e != value)
    }
    setCheckedRisk(riskArry);
    risk_filter(riskArry.toString())
  }

  const handle_sub_asset = (e) => {
    const { value, checked } = e.target
    // console.log(value, 'Sub Asset') 
    let assetkArry
    // if(checked){
    //   assetkArry=[...checkedAsset,value]
    // }else{
    //   assetkArry=checkedAsset.filter((e)=>e!=value)
    // }
    // setCheckedAsset(assetkArry)
    // setCheckedAsset([assetkArry])
    if (checked) {
      assetkArry = [value]
    } else {
      assetkArry = checkedAsset.filter((e) => e != value)
    }
    // console.log(assetkArry)
    setCheckedAsset(assetkArry)
    subAsset_filter(assetkArry?.toString())
  }

  const handle_rating = (e) => {
    const { value, checked } = e.target
    let ratingArry
    // if(checked){
    //   ratingArry=[...checkRate,value]
    // }else{
    //   ratingArry=checkRate?.filter((e)=>e!=value)

    // }
    // setCheckRate(ratingArry)
    if (checked) {
      ratingArry = [value]
    } else {
      ratingArry = checkedAsset.filter((e) => e != value)
    }
    setCheckRate(ratingArry)
    rating_filter(ratingArry)

  }



  //------------------handle reset--------------------------------------------//
  const handle_reset = () => {
    setCheckedAmc([])
    setCheckedRisk([])
    setCheckedAsset([])
    setCheckRate([])
    reset_filter()
  }


  const renderMultiSelect = (label, options, checkedValues, handleChange, disabled = false) => (
    <FormControl className={classes.formControl}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={checkedValues}
        onChange={handleChange}
        renderValue={(selected) => selected.join(', ')}
        disabled={disabled}
      >
        {options.map((option, i) => (
          <MenuItem key={i} value={option.value}>
            <MUICheckbox checked={checkedValues.includes(option.value)} />
            <ListItemText primary={option.name || option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };



  // popover desing 

  // const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const open = Boolean(anchorEl);
  // const id = open ? 'simple-popover-year' : undefined;

  const [popovers, setPopovers] = useState({
    return: { anchorEl: null, open: false },
    rating: { anchorEl: null, open: false },
    risk: { anchorEl: null, open: false },
    asset: { anchorEl: null, open: false },
  });

  const handleClick = (event, type) => {
    setPopovers({
      ...popovers,
      [type]: {
        anchorEl: event.currentTarget,
        open: !popovers[type].open,
      },
    });
  };

  const handleClose = (type) => {
    setPopovers({
      ...popovers,
      [type]: {
        anchorEl: null,
        open: false,
      },
    });
  };


  return (
    <div className={classes.root}>
      {/* <div className={classes.filterStyle}>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleAccordionChange('panel1')}
        className={`${classes.accordion} ${expanded === 'panel1' ? classes.accordionExpanded : ''}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.heading}
        >
          Pick Returns
        </AccordionSummary>
        <SimpleBar style={{ maxHeight: 300 }}>
          <AccordionDetails className={classes.checkBox}>
            <FormGroup>
              {pickReturns?.map((returns, i) => (
                <FormControlLabel
                  key={i}
                  control={<Checkbox color="primary" value={returns.value} checked={checkedAmc.includes(returns.value)} onChange={handle_amc} />}
                  label={returns.name}
                />
              ))}
            </FormGroup>
          </AccordionDetails>
        </SimpleBar>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel2'}
        onChange={handleAccordionChange('panel2')}
        className={`${classes.accordion} ${expanded === 'panel2' ? classes.accordionExpanded : ''}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.heading}
        >
          Pick Rating
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {[...Array(5)].map((_, i) => {
              let ratingCount = 5 - i;
              return (
                <FormControlLabel
                  key={i}
                  control={<Checkbox color="primary" value={5 - i} checked={checkRate?.includes((5 - i).toString())} onChange={handle_rating} />}
                  label={<RatingImage rating={ratingCount} />}
                />
              );
            })}
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel3'}
        onChange={handleAccordionChange('panel3')}
        className={`${classes.accordion} ${expanded === 'panel3' ? classes.accordionExpanded : ''}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          className={classes.heading}
        >
          Pick Risk
        </AccordionSummary>
        <SimpleBar style={{ maxHeight: 300 }}>
          <AccordionDetails className={classes.checkBox}>
            <FormGroup>
              {riskType?.map((risk, i) => (
                <FormControlLabel
                  key={i}
                  control={<Checkbox color="primary" value={risk.value} checked={checkedRisk.includes(risk.value)} onChange={handle_riskProfile} />}
                  label={risk.name}
                  disabled={type === "All Fund" ? false : true}
                />
              ))}
            </FormGroup>
          </AccordionDetails>
        </SimpleBar>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel4'}
        onChange={handleAccordionChange('panel4')}
        className={`${classes.accordion} ${expanded === 'panel4' ? classes.accordionExpanded : ''}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
          className={classes.heading}
        >
          Pick Asset Sub Categories
        </AccordionSummary>
        <SimpleBar style={{ maxHeight: 200 }}>
          <AccordionDetails className={classes.checkBox}>
            <FormGroup>
              {amcCategoryList?.map((ele, i) => (
                <FormControlLabel
                  key={i}
                  control={<Checkbox color="primary" value={ele.id} checked={JSON.stringify(checkedAsset).includes(ele.id)} onChange={handle_sub_asset} />}
                  label={ele.categoryName}
                  disabled={type === "All Fund" ? false : true}
                />
              ))}
            </FormGroup>
          </AccordionDetails>
        </SimpleBar>
      </Accordion>

      <Typography className={classes.reset} onClick={handle_reset}>Reset</Typography>
    </div>
  </div> */}
      <div className={classes.root}>
       
        <div className={classes.filterStyle}
        // style={{ display: 'flex',
        //   //  flexDirection: 'row',
        //     alignItems: 'center' }}
        >
          <Box
            // width={'100%'} display={'flex'} flexDirection={'row'} justifyContent={'center'}

            // width={'100%'} display={'flex'}   flexWrap="wrap"  flexDirection={'row'} justifyContent={'center'}
            className={classes.buttonContainer}
          >
            <Button
              // aria-describedby={id} 
              aria-describedby="return-popover"
              variant="contained"
              // onClick={handleClick}
              onClick={(event) => handleClick(event, 'return')}
              className={classes.button}
            >
              Pick Returns <span style={{ marginLeft: '10px' }} className={popovers.return.open ? classes.open : classes.closed}>{'>'}</span>
            </Button>
            <Popover
              // id={id}
              // open={open}
              // anchorEl={anchorEl}
              // onClose={handleClose}
              id="return-popover"
              open={popovers.return.open}
              anchorEl={popovers.return.anchorEl}
              onClose={() => handleClose('return')}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}

              className={classes.popover}
            >
              {/* <SimpleBar style={{  width: '100%', maxHeight: 200 }}> */}
              <Box maxHeight={200} padding={2}>
                <FormGroup>
                  {pickReturns?.map((returns, i) => {
                    return (
                      <FormControlLabel
                        key={i}
                        control={<Checkbox color="primary" value={returns.value} checked={checkedAmc.includes(returns.value)} onChange={handle_amc} />}
                        label={returns.name} />
                    )
                  })}
                </FormGroup>
                {/* </SimpleBar> */}
              </Box>
            </Popover>
             {/* <Button
              // aria-describedby={id} 
              // onClick={handleClick} 
              aria-describedby="rating-popover"
              variant="contained"
              onClick={(event) => handleClick(event, 'rating')}
              className={classes.button}
            >
              Pick Rating <span style={{ marginLeft: '10px' }} className={popovers.rating.open ? classes.open : classes.closed}>{'>'}</span>
            </Button>
           <Popover
              // id={id}
              // open={open}
              // anchorEl={anchorEl}
              // onClose={handleClose}
              id="rating-popover"
              open={popovers.rating.open}
              anchorEl={popovers.rating.anchorEl}
              onClose={() => handleClose('rating')}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}

              className={classes.popover}
            >
              <Box maxHeight={200} padding={2}>
                <FormGroup>
                  {[...Array(5)].map((rating, i) => {
                    let ratingCount = 5 - i
                    return (
                      <FormControlLabel
                        key={i}
                        control={<Checkbox color="primary" value={5 - i} checked={checkRate?.includes((5 - i).toString())} onChange={handle_rating} />}
                        label={<RatingImage rating={ratingCount} />} />
                    )
                  })}

                </FormGroup>
              </Box>
            </Popover> */}
            {/* <SimpleBar style={{  width: '100%', maxHeight: 200 }}> */}
            {/* </SimpleBar> */}

            <Button
              // aria-describedby={id}
              // onClick={handleClick}
              aria-describedby="risk-popover"
              variant="contained"
              onClick={(event) => handleClick(event, 'risk')}
              className={classes.button}
            >
              Pick Risk <span style={{ marginLeft: '10px' }} className={popovers.risk.open ? classes.open : classes.closed}>{'>'}</span>
            </Button>
            <Popover
              // id={id}
              // open={open}
              // anchorEl={anchorEl}
              // onClose={handleClose}
              id="risk-popover"
              open={popovers.risk.open}
              anchorEl={popovers.risk.anchorEl}
              onClose={() => handleClose('risk')}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}

              className={classes.popover}
            >
              {/* <SimpleBar style={{  width: '100%', maxHeight: 200 }}> */}
              <Box maxHeight={200} padding={2}>
                <FormGroup>
                  {riskType?.map((risk, i) => {
                    return (
                      <FormControlLabel
                        key={i}
                        control={<Checkbox color="primary" value={risk.value} checked={checkedRisk.includes(risk.value)} onChange={handle_riskProfile} />}
                        label={risk.name}
                        disabled={type === "All Fund" ? false : true} />
                    )
                  })}
                </FormGroup>
                {/* </SimpleBar> */}
              </Box>
            </Popover>
            <Button
              // aria-describedby={id} 
              // onClick={handleClick} 
              aria-describedby="asset-popover"
              variant="contained"
              onClick={(event) => handleClick(event, 'asset')}
              className={classes.button}
            >
              Pick Asset Sub Categories <span style={{ marginLeft: '10px' }} className={popovers.asset.open ? classes.open : classes.closed}>{'>'}</span>
            </Button>
            <Popover
              // id={id}
              // open={open}
              // anchorEl={anchorEl}
              // onClose={handleClose}
              id="asset-popover"
              open={popovers.asset.open}
              anchorEl={popovers.asset.anchorEl}
              onClose={() => handleClose('asset')}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}

              className={classes.popover}
            >
              {/* <SimpleBar style={{  width: '100%', maxHeight: 200 }}> */}
              <Box maxHeight={200} padding={2}>
                <FormGroup>
                  {amcCategoryList?.map((ele, i) => {
                    return (
                      <FormControlLabel
                        key={i}
                        control={<Checkbox color="primary" value={ele.id} checked={JSON.stringify(checkedAsset).includes(ele.id)} onChange={handle_sub_asset} />}
                        label={ele.categoryName}
                        disabled={type === "All Fund" ? false : true} />
                    )
                  })}
                </FormGroup>
                {/* </SimpleBar> */}
              </Box>
            </Popover>
          </Box>
          <Typography className={classes.reset} onClick={handle_reset}>Reset</Typography>
        </div>
      </div>
      {/*
         <div className={classes.root}>
          <div className={classes.filterStyle}>
            {renderMultiSelect("Pick Returns", pickReturns, checkedAmc, handle_amc)}
            {renderMultiSelect("Pick Rating", [...Array(5)].map((_, i) => ({
              value: (5 - i).toString(),
              label: <RatingImage rating={5 - i} />
            })), checkRate, handle_rating)}
            {renderMultiSelect("Pick Risk", riskType, checkedRisk, handle_riskProfile, type !== "All Fund")}
            {renderMultiSelect("Pick Asset Sub Categories", amcCategoryList, checkedAsset, handle_sub_asset, type !== "All Fund")}
            <Typography className={classes.reset} onClick={handle_reset}>Reset</Typography>
          </div>
        </div>
         */}
    </div>

  );
}
