import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  CircularProgress,
  Tab,
  Tabs,
} from "@material-ui/core";
import PropTypes from "prop-types";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import OrderList from "./OrderList";
import Filter_OrderType from "./Filter_OrderType";
import httpInstance from "../../../utils/instance";
import {
  GET_ORDERS,
  GET_LUMPSUM_ORDER_STATUS_COMPLETED_STATUS,
  GET_LUMPSUM_ORDER_STATUS_PENDING_STATUS,
  GET_LUMPSUM_ORDER_STATUS_FAILED_STATUS,
  GET_NO_MANDATE_SIPS_ACTIVE_STATUS,
  GET_NO_MANDATE_SIPS_INACTIVE_STATUS,
  GET_REDEMPTION_ORDER_COMPLETED_STATUS,
  GET_REDEMPTION_ORDER_PENDING_STATUS,
  GET_REDEMPTION_ORDER_FAILED_STATUS,
  GET_SIPS_ACTIVE_STATUS,
  GET_SIPS_INACTIVE_STATUS,
  GET_SIPS_PENDING_STATUS,
  GET_SMART_SIPS_ORDER_COMPLETED_STATUS,
  GET_SMART_SIPS_ORDER_PENDING_STATUS,
  GET_SMART_SIPS_ORDER_FAILED_STATUS,
  GET_STP_ORDER_COMPLETED_STATUS,
  GET_STP_ORDER_PENDING_STATUS,
  GET_STP_ORDER_FAILED_STATUS,
  GET_SWITCH_ORDER_COMPLETED_STATUS,
  GET_SWITCH_ORDER_PENDING_STATUS,
  GET_SWITCH_ORDER_FAILED_STATUS,
  GET_SWP_ORDER_COMPLETED_STATUS,
  GET_SWP_ORDER_PENDING_STATUS,
  GET_SWP_ORDER_FAILED_STATUS,
  SUCCESSFULL_RECOMMENDATION,
  GET_NO_MANDATE_SIPS_DETAILS,
  GET_SIPS_DETAILS
} from "../../../utils/endpoints";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { namespace } from "../../../environment";
import GoldList from "./GoldList";
import GoldListTrans from "./GoldListTrans";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import {
  GET_TRANS_BY_STATUS,
  GET_DELIVERY_LIST,
} from "../../../utils/endpoints";
import OrderTypeSelection from "./OrderTypeSelection";
import { Padding } from "@mui/icons-material";
import OrderListLoder from "./OrderListLoder";

const Lumpsum = 0
const Redemption = 1
const SIP = 2
const STP = 3
const Switch = 4
const SWP = 5

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  // console.log(index)
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const LoaderComp = () => {
  return (
    // <div
    //   style={{
    //     display: "flex",
    //     justifyContent: "center",
    //     marginTop: "1rem",
    //     overflow: "hidden",
    //   }}
    // >
    //   <CircularProgress size="2rem" />
    // </div>
    <OrderListLoder />
  );
};

const Index = () => {
  const useStyles = makeStyles((theme) => ({
    subTabtext: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
    },
    textUnderline: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
    },
    title: {
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    text: {
      textAlign: "center",
      marginTop: "10px",
      padding: "20px 0"
    },
    heading: {
      fontSize: "1.2rem",
      textAlign: "center",
      fontWeight: 500,
      marginBottom: "20px",
    },
    root: {
      "& .MuiTab-root": {
        fontFamily: "inherit",
        fontSize: "1rem",
        fontWeight: 600,
        color: "black",
        opacity: 1,
        textTransform: "none",
        background: "none",
        minWidth: "auto",
        "&:hover": {
          backgroundColor: "0E76BC",
        }
      },
      "& .MuiTab-textColorInheri": {
        opacity: 0.9,
      },
    },
    tablebody: {
      // "& div": {
      //     padding: "10px 0",
      // }
      borderRadius: "12px",
      // boxShadow: "0 0 8px -3px #444444",
      border: '1px solid #E4E4E4',
      // boxShadow: '0 0 8px -3px #444444',
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
      padding: "1rem",
      "& > *": {
        padding: " 0",
      },
    },
    activeTab: {
      "& .MuiTab-wrapper": {
        borderBottom: "2px solid #0E76BC",
        color: "#0E76BC",
        fontSize: "1rem",
        fontWeight: 600,
      },
    },
    inactiveTab: {
      "& .MuiTab-wrapper": {
        // borderBottom: "2px solid #0E76BC",
        // color: "#0E76BC",
        borderBottom: "2px solid transparent",
        fontSize: "1rem",
        fontWeight: 600,
        "&:hover": {
          borderBottom: "2px solid #0E76BC !important",
        }
      },
    }
  }));
  const classes = useStyles();

  const [status, setStatus] = useState("Successful");
  const [orders, setOrders] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false)


  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const tab = searchParams.get("order");
  const tabStatus = searchParams.get("status");

  const [value, setValue] = useState(0);

  const [delivery, setDelivery] = useState([]);
  const [buysell, setBuySell] = useState([]);

  useEffect(() => {
    if (tab && tabStatus) {
      handleChangeByQuery(Number(tab), tabStatus)
    } else {
      const newParams = new URLSearchParams(searchParams);
      newParams.set('order', 0); // Replace 'status' with your desired query key
      newParams.set('status', 'Successful'); // Replace 'status' with your desired query key
      navigate(`?${newParams.toString()}`);
    }
  }, [tab])

  const handleChange = (e, newValue) => {
    // console.log(e, newValue)
    if (!loading){ 
      // setValue(newValue);
      // setOrders([]);
      // setPage(0);
      // setHasMore(true);
      if (
        // newValue == 1 || 
        newValue == SIP) {
        // setStatus("Active")
        handleChangeByQuery(newValue, "Active")
        // Update query parameters in the URL
        const newParams = new URLSearchParams(searchParams);
        newParams.set('order', newValue); // Replace 'status' with your desired query key
        newParams.set('status', 'Active'); // Replace 'status' with your desired query key
        navigate(`?${newParams.toString()}`);
      } else {
        // setStatus("Successful")
        handleChangeByQuery(newValue, "Successful")
        const newParams = new URLSearchParams(searchParams);
        newParams.set('order', newValue); // Replace 'status' with your desired query key
        newParams.set('status', 'Successful'); // Replace 'status' with your desired query key
        navigate(`?${newParams.toString()}`);
      }
    }
    // get_orders()
  };


  const handleChangeByQuery = (data, state) => {
    // console.log(data)
    setValue(data);
    setOrders([]);
    setPage(0);
    setHasMore(true);
    if (
      // data == 1 || 
      data == SIP) {
      setStatus(state)
      // Update query parameters in the URL
      // const newParams = new URLSearchParams(searchParams);
      // newParams.set('order', data); // Replace 'status' with your desired query key
      // navigate(`?${newParams.toString()}`);
    } else {
      setStatus(state)
      // const newParams = new URLSearchParams(searchParams);
      // newParams.set('order', data); // Replace 'status' with your desired query key
      // navigate(`?${newParams.toString()}`);
    }

    // get_orders()
  };

  // const get_gold_orders = async () => {
  //   const httpConfig = {
  //     method: "POST",
  //     tokenType: "user",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     params: { type: "buy/sell", status: "all", limit: 1000, from: 0 },
  //   };

  //   const httpConfig1 = {
  //     method: "POST",
  //     tokenType: "user",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     params: { status: "", limit: 1000, from: 0 },
  //   };

  //   try {
  //     const [buysell, delresponse] = await Promise.all([
  //       httpInstance(`${GET_TRANS_BY_STATUS}`, httpConfig),
  //       httpInstance(`${GET_DELIVERY_LIST}`, httpConfig1),
  //     ]);
  //     setDelivery(delresponse);
  //     setBuySell(buysell);
  //   } catch { }
  // };


  // get sip full order details
  const get_full_orderDetails = async (data) => {
    // console.log(data)
    setLoading(true)
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: value === 1 ? { id: data?.id } : { fp_sip_id: data?.fp_sip_id },
    };
    let GET_URL
    if (value == 1) {
      GET_URL = `${GET_NO_MANDATE_SIPS_DETAILS}`
    } else {
      GET_URL = `${GET_SIPS_DETAILS}`
    }
    try {
      const response = await httpInstance(`${GET_URL}`, httpConfig);
      // Update the state data with the response data
      // Merge existing data with response data
      const updatedData = { ...data, ...response?.data?.data };
      // console.log(updatedData)
      // Navigate to the destination URL with the updated state
      navigate(`${namespace}/mutualfund/mutual-fund/order-status/${data?.id ? data?.id : data?.mfOldId}`, {
        state: { data: updatedData, order: true, orderType: 2, returnBack: [SIP, status] }
      });
      setLoading(false)

    } catch {
      setLoading(false)

    }
  }


  const get_orders = async () => {
    setLoading(true)
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        user_id: localStorage.getItem("user_id"),
        // isAllOrders: status ? false : true,
        // status: status,
        // limit: 10,
        // from: page == 0 ? 0 : page * 10,
        // isDiyOrder: false,
      },
    };



    let GET_URL
    if (status === 'Successful') {
      // lumpsum order
      if (value == Lumpsum) {
        GET_URL = GET_LUMPSUM_ORDER_STATUS_COMPLETED_STATUS
      } else if (value == Redemption) {
        GET_URL = GET_REDEMPTION_ORDER_COMPLETED_STATUS
      // } else if (value == 4) {
      //   GET_URL = GET_SMART_SIPS_ORDER_COMPLETED_STATUS
      } else if (value == STP) {
        GET_URL = GET_STP_ORDER_COMPLETED_STATUS
      } else if (value == Switch) {
        GET_URL = GET_SWITCH_ORDER_COMPLETED_STATUS
      } else if (value == SWP) {
        GET_URL = GET_SWP_ORDER_COMPLETED_STATUS
      }
    } else if (status === 'Failed') {
      // lumpsum order
      if (value == Lumpsum) {
        GET_URL = GET_LUMPSUM_ORDER_STATUS_FAILED_STATUS
      } else if (value == Redemption) {
        GET_URL = GET_REDEMPTION_ORDER_FAILED_STATUS
      // } else if (value == 4) {
      //   GET_URL = GET_SMART_SIPS_ORDER_FAILED_STATUS
      } else if (value == STP) {
        GET_URL = GET_STP_ORDER_FAILED_STATUS
      } else if (value == Switch) {
        GET_URL = GET_SWITCH_ORDER_FAILED_STATUS
      } else if (value == SWP) {
        GET_URL = GET_SWP_ORDER_FAILED_STATUS
      }
    } else if (status === 'Pending') {
      //status "Pending"
      // lumpsum order
      if (value == Lumpsum) {
        GET_URL = GET_LUMPSUM_ORDER_STATUS_PENDING_STATUS
      } else if (value == Redemption) {
        GET_URL = GET_REDEMPTION_ORDER_PENDING_STATUS
      } else if (value == SIP) {
        GET_URL = GET_SIPS_PENDING_STATUS
      // } else if (value == 4) {
      //   GET_URL = GET_SMART_SIPS_ORDER_PENDING_STATUS
      } else if (value == STP) {
        GET_URL = GET_STP_ORDER_PENDING_STATUS
      } else if (value == Switch) {
        GET_URL = GET_SWITCH_ORDER_PENDING_STATUS
      } else if (value == SWP) {
        GET_URL = GET_SWP_ORDER_PENDING_STATUS
      }
    } else if (status === 'Active') {
      //status "Pending"
      if (value == 1) {
        GET_URL = GET_NO_MANDATE_SIPS_ACTIVE_STATUS
      } else if (value == SIP) {
        GET_URL = GET_SIPS_ACTIVE_STATUS
      }
    } else if (status === 'Inactive') {
      //status "Pending"
      if (value == 1) {
        GET_URL = GET_NO_MANDATE_SIPS_INACTIVE_STATUS
      } else if (value == SIP) {
        GET_URL = GET_SIPS_INACTIVE_STATUS
      }
    } else {
      return
    }
    try {
      const response = await httpInstance(`${GET_URL}`, httpConfig);
      // console.log('order list', response.data)
      if (response.data?.responseCount == 0 || response.data.data.length == 0) {
        setHasMore(false);
      } else {
        setOrders(response.data?.data);
        setPage(page + 1);
        // if (value == 1 || value == 2) {
        //   const finalData = await get_full_orderDetails(value, response.data?.data)
        //   console.log(finalData)
        //   console.log(...response.data?.data, ...finalData)
        //   setOrders([...response.data?.data, ...finalData] ); // setOrders([...orders, ...finalData])
        //   setPage(page + 1);
        // } else {
        //   setOrders(response.data?.data);
        //   setPage(page + 1);
        // }

      }
      setLoading(false)
    } catch {
      setLoading(false)

    }
  };

  useEffect(() => {
    get_orders();
    // get_gold_orders();
  }, [status, value]);
  console.log(loading)

  const handleStatus = (data) => {
    if (!loading){ 
      setOrders([]);
      setPage(0);
      setHasMore(true);
      setStatus(data);
      const newParams = new URLSearchParams(searchParams);
      newParams.set('status', data); // Replace 'status' with your desired query key
      navigate(`?${newParams.toString()}`);
    }
  };

  const fetchData = () => {
    // get_orders();
    setHasMore(false)
  };



  return (
    <div>
      {/* <Box display="flex" alignItems="start" px={{ xs: 1, md: 17 }} pt={2}>
        <Typography className={classes.subTabtext}>My Profile</Typography>

        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Typography className={classes.textUnderline}>My Orders</Typography>
        </Box>
      </Box> */}

      <Box sx={{ width: "100%" }}>

        <Box px={{ xs: 2, md: 15 }} mt={5}>
          <Typography className={classes.title}>Transactions</Typography>

          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className={classes.root}
            indicatorColor={""}
            variant="scrollable"  // Use a scrollable variant for tabs
            scrollButtons="auto"   // Automatically show scroll buttons when tabs overflow
          >
            <Tab
              label="Lumpsum Orders"
              {...a11yProps(0)}
              className={value == 0 ? classes.activeTab : classes.inactiveTab}
            />
            {/* <Tab
              label="No Mandate SIP Orders"
              {...a11yProps(1)}
              className={value == 1 && classes.activeTab}
            /> */}
            <Tab
              label="Redemption Orders"
              {...a11yProps(1)}
              className={value == 1 ? classes.activeTab : classes.inactiveTab}
            />
            <Tab
              label="SIP Orders"
              {...a11yProps(2)}
              className={value == 2 ? classes.activeTab : classes.inactiveTab}
            />
            {/* <Tab
              label="Smart SIP Orders"
              {...a11yProps(2)}
              className={value == 4 ? classes.activeTab : classes.inactiveTab}
            /> */}
            <Tab
              label="STP Orders"
              {...a11yProps(3)}
              className={value == 3 ? classes.activeTab : classes.inactiveTab}
            />
            <Tab
              label="Switch Orders"
              {...a11yProps(4)}
              className={value == 4 ? classes.activeTab : classes.inactiveTab}
            />
            <Tab
              label="SWP Orders"
              {...a11yProps(5)}
              className={value == 5 ? classes.activeTab : classes.inactiveTab}
            />
          </Tabs>
        </Box>
      
        <Box
          style={{ borderTop: "1px solid #E6E3E3", marginTop: "-10px" }}
        >
          <Box px={{ xs: 2, md: 15 }} mt={3} >
            {/* Lumpsum Orders */}
            <TabPanel value={value} index={0} className={classes.tablebody}>
              <OrderTypeSelection
                handleStatus={handleStatus}
                status={status}
                values={['Successful', 'Failed', 'Pending']}
                OrderType={'Lumpsum Orders'}
              />
              <Grid container spacing={5}>
                <Grid item xs={12} md={12}>
                  <InfiniteScroll
                    dataLength={orders?.length}
                    next={fetchData}
                    hasMore={hasMore}
                    loader={<LoaderComp />}
                    endMessage={
                      <Typography className={classes.text}>
                        No result to display
                      </Typography>
                    }
                  >
                    {orders?.map((data, i) => {
                      return (
                        <Link
                          to={`${namespace}/mutualfund/mutual-fund/order-status/${data?.id ? data?.id : data?.mfOldId
                            }`}
                          state={{ data: data, order: true, orderType: 1, returnBack: [Lumpsum, status] }}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          {" "}
                          <OrderList key={i} orderData={data} />
                        </Link>
                      );
                    })}
                  </InfiniteScroll>
                </Grid>
              </Grid>
            </TabPanel>

            {/* No Mandate SIP Orders */}
            {/* <TabPanel value={value} index={1} className={classes.tablebody}>
              <OrderTypeSelection
                handleStatus={handleStatus}
                status={status}
                values={['Active', 'Inactive']}
                OrderType={'No Mandate SIP Orders'}
              />
              <Grid container spacing={5}>
                <Grid item xs={12} md={12}>
                  <InfiniteScroll
                    dataLength={orders?.length}
                    next={fetchData}
                    hasMore={hasMore}
                    loader={<LoaderComp />}
                    endMessage={
                      <Typography className={classes.text}>
                        No result to display
                      </Typography>
                    }
                  >
                    {orders?.map((data, i) => {
                      return (
                        // <Link
                        //   to={`${namespace}/mutualfund/mutual-fund/order-status/${data?.id ? data?.id : data?.mfOldId
                        //     }`}
                        //   state={{ data: data, order: true, orderType: 2 }}
                        //   style={{ textDecoration: "none", color: "inherit" }}
                        // >
                        <div
                          onClick={() => get_full_orderDetails(data)}
                          // to={`${namespace}/mutualfund/mutual-fund/order-status/${data?.id ? data?.id : data?.mfOldId}`}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          {" "}
                          <OrderList key={i} orderData={data} />
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                </Grid>
              </Grid>
            </TabPanel> */}

            {/* Redemption Orders */}
            <TabPanel value={value} index={1} className={classes.tablebody}>
              {/* <Box px={{ xs: 2, md: 18 }} mt={5}> */}
              <OrderTypeSelection
                handleStatus={handleStatus}
                status={status}
                values={['Successful', 'Failed', 'Pending']}
                OrderType={'Redemption Orders'}
              />
              <Grid container spacing={5}>
                {/* <Grid item xs={6} md={3}>
                  <Filter_OrderType
                    handleStatus={handleStatus}
                    status={status}
                  /> 
                </Grid>*/}

                <Grid item xs={12} md={12}>
                  <InfiniteScroll
                    dataLength={orders?.length}
                    next={fetchData}
                    hasMore={hasMore}
                    loader={<LoaderComp />}
                    endMessage={
                      <Typography className={classes.text}>
                        No result to display
                      </Typography>
                    }
                  >
                    {orders?.map((data, i) => {
                      return (
                        <Link
                          to={`${namespace}/mutualfund/mutual-fund/order-status/${data?.id ? data?.id : data?.mfOldId
                            }`}
                          state={{ data: data, order: true, orderType: 3, returnBack: [Redemption, status] }}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          {" "}
                          <OrderList key={i} orderData={data} />
                        </Link>
                      );
                    })}
                  </InfiniteScroll>
                </Grid>
              </Grid>
              {/* </Box> */}
            </TabPanel>

            {/* SIP Orders */}
            <TabPanel value={value} index={2} className={classes.tablebody}>
              {/* <Box px={{ xs: 2, md: 18 }} mt={5}> */}
              <OrderTypeSelection
                handleStatus={handleStatus}
                status={status}
                values={['Active', 'Inactive']}
                OrderType={'SIP Orders'}
              />
              <Grid container spacing={5}>
                {/* <Grid item xs={6} md={3}>
                  <Filter_OrderType
                    handleStatus={handleStatus}
                    status={status}
                  /> 
                </Grid>*/}

                <Grid item xs={12} md={12}>
                  <InfiniteScroll
                    dataLength={orders?.length}
                    next={fetchData}
                    hasMore={hasMore}
                    loader={<LoaderComp />}
                    endMessage={
                      <Typography className={classes.text}>
                        No result to display
                      </Typography>
                    }
                  >
                    {orders?.map((data, i) => {
                      return (
                        // <Link
                        //   to={`${namespace}/mutualfund/mutual-fund/order-status/${data?.id ? data?.id : data?.mfOldId
                        //     }`}
                        //   state={{ data: data, order: true, orderType: 2 }}
                        //   style={{ textDecoration: "none", color: "inherit" }}
                        // >
                        <div
                          onClick={() => get_full_orderDetails(data)}
                          // to={`${namespace}/mutualfund/mutual-fund/order-status/${data?.id ? data?.id : data?.mfOldId}`}
                          style={{ textDecoration: "none", color: "inherit", cursor: "pointer" }}
                        >
                          {" "}
                          <OrderList key={i} orderData={data} type={'SIP'} />
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                </Grid>
              </Grid>
              {/* </Box> */}
            </TabPanel>

            {/* Smart SIP Orders */}
            {/* <TabPanel value={value} index={3} className={classes.tablebody}>
              <OrderTypeSelection
                handleStatus={handleStatus}
                status={status}
                values={['Successful', 'Failed', 'Pending']}
                OrderType={'No Mandate SIP Orders'}
              />
              <Grid container spacing={5}>
                <Grid item xs={12} md={12}>
                  <InfiniteScroll
                    dataLength={orders?.length}
                    next={fetchData}
                    hasMore={hasMore}
                    loader={<LoaderComp />}
                    endMessage={
                      <Typography className={classes.text}>
                        No result to display
                      </Typography>
                    }
                  >
                    {orders?.map((data, i) => {
                      return (
                        <Link
                          to={`${namespace}/mutualfund/mutual-fund/order-status/${data?.id ? data?.id : data?.mfOldId
                            }`}
                          state={{ data: data, order: true, orderType: 2 }}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          {" "}
                          <OrderList key={i} orderData={data} />
                        </Link>
                      );
                    })}
                  </InfiniteScroll>
                </Grid>
              </Grid>
            </TabPanel> */}

            {/* STP Orders */}
            <TabPanel value={value} index={3} className={classes.tablebody}>
              {/* <Box px={{ xs: 2, md: 18 }} mt={5}> */}
              <OrderTypeSelection
                handleStatus={handleStatus}
                status={status}
                values={['Successful', 'Failed', 'Pending']}
                OrderType={'STP Orders'}
              />
              <Grid container spacing={5}>
                {/* <Grid item xs={6} md={3}>
                  <Filter_OrderType
                    handleStatus={handleStatus}
                    status={status}
                  /> 
                </Grid>*/}

                <Grid item xs={12} md={12}>
                  <InfiniteScroll
                    dataLength={orders?.length}
                    next={fetchData}
                    hasMore={hasMore}
                    loader={<LoaderComp />}
                    endMessage={
                      <Typography className={classes.text}>
                        No result to display
                      </Typography>
                    }
                  >
                    {orders?.map((data, i) => {
                      return (
                        <Link
                          to={`${namespace}/mutualfund/mutual-fund/order-status/${data?.id ? data?.id : data?.mfOldId
                            }`}
                          state={{ data: data, order: true, orderType: 5, returnBack: [STP, status] }}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          {" "}
                          <OrderList key={i} orderData={data} />
                        </Link>
                      );
                    })}
                  </InfiniteScroll>
                </Grid>
              </Grid>
              {/* </Box> */}
            </TabPanel>

            {/* Switch Orders */}
            <TabPanel value={value} index={4} className={classes.tablebody}>
              {/* <Box px={{ xs: 2, md: 18 }} mt={5}> */}
              <OrderTypeSelection
                handleStatus={handleStatus}
                status={status}
                values={['Successful', 'Failed', 'Pending']}
                OrderType={'No Mandate SIP Orders'}
              />
              <Grid container spacing={5}>
                {/* <Grid item xs={6} md={3}>
                  <Filter_OrderType
                    handleStatus={handleStatus}
                    status={status}
                  /> 
                </Grid>*/}

                <Grid item xs={12} md={12}>
                  <InfiniteScroll
                    dataLength={orders?.length}
                    next={fetchData}
                    hasMore={hasMore}
                    loader={<LoaderComp />}
                    endMessage={
                      <Typography className={classes.text}>
                        No result to display
                      </Typography>
                    }
                  >
                    {orders?.map((data, i) => {
                      return (
                        <Link
                          to={`${namespace}/mutualfund/mutual-fund/order-status/${data?.id ? data?.id : data?.mfOldId
                            }`}
                          state={{ data: data, order: true, orderType: 4, returnBack: [Switch, status] }}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          {" "}
                          <OrderList key={i} orderData={data} />
                        </Link>
                      );
                    })}
                  </InfiniteScroll>
                </Grid>
              </Grid>
              {/* </Box> */}
            </TabPanel>

            {/* SWP Orders */}
            <TabPanel value={value} index={5} className={classes.tablebody}>
              {/* <Box px={{ xs: 2, md: 18 }} mt={5}> */}
              <OrderTypeSelection
                handleStatus={handleStatus}
                status={status}
                values={['Successful', 'Failed', 'Pending']}
                OrderType={'No Mandate SIP Orders'}
              />
              <Grid container spacing={5}>
                {/* <Grid item xs={6} md={3}>
                  <Filter_OrderType
                    handleStatus={handleStatus}
                    status={status}
                  /> 
                </Grid>*/}

                <Grid item xs={12} md={12}>
                  <InfiniteScroll
                    dataLength={orders?.length}
                    next={fetchData}
                    hasMore={hasMore}
                    loader={<LoaderComp />}
                    endMessage={
                      <Typography className={classes.text}>
                        No result to display
                      </Typography>
                    }
                  >
                    {orders?.map((data, i) => {
                      return (
                        <Link
                          to={`${namespace}/mutualfund/mutual-fund/order-status/${data?.id ? data?.id : data?.mfOldId
                            }`}
                          state={{ data: data, order: true, orderType: 6, returnBack: [SWP, status] }}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          {" "}
                          <OrderList key={i} orderData={data} />
                        </Link>
                      );
                    })}
                  </InfiniteScroll>
                </Grid>
              </Grid>
              {/* </Box> */}
            </TabPanel>
            {/* <TabPanel value={value} index={4}>
            <Box px={{ xs: 2, md: 18 }} mt={5}>
              <Grid container spacing={5}>
              

                <Grid item xs={12} md={12}>
                  <Typography className={classes.heading}>
                    Gold Delivery
                  </Typography>
                  {delivery?.data?.data?.map((data, i) => {
                    return (
                      <Link
                        to={`${namespace}/myportfolio/my-order?order_type=delivery&order_id=${data.id
                          }&status=${data.status == "PAYMENT_FAILED"
                            ? "failed"
                            : data.status == "CREATED"
                              ? "progress"
                              : "complete"
                          }`}
                        state={{ data: data, order: true }}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        {" "}
                        <GoldList key={i} orderData={data} />
                      </Link>
                    );
                  })}
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography className={classes.heading}>
                    Digital Gold
                  </Typography>
                  {buysell?.data?.data?.map((data, i) => {
                    return (
                      <Link
                        to={`${namespace}/myportfolio/my-order?order_type=buy&order_id=${data.id
                          }&status=${data.status == "PAYMENT_FAILED"
                            ? "failed"
                            : data.status == "CREATED"
                              ? "progress"
                              : "complete"
                          }`}
                        state={{ data: data, order: true }}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        {" "}
                        <GoldListTrans key={i} orderData={data} />
                      </Link>
                    );
                  })}
                </Grid>
              </Grid>
            </Box>
          </TabPanel> */}
          </Box>
        </Box>

      </Box>

      {/* {orders ? 
        <Box px={{xs:2,md:18}} mt={5}>

          
            <Grid container spacing={5}>
                <Grid item xs={6} md={3}>
                    <Filter_OrderType handleStatus={handleStatus} status={status}/>
                </Grid>
                
                <Grid item xs={12}  md={9}>
                  {orders.length>0 ? 
                  orders?.map((data,i)=>{
                    return(
                      <Link to={`/mutualfund/mutual-fund/order-status/${data?.orderId? data?.orderId :  data?.mfOldId }`} state={{data:data,order:true}} style={{textDecoration:'none',color:"inherit"}}>  <OrderList key={i} orderData={data}/></Link>
                    )
                  }): 

                  <Box display="flex" justifyContent="center" >
                  <Typography>No data found</Typography>
                  </Box>
                }
                
                </Grid>
                
            </Grid>
        </Box> : 

        <Box display="flex" justifyContent="center" mt={5} px={{xs:2,md:18}}>
          <Typography>No data found</Typography>
        </Box>
      } */}
    </div>
  );
};

export default Index;
