import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  makeStyles,
  Grid,
  IconButton,
} from "@material-ui/core";

import Modal from "@mui/material/Modal";
import Avatar from "react-avatar";
import { useSelector, useDispatch } from "react-redux";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { GET_USER_ONBOARDING_DETAILS, UPLOAD_PROFILE_PHOTO, VERIFY_SWP_OTP } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { uploadProfilePic } from "../../../redux/User/userActions";
import { BASE_URL } from "../../../environment";
import moment from "moment";

const Index = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const useStyles = makeStyles((theme) => ({
    root: {},
    header: {
      fontWeight: 500,
      fontSize: "1.3rem",
    },
    inputBox: {
      // background: "#E6E6E6",
      padding: "0.6rem 0.6rem",
      borderRadius: "0px",
      border: "none",
      fontFamily: "inherit",
      width: "90%",
      marginBottom: "10px",
      borderBottom: '1px solid #000',
    },

    text: {
      fontSize: "0.8rem",
      textAlign: "center",
      fontFamily: "inherit",
      marginTop: "10px",
      fontWeight: 500,
    },

    box1: {
      width: "30%",
    },
    "@media (max-width: 767px)": {
      box1: {
        width: "90%",
      },
    },
    cameraIcon: {
      height: "35px",
      width: "35px",
      background: "#0E76BC",
      borderRadius: "50%",
      position: "relative",
      top: -35,
      left: 75,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
    primaryButton: {
      padding: "0.8rem 5rem",
      background: "#dc3545",
      color: "#fff",
      fontSize: "0.85rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      fontFamily: "inherit",
    },
  }));
  const classes = useStyles();

  const { userStore } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [userData, setUserData] = useState('')
  const formattedDate = userData?.date_of_birth
    ? moment(userData.date_of_birth).format("MMM DD YYYY")
    : "";
  // get the user data 
  useEffect(() => {
    GetUserData()
  }, [])

  const GetUserData = async (e) => {

    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await httpInstance(
        `${GET_USER_ONBOARDING_DETAILS}`,
        httpConfig
      );
      setUserData(response.data.data)
    } catch { }
  };

  // const handle_photo = async (e) => {
  //   const file = e.target.files[0];

  //   const formData = new FormData();
  //   formData.append("profile_photo", file);

  //   const httpConfig = {
  //     method: "POST",
  //     tokenType: "user",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: formData,
  //   };
  //   try {
  //     const response = await httpInstance(
  //       `${UPLOAD_PROFILE_PHOTO}`,
  //       httpConfig
  //     );
  //     dispatch(uploadProfilePic(response.data?.data?.profile_image));
  //   } catch {}
  // };

  return (
    <div>
      <Box
        px={{ xs: 2, md: 20 }}
        mt={3}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Typography className={classes.header}>Account Details</Typography>
        <Box mt={4}>
          {userStore.user?.profile_image ? (
            <Avatar
              src={`${BASE_URL}${userStore.user?.profile_image}`}
              size="110"
              round={true}
              textSizeRatio={2}
              color="#0E76BC"
            />
          ) : (
            <Avatar
              name={userData?.full_name?.charAt(0)}
              size="110"
              round={true}
              textSizeRatio={2}
              color="#0E76BC"

            />
          )}

          {/* <Box className={classes.cameraIcon}>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={handle_photo}
              />
              <PhotoCameraIcon sx={{ color: "white", height: "20px" }} />
            </IconButton>
          </Box> */}
        </Box>
        <Box className={classes.box1} mt={2}>
          <label className={classes.text}>Name (As per PAN)</label>
          <br />
          <input
            className={classes.inputBox}
            value={userData?.full_name}
            disabled
          />
          <label className={classes.text}>Gender</label>
          <br />
          <input
            className={classes.inputBox}
            value={userData?.gender}
            disabled
          />
          <label className={classes.text}>Date of Birth</label>
          <br />
          <input
            className={classes.inputBox}
            value={formattedDate}

            disabled
          />
          <label className={classes.text}>PAN Number</label>
          <br />
          <input
            className={classes.inputBox}
            value={userData?.pan}
            disabled
          />
          <br />
          <label className={classes.text}>Email</label>
          <br />
          <input
            className={classes.inputBox}
            value={userData?.email}
            disabled
          />
          <br />
          <label className={classes.text}>Mobile Number</label>
          <br />
          <input
            className={classes.inputBox}
            value={userData?.mobile}
            disabled
          />

          <Box display="flex" justifyContent="center" mt={4} mb={6}>
            <button
              className={classes.primaryButton}
              onClick={handleOpen}
              type="button"
            >
              Delete Account
            </button>
          </Box>
        </Box>
        <Typography className={classes.text} > Your profile is verified and there detials are non editable , in case any questions please contact support team.</Typography>
        <Typography className={classes.text} > Email: <a style={{textDecoration: 'none'}} href="mailto:support@mfwealth.co.in">support@mfwealth.co.in</a></Typography>
        <Typography className={classes.text} >Contact Number: +91 9717438991</Typography>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: 600 }}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <p>
              If you want to delete your MF Wealth Account, you can reach out
              to our support team to help you navigate through the following
              steps :
            </p>
            <ol>
              <li>
                Before initiating the closure, you need to stop any active SIPs.
              </li>
              <li>
                For further mutual fund transactions reach out to amc, cams or
                karvy.
              </li>
              <li>
                The team will process complete account deletion after the user's
                request upon ensuring the customer has no active holding in
                mutual funds.
              </li>
            </ol>
            <p>
              Reach out to support -{" "}
              <a href="mailto:support@mfwealth.co.in">support@mfwealth.co.in</a>
            </p>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default Index;
