import { Box, Button, TextField, makeStyles, Typography } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
//import { useStyles } from "../../components/signup/styles";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TodayIcon from '@mui/icons-material/Today';
import number_ordinals from '../../hooks/NumberOrdinal'
import { useParams } from "react-router-dom";


const SipDatePicker = ({ label, placeholder, dates, handle_sipDate, selectedDate }) => {
  const useStyles = makeStyles((theme) => ({

    DateBox: {
      // background: "#E6E6E6",
      background: "#fff",
      padding: "0px 0.2rem",
      // borderRadius: "10px",
      // border: "none",
      borderBottom: "1px solid #000",
      boxSizing: "border-box",
      width: "100%",
      "& input:focus": {
        outline: "none !important",

      },
      marginBottom: "10px"

    },

    customDate: {
      "MuiCalendarPicker-viewTransitionContainer": {
        "& .css-1dozdou": {
          display: "none"
        }
      }

    },
    label: {
      fontSize: "0.7rem",
      color: "#272727",
      fontWeight: 500,
      fontFamily: "Poppins"
    },
    inputBox: {
      // background: "#E6E6E6",
      background: "#fff",
      padding: "0.7rem 0.95rem",
      // borderRadius: "10px",
      border: "none",
      width: "90%",
      fontFamily: "inherit",

    },
    text: {
      fontSize: "0.9rem",
      fontWeight: 500,
    },
    containerBox: {
      display: "inline-grid",
      justifyContent: "center",
      alignItems: "center",
      background: "#fff",
      padding: "20px",
      borderRadius: "5px",
      boxShadow: "0 0 8px -3px #444444;",
      width: "88%",
      marginTop: "10px"
    },
    dates: {
      display: "grid",
      gridTemplateColumns: "repeat(7, 1fr)",
      "& button": {
        cursor: "pointer",
        outline: 0,
        border: 0,
        background: "transparent",
        fontSize: "0.8rem",
        fontFamily: "inherit",
        justifySelf: "center",
        alignSelf: "center",
        width: "40px",
        height: "40px",
        borderRadius: "50px",
        margin: "2px",
        transitionDuration: ".2s",

        "&:first-child": {
          gridColumn: 1,
        },

        "&:hover": {
          background: "#eee",
        },

        "&:focus": {
          background: "#0E76BC",
          color: "#fff",
          fontWeight: 500,
        }
      },
    },




  }))
  const classes = useStyles();
  const [date, setDate] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [showDate, setShowDate] = useState(false)



  const availableDate = dates?.split(",")

  const { code } = useParams()

  useEffect(() => {
    setDate(null)
    setInputValue('')
    setShowDate(false)
   

  }, [code])

  useEffect(()=>{
     if (selectedDate && !inputValue){
      // setInputValue(selectedDate)
      handleChange(selectedDate)
    }

  },[selectedDate])
  


  const handleChange = (newValue) => {
    setDate(newValue)
    setShowDate(false)
    setInputValue(number_ordinals(newValue) + " of every month")
    handle_sipDate(newValue)
  }

  //close menu on outside click

  const ref = useRef()
  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showDate && ref.current && !ref.current.contains(e.target)) {
        setShowDate(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [showDate])



  return (
    <div ref={ref}>

      <label className={classes.label}>{label}</label>
      <Box
        className={classes.DateBox}
        display="flex"
        alignItems={"center"}
        justifyContent="space-between"
      //flexDirection={"row"}
      >
        <input className={classes.inputBox} value={inputValue} placeholder={placeholder} onClick={() => setShowDate(!showDate)} />
        <TodayIcon onClick={() => setShowDate(true)} style={{ cursor: "pointer" }} />
      </Box>
      {showDate && <div className={classes.containerBox}>
        <Box display="flex" justifyContent="center"><Typography className={classes.text}>{placeholder}</Typography></Box>
        <div className={classes.dates}>
          {[...Array(31)].map((ele, i) => {
            return (
              <button
                disabled={!availableDate?.includes((i + 1).toString())}
                style={date == i + 1 ? { background: "#0E76BC", color: "#fff" } : {}}
                key={i}
                onClick={() => { handleChange(i + 1) }}>
                {i + 1}

              </button>
            )
          })}


        </div>
      </div>}



    </div>
  )
}

export default SipDatePicker