import React, { useEffect, useState } from "react";
import { Box, Typography, makeStyles, Grid, Slider } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { GET_PORTFOLIO_FUND_DISTRIBUTE } from "../../utils/endpoints";
import httpInstance from "../../utils/instance";
import { namespace, BASE_URL } from "../../environment";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList

} from "recharts";
import ErrorMessage from "../../components/Validation/ErrorMessage";


const useStyles = makeStyles((theme) => ({
    // tabsFolder: {
    //     border: '1px solid #0E76BC',
    //     borderRadius: '10px',
    //     overflow: 'hidden',
    //     padding: `5px`
    // },
    tabs: {
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        border: '1px solid #0E76BC',
        borderRadius: '10px',

    },
    tablink: {
        borderRadius: '10px',
        flex: 1,
        padding: '10px 20px',
        borderRadius: 0,
        '&:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
        position: 'relative',
        zIndex: 1,
        color: '#0E76BC',
        textTransform: 'capitalize',
    },
    active: {
        borderRadius: '10px',
        backgroundColor: '#0E76BC',
        color: theme.palette.secondary.contrastText,
    },
    activeBox: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '50%',
        height: '100%',
        // backgroundColor: theme.palette.secondary.main,
        backgroundColor: '#0E76BC',
        borderRadius: '10px',

        transition: 'left 0.3s ease-in-out',
    },
}));

const ShowRecommendedFunds = () => {
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState('tab1');
    const [activeBoxPosition, setActiveBoxPosition] = useState(0);
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const tabClickHandler = (tabName, position) => {
        setActiveTab(tabName);
        setActiveBoxPosition(position);
    };
    // const tabHoverHandler = (tabName, position) => {
    //     // if (!activeTab === tabName && !activeBoxPosition === position){

    //     // }
    // }





    // calculate my first corre post request 
    const RecommendedFunds = async () => {
        setLoading(true)
        const httpConfig = {
            method: "GET",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                modal_portfilio_id: 1,
                duration: 8
                ,
                amount: 39000,
                is_onetime: false
            },
            // data: formData
        };

        const httpConfig1 = {
            method: "GET",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                modal_portfilio_id: 1,
                duration: 8
                ,
                amount: 39000,
                is_onetime: false
            },
            // data: formData1
        };

        try {
            const [responseReturn1, responseReturn2] = await Promise.all([
                httpInstance(`${GET_PORTFOLIO_FUND_DISTRIBUTE}`, httpConfig),
                httpInstance(`${GET_PORTFOLIO_FUND_DISTRIBUTE}`, httpConfig1),
            ])
            console.log(responseReturn1, responseReturn2)
            // const response = await httpInstance(`${POST_GOALS_CALCULATE_MY_FIRST_GOAL}`, httpConfig1);
            // console.log(response?.data, 'fadfadfadsfasdfasdfasdf')


        } catch (error) {
            console.log(error.response.data?.error)
            setErrorMessage(error.response.data?.error)
        } finally {
            setLoading(false)

        }
    }

    //As the slider change in the area 

    useEffect(() => {

        RecommendedFunds();
    }, [])




    return (
        <div>
            <div className={classes.tabsFolder}>
                <div className={classes.tabs}>
                    <div className={classes.activeBox} style={{ left: `${activeBoxPosition}%` }}></div>
                    <Button
                        className={`${classes.tablink} ${activeTab === 'tab1' ? classes.active : ''}`}
                        onClick={() => tabClickHandler('tab1', 0)}
                    // onMouseEnter={() => tabHoverHandler('tab1', 0)}
                    >
                        Based on Risk Profile
                    </Button>
                    <Button
                        className={`${classes.tablink} ${activeTab === 'tab2' ? classes.active : ''}`}
                        onClick={() => tabClickHandler('tab2', 50)}>
                        MF Wealth Recommended
                    </Button>
                </div>
            </div>
            {activeTab === 'tab1' &&
                <>
                    {errorMessage ? (
                        <ErrorMessage message={errorMessage} />
                    ) : (
                        <Box>
                            dafadfa
                            <div id="tab1" className={activeTab === 'tab1' ? 'tabcontent active' : 'tabcontent'}>
                                <h3>Tab 1 Content</h3>
                                <p>This is the content of Tab 1.</p>
                            </div>
                        </Box>
                    )}
                </>

            }
            {activeTab === 'tab2' &&
                <>
                    {errorMessage ? (
                        <ErrorMessage message={errorMessage} />
                    ) : (
                        <Box>
                            dafadfa
                            <div id="tab1" className={activeTab === 'tab1' ? 'tabcontent active' : 'tabcontent'}>
                                <h3>Tab 1 Content</h3>
                                <p>This is the content of Tab 1.</p>
                            </div>
                        </Box>
                    )}
                </>
            }

        </div>
    );
};


export default ShowRecommendedFunds;
