import React, { useEffect, useRef, useState } from "react";
import { useStyles } from "../styles";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import TitleLayoult from "../TitleLayoult";
import TextInputField from "../Input/TextInputField";
import httpInstance from "../../../utils/instance";
import { useForm } from "react-hook-form";
import ErrorMessage from "../../Validation/ErrorMessage";
import { useNavigate, createSearchParams, Link } from "react-router-dom";
import { GOOGLE_LOGIN, GET_USER, CREATE_ACCOUNT } from "../../../utils/endpoints";
import { namespace } from "../../../environment";
import CustomToast from "../../UI/CustomToast";
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";


function Step1({ step, setData }) {
  const classes = useStyles();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const navigate = useNavigate();

  const [google, setGoogle] = useState(false);
  const [loading, setLoading] = useState(false)

  const responseGoogle = async (response) => {
    setLoading(true);
    sessionStorage.removeItem("mpin");
    // reset({
    //   email:response.profileObj. email
    // })
    console.log(response)

    const formData = {
      email: response.decoded?.email,
      // loginType: "GOOGLE",
      // secretKey: "A*C:-1/Lg-N!KK-gpwr-QY@z",
      token: response?.credentialResponse.credential,
      type: null,
      //secretKey:process.env.REACT_APP_API_KEY
    };

    const httpConfig = {
      method: "POST",
      data: formData,
    };

    const httpConfig1 = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${GOOGLE_LOGIN}`, httpConfig);
      localStorage.setItem("user_token", response.data?.data?.accessToken);
      localStorage.setItem("refresh_token", response.data?.data?.refreshToken);
      localStorage.setItem("user_id", response.data?.data?.user?.id);

      // navigate({
      //   pathname: '/signIn',
      //   search: `?${createSearchParams({stepcount:"2"})}`,
      // });
      // step(2)

      const status = await httpInstance(`${GET_USER}`, httpConfig1);
      if (status.data?.data?.mpin) {
        navigate({
          pathname: `${namespace}/signIn`,
          search: `?${createSearchParams({ stepcount: "mpin" })}`,
        });
      } else {
        if (status.data?.data?.isMobileNumberVerified) {
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "mpin_generate" })}`,
          });
        } else {
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "2" })}`,
          });
        }
      }
    } catch (error) {
      setError(error.response.data?.error);
      CustomToast(error?.response?.data?.error || 'Somthing went wrong', "FAILED")

      setLoading(false)
      if (error?.response?.data?.error === "user not found, please register") {
        handleToggle()
        setTimeout(() => { setError(''); }, 2000); // Adjust the timeout duration as needed
      }
    }
  };

  const [error, setError] = useState("");

  const handleEmailSubmit = async (data) => {
    sessionStorage.removeItem("mpin");
    setLoading(true)

    //   if(google){
    //   const   formData={emailId: data.email,loginType:"GOOGLE"}

    //   const httpConfig = {
    //     method: "POST",
    //     data: formData,
    //   };

    //     try{
    //       const response = await httpInstance(`${GOOGLE_LOGIN}`,httpConfig)
    //       localStorage.setItem("user_token",response.data?.data?.accessToken)
    //       localStorage.setItem("refresh_token",response.data?.data?.refreshToken)
    //       localStorage.setItem("user_id",response.data?.data?.user?.id)

    //       navigate({
    //         pathname: '/signIn',
    //         search: `?${createSearchParams({stepcount:"2"})}`,
    //       });
    //       step(2)
    //   }
    //   catch(error){
    //     console.log(error)
    //   }
    // }else{

    // Old code 
    // localStorage.setItem("email", data.email);

    // const formData = { emailId: data.email };

    // New code
    // console.log(data)


    localStorage.setItem("mobile", data.mobile);
    localStorage.setItem("full_name", data.full_name);
    localStorage.setItem("email", data.email);

    // const formData = { mobile: data.mobile };

    const formData = {
      full_name: data.full_name,
      mobile: data.mobile,
      email: data.email,
    };

    // localStorage.setItem("formData", JSON.stringify(formData));

    const httpConfig = {
      method: "POST",
      data: formData,
    };

    try {
      const response = await httpInstance(
        // "api/v1/usermgmt/user/login",
        CREATE_ACCOUNT,
        httpConfig
      );

      setError("");


      // localStorage.setItem("sessionId", response?.data?.data?.sessionId);
      setData({
        Alldata: response?.data,
        mobile: data.mobile,
        email: data.email,
        sessionId: response?.data?.data?.sessionId,
        creationTime: response?.data?.data?.creationTime,
      });
      // localStorage.setItem("creationTime", response?.data?.data?.creationTime);
      navigate({
        pathname: `${namespace}/user`,
        search: `?${createSearchParams({ stepcount: "1" })}`,
      });
      step(2);
      setLoading(false)
    } catch (error) {
      setError(error.response.data?.error);
      CustomToast(error?.response?.data?.error || 'Somthing went wrong', "FAILED")
      setLoading(false)
    }
  };
  const [isLogin, setIsLogin] = useState(true);

  const handleToggle = () => {
    if (window.location.hash !== "#signUp") {
      addHash()
    } else {
      removeHash()
    }
    setIsLogin((prev) => !prev);
  };

  const handleLoginSubmit = async (data) => {
    sessionStorage.removeItem("mpin");
    setLoading(true)

    //   if(google){
    //   const   formData={emailId: data.email,loginType:"GOOGLE"}

    //   const httpConfig = {
    //     method: "POST",
    //     data: formData,
    //   };

    //     try{
    //       const response = await httpInstance(`${GOOGLE_LOGIN}`,httpConfig)
    //       localStorage.setItem("user_token",response.data?.data?.accessToken)
    //       localStorage.setItem("refresh_token",response.data?.data?.refreshToken)
    //       localStorage.setItem("user_id",response.data?.data?.user?.id)

    //       navigate({
    //         pathname: '/signIn',
    //         search: `?${createSearchParams({stepcount:"2"})}`,
    //       });
    //       step(2)
    //   }
    //   catch(error){
    //     console.log(error)
    //   }
    // }else{

    // Old code 
    // localStorage.setItem("email", data.email);

    // const formData = { emailId: data.email };

    // New code
    // console.log(data)


    localStorage.setItem("mobile", data.mobile);
    // localStorage.setItem("full_name", data.full_name);
    // localStorage.setItem("email", data.email);

    // const formData = { mobile: data.mobile };

    const formData = {
      // full_name: data.full_name,
      mobile: data.mobile,
      // email: data.email,
    };

    // localStorage.setItem("formData", JSON.stringify(formData));

    const httpConfig = {
      method: "POST",
      data: formData,
    };

    try {
      const response = await httpInstance(
        // "api/v1/usermgmt/user/login",
        "/api/auth/generate_otp",
        httpConfig
      );

      setError("");
      // localStorage.setItem("sessionId", response?.data?.data?.sessionId);
      setData({
        Alldata: response?.data,
        mobile: data.mobile,
        email: data.email,
        sessionId: response?.data?.data?.sessionId,
        creationTime: response?.data?.data?.creationTime,
      });
      localStorage.setItem("creationTime", response?.data?.data?.creationTime);
      navigate({
        pathname: `${namespace}/user`,
        search: `?${createSearchParams({ stepcount: "1" })}`,
      });
      step(2);
      setLoading(false)
    } catch (error) {
      setError(error?.response?.data?.error);
      CustomToast(error?.response?.data?.error || 'Somthing went wrong', "FAILED")
      setLoading(false)
    }
  }


  const [hash, setHash] = useState(window.location.hash);

  useEffect(() => {
    const handleHashChange = () => {
      setHash(window.location.hash);
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  console.log(hash)
  useEffect(() => {
    if (!hash) {
      setIsLogin(true)
    } else {
      setIsLogin(false)
    }
  }, [hash])

  const addHash = () => {
    window.location.hash = '#signUp';
  };
  // Remove the hash from the URL
  const removeHash = () => {
    navigate(window.location.pathname + window.location.search, { replace: true });
  };
  // return(
  // <React.Fragment>
  //     <Box display={'flex'} justifyContent="center">
  //       <Box display={'flex'} alignItems="center" flexDirection={'column'}>
  //         <TitleLayoult title={isLogin ? 'Login to MF Wealth' : 'Welcome to MF Wealth'} />
  //         <Box>
  //           {/* ... (your social login buttons) */}
  //         </Box>
  //         <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
  //           <Typography style={{ color: '#c7c7c7', fontSize: '0.8rem' }}>
  //             or
  //           </Typography>
  //         </Box>

  //         <form onSubmit={isLogin ? handleLoginSubmit : handleEmailSubmit}>
  //           {isLogin ? (
  //             <Box my={2}>
  //               <TextInputField
  //                 name="mobile"
  //                 label="Enter Phone Number"
  //                 placeholder=""
  //                 register={register}
  //                 inputPattern={/^[0-9-+]{10,10}$/}
  //                 handleChange={() => setError('')}
  //               />
  //               {errors.mobile && (
  //                 <ErrorMessage message="Enter valid Phone Number" />
  //               )}
  //             </Box>
  //           ) : (
  //             <React.Fragment>
  //               <Box my={2}>
  //                 <TextInputField
  //                   name="full_name"
  //                   label="Enter Full Name"
  //                   placeholder=""
  //                   register={register}
  //                   inputPattern={/^[A-Za-z\s]+$/}
  //                   handleChange={() => setError('')}
  //                 />
  //                 {errors.full_name && (
  //                   <ErrorMessage message="Only letters and spaces allowed in the name" />
  //                 )}
  //               </Box>
  //               <Box my={2}>
  //                 <TextInputField
  //                   name="mobile"
  //                   label="Enter Phone Number"
  //                   placeholder=""
  //                   register={register}
  //                   inputPattern={/^[0-9-+]{10,10}$/}
  //                   handleChange={() => setError('')}
  //                 />
  //                 {errors.email && (
  //                   <ErrorMessage message="Enter valid Phone Number" />
  //                 )}
  //               </Box>
  //               <Box my={3}>
  //                 <TextInputField
  //                   label="Enter Email ID"
  //                   name="email"
  //                   placeholder=""
  //                   register={register}
  //                   inputPattern={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
  //                 />
  //                 {errors.mobile && (
  //                   <ErrorMessage message="Please Enter Valid Email ID" />
  //                 )}
  //               </Box>
  //             </React.Fragment>
  //           )}

  //           {error && <ErrorMessage message={error} />}

  //           <Box mt={2}>
  //             <button className={classes.primaryButton} type="submit">
  //               {isLogin ? 'Login' : 'Continue'}
  //             </button>
  //           </Box>
  //         </form>

  //         <Box mt={2}>
  //           <button onClick={handleToggle}>
  //             {isLogin ? 'Switch to Signup' : 'Switch to Login'}
  //           </button>
  //         </Box>
  //       </Box>
  //     </Box>
  //   </React.Fragment>
  // )

  const handleLoginSuccess = (credentialResponse, props) => {
    // console.log('Login Success:', credentialResponse, props);
    const idToken = credentialResponse.credential;
    const decoded = jwtDecode(idToken);

    // Extract user details
    // const { name, email, picture } = decoded;
    // console.log('Name:', name);
    // console.log('Email:', email);
    // console.log('Picture:', picture);
    // console.log(decoded, credentialResponse)
    const finalData = {
      decoded,
      credentialResponse
    }
    // console.log(finalData)
    responseGoogle(finalData)

  };

  const handleLoginFailure = () => {
    CustomToast('Login Failed', "FAILED")

  };

  return (
    <React.Fragment>
      <Box display={"flex"} justifyContent="center" >
        <Box display={"flex"} alignItems="center" flexDirection={"column"} className={classes.primaryBackground}>
          <Box display={'flex'} justifyContent={'center'}>
            <Box height={80} width={160} overflow={'hidden'} display={'flex'} justifyContent={'center'} style={{ cursor: 'pointer' }}
              onClick={() => {
                navigate({
                  pathname: `${namespace}/`,
                })
              }}
            >
              <img src="/MF 5 6.svg" height={100} width={250} alt="logo" />
            </Box>
          </Box>
          <Box>
            <Typography variant="h4" className={classes.loginAndSignUpText}>
              {isLogin ? 'Login' : 'Create An Account'}
            </Typography>
            {/* <TitleLayoult title="Welcome to MF Wealth" className='pt-2' /> */}
            <Box maxWidth={'350px'}>
              <Typography variant="h6" className={classes.textToShowInBottom}>
                {isLogin ? 'Securely access your account and take control of your financial future' : 'Please add your name as it appears on your official documents and IDs'}
              </Typography>
            </Box>
          </Box>
          {isLogin && (
            <>
              <Box>
                {/* <button className={classes.socialButton}>
          <img
            className={classes.socialButtonImage}
            src="/images/google-logo.svg"
          />
          <p >Continue with Google</p>
        </button> */}
                {/* <GoogleLogin
              // clientId="901855431398-vo2vjitftml7v641unu1puorobh6u32r.apps.googleusercontent.com"
              clientId="1027070436925-rs5t0r81mo2mn28cacgv99qb4nsu1k3q.apps.googleusercontent.com"
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className={classes.socialButton}
                >
                  <img
                    className={classes.socialButtonImage}
                    src="/images/google-logo.svg"
                  />
                  <p>Continue with Google</p>
                </button>
              )}
              buttonText="Continue with Google"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              // cookiePolicy={"single_host_origin"}
            /> */}
                <GoogleOAuthProvider clientId="1027070436925-rs5t0r81mo2mn28cacgv99qb4nsu1k3q.apps.googleusercontent.com"
                >
                  <Box >
                    <h1
                      className={classes.googleLoginText}
                    >
                      Google Login
                    </h1>
                    <GoogleLogin
                      onSuccess={handleLoginSuccess}
                      onError={handleLoginFailure}
                      text="continue_with"
                      shape="pill"
                      size="large"
                    />
                  </Box>
                </GoogleOAuthProvider>
              </Box>
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent="center"
                mt={2}
              >
                <Typography
                  style={{
                    color: "#c7c7c7",
                    fontSize: "0.8rem",
                  }}
                >
                  or
                </Typography>
              </Box>
            </>
          )}

          {/* <form onSubmit={handleSubmit(handleEmailSubmit)}> */}
          <form onSubmit={isLogin ? handleSubmit(handleLoginSubmit) : handleSubmit(handleEmailSubmit)} style={{ width: '100%' }}>
            {isLogin ? (
              <Box my={2}>
                <TextInputField
                  name="mobile"
                  label="Enter Phone Number"
                  placeholder="Enter Phone Number"
                  register={register}
                  inputPattern={/^[0-9-+]{10,10}$/}
                  handleChange={() => setError('')}
                  type="tel"
                />
                {errors.mobile && (
                  <ErrorMessage message="Enter valid Phone Number" />
                )}
              </Box>
            ) : (
              <React.Fragment>
                <Box my={2}>
                  <TextInputField
                    name="full_name"
                    label="Enter Full Name"
                    placeholder="Enter Full Name"
                    register={register}
                    inputPattern={/^[A-Za-z\s]+$/}
                    handleChange={() => setError("")}
                  />
                  {errors.full_name && (
                    <ErrorMessage message="Only letters and spaces allowed in the name" />
                  )}
                </Box>
                <Box my={2}>
                  <TextInputField
                    name="mobile"
                    label="Enter Phone Number"
                    placeholder="Enter Phone Number"
                    register={register}
                    inputPattern={/^[0-9-+]{10,10}$/}
                    handleChange={() => setError("")}
                    type="tel"
                  />
                  {errors.mobile && (
                    <ErrorMessage message="Enter valid Phone Number" />
                  )}
                </Box>
                <Box my={3}>
                  <TextInputField
                    label="Enter Email ID"
                    name="email"
                    placeholder="Enter Email ID"
                    register={register}
                    inputPattern={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
                    type="email"
                  />
                  {errors.email && (
                    <ErrorMessage message="Please Enter Valid Email ID" />
                  )}
                </Box>
                <Box my={3} display={'flex'} justifyContent={'center'} flexDirection={'row'}>
                  <input
                    type="checkbox"
                    className={classes.checkBox}
                    name="checkBox"
                    {...register("checkBox", {
                      required: true,
                    })}
                  // onChange={handleCheckbox}
                  // checked={sameforBilling ? true : false}
                  />
                  <label className={classes.label} htmlFor="checkBox" style={{ display: "flex", alignItems: 'center' }}>
                    By clicking continue, you agree to our &nbsp;
                    <Link to="/terms-conditions" style={{ color: "blue", textDecoration: "none" }}>
                      Terms & Conditions
                    </Link>
                    .
                  </label>
                  {errors.checkBox && (
                    <ErrorMessage message="Please agree to our Terms & Conditions" />
                  )}
                </Box>
              </React.Fragment>

            )}
            {error && <ErrorMessage message={error} />}

            <Box mt={2}>
              <button className={classes.primaryButton} type="submit" disabled={loading}>
                {loading ? (
                  <CircularProgress size="1rem" color="inherit" />
                ) : (
                  "Continue"
                )}
              </button>
            </Box>
          </form>
          <Box mt={2}>
            {isLogin ?
              <Typography variant="h6" className={classes.createAccountText}>
                Don’t have MF Wealth account?
                <span onClick={handleToggle} className={classes.createAccountTextSpan}>
                  Create Account
                </span>
              </Typography>
              :
              <button className={classes.LoginSignUpSwitchButton} onClick={handleToggle}>
                {isLogin ? 'Switch to Signup' : 'Switch to Login'}
              </button>
            }
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default Step1;
