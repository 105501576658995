// SecondSection.js
import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Grid, Button, makeStyles, Card, CardContent } from "@material-ui/core";
import { Link } from "react-router-dom";
import { namespace } from "../environment";
import { useSelector } from "react-redux";
import CountUp from 'react-countup';


function FourthSection({ offset, handleShowModal }) {
  const [refreshToken, setRefreshToken] = useState("");
  useEffect(() => {
    setRefreshToken(localStorage.getItem("refresh_token"));
  }, [localStorage]);
  // console.log(refreshToken);
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundImage: `linear-gradient(116.74deg, rgba(12, 103, 164, 0.8) 1.66%, rgba(14, 118, 188, 0.8) 100%), url('./images/Rectangle Copy 2.svg')`,
      // backgroundImage: "url('./images/Rectangle Copy 2.svg')",
      // background: "linear-gradient(116.74deg, #0C67A4 1.66%, #0E76BC 100%)",
      // background: "linear-gradient(117.24deg, #0C67A4 0%, #0E76BC 102.4%)",
      padding: "50px 0rem 30px 0rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexDirection: "column",

    },
    headingOne: {
      fontSize: "39px",
      fontWeight: 600,
      color: "#FFFFFF",
      marginBottom: "2.5rem",
      textAlign: "start",
      
    },
    headingOneColor: {
      color: "#0E76BC",
    },
    headingTwo: {
      fontSize: "16px",
      fontWeight: 400,
      textAlign: "start",
      color: "#FFFFFF",
      padding: "0px 10px 20px 0px",
      opacity: 0.8,
    },
    headingThree: {
      fontFamily: 'Roboto',
      fontSize: "20px",
      fontWeight: 400,
      textAlign: "start",
      color: "#E6F1F8",
      padding: "0px 0px 5px 0px",
      opacity: 0.8,
    },
    button: {
      padding: "1rem 4rem",
      background: "#FFFFFF",
      color: "#0E76BC",
      borderRadius: "5px",
      border: "none",
      margin: "0 1.8rem 0 0",
      cursor: "pointer",
      fontSize: "18px",
      fontWeight: 600,
    },
    cardText:{
      fontFamily: "Poppins",
      fontSize: '20px',
      fontWeight: 500,
      textAlign: 'center',
      color: "#0E76BC",
    },
    boxShadow: {
      background: '#FFFFFF',
      boxShadow: ' 0px 4px 4px 0px #00000040',
      minWidth: '300px',
      borderRadius: '5px',
    },
    boxShadowNumber: {
      fontFamily: "Poppins",
      fontSize: '20px',
      fontWeight: 400,
      textAlign: 'center',
      color: "#000000",
    },
    boxShadowData: {
      fontFamily: "Poppins",
      fontSize: '14px',
      fontWeight: 700,
      textAlign: 'center',
      color: "#0E76BC",
    },
    "@media (max-width: 900px)": {
      root: {
        padding: "8rem 0rem 1rem 0rem",
      },
      headingOne: {
        fontSize: "1.6rem",
      },
      headingTwo: {
        fontSize: "12px",
      },
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root} height="100%">
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: { xs: 'center', md: 'left' }, p: 2 }}>
              {/* <Typography variant="h4" component="div" gutterBottom className={classes.headingThree}>
                GET STARTED IN MINUTES
              </Typography> */}
              <Typography variant="h4" component="div" gutterBottom className={classes.headingOne} >
                Customized Investment Basket-
              </Typography>
              {/* <Box maxWidth={'550px'}>
                <Typography variant="body1" gutterBottom className={classes.headingTwo} >
                  Bring all your mutual fund investment at one place. Set Investment
                  Goals, Assess the progress and get the expert recommendations
                </Typography>
              </Box> */}
              {/* <Button
                className={classes.button}
              >
                Join Now
              </Button> */}
              {/* <Grid container spacing={3} justifyContent={{ xs: 'center', md: 'flex-start' }} sx={{ mt: 2 }} style={{ paddingTop: '37px', gap: '20px' }} >
                <Grid item style={{ display: 'flex', }}>
                  <Box className={classes.boxShadow} display={'flex'} maxWidth={'200px'}>


                    <img src="/images/Frame 427320532.svg" alt="Section 2" width="30px" height='31px' className='img-fluid' />
                    <Typography variant="h6" className={classes.boxShadowNumber}>
                      Bharat Next - Gen
                    </Typography>
                  </Box>
                  <Box className={classes.boxShadow} display={'flex'} maxWidth={'200px'}>


                    <img src="/images/Frame 427320532.svg" alt="Section 2" width="30px" height='31px' className='img-fluid' />
                    <Typography variant="h6" className={classes.boxShadowNumber}>
                      Bharat Next - Gen
                    </Typography>
                  </Box>
                </Grid>
                <Grid item className={classes.boxShadow}>
                  <img src="/images/Frame 427320532.svg" alt="Section 2" width="30px" height='31px' className='img-fluid' />
                  <Typography variant="h6" className={classes.boxShadowNumber}>
                    All Weather
                  </Typography>
                  <Typography variant="body2" className={classes.boxShadowData}>Clients</Typography>
                </Grid> */}
              {/* <Grid item className={classes.boxShadow}>
                  <Typography variant="h6" className={classes.boxShadowNumber}>
                    <CountUp end={30} duration={2.5} />
                    +</Typography>
                  <Typography variant="body2" className={classes.boxShadowData}>Cities Served</Typography>
                </Grid> */}
              {/* </Grid> */}
              <Grid item xs={12} sm={12} >
                {/* <Grid container spacing={2} style={{ width:  '100%' }}>
                  {['All Weather', 'High Alpha', 'Wealth Creation With SIP', 'Bharat Next'].map((text, index) => (
                    <Grid item xs={12} sm={6} key={text} style={{ maxWidth: index % 2 === 0 ? '100%' : '50%' }}>
                      <Card variant="outlined" style={{ display: 'flex', alignItems: 'center', padding: '8px' }} >
                        <img src="/images/Frame 427320532.svg" alt="Section 2" width="30px" height='31px' className='img-fluid' />
                        <Typography variant="body2" style={{ marginLeft: '8px' }}>{text}</Typography>
                      </Card>
                    </Grid>
                  ))}
                </Grid> */}
                <Box display={'flex'} marginBottom={'20px'}>
                  <Card variant="outlined" className={classes.cardText} style={{ display: 'flex', alignItems: 'center', padding: '4px', flex: '0 0 55%', marginRight: '20px', borderRadius: '20px' }} >
                    <img src="/images/Frame 427320532.svg" alt="Section 2" width="30px" height='31px' className='img-fluid' />
                    <Typography variant="body2" style={{ marginLeft: '8px' }}>All Weather</Typography>
                  </Card>
                  <Card variant="outlined" className={classes.cardText} style={{ display: 'flex', alignItems: 'center', padding: '4px', flex: '1' , borderRadius: '20px'}} >
                    <img src="/images/Frame 427320532.svg" alt="Section 2" width="30px" height='31px' className='img-fluid' />
                    <Typography variant="body2" style={{ marginLeft: '8px' }}>High Alpha</Typography>
                  </Card>
                </Box>
                <Box display={'flex'} >
                  <Card variant="outlined" className={classes.cardText} style={{ display: 'flex', alignItems: 'center', padding: '4px', flex: '0 0 55%', marginRight: '20px', borderRadius: '20px' }} >
                    <img src="/images/Frame 427320532.svg" alt="Section 2" width="30px" height='31px' className='img-fluid' />
                    <Typography variant="body2" style={{ marginLeft: '8px' }}>Wealth Creation With SIP</Typography>
                  </Card>
                  <Card variant="outlined" className={classes.cardText} style={{ display: 'flex', alignItems: 'center', padding: '4px', flex: '1', borderRadius: '20px' }} >
                    <img src="/images/Frame 427320532.svg" alt="Section 2" width="30px" height='31px' className='img-fluid' />
                    <Typography variant="body2" style={{ marginLeft: '8px' }}>Bharat Next Gen</Typography>
                  </Card>
                </Box>
              </Grid>
            </Box>

          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: 'center' }}>
              <img src="/images/WhatsApp Image 2024-08-06 at 17.56.30_999e70b6.jpg" alt="Section 2" width="100%" height='824.53px' className='img-fluid' />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default FourthSection;

