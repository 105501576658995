import React from "react";
import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";
import MainLayout from "./components/Layout/MainLayout";
import Home from "./containers/Home";
import DigitalGold from "./containers/DigitalGold";
import Complient from "./containers/Signup/Complient";
import Funds from "./containers/Dashboard/Funds";
import Subtab from "./containers/Dashboard/Funds/subtab1";
import ContentSlider from "./containers/Dashboard/Funds/ContentSlider";
import DigitalgoldDash from "./pages/digitalgold/index";
import Header from "./components/header/Header";
import ProtectedRoutes from "./components/Authendication/ProtectedRoutes";
import MpinProtect from "./components/Authendication/MpinProtect";
import Onboard from "./containers/Signup/Onboard/index";
import PaymentSuccess from "./pages/digitalgold/PaymentSuccess";
import BuyFailed from "./containers/DigitalGold/Payement/BuyFailed";
import SellSuccess from "./pages/digitalgold/SellSuccess";
import Portfolio from "./pages/portfolio/index";
import Goals from "./pages/mutualfund/goals/index";
import Specific_Goals from "./pages/mutualfund/goals/specific-goal"
import RecommendPart  from "./pages/mutualfund/goals/RecommendPart/index";
import GoldDelivery from "./pages/portfolio/gold-delivery";
import Deliverysuccess from "./pages/portfolio/delivery-success";
import PaymentFailed from "./pages/digitalgold/PaymentFailed";
import InvestmentDetail from "./pages/portfolio/detail-investment";
import EmptyPortPolio from "./containers/Portfolio/DigitalGold/EmptyPortPolio";
import Order from "./pages/portfolio/order-status";
import All_transacations from "./pages/portfolio/all-transacations";
import Bank_and_autoMandate from "./pages/Profile/Bank_and_autoMandate";
import Fund_details from "./pages/mutualfund/fund_details";
import Payment_success from "./pages/mutualfund/payment-success";
import AboutUs from "./pages/about/AboutUs";
import Faqs from "./pages/about/Faqs";
import Terms_conditions from "./pages/about/Terms_conditions";
import Sip from "./pages/portfolio/mutualfund/sip";
import OrderSummary from "./containers/MutualFund/InvestType/OrderSummary";
import SwitchFund from "./pages/portfolio/mutualfund/switch";
import Edit_Address from "./pages/Profile/edit-address";
import All_fund_transactions from "./pages/portfolio/mutualfund/all-transactions";
import Redeem_success from "./pages/portfolio/mutualfund/redeem-success";
import Switch_success from "./pages/portfolio/mutualfund/switch-success";
import Stp from "./pages/portfolio/mutualfund/stp";
import Swp_success from "./pages/portfolio/mutualfund/swp-success";
import Order_status from "./pages/portfolio/mutualfund/order-status";
import Account_details from "./pages/Profile/account-details";
import Stp_success from "./pages/portfolio/mutualfund/stp-success";
import ViewAnalysis from "./pages/portfolio/mutualfund/view-analysis";
import Reports from "./pages/Profile/reports";
import Nominee_details from "./pages/Profile/nominee-details";
import Change_mpin from "./pages/Profile/change-mpin";
import My_orders from "./pages/Profile/my-orders";
import My_CAS from "./pages/Profile/my_cas";
import RiskProfile from "./pages/Profile/riskProfile";

import Questionareies from "./containers/Assisted/Dashboard/Questionareies";

import Investment_detail from "./pages/mutualfund/assisted/investment-detail";
import Suggested_fund from "./pages/mutualfund/assisted/suggested-fund";
import { namespace } from "./environment";
import MandateProgress from "./containers/MyProfile/BankDetails/MandateProgress";
import Settings from "./pages/Profile/settings";
import Capital_gain_report from "./pages/Profile/Capital_gain_report";
import Privacy_Policy from "./pages/about/Privacy_Policy";
import ContactUs from "./pages/about/ContactUs";
import Cancellation_Policy from "./pages/about/Cancellation_Policy";
import My_Calculation from "./pages/Profile/my_calculation";
// import RiskProfile from "./pages/Profile/riskProfile";
import NotFound from './pages/notFound/index';
import My_CAS_Funds from "./pages/Profile/my_cas_funds";


function AllRoutes() {
  return (
    <React.Fragment>
      <Routes>
        <Route path={`${namespace}`} element={<Home />} />
        {/* <Route path={`${namespace}/digital-gold`} element={<DigitalGold />} /> */}
        <Route path={`${namespace}/user`} element={<Onboard />} />

        <Route path={`${namespace}/about-us`} element={<AboutUs />} />
        {/* <Route path={`${namespace}/faq`} element={<Faqs />} /> */}
        <Route
          path={`/${namespace}/privacy-policy`}
          element={<Privacy_Policy />}
        />
        <Route
          path={`/${namespace}/cancellation-policy`}
          element={<Cancellation_Policy />}
        />
        <Route
          path={`/${namespace}/terms-conditions`}
          element={<Terms_conditions />}
        />
        <Route
          path={`/${namespace}/contact-us`}
          element={<ContactUs />}
        />

        {/* <Route element={<MainLayout />}>
          <Route
            path={`${namespace}/mutualfund/scheme-code/:code`}
            element={<Fund_details />}
          />
        </Route> */}
        <Route path={`${namespace}`} element={<ProtectedRoutes />}>
          <Route path={`${namespace}/onBoarding`} element={<Complient />} />
          {/* <Route path={`${namespace}`} element={<MpinProtect />}> */}
          <Route path={`${namespace}`}>
            <Route element={<MainLayout />}>
              {/* mutual fund */}
              <Route element={<Funds />}>
                <Route
                  path={`${namespace}/dashboard/mutualfund`}
                  element={<Subtab />}
                />
                <Route
                  path={`${namespace}/mutualfund/scheme-code/:code`}
                  element={<Fund_details />}
                />
                {/* <Route
                  path={`${namespace}/dashboard/mutualfund/assisted-services`}
                  element={<ContentSlider />}
                /> */}
                {/* <Route
                  path={`${namespace}/dashboard/mutualfund/assisted-services/questionaries`}
                  element={<Questionareies />}
                /> */}
                {/* <Route
                  path={`${namespace}/dashboard/mutualfund/assisted-services/invest-detail`}
                  element={<Investment_detail />}
                /> */}
                {/* <Route
                  path={`${namespace}/dashboard/mutualfund/assisted-services/suggested-funds`}
                  element={<Suggested_fund />}
                /> */}
              </Route>

              <Route
                path={`${namespace}/mutualfund/order-summary`}
                element={<OrderSummary />}
              />

              {/* <Route
                path={`${namespace}/mutualfund/payment-success`}
                element={<Payment_success />}
              /> */}

              {/* portfolio */}
              {/* <Route
                path={`${namespace}/myportfolio/my-systematic-investment/:id`}
                element={<Sip />}
              /> */} 
              
              <Route
                path={`${namespace}/myportfolio/switch/:id`}
                element={<SwitchFund />}
              />
              <Route
                path={`${namespace}/myportfolio/swp/:isin`}
                element={<Portfolio />}
              />
              <Route
                path={`${namespace}/myportfolio/redeem/:isin`}
                element={<Portfolio />}
              />
              <Route
                path={`${namespace}/myportfolio/stp/:isin`}
                element={<Stp />}
              />
              <Route
                path={`${namespace}/myportfolio/all-fund-transactions/:id`}
                element={<All_fund_transactions />}
              />
              <Route
                path={`${namespace}/mutualfund/redeem-success`}
                element={<Redeem_success />}
              />
              <Route
                path={`${namespace}/mutualfund/switch-success`}
                element={<Switch_success />}
              />
              <Route
                path={`${namespace}/mutualfund/swp-success`}
                element={<Swp_success />}
              />
              <Route
                path={`${namespace}/mutualfund/stp-success`}
                element={<Stp_success />}
              />
              <Route
                path={`${namespace}/mutualfund/mutual-fund/order-status/:id`}
                element={<Order_status />}
              />
              <Route
                path={`${namespace}/myportfolio/view-analysis`}
                element={<ViewAnalysis />}
              />


              {/* Goals */}

              <Route
                path={`${namespace}/goals`}
                element={<Goals />}
              />

              <Route
                path={`${namespace}/goals/type/:variant`}
                element={<Specific_Goals />}
              />

              <Route
                path={`${namespace}/goals/type/:variant/recommendation`}
                element={<RecommendPart />}
              />

              {/* didgital gold */}

              {/* <Route
                path={`${namespace}/dashboard/digitalgold`}
                element={<DigitalgoldDash />}
              />
              <Route
                path={`${namespace}/digitalgold/payment-success`}
                element={<PaymentSuccess />}
              />
              <Route
                path={`${namespace}/digitalgold/payment-failed`}
                element={<BuyFailed />}
              /> */}
              {/* <Route
                path={`${namespace}/digitalgold/sell-success`}
                element={<SellSuccess />}
              /> */}
              <Route
                path={`${namespace}/myportfolio`}
                element={<Portfolio />}
              />
              {/* <Route
                path={`${namespace}/myportfolio/delivery`}
                element={<GoldDelivery />}
              /> */}
              {/* <Route
                path={`${namespace}/myportfolio/delivery-success`}
                element={<Deliverysuccess />}
              /> */}
{/*               
              <Route
                path={`${namespace}/myportfolio/delivery-failed`}
                element={<PaymentFailed />}
              /> */}

              {/* <Route
                path={`${namespace}/myportfolio/digitalgold/detail-investment`}
                element={<InvestmentDetail />}
              /> */}

              <Route
                path={`${namespace}/myportfolio/my-order`}
                element={<Order />}
              />

              {/* <Route
                path={`${namespace}/myportfolio/all-transactions`}
                element={<All_transacations />}
              /> */}

              {/* My profile */}
              <Route
                path={`${namespace}/my-profile/bank-and-autoMandate`}
                element={<Bank_and_autoMandate />}
              />
              <Route
                path={`${namespace}/my-profile/capital-gain-report`}
                element={<Capital_gain_report />}
              />
              <Route
                path={`${namespace}/my-profile/settings`}
                element={<Settings />}
              />
              {/* Not required fields in this part  */}
              {/* <Route
                path={`${namespace}/my-profile/bank-and-autoMandate-progress`}
                element={<MandateProgress />}
              /> */}

              {/* <Route
                path={`${namespace}/my-profile/edit-address`}
                element={<Edit_Address />}
              /> */}

              <Route
                path={`${namespace}/my-profile/basic-details`}
                element={<Account_details />}
              />
              {/* <Route
                path={`${namespace}/my-profile/reports`}
                element={<Reports />}
              /> */}
              {/* <Route
                path={`${namespace}/my-profile/nominee-details`}
                element={<Nominee_details />}
              /> */}
              {/* <Route
                path={`${namespace}/my-profile/change-mpin`}
                element={<Change_mpin />}
              /> */}
              <Route
                path={`${namespace}/my-profile/my-orders`}
                element={<My_orders />}
              />
              <Route
                path={`${namespace}/my-profile/cas`}
                element={<My_CAS />}
              />
              <Route
                path={`${namespace}/my-profile/cas/cas_funds`}
                element={<My_CAS_Funds />}
              />
              <Route
                path={`${namespace}/my-profile/calculation`}
                element={<My_Calculation />}
              />
              <Route
                path={`${namespace}/my-profile/riskProfile`}
                element={<RiskProfile />}
              />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
        {/* Admin */}
      </Routes>
    </React.Fragment>
  );
}

export default AllRoutes;
