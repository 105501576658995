import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { purple } from "@mui/material/colors";
import { useNavigate, createSearchParams, Link } from "react-router-dom";
import { namespace, BASE_URL } from "../../../environment";
import CountUp from "react-countup";
import { Skeleton } from "@mui/material";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: "#0E76BC",
  borderRadius: "39px",
  width: "175px",
  height: "49px",
  fontSize: "15px",
  fontWeight: "500",
  position: "absolute",
  //width: '174.56px',
  //height: '49px',
  left: "990px",
  top: "390px",
  "&:hover": {
    backgroundColor: "#0E76BC",
  },
}));

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardContent: {
    display: "flex",
  },
  cardContentStar: {
    // display: "flex",
  },
  cardContent2: {
    //display:'flex',
    margin: "10px 0px",
  },
  mainCard: {
    //height:'190px',
    //width:'839px'
    // border: "1px solid #DDDDDD",
    border: '1px solid #E4E4E4',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
    borderRadius: "10px",
    marginTop: "1rem",
    padding: "10px",
    opacity: 0,  // Initially hidden
    animation: "$fadeIn 0.5s ease-out forwards", // Apply animation
    transform: 'translateY(20px)',  // Starts slightly off position
    // animation: `$fadeIn 0.5s ease-out forwards`, // Animation rule
    "& p": {
      margin: "4px 0px 4px 0px",
    },
  },
  "@keyframes fadeIn": {
    from: {
      opacity: 0,
      transform: 'translateY(20px)',  // Slide up from 20px below
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)',  // Back to original position
    },
  },
  fundName: {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "400",
    paddingLeft: "10px",
  },
  fundType: {
    fontFamily: "Poppins",
    fontSize: "13px",
    fontWeight: "400",
  },
  rating: {
    fontFamily: "Poppins",
    fontSize: "13px",
    fontWeight: "400",
  },

  nav: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "400",
  },
  navValue: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "600",
  },
  perReturns: {
    fontFamily: "Poppins",
    color: "#0E76BC",
    fontSize: "16px",
    fontWeight: "600",
  },
  yearReturns: {
    fontFamily: "Poppins",
    fontSize: "13px",
    fontWeight: "400",
  },
  primaryButton: {
    padding: "0.9rem 0px",
    fontFamily: "Poppins",
    background: "#0E76BC",
    color: "#fff",
    fontSize: "0.9rem",
    fontWeight: 500,
    borderRadius: "45px",
    border: "none",
    cursor: "pointer",
    marginTop: "3rem",
    width: "100%",
    border: "1px solid #0E76BC",
    transition: "0.3s ease-in-out",
    boxShadow: "none !important",
    textTransform: 'capitalize',
    "&:hover": {
      backgroundColor: "#fff",
      color: "#0E76BC",
    }
  },

  ratingBox: {
    paddingLeft: "1rem",
    paddingRight: "0.2rem",
  },

  starImag: {
    padding: "0px 4px",
  },
  rupees: {
    fontFamily: "Open Sans",
  },
  logo: {
    paddingRight: "10px",
  },
  "@media (max-width: 959.99px)": {
    ratingBox: {
      paddingLeft: "10px",
      display: 'flex',
      justifyContent: 'end',
      width: '100%',
    },
    primaryButton: {
      marginTop: "1rem",
      width: '300px',
    },
  },
  "@media (max-width: 600px)": {
    ratingBox: {
      paddingLeft: "10px",
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    primaryButton: {
      marginTop: "1rem",
      width: '200px',
    },
  },
});

export default function SimpleCard({ fund, access, handleSetFund }) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  const navigate = useNavigate();

  const handleFund = (scheme_code) => {
    navigate({
      pathname: `${namespace}/mutualfund`,
      search: `${createSearchParams({ scheme_code: scheme_code })}`,
    });
  };

  return (
    <Box className={classes.mainCard}>
      <Grid container>
        {/* <Grid item md={1} xs={12}>
          <img
            // src={fund?.img_url}
            src={`${BASE_URL}${fund?.amcLogoUrl}`}
            height={'auto'} width={54} />
        </Grid> */}
        <Grid item xs={12} md={access !== 'recomended' ? 10 : 12} container onClick={() => {
          if (access === 'recomended') handleSetFund(fund)
        }}
        style={{cursor: access === 'recomended' && 'pointer'}}
        >
          <Grid item xs={12} className={classes.fundName}>
            <Box display={'flex'}>
              {fund ? (
                <Box display="flex" alignItems="center">
                  <img
                    // src={fund?.img_url}
                    src={`${BASE_URL}${fund?.amcLogoUrl}`}
                    height={'auto'} width={54} style={{ borderRadius: '5px', marginRight: '10px' }} />
                </Box>
              ) : (
                <Skeleton variant="rounded" border width={50} height={50} />
              )}
              <Box >
                {access === 'recomended' ?
                  // <Typography variant="body">
                  fund?.schemeName

                  // </Typography>
                  :
                  <Link
                    to={`${namespace}/mutualfund/scheme-code/${fund.id}`}
                    state={{ data: fund }}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {/* {fund?.scheme_name} */}
                    {fund?.schemeName}
                  </Link>
                }
                <div className={classes.cardContent}>
                  <Grid item xs={6}>
                    <p className={classes.fundType}>
                      {/* {fund?.asset_sub_category} */}
                      {fund?.primaryCategoryName}
                    </p>
                  </Grid>
                  {/* <Grid item xs={6}>
                    <Box display={'flex'} >
                    <p className={classes.rating}>Rating</p>
                    <div className={classes.cardContentStar}>
                      {fund?.fundRating != "Not Rated" &&
                        (fund?.fundRating == 1 || fund?.fundRating > 1) && (
                          <img
                            src="/images/starRating.svg"
                            height={16}
                            width={16}
                            className={classes.starImag}
                          />
                        )}
                      {fund?.fundRating != "Not Rated" &&
                        (fund?.fundRating == 2 || fund?.fundRating > 2) && (
                          <img
                            src="/images/starRating.svg"
                            height={16}
                            width={16}
                            className={classes.starImag}
                          />
                        )}
                      {fund?.fundRating != "Not Rated" &&
                        (fund?.fundRating == 3 || fund?.fundRating > 3) && (
                          <img
                            src="/images/starRating.svg"
                            height={16}
                            width={16}
                            className={classes.starImag}
                          />
                        )}
                      {fund?.fundRating != "Not Rated" &&
                        (fund?.fundRating == 4 || fund?.fundRating > 4) && (
                          <img
                            src="/images/starRating.svg"
                            height={16}
                            width={16}
                            className={classes.starImag}
                          />
                        )}
                      {fund?.fundRating != "Not Rated" && fund?.fundRating == 5 && (
                        <img
                          src="/images/starRating.svg"
                          height={16}
                          width={16}
                          className={classes.starImag}
                        />
                      )}
                      {fund?.fundRating != "Not Rated" && fund?.fundRating === -1 && (
                        <>
                          <img
                            src="/images/starRatingEmpty.svg"
                            height={16}
                            width={16}
                            className={classes.starImag}
                          />
                          <img
                            src="/images/starRatingEmpty.svg"
                            height={16}
                            width={16}
                            className={classes.starImag}
                          />
                          <img
                            src="/images/starRatingEmpty.svg"
                            height={16}
                            width={16}
                            className={classes.starImag}
                          />
                          <img
                            src="/images/starRatingEmpty.svg"
                            width={16}
                            className={classes.starImag}
                          />
                          <img
                            src="/images/starRatingEmpty.svg"
                            height={16}
                            width={16}
                            className={classes.starImag}
                          />
                        </>
                      )}
                      {fund?.fundRating == "Not Rated" || fund?.fundRating == ""
                        ? "Not Rated"
                        : ""}
                    </div>
                    </Box>
                  </Grid> */}
                  <Grid item xs={6}>
                    <p className={classes.fundType}>{fund?.risk_profile}</p>
                  </Grid>
                </div>
              </Box>
            </Box>
            {/* <div className={classes.cardContent2}> */}
            <Grid item xs={12} container className={classes.cardContent2}>

              <Grid item xs={5} sm={3} md={2}>
                <p className={classes.navValue}>
                  <span className={classes.rupees}>₹</span>
                  {/* {fund?.aum?.toFixed(0)}Cr */}
                  <CountUp end={fund?.aum ? fund?.aum.toFixed(2) : 0} decimals={2} decimal="." duration={3} />Cr

                </p>
                <p className={classes.nav}>AUM</p>
              </Grid>
              <Grid item xs={5} sm={3} md={2}>
                <p className={classes.navValue}>
                  {/* {fund?.expenseRatio?.toFixed(2)}% */}
                  <CountUp end={fund?.expenseRatio ? fund?.expenseRatio.toFixed(2) : 0} decimals={2} decimal="." duration={2.5} />%
                </p>
                <p className={classes.nav}>Expernse Ratio</p>
              </Grid>
              <Grid item xs={5} sm={3} md={2}>
                <p className={classes.navValue}>
                  <span className={classes.rupees}>₹</span>
                  {/* {fund?.nav.toFixed(2)} */}
                  <CountUp end={fund?.nav ? fund?.nav.toFixed(2) : 0} decimals={2} decimal="." duration={2.5} />
                </p>
                <p className={classes.nav}>NAV</p>
              </Grid>
              <Grid item xs={5} sm={3} md={2}>
                <p className={classes.perReturns}>
                  {/* {fund?.ret1year ? fund?.ret1year.toFixed(2) : 0}% */}
                  <CountUp end={fund?.ret1year ? fund?.ret1year.toFixed(2) : 0} decimals={2} decimal="." duration={2.5} /> %

                </p>
                <p className={classes.yearReturns}>Returns( 1yr )</p>
              </Grid>
              <Grid item xs={5} sm={3} md={2}>
                <p className={classes.perReturns}>
                  <CountUp end={fund?.ret3year ? fund?.ret3year.toFixed(2) : 0} decimals={2} decimal="." duration={2.5} /> %
                  {/* {fund?.ret3year ? fund?.ret3year.toFixed(2) : 0}% */}
                </p>
                <p className={classes.yearReturns}>Returns( 3yr )</p>
              </Grid>
              <Grid item xs={5} sm={3} md={2}>
                <p className={classes.perReturns}>
                  {/* {fund?.ret5year ? fund?.ret5year.toFixed(2) : 0}% */}
                  <CountUp end={fund?.ret5year ? fund?.ret5year.toFixed(2) : 0} decimals={2} decimal="." duration={2.5} /> %
                </p>
                <p className={classes.yearReturns}>Returns( 5yr )</p>
              </Grid>
            </Grid>
            {/* </div> */}
          </Grid>
        </Grid>
        {access !== 'recomended' &&
          <Grid item xs={12} md={2} className={classes.ratingBox}>
            {/* <p className={classes.rating}>Rating</p>
          <div className={classes.cardContent}>
            {fund?.fundRating != "Not Rated" &&
              (fund?.fundRating == 1 || fund?.fundRating > 1) && (
                <img
                  src="/images/starRating.svg"
                  height={16}
                  width={16}
                  className={classes.starImag}
                />
              )}
            {fund?.fundRating != "Not Rated" &&
              (fund?.fundRating == 2 || fund?.fundRating > 2) && (
                <img
                  src="/images/starRating.svg"
                  height={16}
                  width={16}
                  className={classes.starImag}
                />
              )}
            {fund?.fundRating != "Not Rated" &&
              (fund?.fundRating == 3 || fund?.fundRating > 3) && (
                <img
                  src="/images/starRating.svg"
                  height={16}
                  width={16}
                  className={classes.starImag}
                />
              )}
            {fund?.fundRating != "Not Rated" &&
              (fund?.fundRating == 4 || fund?.fundRating > 4) && (
                <img
                  src="/images/starRating.svg"
                  height={16}
                  width={16}
                  className={classes.starImag}
                />
              )}
            {fund?.fundRating != "Not Rated" && fund?.fundRating == 5 && (
              <img
                src="/images/starRating.svg"
                height={16}
                width={16}
                className={classes.starImag}
              />
            )}
            {fund?.fundRating != "Not Rated" && fund?.fundRating === -1 && (
              <>
                <img
                  src="/images/starRatingEmpty.svg"
                  height={16}
                  width={16}
                  className={classes.starImag}
                />
                <img
                  src="/images/starRatingEmpty.svg"
                  height={16}
                  width={16}
                  className={classes.starImag}
                />
                <img
                  src="/images/starRatingEmpty.svg"
                  height={16}
                  width={16}
                  className={classes.starImag}
                />
                <img
                  src="/images/starRatingEmpty.svg"
                  width={16}
                  className={classes.starImag}
                />
                <img
                  src="/images/starRatingEmpty.svg"
                  height={16}
                  width={16}
                  className={classes.starImag}
                />
              </>
            )}
            {fund?.fundRating == "Not Rated" || fund?.fundRating == ""
              ? "Not Rated"
              : ""}
          </div> */}
            {/* {fund.id} */}

            <Link
              // to={`${namespace}/mutualfund/scheme-code/${fund.scheme_code}`}
              to={`${namespace}/mutualfund/scheme-code/${fund.id}`}
              state={{ data: fund }}
            >
              {" "}
              <Button className={classes.primaryButton} variant="contained">
                Invest
              </Button>
            </Link>

          </Grid>
        }
      </Grid>
    </Box>
  );
}
