// import React, { useState } from 'react'

// function Index() {


//     return (
//         <div>
//             <h1>404 - Page Not Found</h1>
//             <p>The page you are looking for doesn’t exist.</p>
//         </div>
//     )
// }

// export default Index


import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    notFoundContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        backgroundColor: '#f9f9f9',
    },
    floatingText: {
        fontSize: '10rem',
        fontWeight: 'bold',
        color: '#ff4c4c',
        animation: '$float 3s ease-in-out infinite',
    },
    notFoundText: {
        margin: '20px 0',
        color: '#555',
        fontSize: '1.5rem',
    },
    button: {
        marginTop: '20px',
        backgroundColor: '#0C97F0',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#00609e',
        },
    },
    // Keyframes for floating animation
    '@keyframes float': {
        '0%': {
            transform: 'translateY(0)',
        },
        '50%': {
            transform: 'translateY(-20px)',
        },
        '100%': {
            transform: 'translateY(0)',
        },
    },
    glitchContainer: {
        position: 'relative',
        display: 'inline-block',
        overflow: 'hidden',
        color: '#fff',
        fontSize: '3rem', // Adjust the font size as needed
        animation: '$glitch 1s linear infinite',

        // Pseudo-elements
        '&::before': {
            content: 'attr(title)', // For pseudo-element content
            position: 'absolute',
            left: 0,
            animation: '$glitchTop 1s linear infinite',
            clipPath: 'polygon(0 0, 100% 0, 100% 33%, 0 33%)',
            '-webkit-clip-path': 'polygon(0 0, 100% 0, 100% 33%, 0 33%)',
        },
        '&::after': {
            content: 'attr(title)', // For pseudo-element content
            position: 'absolute',
            left: 0,
            animation: '$glitchBottom 1.5s linear infinite',
            clipPath: 'polygon(0 67%, 100% 67%, 100% 100%, 0 100%)',
            '-webkit-clip-path': 'polygon(0 67%, 100% 67%, 100% 100%, 0 100%)',
        },
    },
    '@keyframes glitch': {
        '2%, 64%': {
            transform: 'translate(2px, 0) skew(0deg)',
        },
        '4%, 60%': {
            transform: 'translate(-2px, 0) skew(0deg)',
        },
        '62%': {
            transform: 'translate(0, 0) skew(5deg)',
        },
    },
    '@keyframes glitchTop': {
        '2%, 64%': {
            transform: 'translate(2px, -2px)',
        },
        '4%, 60%': {
            transform: 'translate(-2px, 2px)',
        },
        '62%': {
            transform: 'translate(13px, -1px) skew(-13deg)',
        },
    },
    '@keyframes glitchBottom': {
        '2%, 64%': {
            transform: 'translate(-2px, 0)',
        },
        '4%, 60%': {
            transform: 'translate(-2px, 0)',
        },
        '62%': {
            transform: 'translate(-22px, 5px) skew(21deg)',
        },
    },
}));

const NotFound = () => {
    const classes = useStyles();

    return (
        <Box className={classes.notFoundContainer}>
            <Box className={classes.glitchContainer}>
                <Typography className={classes.floatingText}>404</Typography>
            </Box>
            <Typography className={classes.notFoundText}>
                Oops! The page you're looking for doesn't exist.
            </Typography>
            <Button
                variant="contained"
                className={classes.button}
                component={Link}
                to="/"
            >
                Go Back to Homepage
            </Button>
        </Box>
    );
};

export default NotFound;
