import React, { useEffect, useState } from "react";
import { Box, Typography, makeStyles, Grid, Slider, CircularProgress } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { POST_GOALS_CALCULATE_EMERGENCY_FUND } from "../../utils/endpoints";
import httpInstance from "../../utils/instance";
import { namespace, BASE_URL } from "../../environment";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList

} from "recharts";
import ShowRecommendedFunds from "./ShowRecommendedFunds";
import { useForm } from 'react-hook-form';
import ErrorMessage from "../../components/Validation/ErrorMessage";
import { grey } from "@material-ui/core/colors";
import ScrollingNumber from "../../components/common/ScrollingNumber";
import CountUp from "react-countup";





const EmergencyFund = ({ stateData }) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            marginBottom: '3rem',
            // padding: "10px",
            borderRadius: "12px",
            // boxShadow: "0 0 8px -3px #444444",
            border: '1px solid #E4E4E4',
            // boxShadow: '0 0 8px -3px #444444',
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
            padding: "1rem",
            '& input[type=number]': {
                '-moz-appearance': 'textfield', /* Firefox */
                '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                    display: 'none',
                },
                '-webkit-appearance': 'none', /* Chrome, Safari, Opera */
                '&[type=number]': {
                    '-moz-appearance': 'textfield', /* Firefox */
                },
                '&::-webkit-outer-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                },
                '&::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                },
                '&[type=number]': {
                    '-moz-appearance': 'textfield', /* Firefox */
                },
            },


        },
        mainGrid: {
            padding: "30px 4rem 0px 8rem",
        },
        subTabtext: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
            fontWeight: 500,
        },
        textUnderline: {
            fontSize: "0.7rem",
            textDecoration: "underline",
            fontFamily: "inherit",
        },
        text: {
            fontSize: "0.8rem",
            fontWeight: 700,
        },
        subText: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
            fontWeight: 500,
            paddingLeft: '5px',
        },
        text1: {
            fontSize: "0.8rem",
        },
        text2: {
            fontSize: "1.4rem",
            fontFamily: "inherit",
            fontWeight: 500,
            // paddingLeft: '5px', 
        },
        text3: {
            fontSize: "1.2rem",
            fontFamily: "inherit",
            fontWeight: 700,
            // paddingLeft: '5px', 
        },
        text4: {
            fontSize: "1.8rem",
            fontFamily: "inherit",
            fontWeight: 700,
            color: "#0E76BC",
        },
        textBold: {
            fontSize: "0.8rem",
            fontWeight: 700,
            marginBottom: "1rem",
        },

        thumb: {
            background: "#bdbdbd",
            width: "20px",
            height: "20px",
            boxShadow: 'none !important',
            ' &.Mui-focusVisible': {
                boxShadow: 'none !important',
            },
            '&.Mui-active': {
                boxShadow: 'none !important',
            },
            // boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
        },
        mark: {
            background: "black"
        },
        rail: {
            background: "#EBEBEB",
            height: "10px",
            borderRadius: '10px',
        },
        track: {
            background: "#0E76BC",
            height: "10px",
            borderRadius: '10px',
        },
        borderBox: {
            // border: "1px solid #E4E4E4",
            // borderRadius: "5px",
            // padding: "1.5rem",
            // width: "75%",
            '& .MuiSlider-valueLabel': {
                '& > *': {

                    backgroundColor: 'white',
                    color: "black"
                },
            }
        },
        gridBox: {
            border: '1px solid #0E76BC',
            cursor: 'pointer',
            margin: '5px',
            borderRadius: '10px',
            transition: '0.2s ease-out',
            padding: '10px 15px',
            "&:hover": {
                backgroundColor: '#0E76BC',
                color: '#fff',
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            },
        },
        gridBoxActive: {
            backgroundColor: '#0E76BC',
            color: '#fff',
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        },
        bottomBox: {
            padding: '10px',
            borderRadius: "10px",
            backgroundColor: '#c7dfef',
            color: '#000',
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        },
        rupees: {
            fontFamily: "Open Sans"
        },
        primaryButton: {
            padding: "0.8rem 5rem",
            background: "#0E76BC",
            color: "#fff",
            fontSize: "0.8rem",
            fontWeight: 600,
            borderRadius: "45px",
            border: "none",
            cursor: "pointer",
            fontFamily: "inherit",
            border: '1px solid #0E76BC',
            '&:hover': {
                backgroundColor: "#fff",
                color: "#0E76BC",
            },
            '&:disabled': {
                opacity: '0.7',
                cursor: 'not-allowed',
            },
        },
        "@media (max-width: 767px)": {
            mainGrid: {
                padding: "0px 10px 0px 10px",
            },
        },
        rupeesSymbol: {
            position: 'relative',
            left: '9px',
            bottom: '28px',
            marginRight: '5px',
            fontSize: '13px',
            fontFamily: "Open Sans",
            display: 'block',
            height: '0px',
            width: '0px',
        },
        percentageSymbol: {
            position: 'relative',
            left: '98%',
            bottom: '28px',
            marginRight: '5px',
            fontSize: '13px',
            fontFamily: "Open Sans",
            display: 'block',
            height: '0px',
            width: '0px',
        },
        inputBox: {
            // background: "#E6E6E6",
            padding: "0.7rem 0.95rem",
            // borderRadius: "10px",
            border: "none",
            borderBottom: "1px solid #000",
            width: "-webkit-fill-available",
            fontFamily: "inherit",
            paddingLeft: '20px',
            outline: 'none',

            '& input[type=number]': {
                '-moz-appearance': 'textfield', /* Firefox */
                '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                    display: 'none',
                },
                '-webkit-appearance': 'none', /* Chrome, Safari, Opera */
                '&[type=number]': {
                    '-moz-appearance': 'textfield', /* Firefox */
                },
                '&::-webkit-outer-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                },
                '&::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                },
                '&[type=number]': {
                    '-moz-appearance': 'textfield', /* Firefox */
                },
            },
        },
        label: {
            fontSize: "0.7rem",
            fontWeight: 500,
        },
    }));
    const classes = useStyles();
    const location = useLocation();

    const { register, handleSubmit, formState: { errors } } = useForm();




    const [goalsSelection, setGoalsSelection] = useState(null)
    const [sliderValueOnChangeEnd, setSliderValueOnChangeEnd] = useState(2000)
    const [sliderValueOnChangeEndYear, setSliderValueOnChangeEndYear] = useState(3)
    const [loading, setLoading] = useState(false);
    const [calculatedEmergencyFund, setCalculatedEmergencyFund] = useState(null);
    const [showListChart, setShowListChart] = useState(false);
    const [showRecommendedFundsPage, setShowRecommendedFundsPage] = useState(false)
    const [error, setError] = useState('')
    const [reccomndedData, setReccomndedData] = useState(null)

    const navigate = useNavigate();

    console.log(location.pathname, stateData)


    // calculate my Emergency fund post request 
    const CalculateEmergencyFund = async (data) => {
        // console.log(data)
        setError('')
        setLoading(true)
        const formData = {
            monthly_income: data?.monthlyIncome,
            monthly_expense: data?.monthlyExpenses,
            existing_investment: data?.existingInvestment,
            expected_return: data?.freshInvestment
        }
        const httpConfig1 = {
            method: "POST",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            // params: { isin: fundData?.isinCode },
            data: formData
        };

        try {
            const response = await httpInstance(`${POST_GOALS_CALCULATE_EMERGENCY_FUND}`, httpConfig1);
            // console.log(response?.data, 'fadfadfadsfasdfasdfasdf')

            setCalculatedEmergencyFund(response?.data);
            setShowListChart(true)
        } catch (error) {
            setError(error.response.data.error)
        } finally {
            setLoading(false)

        }
    }

    // CustomBar Label
    const CustomBarLabel = ({ fill, ...props }) => (
        <g transform={`translate(${props.x},${props.y})`}>
            <text fill={fill} fontSize={10} textAnchor="middle" dominantBaseline="middle" dy={-10} dx={10} >
                ₹{props.value.toFixed(2)}
            </text>
        </g>
    );

    //As the slider change in the area 

    useEffect(() => {
        if (showListChart) {
            CalculateEmergencyFund();
        }
    }, [sliderValueOnChangeEnd])

    //See recomendation function call after graph 

    const SeeRecommendedFunds = () => {
        if (reccomndedData) {
            setError('')
            setShowRecommendedFundsPage(true)
            console.log(reccomndedData)
            handle_navigate(reccomndedData)
        }
        else {
            setError('Please select any one option to proceed!');
        }


    }


    //show lakhs cr valus shorted 
    const formatNumber = (value) => {
        if (value < 1000) {
            return value.toString();
        } else if (value < 100000) {
            return (value / 1000).toFixed(1) + ' ' + 'K';
        } else if (value < 10000000) {
            return (value / 100000).toFixed(1) + ' ' + 'Lakh';
        } else {
            return (value / 10000000).toFixed(1) + ' ' + 'Crore';
        }
        // if (value < 1000) {
        //     return value.toString();
        // } else if (value < 100000) {
        //     console.log(<ScrollingNumber value={value / 1000} time={3} />?.props.children);
        //     return <ScrollingNumber value={value / 1000} time={3} /> + 'K';
        // } else if (value < 10000000) {
        //     return <ScrollingNumber value={value / 100000} time={2} /> + 'Lakh';
        // } else {
        //     return <ScrollingNumber value={value / 10000000} time={1} /> + 'Crore';
        // }
    };

    const onSubmit = (data) => {
        console.log(data); // Here you can handle form submission
    };

    const handleTelChange = (event) => {
        const value = event.target.value;
        // Allow any character to be entered
        event.target.value = value;
    };
    const handleInputChange = (event) => {
        // Remove non-numeric characters
        const newValue = event.target.value.replace(/\D/g, '');
        // Update the input value
        event.target.value = newValue;
    };


    // handle grid selection based on the mounths valuevation 
    const handleGridSelection = (value, time) => {
        // console.log(value, time)
        setReccomndedData({
            value: value, time: time
        })
    }


    const handle_navigate = (data) => {
        navigate(`${namespace}${location.pathname}/recommendation`, {
            state: {
                data: data,
                goalData: stateData.data
            },
        });
    };



    return (
        <>
            <Box
                display="flex"
                justifyContent="flex-start"
                px={{ xs: 2, md: 15 }}
                mt={5}
                className={classes.backButton}
            >
              {!showListChart ?
                    <Link to={`${namespace}/goals`}>
                        <img src="/images/backIcon.svg" height="auto" width="20px" />
                    </Link>
                    :
                    <div onClick={() => {
                        setShowListChart(false)
                    }} style={{ cursor: 'pointer' }}>
                        <img src="/images/backIcon.svg" height="auto" width="20px" />
                    </div>
                }
            </Box>
            <Box
                // display="flex"
                px={{ xs: 1, md: 15 }}
                pt={2}
            // style={{ borderBottom: "1px solid #E6E3E3" }}
            >
                <div className={classes.root}>
                    {!showListChart ? (
                        <>

                            < Typography className={classes.text2} style={{ textAlign: 'start', paddingBottom: '10px' }}>Build an Emergency Fund </Typography>
                            < Typography className={classes.text1} style={{ textAlign: 'start', marginTop: '0px' }}>Secure Your Future with a Strong Emergency Fund</Typography>

                            {/* <ScrollingNumber value={7} /> */}
                            <Box>
                                <form onSubmit={handleSubmit(CalculateEmergencyFund)} >
                                    <Grid container spacing={2} style={{ marginTop: "15px" }}>
                                        <Grid item xs={12} sm={6} >
                                            <div>
                                                {/* <label htmlFor="monthlyIncome">Monthly Income</label> */}
                                                <label className={classes.label}>What is your Monthly Income</label>

                                                <input
                                                    className={classes.inputBox}
                                                    type="text"
                                                    id="monthlyIncome"
                                                    pattern="[0-9]*"
                                                    inputmode="numeric"
                                                    placeholder="Enter Monthly Income"
                                                    // {...register('monthlyIncome', { required: 'Monthly Income is required' })}
                                                    {...register('monthlyIncome', {
                                                        required: 'Monthly Income is required',
                                                        validate: value => !isNaN(value) || 'Please enter a valid number'
                                                    })}
                                                    onChange={handleInputChange}

                                                />
                                                <span className={classes.rupeesSymbol} style={{ marginRight: '5px', }}>₹</span>

                                                {errors.monthlyIncome ? (
                                                    // <span>{errors.monthlyIncome.message}</span>
                                                    <ErrorMessage message={errors.monthlyIncome.message} />
                                                ) : (
                                                    <Box height={20}></Box>
                                                )
                                                }
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>

                                            <div>
                                                {/* <label htmlFor="monthlyExpenses">Monthly Expenses</label> */}
                                                <label className={classes.label}>What is your Monthly Expense inclusive of liabiluty?</label>

                                                <input
                                                    className={classes.inputBox}
                                                    type="text"
                                                    id="monthlyExpenses"
                                                    pattern="[0-9]*"
                                                    inputmode="numeric"
                                                    placeholder="Enter Monthly Expenses"
                                                    {...register('monthlyExpenses', { required: 'Monthly Expenses is required' })}
                                                    onChange={handleInputChange}
                                                />
                                                <span className={classes.rupeesSymbol} style={{ marginRight: '5px' }}>₹</span>

                                                {errors.monthlyExpenses ? (
                                                    <ErrorMessage message={errors.monthlyExpenses.message} />
                                                ) : (
                                                    <Box height={20}></Box>
                                                )
                                                }
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>

                                            <div>
                                                {/* <label htmlFor="freshInvestment">Fresh Investment</label> */}
                                                <label className={classes.label}>Existing investment (FD, Cash & Liquid Mutual Fund)</label>
                                                <input
                                                    className={classes.inputBox}
                                                    type="text"
                                                    id="existingInvestment"
                                                    pattern="[0-9]*"
                                                    inputmode="numeric"
                                                    placeholder="Existing investment"
                                                    {...register('existingInvestment', { required: 'Existing Investment is required' })}
                                                    onChange={handleInputChange}

                                                />
                                                <span className={classes.rupeesSymbol} style={{ marginRight: '5px', }}>₹</span>
                                                {errors.existingInvestment ? (
                                                    <ErrorMessage message={errors.existingInvestment.message} />
                                                ) : (
                                                    <Box height={20}></Box>
                                                )
                                                }
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>

                                            <div>
                                                {/* <label htmlFor="freshInvestment">Fresh Investment</label> */}
                                                <label className={classes.label}>Expected return on fersh investment (%)</label>

                                                <input
                                                    className={classes.inputBox}
                                                    type="text"
                                                    id="freshInvestment"
                                                    pattern="[0-9]*"
                                                    inputmode="numeric"
                                                    placeholder="Expected return"
                                                    maxLength={3}
                                                    max={100}
                                                    // onChange={handleTelChange}
                                                    {...register('freshInvestment', {
                                                        required: 'Fresh Investment is required',
                                                        validate: value =>
                                                            value <= 100 || 'Total investment percentage should not exceed 100%',
                                                    })}
                                                    onChange={handleInputChange}
                                                />
                                                <span className={classes.percentageSymbol} style={{ marginRight: '5px', }}>%</span>

                                                {errors.freshInvestment ? (
                                                    <ErrorMessage message={errors.freshInvestment.message} />
                                                ) : (
                                                    <Box height={20}></Box>
                                                )}
                                            </div>
                                        </Grid>
                                    </Grid>
                                    {error && <Box textAlign={'center'}>
                                        <ErrorMessage message={error} />
                                    </Box>}
                                    <Box mt={3} display='flex' justifyContent='center' alignItems='center' >
                                        <button className={classes.primaryButton} type="submit" disabled={loading}>
                                            {loading ? (
                                                <CircularProgress size="1rem" color="inherit" />
                                            ) : (
                                                "Calculate Now"
                                            )}</button>
                                    </Box>
                                </form>
                            </Box>

                        </>
                    ) : (
                        <>
                            {showRecommendedFundsPage ? (
                                <>
                                    {/* <ShowRecommendedFundsOnly reccomndedData={reccomndedData} /> */}
                                </>

                            ) : (

                                <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                                    < Typography className={classes.text2} style={{ marginTop: '5px' }}>It is recommended that you have an emergency fund of ... </Typography>
                                    < Typography className={classes.text4} style={{ marginTop: '5px', marginBottom: '15px' }}><span className={classes.rupees}>₹</span> {" "}
                                        {calculatedEmergencyFund?.corpus_amount?.toLocaleString('en-IN')}
                                        {/* <ScrollingNumber value={calculatedEmergencyFund?.corpus_amount} time={20} /> */}
                                        {/* <CountUp
                                    end={calculatedEmergencyFund?.corpus_amount ? calculatedEmergencyFund?.corpus_amount : 0}
                                    duration={2.5}
                                    formattingFn={(number) => { return number.toLocaleString('en-IN') }}
                                /> */}
                                    </Typography>

                                    <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Grid item xs={12} sm={5} md={3} lg={3} style={{ textAlign: 'center' }} className={`${classes.gridBox} ${reccomndedData?.value === calculatedEmergencyFund?.amount_for_six_months && classes.gridBoxActive}`}
                                            onClick={() => handleGridSelection(calculatedEmergencyFund?.amount_for_six_months, 6)}
                                        >
                                            < Typography className={classes.text1} style={{ marginTop: '5px' }}>You can achieve in 6 months</Typography>
                                            < Typography className={classes.text3} style={{ marginTop: '5px' }}>
                                                <span className={classes.rupees}>₹</span>
                                                {calculatedEmergencyFund?.amount_for_six_months?.toLocaleString('en-IN')}
                                                {/* <CountUp
                                            end={calculatedEmergencyFund?.amount_for_six_months ? calculatedEmergencyFund?.amount_for_six_months : 0}
                                            duration={2}
                                            formattingFn={(number) => { return number.toLocaleString('en-IN') }}
                                        /> */}
                                                /month  </Typography>


                                        </Grid>
                                        <Grid item xs={12} sm={5} md={3} lg={3} style={{ textAlign: 'center' }} className={`${classes.gridBox} ${reccomndedData?.value === calculatedEmergencyFund?.amount_for_nine_months && classes.gridBoxActive}`} onClick={() => handleGridSelection(calculatedEmergencyFund?.amount_for_nine_months, 9)}>
                                            < Typography className={classes.text1} style={{ marginTop: '5px' }}>You can achieve in 9 months</Typography>

                                            < Typography className={classes.text3} style={{ marginTop: '5px' }}><span className={classes.rupees}>₹</span>
                                                {calculatedEmergencyFund?.amount_for_nine_months?.toLocaleString('en-IN')}
                                                {/* <CountUp
                                            end={calculatedEmergencyFund?.amount_for_nine_months ? calculatedEmergencyFund?.amount_for_nine_months : 0}
                                            duration={2}
                                            formattingFn={(number) => { return number.toLocaleString('en-IN') }}
                                        /> */}
                                                /month  </Typography>
                                            {/* <Typography className={classes.text3} style={{ marginTop: '5px' }}>
                                        <span className={classes.rupees}>₹</span>
                                        {formatNumber(<ScrollingNumber value={calculatedEmergencyFund?.invested} time={3} />)}
                                    </Typography> */}
                                            {/* {console.log(<ScrollingNumber value={calculatedEmergencyFund?.invested} time={3} />)} */}
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={3} lg={3} style={{ textAlign: 'center' }} className={`${classes.gridBox} ${reccomndedData?.value === calculatedEmergencyFund?.amount_for_twelve_months && classes.gridBoxActive}`} onClick={() => handleGridSelection(calculatedEmergencyFund?.amount_for_twelve_months, 12)}>
                                            < Typography className={classes.text1} style={{ marginTop: '5px' }}>You can achieve in 12 months</Typography>

                                            < Typography className={classes.text3} style={{ marginTop: '5px' }}><span className={classes.rupees}>₹</span>
                                                {calculatedEmergencyFund?.amount_for_twelve_months?.toLocaleString('en-IN')}
                                                {/* <CountUp
                                            end={calculatedEmergencyFund?.amount_for_twelve_months ? calculatedEmergencyFund?.amount_for_twelve_months : 0}
                                            duration={2}
                                            formattingFn={(number) => { return number.toLocaleString('en-IN') }}
                                        /> */}
                                                /month  </Typography>

                                        </Grid>
                                        <Grid item xs={12} sm={5} md={3} lg={3} style={{ textAlign: 'center' }} className={`${classes.gridBox} ${reccomndedData?.value === calculatedEmergencyFund?.amount_for_fifteen_months && classes.gridBoxActive}`} onClick={() => handleGridSelection(calculatedEmergencyFund?.amount_for_fifteen_months, 15)}>
                                            < Typography className={classes.text1} style={{ marginTop: '5px' }}>You can achieve in 15 months</Typography>

                                            < Typography className={classes.text3} style={{ marginTop: '5px' }}><span className={classes.rupees}>₹</span>
                                                {calculatedEmergencyFund?.amount_for_fifteen_months?.toLocaleString('en-IN')}
                                                {/* <CountUp
                                            end={calculatedEmergencyFund?.amount_for_fifteen_months ? calculatedEmergencyFund?.amount_for_fifteen_months : 0}
                                            duration={2}
                                            formattingFn={(number) => { return number.toLocaleString('en-IN') }}
                                        /> */}
                                                /month  </Typography>
                                        </Grid>
                                    </Grid>
                                    <Box textAlign={'center'} marginTop={5} className={classes.bottomBox}>
                                        < Typography className={classes.text3} style={{ marginTop: '5px' }}>Thumb Rule</Typography>

                                        < Typography className={classes.text1} style={{ marginTop: '5px', textAlign: 'center' }}>The basic thumb rule is to keep at least 6 months' worth of essential living expenses set aside as an Emergency Fund </Typography>

                                    </Box>
                                    {error && <Box textAlign={'center'}>
                                        <ErrorMessage message={error} />
                                    </Box>}
                                    <Box mt={3} display='flex' justifyContent='center' alignItems='center'>
                                        <button onClick={SeeRecommendedFunds} className={classes.primaryButton} disabled={loading}>
                                            {loading ? (
                                                <CircularProgress size="1rem" color="inherit" />
                                            ) : (
                                                "See Recommended Funds"
                                            )}</button>
                                    </Box>
                                </Box>
                            )}
                        </>
                    )}

                </div >
            </Box>
        </>
    );
};

export default EmergencyFund;
