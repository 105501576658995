import React, { useState, useEffect } from 'react'
import { Box, Typography, makeStyles, Grid, Table, TableBody, TableCell, TableHead, TableRow, Container, Tab, Tabs, LinearProgress } from "@material-ui/core";
import { GET_HOLDINGS } from '../../utils/endpoints'
import httpInstance from '../../utils/instance'
import { borderBottom, fontSize } from '@mui/system';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';



const RiskAndValidation = ({ fundData }) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            marginTop: "1rem",
            borderRadius: "12px",
            // boxShadow: "0 0 8px -3px #444444",
            border: '1px solid #E4E4E4',
            // boxShadow: '0 0 8px -3px #444444',
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
            padding: "1rem",
        },
        tableCellsLists: {

            "& .MuiLinearProgress-colorPrimary": {
                backgroundColor: "#25AAE1",
            },
            "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: "#0E76BC",
            },
        },
        title: {
            fontWeight: 600,
            // padding: "0px 7rem",
            fontSize: "1.2rem"
        },
        tableHead: {
            fontWeight: 600
        },
        tab_box: {
            padding: "0px 0rem"
        },
        tab_style: {
            width: "100%",
            background: "white",
            color: "black",
            borderBottom: '1px solid #E5E3E3',
            "& .MuiTab-root": {
                fontFamily: "inherit",
                fontSize: "1rem",
                color: "black",
                opacity: 1,
                textTransform: "none",
                background: "none",
                minWidth: "auto",
                padding: "0px"
            },
            "& .MuiTab-textColorInheri": {
                opacity: 0.9
            },
            "& .MuiTabs-root": {
                height: "42px",
                minHeight: "42px",
                padding: "0px"
            },
            "& .MuiTabs-flexContainer": {
                display: "flex",
                justifyContent: "space-between"
            },
            "& .MuiTabs-scrollable": {
                overflowY: "clip"
            }


        },
        tabWidth: {
            width: '25%',
            color: '#272727',
            fontSize: '15px',
            fontFamily: 'Poppins',
            fontWeight: '400'
        },
        activeTab: {
            "& .MuiTab-wrapper": {
                borderBottom: "2px solid #0E76BC",
                color: "#0E76BC",
                paddingBottom: "5px",

            }

        },

        inActiveTab: {
            "& .MuiTab-wrapper": {
                paddingBottom: "5px",

            }

        },
        "@media (max-width: 600px)": {
            tab_box: {
                padding: "20px"
            },
            activeTab: {
                "& .MuiTab-wrapper": {
                    padding: "10px"
                }
            },
            inActiveTab: {
                "& .MuiTab-wrapper": {
                    padding: "10px"

                }
            },
        },
        "@media (min-width: 992px)": {
            tablefirstCell: {
                fontWeight: 600,
                width: "45%",
                paddingLeft: "7rem",

            },
            tableBodyfirstCell: {
                paddingLeft: "7rem",
                width: "45%",

            },
            tablelastCell: {
                fontWeight: 600,
                paddingRight: "5rem",

            },
            tableBodylastCell: {
                paddingRight: "5rem",
            },
        },
        viewAll: {
            fontSize: "0.9rem",
            fontWeight: 600,
            color: '#0E76BC',
            textDecoration: "underline",
            cursor: "pointer",
        },
        text1: {
            fontSize: "0.8rem",
            margin: "0.5rem 0",
            borderBottom: "1px solid #e4e4e4",
        },

        textBold: {
            fontSize: "0.8rem",
            fontWeight: 700,
            margin: "0.5rem 0",
            borderBottom: "1px solid #e4e4e4",
        },
        rupees: {
            fontFamily: "Open Sans",
        },
        containerRiskValidation: {
            // padding: "0px 7rem",
        },

    }))
    const classes = useStyles();


    return (
        <div className={classes.root}>
            <Box mt={0}>
                <Typography className={classes.title}>Risk and Validation</Typography>
                <Box mt={1}>
                    <Grid container className={classes.containerRiskValidation}>
                        <Grid item xs={3} className={classes.textBold}>
                            {" "}
                            Return Since Launch (%){" "}
                        </Grid>
                        <Grid item xs={3} className={classes.text1}>
                            {/* <span className={classes.rupees}>₹</span> */}
                            {/* {fundData?.min_sip_amount} */}
                            {fundData ?
                                <>{fundData?.fundRetSinceLaunch ? fundData?.fundRetSinceLaunch?.toFixed(2) : ''}</>
                                :
                                <Skeleton variant="text" width="50%" />
                            }
                        </Grid>
                        <Grid item xs={3} className={classes.textBold}>
                            {" "}
                            Std Dev (%){" "}
                        </Grid>
                        <Grid item xs={3} className={classes.text1}>
                            {/* <span className={classes.rupees}>₹</span> */}
                            {/* {fundData?.min_sip_amount} */}
                            {fundData ?
                                <>
                                    {fundData?.standardDeviation ? fundData?.standardDeviation?.toFixed(2) : ''}
                                </>
                                :
                                <Skeleton variant="text" width="50%" />
                            }
                        </Grid>
                        <Grid item xs={3} className={classes.textBold}>
                            {" "}
                            Sharpe (%){" "}
                        </Grid>
                        <Grid item xs={3} className={classes.text1}>
                            {/* <span className={classes.rupees}>₹</span> */}
                            {/* {fundData?.min_sip_amount} */}

                            {fundData ?
                                <>
                                    {fundData?.sharpeRatio ? fundData?.sharpeRatio?.toFixed(2) : ''}
                                </>
                                :
                                <Skeleton variant="text" width="50%" />
                            }

                        </Grid>
                        <Grid item xs={3} className={classes.textBold}>
                            {" "}
                            Beta (%){" "}
                        </Grid>
                        <Grid item xs={3} className={classes.text1}>
                            {/* <span className={classes.rupees}>₹</span> */}
                            {/* {fundData?.min_sip_amount} */}
                            {fundData ?
                                <>
                                    {fundData?.volatilityMeasure?.measureDetailMap?.Beta?.fundMeasure ? fundData?.volatilityMeasure?.measureDetailMap?.Beta?.fundMeasure?.toFixed(2) : ''}
                                </>
                                :
                                <Skeleton variant="text" width="50%" />
                            }
                        </Grid>
                        <Grid item xs={3} className={classes.textBold}>
                            {" "}
                            Alpha (%){" "}
                        </Grid>
                        <Grid item xs={3} className={classes.text1}>
                            {/* <span className={classes.rupees}>₹</span> */}
                            {/* {fundData?.min_sip_amount} */}
                            {fundData ?
                                <>
                                    {fundData?.volatilityMeasure?.measureDetailMap?.Alpha?.fundMeasure ? fundData?.volatilityMeasure?.measureDetailMap?.Alpha?.fundMeasure?.toFixed(2) : ''}
                                </>
                                :
                                <Skeleton variant="text" width="50%" />
                            }
                        </Grid>
                        <Grid item xs={3} className={classes.textBold}>
                            {" "}
                            Risk Grade {" "}
                        </Grid>
                        <Grid item xs={3} className={classes.text1}>
                            {/* <span className={classes.rupees}>₹</span> */}
                            {/* {fundData?.min_sip_amount} */}
                            {fundData ?
                                <>
                                    {fundData?.riskGrade ? fundData?.riskGrade : 'N/A'}
                                </>
                                :
                                <Skeleton variant="text" width="50%" />
                            }
                        </Grid>
                        <Grid item xs={3} className={classes.textBold}>
                            {" "}
                            Return Grade {" "}
                        </Grid>
                        <Grid item xs={3} className={classes.text1}>
                            {/* <span className={classes.rupees}>₹</span> */}
                            {/* {fundData?.min_sip_amount} */}
                            {fundData ?
                                <>
                                    {fundData?.returnGrade}
                                </>
                                :
                                <Skeleton variant="text" width="50%" />
                            }
                            {/* {console.log(fundData?.returnGrade, 'adfadfadf')} */}
                            {/* {fundData?.returnGrade ? fundData?.returnGrade?.toFixed(2) : 'N/A'} */}
                        </Grid>
                        <Grid item xs={3} className={classes.textBold}>
                            {" "}
                            Turnover {" "}
                        </Grid>
                        <Grid item xs={3} className={classes.text1}>
                            {/* <span className={classes.rupees}>₹</span> */}
                            {/* {fundData?.min_sip_amount} */}
                            {fundData ?
                                <>
                                    {fundData?.turnoverRatio ? fundData?.turnoverRatio.toFixed(2) : "0.00"}
                                </>
                                :
                                <Skeleton variant="text" width="50%" />
                            }
                        </Grid>
                    </Grid>
                </ Box>
            </Box>
        </div>
    )
}

export default RiskAndValidation