// import React from 'react';

// const FormatAmount = ({ amount }) => {
//   const formatAmount = (amount) => {
//     if (amount >= 10000000) {
//       // Convert to crores
//       return (amount / 10000000).toFixed(2) + ' Cr';
//     } else if (amount >= 100000) {
//       // Convert to lakhs
//       return (amount / 100000).toFixed(2) + ' L';
//     } else {
//       // Format with commas
//       return amount.toLocaleString('en-IN');
//     }
//   };

//   return <span>{formatAmount(amount)}</span>;
// };

// export default FormatAmount;

export const formatAmount = (amount) => {
  if (amount >= 10000000) {
    // Convert to crores
    return (amount / 10000000).toFixed(2) + ' Cr';
  } else if (amount >= 100000) {
    // Convert to lakhs
    return (amount / 100000).toFixed(2) + ' L';
  } else {
    // Format with commas
    const final = amount.toFixed(2)
    // console.log(final, typeof(final), (Number(final)).toLocaleString('en-IN'))
    // return amount.toLocaleString('en-IN');
    return (Number(final)).toLocaleString('en-IN')
  }
};


export const formatAmountNumber = (amount) => {
  if (amount >= 10000000) {
    // Convert to crores
    return (amount / 10000000) //.toFixed(2) + ' Cr';
  } else if (amount >= 100000) {
    // Convert to lakhs
    return (amount / 100000)  //.toFixed(2) + ' L';
  } else {
    // Format with commas
    // const final = amount.toFixed(2)
    // console.log(final, typeof(final), (Number(final)).toLocaleString('en-IN'))
    return amount
    // return (Number(final)).toLocaleString('en-IN')
  }
};

export const formatAmountNumberSign = (amount) => {
  if (amount >= 10000000) {
    // Convert to crores
    return ' Cr' //.toFixed(2) + ' Cr';
  } else if (amount >= 100000) {
    // Convert to lakhs
    return ' L'  //.toFixed(2) + ' L';
  } else {
    // Format with commas
    // const final = amount.toFixed(2)
    // console.log(final, typeof(final), (Number(final)).toLocaleString('en-IN'))
    return ''
    // return (Number(final)).toLocaleString('en-IN')
  }
};


