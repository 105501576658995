import React, { useEffect, useState } from "react";
import { Box, Typography, makeStyles, Grid, Slider, CircularProgress } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { GET_GOALS_CALCULATE_FUTURE_VALUE, POST_GOALS_CALCULATE_RETIREMENT_PLAN } from "../../utils/endpoints";
import httpInstance from "../../utils/instance";
import { namespace, BASE_URL } from "../../environment";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList

} from "recharts";
import ShowRecommendedFunds from "./ShowRecommendedFunds";
import ErrorMessage from "../../components/Validation/ErrorMessage";
import { event } from "react-ga";
import { formatAmountNumber, formatAmountNumberSign } from "../../components/common/FormatAmount";
import CountUp from "react-countup";

const ScrollingNumber = ({ value, time }) => {
    const [displayedValue, setDisplayedValue] = useState(value - 10);

    useEffect(() => {
        const interval = setInterval(() => {
            if (displayedValue < value) {
                setDisplayedValue(prevValue => prevValue + 1);
            } else if (displayedValue > value) {
                setDisplayedValue(prevValue => prevValue - 1);
            }
        }, time); // Adjust speed of animation as needed

        return () => clearInterval(interval);
    }, [displayedValue, value]);
    console.log(value);

    return <span>{displayedValue}</span>;
};







const RetirementPlan = ({ stateData }) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            marginBottom: '3rem',
            // padding: "10px",
            borderRadius: "12px",
            // boxShadow: "0 0 8px -3px #444444",
            border: '1px solid #E4E4E4',
            // boxShadow: '0 0 8px -3px #444444',
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
            padding: "1rem",
        },
        mainGrid: {
            padding: "30px 4rem 0px 8rem",
        },
        subTabtext: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
            fontWeight: 500,
        },
        textUnderline: {
            fontSize: "0.7rem",
            textDecoration: "underline",
            fontFamily: "inherit",
        },
        text: {
            fontSize: "0.8rem",
            fontWeight: 700,
        },
        subText: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
            fontWeight: 500,
            paddingLeft: '5px',
        },
        text1: {
            fontSize: "0.8rem",
        },
        text2: {
            fontSize: "1.2rem",
            fontFamily: "inherit",
            fontWeight: 500,
            // paddingLeft: '5px', 
        },
        text3: {
            fontSize: "1.2rem",
            fontFamily: "inherit",
            fontWeight: 700,
            // paddingLeft: '5px', 
        },
        textBold: {
            fontSize: "0.8rem",
            fontWeight: 700,
            marginBottom: "1rem",
        },
        text11: {
            fontSize: "0.7rem",
            color: '#777777',
        },
        // thumb: {
        //     background: "#bdbdbd",
        //     width: "20px",
        //     height: "20px",
        //     boxShadow: 'none !important',
        //     ' &.Mui-focusVisible': {
        //         boxShadow: 'none !important',
        //     },
        //     '&.Mui-active': {
        //         boxShadow: 'none !important',
        //     },
        //     // boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
        // },
        // mark: {
        //     background: "black"
        // },
        // rail: {
        //     background: "#EBEBEB",
        //     height: "10px",
        //     borderRadius: '10px',
        // },
        // track: {
        //     background: "#0E76BC",
        //     height: "10px",
        //     borderRadius: '10px',
        // },
        thumb: {
            background: "#fff",
            border: "3px solid #0E76BC",
            // marginBottom: '-12px',
            width: "16px",
            height: "16px",
            boxShadow: 'none !important',
            ' &.Mui-focusVisible': {
                boxShadow: 'none !important',
            },
            '&.Mui-active': {
                boxShadow: 'none !important',
            },
        },
        mark: {
            background: "black"
        },
        rail: {
            background: "#0E76BC",
            height: "7px",
            borderRadius: '5px',
        },
        track: {
            background: "#0E76BC",
            height: "7px",
            borderRadius: '5px',
        },
        valueLabel: {
            width: "16px",
            height: "16px",
            top: '-20px',
            // left: '-1px',
            backgroundColor: 'transparent',
            '& .PrivateValueLabel-circle-233': {
                backgroundColor: 'transparent',
            },
            '& .PrivateValueLabel-circle-229': {
                backgroundColor: 'transparent',
            },
            '& > *': {
                backgroundColor: 'transparent',
                // color: "black"
            },
            '& .MuiSlider-valueLabel': {
                '& > *': {
                    backgroundColor: 'transparent',
                    // color: "black"
                },
            }
        },
        borderBox: {
            // border: "1px solid #E4E4E4",
            // borderRadius: "5px",
            // padding: "1.5rem",
            // width: "75%",
            // '& .MuiSlider-valueLabel': {
            //     '& > *': {

            //         backgroundColor: 'white',
            //         color: "black"
            //     },
            // }
        },
        rupees: {
            fontFamily: "Open Sans"
        },
        primaryButton: {
            padding: "0.8rem 5rem",
            background: "#0E76BC",
            color: "#fff",
            fontSize: "0.8rem",
            fontWeight: 600,
            borderRadius: "45px",
            border: "none",
            cursor: "pointer",
            fontFamily: "inherit"

        },
        "@media (max-width: 767px)": {
            mainGrid: {
                padding: "0px 10px 0px 10px",
            },
        },
        rupeesSymbol: {
            position: 'relative',
            left: '9px',
            bottom: '28px',
            marginRight: '5px',
            fontSize: '13px',
            fontFamily: "Open Sans",
            display: 'block',
            height: '0px',
            width: '0px',
        },
        percentageSymbol: {
            position: 'relative',
            left: '98%',
            bottom: '28px',
            marginRight: '5px',
            fontSize: '13px',
            fontFamily: "Open Sans",
            display: 'block',
            height: '0px',
            width: '0px',
        },
        inputBox: {
            // background: "#E6E6E6",
            padding: "0.7rem 0.95rem",
            borderRadius: "0px",
            border: "none",
            borderBottom: "1px solid #000",
            width: "-webkit-fill-available",
            fontFamily: "inherit",
            paddingLeft: '20px',
            outline: 'none',

            '& input[type=number]': {
                '-moz-appearance': 'textfield', /* Firefox */
                '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                    display: 'none',
                },
                '-webkit-appearance': 'none', /* Chrome, Safari, Opera */
                '&[type=number]': {
                    '-moz-appearance': 'textfield', /* Firefox */
                },
                '&::-webkit-outer-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                },
                '&::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                },
                '&[type=number]': {
                    '-moz-appearance': 'textfield', /* Firefox */
                },
            },

        },
        label: {
            fontSize: "0.7rem",
            fontWeight: 500,
        },
        gridBox: {
            // cursor: 'pointer',
            borderRadius: '50px',
            transition: '0.2s ease-out',
            padding: '15px 25px',
            border: '1px solid #0E76BC',
            margin: '15px 0px',
            maxWidth: '400px',
            width: '100%',
            // "&:hover": {
            //     backgroundColor: '#0E76BC',
            //     color: '#fff',
            //     boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            // },
        },
        gridBoxDip: {
            borderRadius: '50px',
            transition: '0.2s ease-out',
            padding: '10px 30px',
            border: '1px solid #0E76BC',
            margin: '15px 0px',
            backgroundColor: '#0E76BC',
            color: '#fff'
        }
    }));
    const classes = useStyles();
    const location = useLocation();

    const [goalsSelection, setGoalsSelection] = useState(null)
    const [sliderValueMyAge, setSliderValueMyAge] = useState(18)
    const [sliderRetireAtTheAge, setSliderRetireAtTheAge] = useState(18)
    const [lifeExpectancyAge, setLifeExpectancyAge] = useState(18)
    const [loading, setLoading] = useState(false)
    const [calculatedRetirement, setCalculatedRetirement] = useState();
    const [showBarchart, setShowBarChart] = useState(false);
    const [showRecommendedFundsPage, setShowRecommendedFundsPage] = useState(false)
    const [error, setError] = useState('');
    const [errorsInput, setErrorsInput] = useState('');
    const [errorsInputExistingInvestment, setErrorsInputExistingInvestment] = useState('');
    const [errorsInputMonthlyExpensesAtRetirementAge, setErrorsInputMonthlyExpensesAtRetirementAge] = useState('');
    const [errorsInputReturnExistingInvestment, setErrorsInputReturnExistingInvestment] = useState('');
    const [errorsInputExpectedInflationRate, setErrorsInputExpectedInflationRate] = useState('');
    const [errorsInputExpectedReturnTillRetirement, setErrorsInputExpectedReturnTillRetirement] = useState('');
    const [expenses, setExpenses] = useState();
    const [existingInvestment, setExistingInvestment] = useState()
    const [monthlyExpensesAtRetirementAge, setMonthlyExpensesAtRetirementAge] = useState()
    const [returnExistingInvestment, setReturnExistingInvestment] = useState()
    const [expectedInflationRate, setExpectedInflationRate] = useState()
    const [expectedReturnTillRetirement, setExpectedReturnTillRetirement] = useState()


    const [expencsesEveryYear, setExpencsesEveryYear] = useState(0)

    const navigate = useNavigate();


    // calculate my first corre post request 
    const CalculateRetirementPlan = async () => {
        // console.log(sliderValueMyAge, lifeExpectancyAge, sliderRetireAtTheAge)
        // console.log(sliderRetireAtTheAge > sliderValueMyAge && lifeExpectancyAge > sliderValueMyAge && lifeExpectancyAge > sliderRetireAtTheAge)
        // console.log(sliderRetireAtTheAge > sliderValueMyAge)


        if (!expenses) {
            setErrorsInput('Enter expenses')
            return

        } else if (sliderRetireAtTheAge <= sliderValueMyAge) {
            setError('Retirement age should be greater than your current age')
            return
        } else if (lifeExpectancyAge <= sliderValueMyAge) {
            setError('Life expectancy must be grater than your current age')
            return
        } else if (lifeExpectancyAge <= sliderRetireAtTheAge) {
            setError('life Expectancy Age must be grater than your retirement age')
            return
        } else if (sliderRetireAtTheAge > sliderValueMyAge && lifeExpectancyAge > sliderValueMyAge && lifeExpectancyAge > sliderRetireAtTheAge && expenses) {



            setErrorsInput('')
            setError('')
            // console.log(amount);
            // CalculateParkYourSpareMoney();

            setLoading(true)
            const formData = {
                current_monthly_expense: expenses,
                age: sliderValueMyAge,
                retire_at_age: sliderRetireAtTheAge,
                life_expectancy: lifeExpectancyAge,
                existing_investments: existingInvestment,
                expected_rate_existing_investment: returnExistingInvestment,
                expected_inflation: expectedInflationRate,
                expected_rate_new_investment: expectedReturnTillRetirement,
                // retirement_monthly_expense: monthlyExpensesAtRetirementAge,
                retirement_monthly_expense: expenses,
                yearly_rate_of_expense_increase: expencsesEveryYear
            }
            const httpConfig1 = {
                method: "POST",
                tokenType: "user",
                headers: {
                    "Content-Type": "application/json",
                },
                // params: { isin: fundData?.isinCode },
                data: formData
            };

            try {
                const response = await httpInstance(`${POST_GOALS_CALCULATE_RETIREMENT_PLAN}`, httpConfig1);
                setCalculatedRetirement(response?.data);
                setShowBarChart(true)
            } catch (error) {
                setError(error.response?.data?.error)
            } finally {
                setLoading(false)

            }
            return
        } else {
            setError('someting wentsssss wrong')
        }

    }



    // calculate my first corre post request 
    const CalculateMonthlyExpensesInRetirementAge = async () => {

        const httpConfig1 = {
            method: "GET",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            params: { current_value: expenses, years: sliderRetireAtTheAge - sliderValueMyAge, inflation_rate: expectedInflationRate },

        };

        try {
            const response = await httpInstance(`${GET_GOALS_CALCULATE_FUTURE_VALUE}`, httpConfig1);
            console.log(response)
            setMonthlyExpensesAtRetirementAge(response?.data?.data?.future_value);
            // setCalculatedRetirement(response?.data);
            // setShowBarChart(true)
        } catch (error) {
            // setError(error.response?.data?.error)
        } finally {
            setLoading(false)

        }


    }

    useEffect(() => {
        CalculateRetirementPlan()
    }, [expencsesEveryYear])



    useEffect(() => {
        if (expenses && sliderValueMyAge && expectedInflationRate) {
            CalculateMonthlyExpensesInRetirementAge()
        }
    }, [expenses, sliderValueMyAge, expectedInflationRate])


    // CustomBar Label
    const CustomBarLabel = ({ fill, ...props }) => (
        <g transform={`translate(${props.x},${props.y})`}>
            <text fill={fill} fontSize={10} textAnchor="middle" dominantBaseline="middle" dy={-10} dx={10} >
                ₹{formatNumber(props.value.toFixed(2))}
            </text>
        </g>
    );

    //As the slider change in the area 

    // useEffect(() => {
    //     if (showBarchart) {
    //         CalculateRetirementPlan();
    //     }
    // }, [sliderValueMyAge])

    //show lakhs cr valus shorted 
    const formatNumber = (value) => {
        if (value < 1000) {
            return value.toString();
        } else if (value < 100000) {
            return (value / 1000).toFixed(1) + ' ' + 'K';
        } else if (value < 10000000) {
            return (value / 100000).toFixed(1) + ' ' + 'Lakh';
        } else {
            return (value / 10000000).toFixed(1) + ' ' + 'Crore';
        }
    };

    const handleInputChangeValue = (event, type) => {
        // Remove non-numeric characters
        // const newValue = event.target.value.replace(/\D/g, '');
        // // Update the input value
        // // event.target.value = newValue;
        // if (newValue <= 100) {
        if (type === "expenses") {
            setExpenses(event.target.value);
            setErrorsInput('')
        } else if (type === "existingInvestment") {
            setExistingInvestment(event.target.value)
            setErrorsInputExistingInvestment('')
        } else if (type === "monthlyExpensesAtRetirementAge") {
            setMonthlyExpensesAtRetirementAge(event.target.value)
            setErrorsInputMonthlyExpensesAtRetirementAge('')
        }


        // }
    };

    const handleInputChangePercentage = (event, type) => {
        // Remove non-numeric characters
        const newValue = event.target.value.replace(/\D/g, '');
        // Update the input value
        // event.target.value = newValue;
        if (newValue <= 100) {
            if (type === "returnExistingInvestment") {
                setReturnExistingInvestment(newValue)
                setErrorsInputReturnExistingInvestment('')
            } else if (type === "expectedInflationRate") {
                setExpectedInflationRate(newValue)
                setErrorsInputExpectedInflationRate('')
            } else if (type === "expectedReturnTillRetirement") {
                setExpectedReturnTillRetirement(newValue)
                setErrorsInputExpectedReturnTillRetirement('')
            }
        }



    };



    //See recomendation function call after graph 
    const SeeRecommendedFunds = () => {
        setShowRecommendedFundsPage(true)
        handle_navigate(calculatedRetirement)

    }

    // handle navigation to the recommendation page 
    const handle_navigate = (data) => {
        // console.log(data?.data)
        navigate(`${namespace}${location.pathname}/recommendation`, {
            state: {
                data: {
                    value: calculatedRetirement?.monthly_investment,
                    time: data?.crore_duration
                },
                goalData: stateData.data
            },
        });
    };



    return (
        <>
            <Box
                display="flex"
                justifyContent="flex-start"
                px={{ xs: 2, md: 15 }}
                mt={5}
                className={classes.backButton}
            >
                {!showBarchart ?
                    <Link to={`${namespace}/goals`}>
                        <img src="/images/backIcon.svg" height="auto" width="20px" />
                    </Link>
                    :
                    <div onClick={() => {
                        setShowBarChart(false)
                    }} style={{ cursor: 'pointer' }}>
                        <img src="/images/backIcon.svg" height="auto" width="20px" />
                    </div>
                }
            </Box>
            <Box
                // display="flex"
                px={{ xs: 1, md: 15 }}
                pt={2}
            // style={{ borderBottom: "1px solid #E6E3E3" }}
            >
                <div className={classes.root}>
                    {!showBarchart ? (
                        <>
                            < Typography className={classes.text2} style={{ textAlign: 'start', paddingBottom: '10px' }}> Retirement Plan </Typography>
                            {/* <ScrollingNumber value={7} /> */}
                            <Box>
                                < Typography className={classes.text3} style={{ marginTop: '5px' }}>Secure Your Golden Years: Plan Your Retirement with Confidence!</Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} md={6} style={{}}>

                                        <Box marginTop={3}>
                                            {/* <label htmlFor="freshInvestment">Fresh Investment</label> */}
                                            <label className={classes.label}>My current monthly expenses are</label>

                                            <input
                                                className={classes.inputBox}
                                                type="text"
                                                placeholder="Expected expenses"
                                                // maxLength={3}
                                                // onChange={handleTelChange}
                                                onChange={(event) => handleInputChangeValue(event, 'expenses')}
                                                value={expenses}
                                                onClick={(e) => !expenses ? setErrorsInput('Please enter expenses') : ''}
                                            />
                                            <span className={classes.rupeesSymbol} style={{ marginRight: '5px', }}>₹</span>

                                            {errorsInput ? (
                                                <ErrorMessage message={errorsInput} />
                                            ) : (
                                                <Box height={20}></Box>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6} style={{}}>

                                        < Typography className={classes.text1} style={{ marginTop: '5px' }}>My age is </Typography>

                                        <Box mt={3} px={3} className={classes.borderBox}>
                                            <Slider
                                                classes={{
                                                    thumb: classes.thumb,
                                                    rail: classes.rail,
                                                    track: classes.track,
                                                    valueLabel: classes.valueLabel,
                                                    mark: classes.mark
                                                }}
                                                defaultValue={sliderValueMyAge || 18}
                                                aria-label="Default"
                                                valueLabelDisplay="on"
                                                size="medium"
                                                valueLabelFormat={(x) => {
                                                    return (
                                                        <p style={{ paddingLeft: '10px', color: "black" }}><span className={classes.rupees}></span>{x.toLocaleString('en-IN')}Y</p>
                                                    )
                                                }}
                                                min={18}
                                                max={60}
                                                // value={sliderValueMyAge}
                                                // onChange={(e, value) => return_calculator(value)}
                                                onChangeCommitted={(e, value) => {
                                                    // console.log("Slider value after release:", value);
                                                    // sliderAmountChangeIntheReturnCalculator(value)
                                                    setSliderValueMyAge(value)
                                                    // Add any other logic you want to perform after the slider is released
                                                }}
                                            />

                                        </Box>
                                        <Box px={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography
                                                variant="body2"
                                            //   onClick={() => setVal(MIN)}
                                            //   sx={{ cursor: 'pointer' }}
                                            >
                                                {/* <span className={classes.rupees}>₹</span> */}
                                                18Y
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                            //   onClick={() => setVal(MAX)}
                                            //   sx={{ cursor: 'pointer' }}
                                            >
                                                {/* <span className={classes.rupees}>₹</span> */}
                                                60Y
                                            </Typography>
                                        </Box>

                                    </Grid>
                                    <Grid item xs={12} md={6} style={{}}>
                                        < Typography className={classes.text1} style={{ marginTop: '20px' }}>I wnat to retire at the age of </Typography>

                                        <Box mt={3} px={3} className={classes.borderBox}>
                                            <Slider
                                                classes={{
                                                    thumb: classes.thumb,
                                                    rail: classes.rail,
                                                    track: classes.track,
                                                    valueLabel: classes.valueLabel,
                                                    mark: classes.mark
                                                }}
                                                defaultValue={sliderRetireAtTheAge || 18}
                                                aria-label="Default"
                                                valueLabelDisplay="on"
                                                size="medium"
                                                valueLabelFormat={(x) => {
                                                    return (
                                                        <p style={{ paddingLeft: '10px', color: "black" }}><span className={classes.rupees}></span>{x.toLocaleString('en-IN')}Y</p>
                                                    )
                                                }}
                                                min={18}
                                                max={80}
                                                // value={sliderRetireAtTheAge}
                                                // onChange={(e, value) => return_calculator(value)}
                                                onChangeCommitted={(e, value) => {
                                                    // console.log("Slider value after release:", value);
                                                    // sliderAmountChangeIntheReturnCalculator(value)
                                                    setSliderRetireAtTheAge(value)
                                                    // Add any other logic you want to perform after the slider is released
                                                }}
                                            />

                                        </Box>
                                        <Box px={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography
                                                variant="body2"
                                            //   onClick={() => setVal(MIN)}
                                            //   sx={{ cursor: 'pointer' }}
                                            >
                                                {/* <span className={classes.rupees}>₹</span> */}
                                                18Y
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                            //   onClick={() => setVal(MAX)}
                                            //   sx={{ cursor: 'pointer' }}
                                            >
                                                {/* <span className={classes.rupees}>₹</span> */}
                                                80Y
                                            </Typography>
                                        </Box>

                                    </Grid>
                                    <Grid item xs={12} md={6} style={{}}>

                                        < Typography className={classes.text1} style={{ marginTop: '20px' }}>Life expectancy age</Typography>

                                        <Box mt={3} px={3} className={classes.borderBox}>
                                            <Slider
                                                classes={{
                                                    thumb: classes.thumb,
                                                    rail: classes.rail,
                                                    track: classes.track,
                                                    valueLabel: classes.valueLabel,
                                                    mark: classes.mark
                                                }}
                                                defaultValue={lifeExpectancyAge || 18}
                                                aria-label="Default"
                                                valueLabelDisplay="on"
                                                size="medium"
                                                valueLabelFormat={(x) => {
                                                    return (
                                                        <p style={{ paddingLeft: '10px', color: "black" }}><span className={classes.rupees}></span>{x.toLocaleString('en-IN')}Y</p>
                                                    )
                                                }}
                                                min={18}
                                                max={100}
                                                // value={lifeExpectancyAge}
                                                // onChange={(e, value) => return_calculator(value)}
                                                onChangeCommitted={(e, value) => {
                                                    // console.log("Slider value after release:", value);
                                                    // sliderAmountChangeIntheReturnCalculator(value)
                                                    setLifeExpectancyAge(value)
                                                    // Add any other logic you want to perform after the slider is released
                                                }}
                                            />

                                        </Box>
                                        <Box px={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography
                                                variant="body2"
                                            //   onClick={() => setVal(MIN)}
                                            //   sx={{ cursor: 'pointer' }}
                                            >
                                                {/* <span className={classes.rupees}>₹</span> */}
                                                18Y
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                            //   onClick={() => setVal(MAX)}
                                            //   sx={{ cursor: 'pointer' }}
                                            >
                                                {/* <span className={classes.rupees}>₹</span> */}
                                                100Y
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6} style={{}}>
                                        <Box marginTop={3}>
                                            {/* <label htmlFor="freshInvestment">Fresh Investment</label> */}
                                            <label className={classes.label}>Existing investment(FD, Mutual FUnds and Others)</label>

                                            <input
                                                className={classes.inputBox}
                                                type="text"
                                                placeholder="Expected investment"
                                                // maxLength={3}
                                                // onChange={handleTelChange}
                                                onChange={(event) => handleInputChangeValue(event, 'existingInvestment')}
                                                value={existingInvestment}
                                                onClick={(e) => !existingInvestment ? setErrorsInputExistingInvestment('Please enter existing investment') : ''}
                                            />
                                            <span className={classes.rupeesSymbol} style={{ marginRight: '5px', }}>₹</span>

                                            {errorsInputExistingInvestment ? (
                                                <ErrorMessage message={errorsInputExistingInvestment} />
                                            ) : (
                                                <Box height={20}></Box>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6} style={{}}>
                                        <Box marginTop={3}>
                                            {/* <label htmlFor="freshInvestment">Fresh Investment</label> */}
                                            <label className={classes.label}>Expected rate of return in existing Investments (%)</label>

                                            <input
                                                className={classes.inputBox}
                                                type="text"
                                                placeholder="Expected investment"
                                                maxLength={3}
                                                // onChange={handleTelChange}
                                                onChange={(event) => handleInputChangePercentage(event, 'returnExistingInvestment')}
                                                value={returnExistingInvestment}
                                                onClick={(e) => !returnExistingInvestment ? setErrorsInputReturnExistingInvestment('Please enter return existing investment') : ''}
                                            />
                                            <span className={classes.percentageSymbol} style={{ marginRight: '5px', }}>%</span>

                                            {errorsInputReturnExistingInvestment ? (
                                                <ErrorMessage message={errorsInputReturnExistingInvestment} />
                                            ) : (
                                                <Box height={20}></Box>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6} style={{}}>
                                        <Box marginTop={3}>
                                            {/* <label htmlFor="freshInvestment">Fresh Investment</label> */}
                                            <label className={classes.label}>Expected inflation rate (%)</label>

                                            <input
                                                className={classes.inputBox}
                                                type="text"
                                                placeholder="Expected investment"
                                                maxLength={3}
                                                // onChange={handleTelChange}
                                                // onChange={handleInputChange}
                                                onChange={(event) => handleInputChangePercentage(event, 'expectedInflationRate')}
                                                value={expectedInflationRate}
                                                onClick={(e) => !expectedInflationRate ? setErrorsInputExpectedInflationRate('Please enter expected inflation rate') : ''}
                                            />
                                            <span className={classes.percentageSymbol} style={{ marginRight: '5px', }}>%</span>

                                            {errorsInputExpectedInflationRate ? (
                                                <ErrorMessage message={errorsInputExpectedInflationRate} />
                                            ) : (
                                                <Box height={20}></Box>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6} style={{}}>
                                        <Box marginTop={3}>
                                            {/* <label htmlFor="freshInvestment">Fresh Investment</label> */}
                                            <label className={classes.label}>Expected Rate of Return till Retirement (%)</label>

                                            <input
                                                className={classes.inputBox}
                                                type="text"
                                                placeholder="Expected investment"
                                                maxLength={3}
                                                // onChange={handleTelChange}
                                                // onChange={handleInputChange}
                                                onChange={(event) => handleInputChangePercentage(event, 'expectedReturnTillRetirement')}
                                                value={expectedReturnTillRetirement}
                                                onClick={(e) => !expectedReturnTillRetirement ? setErrorsInputExpectedReturnTillRetirement('Please enter expected return till retirement') : ''}
                                            />
                                            <span className={classes.percentageSymbol} style={{ marginRight: '5px', }}>%</span>

                                            {errorsInputExpectedReturnTillRetirement ? (
                                                <ErrorMessage message={errorsInputExpectedReturnTillRetirement} />
                                            ) : (
                                                <Box height={20}></Box>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6} style={{}}>
                                        <Box marginTop={3}>
                                            {/* <label htmlFor="freshInvestment">Fresh Investment</label> */}
                                            <label className={classes.label}>Projected monthly expenses at retirement age?</label>

                                            <input
                                                className={classes.inputBox}
                                                type="text"
                                                placeholder="Expected investment"
                                                // maxLength={3}
                                                // onChange={handleTelChange}
                                                onChange={(event) => handleInputChangeValue(event, 'monthlyExpensesAtRetirementAge')}
                                                disabled
                                                value={monthlyExpensesAtRetirementAge}
                                                onClick={(e) => !monthlyExpensesAtRetirementAge ? setErrorsInputMonthlyExpensesAtRetirementAge('Please enter monthly expenses at retirement age') : ''}
                                            />
                                            <span className={classes.rupeesSymbol} style={{ marginRight: '5px', }}>₹</span>

                                            {errorsInputMonthlyExpensesAtRetirementAge ? (
                                                <>
                                                    <ErrorMessage message={errorsInputMonthlyExpensesAtRetirementAge} />
                                                    < Typography className={classes.text11} style={{ marginTop: '5px', textAlign: 'start' }}>It is automatically generated based on your information, but you can adjust it to match your expectations.</Typography>
                                                </>
                                            ) : (
                                                <Box height={20}>
                                                    < Typography className={classes.text11} style={{ marginTop: '5px', textAlign: 'start' }}>It is automatically generated based on your information, but you can adjust it to match your expectations.</Typography>
                                                </Box>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                            {error && <Box textAlign={'center'}>
                                <ErrorMessage message={error} />
                            </Box>}
                            <Box mt={3} display='flex' justifyContent='center' alignItems='center'>
                                <button onClick={CalculateRetirementPlan} className={classes.primaryButton}
                                    disabled={loading}

                                >
                                    {loading ? (
                                        <CircularProgress size="1rem" color="inherit" />
                                    ) : (
                                        "Calculate Now"
                                    )}</button>
                            </Box>
                        </>
                    ) : (
                        <>
                            {showRecommendedFundsPage ? (
                                <ShowRecommendedFunds />

                            ) : (




                                <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                                    < Typography className={classes.text2} style={{ marginTop: '5px' }}>Total corpus you need for retirement</Typography>

                                    < Typography className={classes.text3} style={{ marginTop: '10px', marginBottom: '10px' }}> <span className={classes.rupees}>₹</span>
                                        {/* {(<ScrollingNumber value={calculatedRetirement?.total_corpus?.toFixed(2)} time={20} />)} */}
                                        <CountUp
                                            end={calculatedRetirement?.total_corpus ? formatAmountNumber(calculatedRetirement?.total_corpus) : 0}
                                            duration={2}
                                            decimals={2} decimal="."
                                            formattingFn={(number) => { return number.toLocaleString('en-IN') }}
                                        />
                                        {formatAmountNumberSign(calculatedRetirement?.total_corpus)}
                                    </Typography>
                                    <Box style={{ textAlign: 'center', marginTop: '5px' }} className={classes.gridBoxDip} >
                                        < Typography className={classes.text1} > Monthly </Typography>
                                    </Box>
                                    <Box style={{ textAlign: 'center', marginTop: '5px' }} className={classes.gridBox} >
                                        < Typography className={classes.text1} style={{ marginTop: '5px' }}> You can achive in </Typography>

                                        < Typography className={classes.text3} style={{ marginTop: '5px' }}><span className={classes.rupees}>₹</span>
                                            {/* {(calculatedRetirement?.monthly_investment)?.toFixed(2)} */}
                                            <CountUp
                                                end={calculatedRetirement?.monthly_investment ? formatAmountNumber(calculatedRetirement?.monthly_investment) : 0}
                                                duration={2}
                                                // decimals={2} decimal="."
                                                formattingFn={(number) => { return number.toLocaleString('en-IN') }}
                                            />
                                            {formatAmountNumberSign(calculatedRetirement?.monthly_investment)}
                                            /month  </Typography>

                                    </Box>
                                    < Typography className={classes.text1} style={{ marginTop: '5px', textAlign: 'start' }} > Your expencess will increase every year </Typography>

                                    <Box width={'100%'}>
                                        <Box mt={8} px={3} className={classes.borderBox}>
                                            <Slider
                                                classes={{
                                                    thumb: classes.thumb,
                                                    rail: classes.rail,
                                                    track: classes.track,
                                                    valueLabel: classes.valueLabel,
                                                    mark: classes.mark
                                                }}
                                                defaultValue={0}
                                                aria-label="Default"
                                                valueLabelDisplay="on"
                                                size="medium"
                                                valueLabelFormat={(x) => {
                                                    return (
                                                        <p style={{ paddingLeft: '10px', color: "black" }}><span className={classes.rupees}></span>{x.toLocaleString('en-IN')}%</p>
                                                    )
                                                }}
                                                min={0}
                                                max={12}
                                                // onChange={(e, value) => return_calculator(value)}
                                                onChangeCommitted={(e, value) => {
                                                    // console.log("Slider value after release:", value);
                                                    // sliderAmountChangeIntheReturnCalculator(value)
                                                    setExpencsesEveryYear(value)
                                                    // Add any other logic you want to perform after the slider is released
                                                }}
                                            />

                                        </Box>
                                        <Box px={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography
                                                variant="body2"
                                            //   onClick={() => setVal(MIN)}
                                            //   sx={{ cursor: 'pointer' }}
                                            >
                                                {/* <span className={classes.rupees}>₹</span> */}
                                                0%
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                            //   onClick={() => setVal(MAX)}
                                            //   sx={{ cursor: 'pointer' }}
                                            >
                                                {/* <span className={classes.rupees}>₹</span> */}
                                                12%
                                            </Typography>
                                        </Box>
                                    </Box>

                                    {/* <Box display='flex' justifyContent='center' alignItems='center' style={{ width: '100%', }} > */}
                                    <Box style={{ overflowX: 'auto', overflowY: 'hidden', maxWidth: '100%' }}>
                                        <Box style={{ width: '1000px' }}>
                                            <ResponsiveContainer width={'100%'} height={270} >

                                                <BarChart
                                                    // barGap={25}
                                                    //  width={900} height={250} 
                                                    data={calculatedRetirement?.investment_graph}
                                                    margin={{ top: 50, right: 30, left: 20, bottom: 5 }}
                                                    // style={{overflow: 'scroll'}}
                                                    // padding={{ top: 10, right: 10, left: 10, bottom: 10 }}
                                                    barCategoryGap="30%" // Adjust space between categories
                                                    barGap={10} // Adjust space between bars within the same category
                                                >
                                                    {/* <XAxis dataKey="year" xAxisId={0} allowDuplicatedCategory={false}
                                                    //yAxisId="left"
                                                    // dataKey="baseValue"
                                                    // tick={false}
                                                    tick={{ fontSize: 12 }}  // Set the font size for X-axis ticks
                                                    label={{ fontSize: 14 }}
                                                    axisLine={false} />
                                                <XAxis dataKey="year" xAxisId={1} hide allowDuplicatedCategory={false}
                                                    //yAxisId="left"
                                                    // dataKey="baseValue"
                                                    label={false}
                                                    tick={false}
                                                    // tick={null}  // Remove X-axis ticks
                                                    // label={null} // Remove X-axis label
                                                    // scale="auto"
                                                    axisLine={false}
                                                />
                                                <YAxis allowDuplicatedCategory={false}
                                                    //yAxisId="left"
                                                    // dataKey="baseValue"
                                                    label={false}
                                                    tick={false}
                                                    // tick={null}  // Remove X-axis ticks
                                                    // label={null} // Remove X-axis label
                                                    // scale="auto"
                                                    hide
                                                    axisLine={false}
                                                /> */}
                                                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                                    {/* <Tooltip /> */}
                                                    {/* <Legend /> */}

                                                    {/* <Bar dataKey="total" barSize={30} xAxisId={1} fill="#0E76BC" fillOpacity={1}>
                                                    <LabelList dataKey="total" position="top" fill='#0E76BC' content={<CustomBarLabel fill="#ff0000" />} offset={10} />
                                                </Bar>

                                                <Bar dataKey="invested_amount" barSize={30} xAxisId={0} fill="#ccc" /> */}
                                                    <XAxis
                                                        // dataKey="year"
                                                        // tick={{ fontSize: 12 }}
                                                        // axisLine={false}
                                                        // hide
                                                        dataKey="year" xAxisId={0} allowDuplicatedCategory={false}
                                                        //yAxisId="left"
                                                        // dataKey="baseValue"
                                                        // tick={false}
                                                        tick={{ fontSize: 12 }}  // Set the font size for X-axis ticks
                                                        label={{ fontSize: 14 }}
                                                        axisLine={false}
                                                    />

                                                    <YAxis axisLine={false} tick={false} hide />
                                                    {/* <Tooltip /> */}

                                                    {/* First Bar */}
                                                    <Bar
                                                        dataKey="invested_amount"
                                                        barSize={20}

                                                        fill="#ccc"
                                                        stackId="a" // Use a different stack ID for side-by-side bars
                                                        radius={[5, 5, 5, 5]} // Top corners are rounded
                                                    >
                                                        {/* <LabelList
                      dataKey="invested_amount"
                      position="top"
                      content={<CustomBarLabel fill="#ccc" />}
                    /> */}
                                                    </Bar>

                                                    {/* Second Bar */}

                                                    <Bar
                                                        dataKey="total"
                                                        barSize={20}
                                                        fill="#0E76BC"
                                                        stackId="b" // Use the same stack ID to group within the same category

                                                        radius={[5, 5, 5, 5]} // Top corners are rounded
                                                    >
                                                        <LabelList
                                                            dataKey="total"
                                                            position="top"
                                                            content={<CustomBarLabel fill="#000" />}
                                                        />
                                                    </Bar>

                                                </BarChart>
                                            </ResponsiveContainer>
                                        </Box>
                                    </Box>

                                    <Box mt={4} display='flex' justifyContent='center' alignItems='center'>
                                        <button onClick={SeeRecommendedFunds} className={classes.primaryButton}
                                            disabled={loading}
                                        >
                                            {loading ? (
                                                <CircularProgress size="1rem" color="inherit" />
                                            ) : (
                                                "See Recommended Funds"
                                            )}</button>
                                    </Box>
                                </Box>
                            )}
                        </>
                    )}

                </div>
            </Box>
        </>
    );
};

export default RetirementPlan;
