import { Box, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useStyles } from "../styles";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function DatePickerField({
  label = "default label",
  name,
  // placeholder = "Please enter the field",
  placeholder,
  handleChange,
  dateValue,
  maximumDate,
  minimumDate,
  Controller,
  defaultDate,
  control,
  
}) {
  const classes = useStyles();

  const ref = React.useRef();
  
  

  const viewCalendar = () => {
    ref.current.openCalendar();
  };

  

  return (
    <React.Fragment>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="flex-start"
        flexDirection="column"
      >
        <label className={classes.label}>{label}</label>
        <Box
          className={classes.DateBox}
          display="flex"
          alignItems={"center"}
          justifyContent="space-between"
          flexDirection={"row"}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              
              value={dateValue}
              inputFormat="dd/MM/yyyy"
              allowSameDateSelection={true}
              InputProps={{
                disableUnderline: true,
                style:{fontSize:"0.8rem",width:"17rem"}
               }}
              onChange={(newValue) => {
                handleChange(newValue);
              }}
              renderInput={(params) => <TextField {...params}   placeholder={placeholder} sx={{border:"none",fontSize:"0.5rem"}} onKeyDown={e => e.preventDefault()}/>}
              style={{ width: 250 }}
              maxDate={maximumDate }
              minDate={minimumDate}
              defaultCalendarMonth={defaultDate}
              
              
            />
          </LocalizationProvider>
          {/* <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <DatePicker
            onChange={onChange} value={date}
            className={classes.calendar}
            calendarIcon={null}
            clearIcon={null}
            format={"dd/MM/yyyy"}
            style={{border:"none"}}
          />
          )}
          /> */}

          {/* <img
            src="/images/date.svg"
            height={20}
            width={20}
            onClick={() => viewCalendar()}
          /> */}
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default DatePickerField;
