import React, { useEffect, useState } from "react";
import TitleLayoult from "../TitleLayoult";
import {
  Box,
  MenuItem,
  Select,
  Typography,
  FormControl,
  Checkbox,
  FormControlLabel,
  createTheme,
  ThemeProvider,
  CircularProgress,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TextInputField from "../Input/TextInputField";
import { useStyles } from "../styles";
import { useForm, Controller } from "react-hook-form";
import ErrorMessage from "../../Validation/ErrorMessage";
import {
  GET_ALL_STATES,
  ADD_ADDRESS,
  KYC_STATUS,
  GET_PERSONAL_DETAILS,
  GET_ADDRESS,
  GET_PINCODE_BASED_API,
} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";
import { namespace } from "../../../environment";
import StepRedirector from "../Common/StepRedirector";
import StatusApiData from "../Common/StatusApiData";
import CustomToast from "../../UI/CustomToast";

function Step7({ }) {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const steps = searchParams.get("stepcount");

  const defaultValues = {
    AddressLine1: "",
    AddressLine2: "",
    city: "",
    pincode: "",
    permanentstate: "",
    currentAddressLine1: "",
    currentAddressLine2: "",
    currentCity: "",
    currentPincode: "",
    currentState: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({ defaultValues });

  const theme = createTheme({
    overrides: {
      MuiInputBase: {
        input: {
          padding: "8px 10px",
        },
      },
      MuiSelect: {
        select: {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
  });

  const navigate = useNavigate();


  // //List of Indian States

  const [allStates, setAllStates] = useState([
    { "id": 1, "type": "Andhra Pradesh" },
    { "id": 2, "type": "Arunachal Pradesh" },
    { "id": 3, "type": "Assam" },
    { "id": 4, "type": "Bihar" },
    { "id": 5, "type": "Chhattisgarh" },
    { "id": 6, "type": "Goa" },
    { "id": 7, "type": "Gujarat" },
    { "id": 8, "type": "Haryana" },
    { "id": 9, "type": "Himachal Pradesh" },
    { "id": 10, "type": "Jharkhand" },
    { "id": 11, "type": "Karnataka" },
    { "id": 12, "type": "Kerala" },
    { "id": 13, "type": "Madhya Pradesh" },
    { "id": 14, "type": "Maharashtra" },
    { "id": 15, "type": "Manipur" },
    { "id": 16, "type": "Meghalaya" },
    { "id": 17, "type": "Mizoram" },
    { "id": 18, "type": "Nagaland" },
    { "id": 19, "type": "Odisha" },
    { "id": 20, "type": "Punjab" },
    { "id": 21, "type": "Rajasthan" },
    { "id": 22, "type": "Sikkim" },
    { "id": 23, "type": "Tamil Nadu" },
    { "id": 24, "type": "Telangana" },
    { "id": 25, "type": "Tripura" },
    { "id": 26, "type": "Uttar Pradesh" },
    { "id": 27, "type": "Uttarakhand" },
    { "id": 28, "type": "West Bengal" }
  ])



  //List of Indian major Cities
  const [majorCities, setMajorCities] = useState(
    [
      { "id": 1, "type": "Kolkata" },
      { "id": 2, "type": "Howrah" },
      { "id": 3, "type": "Durgapur" },
      { "id": 4, "type": "Asansol" },
      { "id": 5, "type": "Siliguri" },
      { "id": 6, "type": "Maheshtala" },
      { "id": 7, "type": "Rajpur Sonarpur" },
      { "id": 8, "type": "South Dum Dum" },
      { "id": 9, "type": "Rajarhat Gopalpur" },
      { "id": 10, "type": "Bhatpara" },
      { "id": 11, "type": "Panihati" },
      { "id": 12, "type": "Kamarhati" },
      { "id": 13, "type": "Barddhaman" },
      { "id": 14, "type": "Kulti" },
      { "id": 15, "type": "Bally" },
      { "id": 16, "type": "Barasat" },
      { "id": 17, "type": "North Dum Dum" },
      { "id": 18, "type": "Baranagar" },
      { "id": 19, "type": "Uluberia" },
      { "id": 20, "type": "Naihati" },
      { "id": 21, "type": "Bidhan Nagar" },
      { "id": 22, "type": "Kharagpur" },
      { "id": 23, "type": "English Bazar" },
      { "id": 24, "type": "Haldia" },
      { "id": 25, "type": "Madhyamgram" },
      { "id": 26, "type": "Baharampur" },
      { "id": 27, "type": "Raiganj" },
      { "id": 28, "type": "Serampore" },
      { "id": 29, "type": "Hugli and Chinsurah" },
      { "id": 30, "type": "Medinipur" },
      { "id": 31, "type": "Chandannagar" },
      { "id": 32, "type": "Uttarpara Kotrung" },
      { "id": 33, "type": "Krishnanagar" },
      { "id": 34, "type": "Barrackpur" },
      { "id": 35, "type": "Santipur" },
      { "id": 36, "type": "Balurghat" },
      { "id": 37, "type": "Habra" },
      { "id": 38, "type": "Jamuria" },
      { "id": 39, "type": "Bankura" },
      { "id": 40, "type": "North Barrackpur" },
      { "id": 41, "type": "Raniganj" },
      { "id": 42, "type": "Nabadwip" },
      { "id": 43, "type": "Basirhat" },
      { "id": 44, "type": "Halisahar" },
      { "id": 45, "type": "Rishra" },
      { "id": 46, "type": "Ashokenagar Kalyangarh" },
      { "id": 47, "type": "Baidyabati" },
      { "id": 48, "type": "Puruliya" },
      { "id": 49, "type": "Kanchrapara" },
      { "id": 50, "type": "Dabgram" },
      { "id": 51, "type": "Darjiling" },
      { "id": 52, "type": "Titagarh" },
      { "id": 53, "type": "Dum Dum" },
      { "id": 54, "type": "Bally Town" },
      { "id": 55, "type": "Champdani" },
      { "id": 56, "type": "Bongaon" },
      { "id": 57, "type": "Khardaha" },
      { "id": 58, "type": "Jalpaiguri" },
      { "id": 59, "type": "Bansberia" },
      { "id": 60, "type": "Bhadreswar" },
      { "id": 61, "type": "Kalyani" }
    ])




  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [addressData, setAddressData] = useState()

  const [sameAsPermanent, setSameAsPermanent] = useState(true);
  const [error, setError] = useState();
  const [isComplient, setIsComplient] = useState(false);
  function convertToCustomFormat(inputString) {
    // Split the input string by space
    const words = inputString.split(' ');

    // Capitalize the first letter of each word
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

    // Join the words back together with a space
    const result = capitalizedWords.join(' ');
    console.log('result')

    return result;
  }
  const resetData = (data) => {
    // console.log(data)
    // console.log(data?.state.charAt(0).toUpperCase() + data?.state.slice(1))
    // console.log(convertToCustomFormat(data?.city))
    reset({
      AddressLine1: data?.line_1,
      // AddressLine2: data.permAddressLine2,
      city: convertToCustomFormat(data?.city),
      pincode: data?.pincode,
      permanentstate: data?.state ? data?.state.charAt(0).toUpperCase() + data?.state.slice(1) : data?.state_name.charAt(0) + data?.state_name.slice(1).toLowerCase(),
      // currentAddressLine1: data.currAddressLine1,
      // currentAddressLine2: data.currAddressLine2,
      // currentCity: data.currCity,
      // currentPincode: data.currPincode,
      // currentState: data.state_name,
    });
    // setSameAsPermanent(data.permSameAsCurr);
  };

  useEffect(async () => {
    const httpConfig = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const httpConfig1 = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // const [stateResponse, status] = await Promise.all([
      //   // httpInstance(`${GET_ALL_STATES}`, httpConfig),
      //   httpInstance(`${KYC_STATUS}`, httpConfig1),
      // ]);
      // const states = stateResponse?.data?.data.filter((ele) => {
      //   return ele.countryId === 101;
      // });
      // setAllStates(states);
      const status = await httpInstance(`${KYC_STATUS}`, httpConfig1);
      // console.log(status.data?.data?.is_kyc_compliant)
      setIsComplient(status.data?.data?.is_kyc_compliant);
      const deatils = await httpInstance(
        `${GET_ADDRESS}`,
        httpConfig1
      );
      // console.log(deatils.data);
      resetData(deatils.data?.data);
      // setIsComplient(deatils.data?.data?.is_kyc_compliant)
      const dataFinal = StepRedirector(status.data.data, steps) // secutiry redirection
      // console.log(dataFinal, steps)
      if (dataFinal) {
        navigate({
          pathname: `${namespace}/dashboard/mutualfund`,
          // search: `?${createSearchParams({ stepcount: "2" })}`,
        });
      }
      setAddressData(deatils.data?.data)
    } catch (error) {
      const complient = await StatusApiData()
      // setIsComplient(complient.data?.data?.isKycCompliant);
      setIsComplient(complient.data.data?.is_kyc_compliant)
      // console.log(complient?.data)
      const dataFinal = StepRedirector(complient.data.data, steps) // secutiry redirection
      if (dataFinal) {
        navigate({
          pathname: `${namespace}/dashboard/mutualfund`,
          // search: `?${createSearchParams({ stepcount: "2" })}`,
        });
      }

    }
  }, []);

  const handleCurrentAddress = (e) => {
    if (e.target.checked) {
      setSameAsPermanent(true);
    } else {
      setSameAsPermanent(false);
    }
  };



  const handlePincodeChange = async (e) => {
    // console.log('asdfasdf')
    const enteredPincode = e.target.value;
    if (enteredPincode.length === 6) {
      // Call the API to fetch city and state data when pin code has 5 digits
      const httpConfig1 = {
        method: "GET",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const pinCodeResponse = await httpInstance(
          `${GET_PINCODE_BASED_API}${enteredPincode}`,
          httpConfig1
        );
        // console.log('pinCodeResponse', pinCodeResponse);
        resetData(pinCodeResponse.data?.data);
      } catch (error) {
        CustomToast(error.response.data.error, "FAILED")
       }
    } else {
      // Clear city and state data if the pin code has less than 5 digits
      // setCityStateData(null);
    }
  }


  const submit_address = async (data) => {
    // console.log(data)
    setLoading(true)
    setError(false)
    setApiError(null)
    const formData = {
      // permAddressLine1: data.AddressLine1,
      // permAddressLine2: data.AddressLine2,
      // permCity: data.city,
      // permPincode: data.pincode,
      // permState: data.permanentstate,
      // permCountry: 101,
      // permSameAsCurr: sameAsPermanent,
      // currAddressLine1: data.currentAddressLine1,
      // currAddressLine2: data.currentAddressLine2,
      // currCity: data.currentCity,
      // currPincode: data.currentPincode,
      // currState: data.currentState,
      // currCountry: 101,
      // permAddressLine1: data.AddressLine1,
      // permAddressLine2: data.AddressLine2,
      // permCity: data.city,
      // permPincode: data.pincode,
      // permState: data.permanentstate,
      // permCountry: 101,
      // permSameAsCurr: sameAsPermanent,
      // currAddressLine1: data.currentAddressLine1,
      // currAddressLine2: data.currentAddressLine2,
      // currCity: data.currentCity,
      // currPincode: data.currentPincode,
      // currState: data.currentState,
      // currCountry: 101,

      user_id: localStorage.getItem("user_id"),
      pincode: data.pincode,
      line_1: data.AddressLine1.toLowerCase(),
      line_2: "--",
      line_3: "--,",
      city: data.city.toLowerCase(),
      state: data.permanentstate.toLowerCase()
    };

    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    try {
      await httpInstance(`${ADD_ADDRESS}`, httpConfig);
      // console.log(is)

      navigate({
        pathname: `${namespace}/onBoarding`,
        search: `?${createSearchParams(
          isComplient ? { stepcount: "7" } : { stepcount: "17" }
        )}`,
      });
      setLoading(false)
      CustomToast(addressData?.city ? "Updated Address Details" : "Added Address Detials", "SUCCESS")
    } catch (error) {
      if (error.response.data.statusCode === 400) {
        // setError(error.response.data.message);
        CustomToast(error.response.data.error, "FAILED")
        setApiError(error?.response?.data?.error)
        setError(true)
        setLoading(false)
      }
    }
  };

  const handleback = () => {
    navigate({
      pathname: `${namespace}/onBoarding`,
      search: `?${createSearchParams(
        // { stepcount: "4" }
        isComplient ? { stepcount: "5" } : { stepcount: "15" }
      )}`,
    });
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="flex-start"
        px={20}
        className={classes.backButton}
        onClick={handleback}
      >
        <img src="/images/backIcon.svg" />
      </Box>
      <Box display={"flex"} justifyContent="center">
        <Box display={"flex"} alignItems="flex-start" flexDirection={"column"}>
          <Box>
            <TitleLayoult
              title="Address Details"
              description="Please make sure this address matches with your POA"
            />
          </Box>
          <Box
            //display={"flex"}
            alignItems="flex-start"
            flexDirection={"column"}
            justifyContent="flex-start"
          >
            <form onSubmit={handleSubmit(submit_address)}>
              <h3>Permanent Address</h3>
              <Box my={1}>
                <TextInputField
                  name="AddressLine1"
                  label="Address"
                  placeholder="Address"
                  register={register}
                />
                {errors.AddressLine1 && (
                  <ErrorMessage message="Please Enter Address" />
                )}
              </Box>
              {/* <Box my={1}>
                <TextInputField
                  name="AddressLine2"
                  label="Address Line 2"
                  placeholder=""
                  register={register}
                />
                {errors.AddressLine2 && (
                  <ErrorMessage message="Please Enter Address" />
                )}
              </Box> */}
              <Box my={1}>
                <TextInputField
                  name="pincode"
                  label="Pin Code"
                  placeholder="Pincode"
                  register={register}
                  handleChange={handlePincodeChange}
                  inputPattern={/^[0-9 ]+$/}
                />
                {errors.pincode && (
                  <ErrorMessage message="Please Enter Pin Code" />
                )}
              </Box>
              <Box my={1}>
                <TextInputField
                  name="city"
                  label="City"
                  placeholder="City"
                  register={register}
                  disable={true}
                />
                {errors.city && <ErrorMessage message="Please Enter City" />}
              </Box>
              <ThemeProvider theme={theme}>
                <Box
                  my={1}
                  display="flex"
                  alignItems={"flex-start"}
                  justifyContent="flex-start"
                  flexDirection="column"
                  width={"100%"}
                >
                  <label className={classes.label}>State</label>
                  <Box width={"100%"} className={classes.Select}>
                    <FormControl fullWidth className={classes.Select}>
                      <Controller
                        control={control}
                        name={`permanentstate`}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            disableUnderline
                            className={classes.selectItem}
                            IconComponent={KeyboardArrowDownIcon}
                            // disabled
                            readOnly
                            MenuProps={{
                              style: {
                                maxHeight: 250,
                                top: 5,
                                minWidth: 200,
                              },
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            {allStates &&
                              allStates?.map((ele) => {
                                return (
                                  <MenuItem
                                    key={ele.id}
                                    className={classes.menuItem}
                                    value={ele.type}
                                  >
                                    {ele.type}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Box>
                  {errors.permanentstate && (
                    <ErrorMessage message="Please Select State" />
                  )}
                </Box>
              </ThemeProvider>

              {/* <Box my={1}>
                <FormControlLabel
                  classes={{ label: classes.label }}
                  label="Current Address same as Permanent Address?"
                  control={
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={sameAsPermanent === true}
                      onChange={handleCurrentAddress}
                    />
                  }
                />
              </Box> */}
              {!sameAsPermanent && (
                <div>
                  <h3>Current Address</h3>
                  <Box my={1}>
                    <TextInputField
                      name="currentAddressLine1"
                      label="Address Line 1"
                      placeholder=""
                      register={register}
                    />
                    {errors.currentAddressLine1 && (
                      <ErrorMessage message="Please Enter Address" />
                    )}
                  </Box>
                  <Box my={1}>
                    <TextInputField
                      name="currentAddressLine2"
                      label="Address Line 2"
                      placeholder="Address"
                      register={register}
                    />
                    {errors.currentAddressLine2 && (
                      <ErrorMessage message="Please Enter Address" />
                    )}
                  </Box>
                  <Box my={1}>
                    <TextInputField
                      name="currentCity"
                      label="City"
                      placeholder="City"
                      register={register}
                    />
                    {errors.currentCity && (
                      <ErrorMessage message="Please Enter City" />
                    )}
                  </Box>
                  <ThemeProvider theme={theme}>
                    <Box
                      my={1}
                      display="flex"
                      alignItems={"flex-start"}
                      justifyContent="flex-start"
                      flexDirection="column"
                      width={"100%"}
                    >
                      <label className={classes.label}>State</label>
                      <Box width={"100%"} className={classes.Select}>
                        <FormControl fullWidth className={classes.Select}>
                          <Controller
                            control={control}
                            name={`currentState`}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                disableUnderline
                                className={classes.selectItem}
                                IconComponent={KeyboardArrowDownIcon}
                                MenuProps={{
                                  style: {
                                    maxHeight: 250,
                                    top: 5,
                                    minWidth: 200,
                                  },
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                }}
                                {...register("currentState", {
                                  required: true,
                                })}
                              >
                                {allStates &&
                                  allStates?.map((ele) => {
                                    return (
                                      <MenuItem
                                        key={ele.id}
                                        className={classes.menuItem}
                                        value={ele.name}
                                      >
                                        {ele.name}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Box>
                      {errors.currentState && (
                        <ErrorMessage message="Please Select State" />
                      )}
                    </Box>
                  </ThemeProvider>
                  <Box my={1}>
                    <TextInputField
                      name="currentPincode"
                      label="Pin Code"
                      placeholder="Pin Code"
                      register={register}
                      inputPattern={/^[0-9 ]+$/}
                    />
                    {errors.currentPincode && (
                      <ErrorMessage message="Please Enter Pin Code" />
                    )}
                  </Box>
                </div>
              )}
              {error && <ErrorMessage message={apiError || "Something wrong happened"} />}
              <Box mt={5}>
                <button className={classes.primaryButton} type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress size="1rem" color="inherit" />
                  ) : (
                    "Next"
                  )}
                </button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default Step7;
