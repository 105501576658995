import React, { useEffect, useState } from "react";
import TitleLayoult from "../TitleLayoult";
import { useDropzone } from "react-dropzone";
import { Box } from "@material-ui/core";
import FileUpload from "../uploader/FileUpload";
import { useStyles } from "../styles";
import ErrorMessage from "../../Validation/ErrorMessage";
import { UPLOAD_PAN, GET_UPLOADED_PAN, GET_KYC_DATA, IDENTITY_PROOF_DOCUMENT } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useNavigate, createSearchParams } from "react-router-dom";
import { namespace } from "../../../environment";
import CustomToast from "../../UI/CustomToast";

function UploadPan() {
  const navigate = useNavigate();

  const classes = useStyles();

  const [panUrl, setPanUrl] = useState("");
  const [nextbutton, setNextButton] = useState(false);
  const [loading, setLoading] = useState(false)
  const [acceptedFile, setAcceptedFile] = useState([])

  useEffect(async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // const response = await httpInstance(`${GET_KYC_DATA}`, httpConfig);
      const response = await httpInstance(`${GET_UPLOADED_PAN}`, httpConfig);
      // setPanUrl(response.data?.data?.userKycFilesData?.panPublicUrl);
      setPanUrl(response.data?.data?.front_document_path)
    } catch { }
  }, []);



  const submit_signature = async (acceptedFile, handleNext) => {
    setAcceptedFile(acceptedFile)
    // console.log(handleNext, acceptedFile)
    // setNextButton(true || );
  }
  // const [startPolling, setStartPolling] = useState(false); // Track when to start polling

  const submit_signatures = async (data) => {
    // console.log('asdfasdfa')
    setLoading(true)
    const httpConfig1 = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      // data: formData,
    };
    if (acceptedFile[0]) {
      // console.log(acceptedFile)
      // console.log(handleNext)
      const formData = new FormData();
      // formData.append("pan_file", acceptedFile[0]);
      formData.append("front_side_file", acceptedFile[0]);
      // formData.append("front_side_file", acceptedFile[0]);
      formData.append("user_id", localStorage.getItem("user_id"));
      formData.append("type", "proof_of_identity");
      formData.append("document_type", "pan")

      const httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };
      const httpConfig1 = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        // data: formData,
      };
      try {
        await httpInstance(`${UPLOAD_PAN}`, httpConfig);
        setNextButton(true);
        handleNext();
        // navigate({
        //   pathname: `${namespace}/onBoarding`,
        //   search: `?${createSearchParams({stepcount:"6"})}`,
        // });
        CustomToast('Upload Proof is uploaded', "SUCCESS")
        setLoading(false)
        try {
          const response = await httpInstance(`${IDENTITY_PROOF_DOCUMENT}`, httpConfig1);
          const url = response?.data?.data?.fetch?.redirect_url;
          if (response?.data?.data?.fetch?.status === "successful" && !url){
            // setNextButton(true);
            handleNext();

          } else  {
            // Open the link in a new tab
            const newTab = window.open(url, '_blank');

            // Check if the user has completed the action in the new tab
            const interval = setInterval(() => {
              // Assuming the API or URL change or signal indicates completion
              if (newTab.closed) {
                clearInterval(interval);
                // Navigate to /mutualfund
                navigate('/mutualfund');
              }
            }, 1000);
          }

        } catch (error) {

        }

      } catch (error) {
        CustomToast(error.response.data.error || 'Somthing went wrong', "FAILED")
        setLoading(false)
      }
    } else {
      try {
        const response = await httpInstance(`${IDENTITY_PROOF_DOCUMENT}`, httpConfig1);
        const url = response?.data?.data?.fetch?.redirect_url;

        if (response?.data?.data?.fetch?.status === "successful" && !url){
          // setNextButton(true);
          handleNext();

        } else  {
          // Open the link in a new tab
          const newTab = window.open(url, '_blank');
          // setStartPolling(true); // Start polling after opening the new tab

          // const newTabEnd = window.screen('https://api.maxwealth.money/api/onboarding/identity_postback/mfwealth')
          // // console.log(newTab, newTabEnd)
          // // Check if the user has completed the action in the new tab
          // const interval = setInterval(() => {
          //   try {
          //     // Check if the new tab's URL includes "&status=failed"
          //     if (newTabEnd?.location.href.includes("&status=failed")) {
          //       newTab.close(); // Close the tab
          //       clearInterval(interval); // Clear the interval

          //       // Optionally, handle failure logic or show an error
          //       console.log("Failed status detected, tab closed.");
          //     } else if (newTab.closed) {
          //       // If the user completes the action and closes the tab
          //       clearInterval(interval);
          //       navigate('/mutualfund'); // Navigate to the mutual fund page
          //     }
          //   } catch (error) {
          //     // Catch any cross-origin errors if the URL is not from the same origin
          //     console.error("Cross-origin access denied, cannot check the tab URL.");
          //   }
          // }, 1000);
          const interval = setInterval(() => {
            // Assuming the API or URL change or signal indicates completion
            if (newTab.closed) {
              clearInterval(interval);
              // Navigate to /mutualfund
              // navigate('/mutualfund');
              handleNext();
            }
          }, 1000);
        }

      } catch (error) {

      }
      // handleNext();
      setLoading(false)

    }
  };

  // const [status, setStatus] = useState(null);


  // useEffect(() => {
  //   if (!startPolling) return; // Start polling only if handleIdentityProof is triggered

  //   const interval = setInterval(async () => {
  //     try {
  //       const statusResponse = await fetch('https://api.maxwealth.money/api/onboarding/identity_postback/mfwealth'); // Replace with your backend status endpoint
  //       const data = await statusResponse.json();
  //       if (data.status) {
  //         setStatus(data.status);
  //         clearInterval(interval); // Stop polling once we get a response
  //         setStartPolling(false); // Stop further polling
  //       }
  //     } catch (error) {
  //       console.error("Error fetching identity status:", error);
  //     }
  //   }, 1000);

  //   return () => clearInterval(interval); // Clean up on unmount
  // }, [startPolling]);

  // // Redirect based on status
  // useEffect(() => {
  //   if (status === 'failed') {
  //     navigate('/mutualfund');
  //   }
  // }, [status, navigate]);

  const handleNext = () => {
    navigate({
      pathname: `${namespace}/onBoarding`,
      // search: `?${createSearchParams({ stepcount: "6" })}`,
      search: `?${createSearchParams({ stepcount: "16" })}`,
    });
  };

  const handleback = () => {
    navigate({
      pathname: `${namespace}/onBoarding`,
      search: `?${createSearchParams({ stepcount: "14" })}`,
    });
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="flex-start"
        px={20}
        className={classes.backButton}
        onClick={handleback}
      >
        <img src="/images/backIcon.svg" />
      </Box>
      <Box display={"flex"} justifyContent="center">
        <Box display={"flex"} alignItems="flex-start" flexDirection={"column"}>
          <TitleLayoult
            title={"Upload Proof Of Identity"}
            description={"Please Upload PAN Card as Proof of Identity"}
            align={""}
          />
          <Box>
            <FileUpload
              handleSubmit={submit_signature}
              fileUrl={panUrl}
              handleNext={submit_signatures}
              nextbutton={nextbutton}
              validation={/\.(png|jpg)$/}
              type=".png/.jpg /.jpeg"
              loadings={loading}
            />
          </Box>
          {/* {uploadCount === 3 ? (
        <Box mt={3}>
          <button className={classes.primaryButton} >
            Next
          </button>
        </Box>
      ) : (
        ""
      )} */}
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default UploadPan;
