import { Box, CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import TitleLayoult from "../TitleLayoult";
import { useStyles } from "../styles";
import { useForm } from "react-hook-form";
import {
  ADHAR_VERIFICATION,
  CREAT_KYC,
  ESIGN_SUBMIT,
  GET_ADHAR_VERIFICATION,
  KYC_STATUS,
} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useNavigate, createSearchParams } from "react-router-dom";
import ErrorMessage from "../../Validation/ErrorMessage";
import { namespace } from "../../../environment";

function AadharVerification({ step }) {
  const classes = useStyles();

  const navigate = useNavigate();
  const defaultValues = {
    adhar: "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm(defaultValues);

  const [Lat, setLat] = useState("");
  const [Lng, setLng] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isComplient, setIsComplient] = useState(false)
  //---------------------get cords-----------------------------------------------------//

  const success = ({ coords }, data) => {
    setLat(coords.latitude);
    setLng(coords.longitude);
    handleAdharSubmit(data, coords.latitude, coords.longitude);
  };

  const onError = (error) => {
    alert("Unable to retrieve your location");
  };

  const getCords = (data) => {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported by your browser");
    } else {
      navigator.geolocation.getCurrentPosition(function (position) {
        success(position, data);
      }, onError);
    }
  };

  // const getCords=()=>{
  //   if (!navigator.geolocation) {
  //     alert('Geolocation is not supported by your browser');
  //   } else {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       setLat(position.coords.latitude);
  //       setLng(position.coords.longitude);
  //     }, () => {

  //     });
  //   }
  // }

  // useEffect(()=>{
  //   getCords()
  // },[navigator.geolocation.watchPosition(success, onError) ])

  const resetData = (data) => {
    console.log(data)
    reset({
      adhar: data?.aadhaar_number,
    });
  };


  useEffect(async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${KYC_STATUS}`, httpConfig);
      console.log(response)
      setIsComplient(response.data?.data?.is_kyc_compliant);
      const responseAdharData = await httpInstance(`${GET_ADHAR_VERIFICATION}`, httpConfig);
      console.log(responseAdharData)
      resetData(responseAdharData.data);

    } catch { }
  }, []);


  // Assume there's a function to check if KYC is complete
const isKYCComplete = () => {
  // Logic to check if KYC is complete
  // This might involve checking the query parameters in the URL or using a callback
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('status') === 'complete'; // Adjust based on your actual implementation
};

// // Check KYC status periodically
// const checkStatusInterval = setInterval(() => {
//   // console.log('adfadsfad')
//   if (isKYCComplete()) {
//     // KYC is complete; redirect to onboarding page
//     clearInterval(checkStatusInterval);

//     // Redirect to onboarding page
//     window.location.href = '/user-page'; // Replace with your actual URL
//   }
// }, 5000);



  const creat_kyc = async (lat, lng) => {
    const kycForm = {
      isFinalSubmit: true,
      latitude: lat,
      longitude: lng,
    };
    const httpConfig1 = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: kycForm,
    };

    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };
    console.log(httpConfig1)
    try {
      // await httpInstance(`${CREAT_KYC}`, httpConfig1);
      const response = await httpInstance(`${ESIGN_SUBMIT}`, httpConfig);
      console.log(response)

      const res = await window.location.replace(`${response.data?.data?.redirect_url}`);
      console.log(res)
      const checkStatusInterval = setInterval(async () => {
        try {
          const statusResponse = await httpInstance(`${response.data?.data?.redirect_url}`, httpConfig);
          if (statusResponse.data?.status === 'successful') {
            // The KYC process is complete; you can stop checking and resume your flow
            clearInterval(checkStatusInterval);
      
            // Now, you can continue with the next steps of your flow
            console.log('KYC process completed successfully.');
          }
        } catch (error) {
          // Handle errors during status check
          console.error(error);
        }
      }, 5000);
      // navigate({
      //   pathname: `${response.data?.data?.fpEsignRedirectUrl}`,
      // });
    } catch (error) {
      // console.log(error.response?.data)
      if (error.response?.data?.error) {
        setError(error.response?.data?.error);
        setLoading(false);
        if (error.response?.data?.error === "Please try again in a few seconds we are processing your file"){
          // window.location.href = '/dashboard/mutualfund';
          setTimeout(() => {
            navigate({
              pathname: `${namespace}/dashboard/mutualfund`,
              
            });
          }, 5000);
        }
      }
      //  else {

      //   const res = await window.location.replace(`https://s.finprim.com/v2/esigns/esign_a009ae9219554cac8cf65b51215c8fb5/simulate_esign?token=9985cb96-bc41-4cf1-91d7-2b782a7f09a2`);
      // console.log(res)
      // // const checkStatusInterval = setInterval(async () => {
      // //   try {
      // //     const statusResponse = await httpInstance(`https://s.finprim.com/v2/esigns/esign_a009ae9219554cac8cf65b51215c8fb5/simulate_esign?token=9985cb96-bc41-4cf1-91d7-2b782a7f09a2`, httpConfig);
      // //     if (statusResponse.data?.status === 'completed') {
      // //       // The KYC process is complete; you can stop checking and resume your flow
      // //       clearInterval(checkStatusInterval);
      
      // //       // Now, you can continue with the next steps of your flow
      // //       console.log('KYC process completed successfully.');
      // //     }
      // //   } catch (error) {
      // //     // Handle errors during status check
      // //     console.error(error);
      // //   }
      // // }, 5000);


      //   // creat_kyc();
      // }
    }
  };

  const handleAdharSubmit = async (data, lat, lng) => {
    setLoading(true);
    if (!lat || !lng) {
      alert("Unable to retrieve your location");
    } else {
      const httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: { aadhaar_number: data.adhar },
      };

      try {
        await httpInstance(`${ADHAR_VERIFICATION}`, httpConfig);
        creat_kyc(lat, lng);
        // navigate({
        //   pathname: '/signIn',
        //   search: `?${createSearchParams({stepcount:"14"})}`,
        // });
      } catch { 
        creat_kyc()
      }
    }
  };

  const handleback = () => {
    navigate({
      pathname: `${namespace}/onBoarding`,
      search: `?${createSearchParams({ stepcount: "17" })}`,
    });
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="flex-start"
        px={20}
        className={classes.backButton}
        onClick={handleback}
      >
        <img src="/images/backIcon.svg" />
      </Box>
      <Box display={"flex"} justifyContent="center">
        <Box display={"flex"} alignItems="flex-start" flexDirection={"column"}>
          <Box>
            <TitleLayoult
              title="Aadhar Verification"
              description="Please enter aadhar number for verification"
            />
          </Box>
          <form onSubmit={handleSubmit(getCords)}>
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="flex-start"
              flexDirection="column"
              width={"100%"}
            >
              <label className={classes.label}>
                Enter last 4 digits of your Adhar
              </label>
              <input
                type="text"
                className={classes.inputBox}
                {...register("adhar", {
                  required: true,
                  pattern: /^[0-9]+$/,
                  maxLength: 4,
                })}
              />
              <ErrorMessage message="Aadhar Verification will take some time." />
              {errors.adhar && errors.adhar.type === "required" && (
                <ErrorMessage message="Enter last 4 digits of Adhar" />
              )}
              {errors.adhar && errors.adhar.type === "pattern" && (
                <ErrorMessage message="Please enter valid digits" />
              )}
              {errors.adhar && errors.adhar.type === "maxLength" && (
                <ErrorMessage message="Enter last 4 digits of Adhar" />
              )}

              {error && <ErrorMessage message={error} />}
            </Box>
            <Box mt={4}>
              <button className={classes.primaryButton} type="submit">
                {loading ? (
                  <CircularProgress size="1rem" color="inherit" />
                ) : (
                  "Verify"
                )}
              </button>
            </Box>
          </form>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default AadharVerification;
