import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@material-ui/core/IconButton";
import CardData from "./card";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress, Box, Typography } from "@material-ui/core";
import FundSkeleton from "./CommonLoader/FundSkeleton";

const useStyles = makeStyles((theme) => ({
  searchBar: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    margin: "28px 0px 20px 0px",
    borderRadius: "10px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: 100,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
  },
  SearchBarMargin: {
    marginTop: "0rem",
    // marginLeft: "2rem",
  },
  text: {
    marginTop: '20px',
    fontSize: '.8rem',
    color: 'grey',
    textAlign: "center",
  },
  "@media (max-width: 600px)": {
    SearchBarMargin: {
      marginLeft: "0rem",
    },
  },
}));



export default function CustomizedInputBase({
  allFunds,
  get_allFunds,
  noMore,
  search_filter,
  filterData
}) {
  const classes = useStyles();

  const fetchData = () => {
    get_allFunds();
  };

  const handle_search = (e) => {
    // if (/^[A-Za-z\s]+$/.test(e.target.value)) {
    // Valid input, continue with the search logic
    // ...
    search_filter(e.target.value);

    // } else {
    //   // Invalid input, prevent the input from being entered
    //   e.preventDefault();
    // }
  };
  // console.log("noMore", noMore);

  const LoaderComp = () => {
    return (
      // <div
      //   style={{
      //     display: "flex",
      //     justifyContent: "center",
      //     marginTop: "2rem",
      //     overflow: "hidden",
      //   }}
      // >
      //   <CircularProgress size="2rem" />
      // </div>
      <>
        {allFunds.length <= 0 ?
          [1, 2, 3].map((key, index) => (
            <div key={key}>
              <FundSkeleton />
            </div>
          ))
          :
          [1].map((key, index) => (
            <div key={key}>
              <FundSkeleton />
            </div>
          ))
        }
      </>
    );
  };

  return (
    <div className={classes.SearchBarMargin}>
      {/* <Paper className={classes.searchBar} variant="outlined">
        <InputBase
          className={classes.input}
          placeholder="Search for Mutual Funds"
          inputProps={{ "aria-label": "Search for Mutual Funds" }}
          onChange={handle_search}
        />
        <IconButton
          type="button"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
      </Paper> */}
      {/* {filterData?.risk_profile || filterData?.ratings || filterData?.asset_sub_category}
      &#x27;{filterData?.risk_profile}&#x27;{' '}&#x27;{filterData?.amc_code}{' '}&#x27;{filterData?.ratings}&#x27;{' '}&#x27;{filterData?.asset_sub_category}&#x27; */}
      <div>
        <InfiniteScroll
          style={{
            overflow: "hidden"
          }}
          dataLength={allFunds?.length}
          next={fetchData}
          hasMore={noMore}
          loader={<LoaderComp />}
          endMessage={
            <Typography className={classes.text}>
              No More Funds to display
            </Typography>
          }
        >
          {allFunds?.map((fund) => {
            return <CardData key={fund.scheme_code} fund={fund} />;
          })}
        </InfiniteScroll>
      </div>
      {/* {allFunds?.map((fund)=>{
        return(
          <CardData
            key={fund.scheme_code}
            fund={fund}
          />
        )
      })} */}
    </div>
  );
}
