import {
  Box,
  Select,
  FormControl,
  MenuItem,
  CircularProgress,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useState, useEffect } from "react";
import TextInputField from "../Input/TextInputField";
import TitleLayoult from "../TitleLayoult";
import { useStyles } from "../styles";
import { useForm, Controller } from "react-hook-form";
import ErrorMessage from "../../Validation/ErrorMessage";
import {
  GET_DETAILS_BY_IFSC,
  GET_ACCOUNT_TYPES,
  ADD_BANK_DETAILS,
  GET_BANK_DETAILS,
  OCR_READING,
  GET_USER_ONBOARDING_STATUS,
  GET_USER_ONBOARDING_DETAILS
} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useNavigate, createSearchParams } from "react-router-dom";
import ReactLoading from "react-loading";
import { namespace } from "../../../environment";
import CustomToast from "../../UI/CustomToast";

function Step9({ step }) {
  const classes = useStyles();

  const theme = createTheme({
    overrides: {
      MuiInputBase: {
        input: {
          padding: "8px 10px",
          fontFamily: "inherit",
        },
      },
      MuiSelect: {
        select: {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({ defaultValues: { accountType: "" } });

  const navigate = useNavigate();

  const [ifscDetail, setIfscDetail] = useState({});
  const [IfscError, setIfscError] = useState(false);
  const [fillType, setFillType] = useState("manual");
  const [accountTypes, setAccountType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [typeAccount, setTypeAcount] = useState("");
  const [error, setError] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [isComplient, setIsComplient] = useState(false);
  const [bankData, setBankData] = useState('')

  const resetData = async (data) => {
    // console.log(data.full_name)
    if (data.ifsc_code) {
      await get_bank_data(data?.ifsc_code.toUpperCase());
    }
    console.log(data.full_name)
    reset({
      accountName: data.full_name ? data?.full_name.toUpperCase() : data?.account_holder_name.toUpperCase(),
      accountNumber: data?.account_number ? data?.account_number : '',
      // accountType: data?.accountType?.id,
      ifsc: data?.ifsc_code ? data?.ifsc_code.toUpperCase() : '',
    });
    // setTypeAcount(data.accountType.id);
  };

  useEffect(async () => {
    const httpConfig = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const httpConfig1 = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // const response = await httpInstance(`${GET_ACCOUNT_TYPES}`, httpConfig);
      // setAccountType(response.data.data);
      const status = await httpInstance(`${GET_USER_ONBOARDING_DETAILS}`, httpConfig1);
      setIsComplient(status.data.data.is_kyc_compliant);
      // const Kyc_status = await httpInstance(`${GET_USER_ONBOARDING_STATUS}`, httpConfig1);
      resetData(status.data?.data)
      console.log(status.data?.data)
    } catch {

    }
    try {
      const bankData = await httpInstance(`${GET_BANK_DETAILS}`, httpConfig1);
      resetData(bankData.data?.data);
      setBankData(bankData.data?.data)

    } catch {

    }
  }, []);
  //-----------------------------fill type-----------------------------------------------------------------------//
  const handleFillType = async (e) => {
    setFillType(e.target.value);
    if (e.target.value === "auto") {
      setSpinner(true);
      const httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const response = await httpInstance(`${OCR_READING}`, httpConfig);
        const data = response.data?.data;
        reset({
          accountNumber: data.accountNumber,
          ifsc: data.ifscCode,
          accountType: "",
          accountName: "",
        });
        get_bank_data(data.ifscCode);
        setSpinner(false);
      } catch { }
    } else {
      setSpinner(false);
      reset({
        accountNumber: "",
        ifsc: "",
        accountName: "",
        accountType: "",
        bankName: "",
        branchName: "",
        branchState: "",
        branchAddress: "",
      });
    }
  };

  //-----------------handleAccountType--------------------------------//
  const handleAccountType = (e) => {
    setTypeAcount(e.target.value);
  };
  //---------------------------set bank details by ifsc---------------------------------------------------------//

  const get_bank_data = async (ifsc) => {
    // console.log(ifsc)
    // const formData = {
    //   ifsc: ifsc,
    // };
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      // data: formData
      params: { ifsc: ifsc.toUpperCase() },
    };

    try {
      const response = await httpInstance(
        // `${GET_DETAILS_BY_IFSC}${ifsc}`,
        `${GET_DETAILS_BY_IFSC}`,

        httpConfig
      );
      // console.log(response)
      setIfscDetail(response.data?.data);

      reset({
        bankName: response.data?.data?.bank_name,
        branchName: response.data?.data?.branch_name,
        branchState: response.data?.data?.state,
        branchAddress: response.data?.data?.branch_address,
        accountType: typeAccount,
      });

      setIfscError(false);
    } catch (error) {
      setIfscError(true);
      CustomToast(error.response.data.error, "FAILED")
    }
  };

  const handleIfsc = async (e) => {
    if (e.target.value.length === 11) {
      get_bank_data(e.target.value);
    }
  };

  //-----------------------------------------------------------------------------------------------------------------------//

  const submit_bank_details = async (data) => {
    console.log(data)
    setError(false);
    if (!ifscDetail.bank_name) {
      setIfscError(true);
    } else {
      console.log(bankData.account_holder_name == data?.accountName?.toUpperCase() && bankData.account_number == data?.accountNumber && bankData.ifsc_code == data?.ifsc?.toUpperCase())
      if (bankData?.account_holder_name?.toUpperCase() == data?.accountName && bankData?.account_number == data?.accountNumber && bankData?.ifsc_code?.toUpperCase() == data?.ifsc) {
        navigate({
          pathname: `${namespace}/onBoarding`,
          search: `?${createSearchParams
            // ({ stepcount: "12" })}`,
            (isComplient ? { stepcount: "6" } : { stepcount: "13" }
            )}`,
        });
      } else {
        setLoading(true);
        setError(false);
        setApiError(null);
        // const formData = {
        //   accountHolderName: data.accountName.toUpperCase(),
        //   accountNumber: data.accountNumber,
        //   ifscCode: data.ifsc,
        //   bankName: ifscDetail.bank_name,
        //   branchName: ifscDetail.branch_name,
        //   accountType: parseInt(data.accountType),
        //   isPrimaryAccount: true,
        // };

        const formData = {
          user_id: localStorage.getItem("user_id"),
          account_holder_name: data.accountName.toUpperCase(),
          account_number: data.accountNumber,
          ifsc_code: data.ifsc.toUpperCase(),
          bank_name: ifscDetail.bank_name
        };
        const httpConfig = {
          method: "POST",
          tokenType: "user",
          headers: {
            "Content-Type": "application/json",
          },
          data: formData,
        };
        try {
          await httpInstance(`${ADD_BANK_DETAILS}`, httpConfig);
          navigate({
            pathname: `${namespace}/onBoarding`,
            search: `?${createSearchParams
              // ({ stepcount: "12" })}`,
              (isComplient ? { stepcount: "6" } : { stepcount: "13" }
              )}`,
          });
          setLoading(false)
          // CustomToast(fatcaData?.annual_income ? "Updated Occupation Details" : "Added Occupation Detials", "SUCCESS")
        } catch (error) {
          setLoading(false);
          CustomToast(error.response.data.error, "FAILED")
          setApiError(error?.response?.data?.error)
          setError(true)
          setLoading(false)
        }
      }

    }
    // navigate({
    //   pathname: `${namespace}/signIn`,
    //   search: `?${createSearchParams({ stepcount: "22" })}`,
    // });
  };

  const handleback = () => {
    navigate({
      pathname: `${namespace}/onBoarding`,
      // search: `?${createSearchParams({ stepcount: "9" })}`,
      search: `?${createSearchParams
        // ({ stepcount: "12" })}`,
        (isComplient ? { stepcount: "4" } : { stepcount: "10" }
        )}`,
    });
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="flex-start"
        px={20}
        className={classes.backButton}
        onClick={handleback}
      >
        <img src="/images/backIcon.svg" />
      </Box>
      <Box display={"flex"} justifyContent="center">
        <Box
          display={"flex"}
          alignItems="flex-start"
          flexDirection={"column"}
          width={"30%"}
        >
          <TitleLayoult
            title="Bank Details"
            description="Please add your Bank details"
          />
          <Box display="flex" flexDirection="column">
            {/* <Box
              display="flex"
              flexDirection="column"
              alignItems={"flex-start"}
              justifyContent="flex-start"
              width={"100%"}
            >
              <Box my={1}>
                <Box my={1} className={classes.label}>
                  How would you like to fill the details
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <input
                      type="radio"
                      height="16px"
                      width="16px"
                      style={{ height: "16px", width: "16px", margin: 0 }}
                      value="manual"
                      checked={fillType === "manual"}
                      onChange={handleFillType}
                    />
                    <Box fontSize={12} fontWeight={500} ml={1}>
                      Manual
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    ml={6}
                  >
                    <input
                      type="radio"
                      height="16px"
                      width="16px"
                      name="fillType"
                      style={{ height: "16px", width: "16px", margin: 0 }}
                      value="auto"
                      checked={fillType === "auto"}
                      onChange={handleFillType}
                    />
                    <Box fontSize={12} fontWeight={500} ml={1}>
                      Auto
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box> */}

            {spinner ? (
              <Box p={5} justifyContent="center" display="flex">
                {" "}
                <ReactLoading
                  type="spinningBubbles"
                  color="	#808080"
                  height={"25%"}
                  width={"25%"}
                />{" "}
              </Box>
            ) : (
              <div>
                <form onSubmit={handleSubmit(submit_bank_details)}>
                  <Box my={1}>
                    <TextInputField
                      name="accountName"
                      placeholder="Account Holder Name"
                      label="Account Holder Name"
                      register={register}
                      inputPattern={
                        /^(?=(?:^\w))([a-zA-Z](\s?)([a-zA-Z]\s?)+)*\s*$/
                      }
                    />
                    {errors.accountName && (
                      <ErrorMessage message="Enter Account Holder Name" />
                    )}
                  </Box>

                  <Box my={1}>
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      flexDirection="column"
                      width={"100%"}
                    >
                      <label className={classes.label}>Account Number</label>
                      <input
                        type="text"
                        className={classes.inputBox}
                        {...register("accountNumber", {
                          required: true,
                          pattern: /^(?=(?:^\w))([0-9]+)$/,
                          maxLength: 18,
                          minLength: 9,
                        })}
                        placeholder="Account Number"
                      />
                      {errors.accountNumber && (
                        <ErrorMessage message="Enter Account Number" />
                      )}
                    </Box>
                    {/* <ThemeProvider theme={theme}>
                      <Box
                        my={1}
                        display="flex"
                        alignItems={"flex-start"}
                        justifyContent="flex-start"
                        flexDirection="column"
                        width={"100%"}
                      >
                        <label className={classes.label}>Account Type</label>
                        <Box width={"100%"} className={classes.Select}>
                          <FormControl fullWidth className={classes.Select}>
                            <Controller
                              control={control}
                              name={`accountType`}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  disableUnderline
                                  className={classes.selectItem}
                                  IconComponent={KeyboardArrowDownIcon}
                                  MenuProps={{
                                    style: {
                                      maxHeight: 250,
                                    },
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                  }}
                                  {...register("accountType", {
                                    onChange: (e) => handleAccountType(e),
                                  })}
                                >
                                  {accountTypes &&
                                    accountTypes?.map((ele) => {
                                      return (
                                        <MenuItem
                                          key={ele.id}
                                          className={classes.menuItem}
                                          value={ele.id}
                                        >
                                          {ele.type}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              )}
                            />
                          </FormControl>
                        </Box>
                        {errors.accountType && (
                          <ErrorMessage message="Select Account Type" />
                        )}
                      </Box>
                    </ThemeProvider> */}
                  </Box>
                  <Box my={1}>
                    <TextInputField
                      name="ifsc"
                      placeholder="IFSC Code"
                      label="IFSC Code"
                      value=""
                      register={register}
                      handleChange={handleIfsc}
                    />
                    { }
                    {errors.ifsc ? (
                      <ErrorMessage message="Enter IFSC Code" />
                    ) : (
                      IfscError && <ErrorMessage message="Invalid IFSC Code" />
                    )}
                  </Box>
                  <Box my={1}>
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      flexDirection="column"
                      width={"100%"}
                    >
                      <label className={classes.label}>Bank Name</label>
                      <input
                        type="text"
                        className={classes.inputBox}
                        disabled
                        {...register("bankName")}
                        placeholder="Bank Name"
                      />
                    </Box>
                  </Box>
                  {/* <Box my={1}>
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      flexDirection="column"
                      width={"100%"}
                    >
                      <label className={classes.label}>Branch Name</label>
                      <input
                        type="text"
                        className={classes.inputBox}
                        disabled
                        {...register("branchName")}
                      />
                    </Box>
                  </Box>
                  <Box my={1}>
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      flexDirection="column"
                      width={"100%"}
                    >
                      <label className={classes.label}>Branch State</label>
                      <input
                        type="text"
                        className={classes.inputBox}
                        disabled
                        {...register("branchState")}
                      />
                    </Box>
                  </Box>
                  <Box my={1}>
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      flexDirection="column"
                      width={"100%"}
                    >
                      <label className={classes.label}>Branch Address</label>
                      <input
                        type="text"
                        className={classes.inputBox}
                        disabled
                        {...register("branchAddress")}
                      />
                    </Box>
                  </Box> */}

                  {error && <ErrorMessage message={apiError || "Something wrong happened"} />}
                  <Box mt={3}>
                    <button className={classes.primaryButton} type="submit" disabled={loading}>
                      {loading ? (
                        <CircularProgress size="1rem" color="inherit" />
                      ) : (
                        "Next"
                      )}
                    </button>
                  </Box>
                </form>
              </div>
            )}
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default Step9;
