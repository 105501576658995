import React, { useState, useEffect } from 'react'
import { Box, Typography, makeStyles, Grid, Slider, } from "@material-ui/core";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList

} from "recharts";

import httpInstance from '../../utils/instance'
import { GET_HOLDINGS, GET_FUND_PERFORMANCE_DATA } from '../../utils/endpoints';


const FundPerformance = ({ schemeCode, fundData }) => {
    const useStyles = makeStyles((theme) => ({

        // graphButtonInactive: {
        //   border: "none",
        //   background: "none",
        //   padding: "5px",
        //   borderRadius: "10px",
        //   fontSize: "0.7rem",
        //   cursor: "pointer",
        //   outline: "none",
        //   "&:hover": {
        //     background: "#6FDDB8",
        //     color: "white",
        //     padding: "5px",
        //   },
        //   "&:active": {
        //     background: "#6FDDB8",
        //     color: "white"
        //   }
        // },

        // activeButton: {
        //   background: "#6FDDB8",
        //   color: "white",
        //   padding: "5px",
        //   border: "none",
        //   borderRadius: "10px",
        //   fontSize: "0.7rem",
        //   cursor: "pointer",
        //   outline: "none",
        // },

        root: {
            marginTop: "1rem",
            borderRadius: "12px",
            // boxShadow: "0 0 8px -3px #444444",
            border: '1px solid #E4E4E4',
            // boxShadow: '0 0 8px -3px #444444',
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
            padding: "1rem",
        },
        title: {
            fontWeight: 600,
            // padding: "0px 8rem",
            fontSize: "1.2rem"
        },

        borderBox: {
            border: "1px solid #E4E4E4",
            borderRadius: "5px",
            padding: "1.5rem",
            width: "75%",
            '& .MuiSlider-valueLabel': {
                '& > *': {

                    backgroundColor: 'white',
                    color: "black"
                },
            }
        },
        ButtonInactive: {
            border: "none",
            background: "none",
            padding: "5px 20px",
            borderRadius: "20px",
            fontSize: "0.75rem",
            fontFamily: "inherit",
            marginRight: "1rem",
            marginLeft: "1rem",
            cursor: "pointer",
            outline: "none",
            "&:hover": {
                background: "#0E76BC",
                color: "white",

            },
            "&:active": {
                background: "#0E76BC",
                color: "white"
            }
        },

        activeButton: {
            background: "#0E76BC",
            color: "white",
            padding: "5px 20px",
            border: "none",
            borderRadius: "20px",
            fontSize: "0.75rem",
            fontFamily: "inherit",
            marginRight: "1rem",
            marginLeft: "1rem",
            cursor: "pointer",
            outline: "none",
        },
        activeYearButton: {
            background: "#0E76BC",
            color: "white",
            padding: "5px 20px",
            border: "none",
            borderRadius: "20px",
            fontSize: "0.7rem",
            fontWeight: 700,
            fontFamily: "inherit",
            marginRight: "0.5rem",
            marginLeft: "0.5rem",
            cursor: "pointer",
            outline: "none",

        },

        inactiveYearButton: {
            border: "1px solid #0E76BC ",
            background: "none",
            padding: "5px 20px",
            borderRadius: "20px",
            fontSize: "0.7rem",
            fontWeight: 700,
            fontFamily: "inherit",
            marginRight: "0.5rem",
            marginLeft: "0.5rem",
            color: "#0E76BC",
            cursor: "pointer",
            outline: "none",
            "&:hover": {
                background: "#0E76BC",
                color: "white",

            },
            "&:active": {
                background: "#0E76BC",
                color: "white"
            }
        },

        text: {
            fontSize: "0.8rem"
        },

        // title: {
        //   fontSize: "0.9rem",
        //   marginTop: "1rem"
        // },

        thumb: {
            background: "#F3C04F",
            width: "15px",
            height: "15px",
            boxShadow: 'none !important',
            ' &.Mui-focusVisible': {
                boxShadow: 'none !important',
            },
            '&.Mui-active': {
                boxShadow: 'none !important',
            },

        },
        mark: {
            background: "black"
        },
        rail: {
            background: "#FFE3A4",
            height: "5px"
        },
        track: {
            background: "#F3C04F",
            height: "5px"
        },
        rupees: {
            fontFamily: "Open Sans"
        },
        "@media (max-width: 600px)": {
            activeButton: {
                marginRight: "0rem",
            },
            ButtonInactive: {
                marginRight: "0rem",
            },
            activeYearButton: {
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
                padding: "2px 10px",
            },
            inactiveYearButton: {
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
                padding: "2px 10px",
            }

        },
        "@media (max-width: 400px)": {

            activeYearButton: {
                marginRight: "0.3rem",
                marginLeft: "0.3rem",
                padding: "1px 5px",
            },
            inactiveYearButton: {
                marginRight: "0.3rem",
                marginLeft: "0.3rem",
                padding: "1px 5px",
            }

        }

    }))
    const classes = useStyles();

    const [button, setButton] = useState('sip')
    const [yearButton, setYearButton] = useState('1Y')
    const [totalReturn, setTotalReturn] = useState()
    const [invest, setInvest] = useState(5000)

    const [sliderVlaueOnChangeEnd, setSliderVlaueOnChangeEnd] = useState(5000)
    const [calculatedThisFund, setCalculatedThisFund] = useState(null);
    const [calculatedProfit, setCalculatedProfit] = useState(null)
    const [benchMarkData, setBenchMarkData] = useState(null)

    const [barChartData, setBarChartData] = useState([
        {
            name: "Bechmark",
            value: 0,
            fill: '#1f77b4',
            // amt: 2400,
        },
        {
            name: 'This Fund',
            value: 0,
            fill: '#ff7f0e'
            // amt: 2210,
        },

    ])


    // const CustomBarLabel = ({ fill, ...props }) => (
    //     <g transform={`translate(${props.x},${props.y})`}>
    //         <text fill={fill} fontSize={14} textAnchor="start" dominantBaseline="start"
    //         // dy={0} dx={0} 
    //         >
    //             ₹{props.value.toFixed(2)}
    //         </text>
    //     </g>
    // );

    const CustomizedLabel = ({ props }) => (<text
        x={props.x}
        y={props.y}

        fontSize='16'
        fontFamily='sans-serif'
        fill={props.fill}
        textAnchor="start">{props.value}%</text>

    );

    const CustomizedLabels = ({ x, y, value }) => {
        // console.log(value)
        return (
            // <>
            // </>
            <text x={x} y={y} dx={10} textAnchor="middle" fill="#fff">
                {value}
            </text>
        )
    };
    const CustomizedLabelsss = ({ x, y, fill, value }) => (
        <text
            x={x}
            y={y}
            fontSize='16'
            fontFamily='sans-serif'
            fill={'red'}
            textAnchor="start">{value}%
        </text>
    );

    const get_holding = async () => {
        // console.log(schemeCode)
        // const formData={
        //     scheme_code:schemeCode,
        //     as_on:"",
        //     portfolio_type:''
        // }
        const httpConfig = {
            method: "GET",
            tokenType: "user",
            // test: 'test',
            // headers:{
            //   "Content-Type": "application/json",
            // },
            // data:formData,
        };
        try {
            const response = await httpInstance(`${GET_FUND_PERFORMANCE_DATA}`, httpConfig)
            setBenchMarkData(response.data?.data)
            // console.log(response.data?.data)
        }
        catch {

        }
    }
    // console.log(holdingData)
    useEffect(() => {
        get_holding()
    }, [])


    useEffect(() => {
        if (benchMarkData && fundData) {
            calculatingBarGraphValue(yearButton)
        }
        // sliderAmountChangeIntheReturnCalculator(sliderVlaueOnChangeEnd, button)
    }, [fundData, benchMarkData, yearButton])



    // Calculate Profit aboslute return
    const calculatingBarGraphValue = (value) => {
        // console.log(value)
        // console.log(fundData?.fundRet1month)
        let selectedFundData;
        switch (value) {
            case '1M':
                selectedFundData = [
                    {
                        name: 'Bechmark',
                        value: benchMarkData?.fundRet1month?.toFixed(2),
                        fill: '#1f77b4',
                        // amt: 2210,
                    },
                    {
                        name: 'This Fund',
                        value: fundData?.fundRet1month?.toFixed(2),
                        fill: '#ff7f0e',
                        // amt: 2290,
                    },
                ]
                // returns.fund_1_year;
                break;
            case '3M':
                selectedFundData = [
                    {
                        name: 'Bechmark',
                        value: benchMarkData?.fundRet3month?.toFixed(2),
                        fill: '#1f77b4',
                        // amt: 2210,
                    },
                    {
                        name: 'This Fund',
                        value: fundData?.fundRet3month?.toFixed(2),
                        fill: '#ff7f0e',
                        // amt: 2290,
                    },
                ];
                break;
            case '6M':
                selectedFundData = selectedFundData = [
                    {
                        name: 'Bechmark',
                        value: benchMarkData?.fundRet6month?.toFixed(2),
                        fill: '#1f77b4',
                        // amt: 2210,
                    },
                    {
                        name: 'This Fund',
                        value: fundData?.fundRet6month?.toFixed(2),
                        fill: '#ff7f0e',
                        // amt: 2290,
                    },
                ];
                break;
            case '1Y':
                selectedFundData = [
                    {
                        name: 'Bechmark',
                        value: benchMarkData?.fundRet1year?.toFixed(2),
                        fill: '#1f77b4',
                        // amt: 2210,
                    },
                    {
                        name: 'This Fund',
                        value: fundData?.fundRet1year?.toFixed(2),
                        fill: '#ff7f0e',
                        // amt: 2290,
                    },
                ]
                // returns.fund_1_year;
                break;
            case '3Y':
                selectedFundData = [
                    {
                        name: 'Bechmark',
                        value: benchMarkData?.fundRet3year?.toFixed(2),
                        fill: '#1f77b4',
                        // amt: 2210,
                    },
                    {
                        name: 'This Fund',
                        value: fundData?.fundRet3year?.toFixed(2),
                        fill: '#ff7f0e',
                        // amt: 2290,
                    },
                ];
                break;
            case '5Y':
                selectedFundData = selectedFundData = [
                    {
                        name: 'Bechmark',
                        value: benchMarkData?.fundRet5year?.toFixed(2),
                        fill: '#1f77b4',
                        // amt: 2210,
                    },
                    {
                        name: 'This Fund',
                        value: fundData?.fundRet5year?.toFixed(2),
                        fill: '#ff7f0e',
                        // amt: 2290,
                    },
                ];
                break;
            case 'ALL':
                selectedFundData = selectedFundData = [
                    {
                        name: 'Bechmark',
                        value: benchMarkData?.fundRetSinceLaunch?.toFixed(2),
                        fill: '#1f77b4',
                        // amt: 2210,
                    },
                    {
                        name: 'This Fund',
                        value: fundData?.fundRetSinceLaunch?.toFixed(2),
                        fill: '#ff7f0e',
                        // amt: 2290,
                    },
                ];
                break;
            default:
                console.log("Invalid index. Please provide a valid index (1, 2, or 3).");
                return []; // Return null or handle the error case appropriately
        }

        // Now you can use 'selectedFundData' as needed
        // console.log(selectedFundData);
        setBarChartData(selectedFundData)

    }
    console.log(benchMarkData, fundData)


    const handleButton = (type) => {
        setButton(type)
    }

    const handleYearButton = (type) => {
        setYearButton(type)

    }



    const renderCustomizedLabel = (props) => {
        const { x, y, width, height, value } = props;
        // const formattedValue = (value * 100).toFixed(2); // Convert to percentage with 2 decimal places
        // const isInside = formattedValue.length < 5; // Determine label position

        // Adjust label position based on value length
        // const offset = isInside ? -40 : 5;
        // console.log(y , x, x + value)


        return (
            // <text x={x + width - offset} y={y + height - 10 } fill={isInside ? '#285A64' : '#000'} textAnchor="middle">
            //     {value}%
            // </text>
            <text x={width ? width + (window.innerWidth > 600 ? 25 : 20) + x : 20 + x} y={y + (height / 2) + (window.innerWidth > 600 ? 4 : 2)} fill={'#000'} textAnchor="middle" fontSize={window.innerWidth > 600 ? 12 : 10}>
                {value ? value : 0}%
            </text>
        );
    };


    return (
        <div className={classes.root}>
            <Typography className={classes.title}>Fund Performance</Typography>
            {/* <Box className={classes.borderBox} mt={3}> */}
            <Box px={{ xs: 2, md: 5 }} >

                <Box display='flex' justifyContent='center'>
                    {/* <ResponsiveContainer
                        width={600} height={120}
                    >
                        <BarChart
                            data={barChartData}
                            layout="vertical"
                            barCategoryGap="10%"
                            width={600} height={150}
                            margin={{ top: 20, right: 400, left: 0, bottom: 0 }}
                        > */}
                    <ResponsiveContainer
                        width="100%" height={120}
                        maxWidth={window.innerWidth > 600 ? 600 : "90%"} // Sets a max width based on screen size

                    >
                        <BarChart
                            data={barChartData}
                            layout="vertical"
                            barCategoryGap="10%"
                            width={600} height={150}
                            margin={{ top: 20, right: 20, left: 20, bottom: 0 }}
                        >
                            <XAxis type="number" hide tick={{ fontSize: 12 }} width={50}
                                domain={['auto', 'auto']} // Allows automatic scaling for positive and negative values

                            />
                            <YAxis type="category"
                                // width={100} 
                                padding={{ left: 20 }} dataKey="name"
                                // tick={{ fontSize: 12 }}  // Set the font size for X-axis ticks
                                label={{ fontSize: 14 }}
                                width={window.innerWidth > 600 ? 100 : 50} // Adjust width based on screen size
                                tick={{ fontSize: window.innerWidth > 600 ? 12 : 10 }}
                            />
                            {/* <Tooltip /> */}
                            <Bar
                                dataKey="value"
                                fill="#1"
                                // barSize={25}
                                barSize={window.innerWidth > 600 ? 25 : 15} // Adjust bar size based on screen size

                                radius={[0, 5, 5, 0]}
                            >
                                {/* <LabelList
                                    dataKey="value"
                                    position="right"
                                    fill="#000000"
                                    textAnchor="middle"
                                    dx={10}
                                // values=''
                                /> */}
                                <LabelList dataKey="value" content={renderCustomizedLabel} position="right"
                                    // style={{ fill: 'red' }}
                                    style={{ fill: 'black', fontSize: window.innerWidth > 600 ? 14 : 10 }}

                                />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </Box>

            </Box>
            <Box mt={3} display='flex' justifyContent='center'>
                <button className={yearButton == "1M" ? classes.activeYearButton : classes.inactiveYearButton} onClick={() => handleYearButton("1M")}>1 M</button>
                <button className={yearButton == "3M" ? classes.activeYearButton : classes.inactiveYearButton} onClick={() => handleYearButton("3M")}>3 M</button>
                <button className={yearButton == "6M" ? classes.activeYearButton : classes.inactiveYearButton} onClick={() => handleYearButton("6M")}>6 M</button>
                <button className={yearButton == "1Y" ? classes.activeYearButton : classes.inactiveYearButton} onClick={() => handleYearButton("1Y")}>1 Y</button>
                <button className={yearButton == "3Y" ? classes.activeYearButton : classes.inactiveYearButton} onClick={() => handleYearButton("3Y")}>3 Y</button>
                <button className={yearButton == "5Y" ? classes.activeYearButton : classes.inactiveYearButton} onClick={() => handleYearButton("5Y")}>5 Y</button>
                <button className={yearButton == "ALL" ? classes.activeYearButton : classes.inactiveYearButton} onClick={() => handleYearButton("ALL")}>ALL</button>

                {/* <button className={yearButton == "5" ? classes.activeYearButton : classes.inactiveYearButton} onClick={() => handleYearButton("5")}>5 Year</button>
                    <button className={yearButton == "5" ? classes.activeYearButton : classes.inactiveYearButton} onClick={() => handleYearButton("5")}>5 Year</button> */}

            </Box>
        </div>
    )
}

export default FundPerformance